import { Component, OnInit } from '@angular/core';
import { ProductosService, datosGuardar} from '../../services/productos.service';
import Swal from 'sweetalert2';
import { Router} from '@angular/router';

@Component({
  selector: 'app-guardados',
  templateUrl: './guardados.component.html',
  styleUrls: ['./guardados.component.scss']
})
export class GuardadosComponent implements OnInit {

   token: any; 
   productos: any;
   totalg = 0;

  datosGuardar: datosGuardar ={

    guardId: 0,
    pieId: 0,
    userId: 0
   // guardFecha: ''
  
  }

  constructor(private _productosService: ProductosService, private _router : Router) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('token'); 
    this.getGuardados(); 
  }

getGuardados(){
  this._productosService.getGuardados().subscribe(
    res =>{
       this.productos=res;
       this.totalg = this.productos.length ;
      },err=>{
        //console.log(err);
        if (err.status == 401) {
          // window.localStorage.removeItem('token');
          this._router.navigateByUrl('/Relogin')
        }
    })
}

postGuardar(item: any){
  this.datosGuardar.pieId = item.pieId;
  this._productosService.postGuardar(this.datosGuardar).subscribe(
    res =>{ 
     },err =>{
       if(err.status == 200){
          Swal.fire(
          'Deleted',
          'Your part has been deleted.',
          'success'
          )
          window.location.reload();
          }
          if (err.status == 401) {
            // window.localStorage.removeItem('token');
            this._router.navigateByUrl('/Relogin')
          }
         console.log(err);
      }
   )}

}
