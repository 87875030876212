import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService, DatosLogin } from '../../services/usuario.service';
import { getMessaging, getToken } from 'firebase/messaging';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FireBaseCredencials } from '../../helpers/Util';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loading = false;
  usuId: any;
  empID: any;
  msg = getMessaging();

  credentials: DatosLogin = {

    Usuario: '',
    Password: ''

  };
  constructor(private auth: UsuarioService, private router: Router) { }

  ngOnInit(): void {
  }

  login() {
    this.loading = true;
    //check if usuIs exist to logout the old user
    if (localStorage.getItem('usuId')) {
      this.logout();
    }
    this.auth.login(this.credentials).subscribe(res => {

      localStorage.setItem('usuId', res.usuId);
      localStorage.setItem('usuSesion', res.usuSesion);
      localStorage.setItem('usuFoto', res.usuFoto);
      localStorage.setItem('token', res.token);
      if (res.empID != 0) {
        localStorage.setItem('empID', res.empID);
      }

      //try to register topics to firebase
      getToken(this.msg, { vapidKey: FireBaseCredencials.vapidKeyToken }).then((tok) => {

        //check if a Company and register topic to empId
        if (res.empID != 0) {
          localStorage.setItem('empID', res.empID);
          fetch('https://iid.googleapis.com/iid/v1/' + tok + '/rel/topics/emp' + res.empID, {
            method: 'POST',
            headers: new Headers({
              'Authorization': FireBaseCredencials.vpauth
            })
          }).then(async (response) => {
            if (response.status == 200) {
              console.log("Company has been suscribed")
            }
            else {
              var ress = await response.json();
              throw 'Error subscribing to topic emp: ' + response.status + ' - ' +  await response.json();
            }
          }).catch(error => {
            console.error(error);
          })
        }

        //register topic to usuId
        fetch('https://iid.googleapis.com/iid/v1/' + tok + '/rel/topics/' + res.usuId, {
          method: 'POST',
          headers: new Headers({
            'Authorization': FireBaseCredencials.vpauth
          })
        }).then(async (response) => {

          if (response.status == 200) {
            console.log("User has been suscribed")
          }
          else {
            console.log(response);
            throw 'Error subscribing to topic Usu: ' + response.status + ' - ' + await response.json();
          }

        }).catch(error => {
          console.error(error);
        })
      })

      this.router.navigateByUrl('/');
    }, (err) => {
      this.loading = false;
      console.error(err);
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: err.error,
        showConfirmButton: false,
        timer: 1500
      })
    });
  }


  logout(): void {

    try {

      //first try to remove usuId topic
      getToken(this.msg, { vapidKey: FireBaseCredencials.vapidKeyToken }).then((tok) => {
        this.usuId = localStorage.getItem('usuId');
        fetch('https://iid.googleapis.com/iid/v1/' + tok + '/rel/topics/' + this.usuId, {
          method: 'DELETE',
          headers: new Headers({
            'Authorization': FireBaseCredencials.vpauth
          })
        }).then(async (resUsu) => {
          if (resUsu.status == 200) {
            console.log("TopicUser Unsubscribed");
          } else {
            throw 'Error Unsubscribing to Usu: ' + resUsu.status + ' - ' + await resUsu.json();
          }
        }).catch(error => {
          console.error(error);
        })

        //check if is a company and remove empId topic
        if (localStorage.getItem('empID')) {

          this.empID = localStorage.getItem('empID');
          fetch('https://iid.googleapis.com/iid/v1/' + tok + '/rel/topics/emp' + this.empID, {
            method: 'DELETE',
            headers: new Headers({
              'Authorization': FireBaseCredencials.vpauth
            })
          }).then(async (resEmp) => {
            if (resEmp.status == 200) {
              console.log("TopicEmp Unsubscribed");
            }
            else {
              throw 'Error Unsubscribing to Emp: ' + resEmp.status + ' - ' + await resEmp.json();
            }
          }).catch(error => {
            console.error(error);
          })

        }
      })

    } catch (error) {

      console.error(error);
    }

    window.localStorage.removeItem('usuId');
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('usuSesion');
    window.localStorage.removeItem('usuFoto');
    window.localStorage.removeItem('firebaseToken');
    window.localStorage.removeItem('empID');

  }

}
