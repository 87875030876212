<app-navbar></app-navbar>
<div id="content">
  <div style="position: relative;" class="d-flex mt-5 border rounded-3">
    <img src="../../../assets/img/banner.jpg" class="fit-bg border rounded-3">
    <div class="container p-3">
      <p class="lead p-5"></p>
      <div class="card p-2 shadow-sm" style="opacity: 0.9;  background-color: rgb(255, 255, 255);">
        <div class="d-flex align-items-center">
          <div class="image px-2">
            <img class="rounded-circle border border-primary fit-image" src="{{usuFoto}}">
          </div>

          <div class="ml-3 w-100">
            <div class="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white ">
              <div class="d-flex flex-column" style="font-size: 30px;">
                <span class="followers">
                  <i class="fa fa-user-o"></i> {{usuSesion}} |
                  <span style="font-size: 30px;">Code:{{UsuId}}</span>
                </span>
                <span class="number2"></span>
              </div>
              <div class="d-flex flex-column"> <span class="followers">
                  <button [routerLink]="['/editprofile']" type="button"
                    class="btn btn-outline-dark mt-1 text-white ">Edit
                    <i class="fa fa-edit"></i>
                  </button>
                </span> <span class="number2"></span>
              </div>
            </div>
          </div>



        </div>
      </div>
    </div>
  </div>

  <div class="row m5-5 mt-5">
    <h1 class="text-primary">Profile <i class="fa fa-tachometer"></i></h1>
  </div>
  <hr>
  <div class="row p-5">
    <div class="col-4">
      <div class="card border-info mb-3" style="max-width: 18rem; background-image: url();">
        <div class="card-header">Total Sale</div>
        <div class="card-body text-center">
          <h1 class="card-title"> $ 0.00</h1>
          <p class="card-text"></p>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card border-warning mb-3" style="max-width: 18rem;">
        <div class="card-header">Total parts</div>
        <div class="card-body text-center"
          style="background-image: url(/src/assets/img/box.jpeg); background-position: center;">
          <h1 class="card-title"> {{totalPiezas | number}}</h1>
          <p class="card-text"></p>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card border-success mb-3" style="max-width: 18rem;">
        <div class="card-header">Parts saveds</div>
        <div class="card-body text-center">
          <h1 class="card-title">{{cantGuard}}</h1>
          <p class="card-text"></p>
        </div>
      </div>
    </div>
    <div class="row p-5">
      <div class="d-flex flex-row bd-highlight mb-3">
        <div class="p-2 bd-highlight">
          <h2 class="text-primary">My parts</h2>
        </div>
        <div class="p-2 bd-highlight"> <button type="button" class="btn btn-outline-primary btn-lg"
            [routerLink]="['/addpieza']">add <i class="fa fa-plus"></i></button></div>
        <div class="p-2 bd-highlight"> <button type="button" class="btn btn-outline-primary btn-lg"
            data-bs-toggle="collapse" data-bs-target="#collapseFilter" aria-expanded="false"
            aria-controls="collapseFilter">Filter <i class="fa fa-filter" aria-hidden="true"></i></button></div>
      </div>
      <div class="collapse" id="collapseFilter">
        <div class="card card-body">
          <form class="row g-3">
            <div class="col-4">
              <label class="ms-2">Part</label>
              <input type="text" class="form-control" id="namepart" placeholder="Part name">
            </div>
            <!-- <div class="col-auto">
              <label for="inputPassword2" class="visually-hidden">Maincar</label>
              <input type="number" class="form-control" id="maincar" value="0" placeholder="ID Maincar">
            </div> -->
            <div class="col-auto">
              <label class="me-2">Status</label>
              <select class="form-select" aria-label="Default select example" id="status">
                <option value="0" selected>Status of part</option>
                <option value="1">Not Posted</option>
                <option value="2">In Edition</option>
                <option value="3">published</option>
                <option value="4">Sold Out</option>
              </select>
            </div>
            <div class="col-4 col-sm-4 col-md-2 col-lg-2 nav-item mt-3">
              <h6 class="text-white">Model</h6>

              <button type="submit" (click)="handlePageChange(1)" class="btn btn-primary mb-3 text-white">Seach</button>
            </div>

          </form>
        </div>
      </div>
      <div *ngIf="cargar; else elseBlock" class="row p-5">
        <div class="d-flex justify-content-center">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>

      <div *ngIf="count == 0" class="p-2 px-0 d-flex justify-content-center">
        <div class="d-flex flex-column justify-content-center">
          <h3 class="d-flex justify-content-center">Data not found.</h3>
          <img class="d-flex justify-content-center" src="../../../assets/img/phvp.png" width="200" height="200" alt="">
        </div>
      </div>

      <ng-template #elseBlock>
        <table class="table table-hover">
          <thead class="text-primary text-center">
            <tr>
              <th scope="col">Image</th>
              <th scope="col">Name</th>
              <th scope="col">Condition</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody class="text-center">
            <tr
              *ngFor="let producto of productos| paginate : { itemsPerPage: pageSize, currentPage: page, totalItems: count  }; let i = index">
              <td><img src="{{producto.imagen1}}" class="fit-imagetb"></td>
              <td class="p-3">{{producto.pieNombre}}</td>
              <td class="p-3">
                <ng-container [ngSwitch]="producto.pieEstado">
                  <ng-container *ngSwitchCase="1">
                    <span class="badge rounded-pill bg-danger">{{producto.estadoString}}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="2">
                    <span class="badge rounded-pill bg-warning">{{producto.estadoString}}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="3">
                    <span class="badge rounded-pill bg-primary">{{producto.estadoString}}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="4">
                    <span class="badge rounded-pill bg-secondary">{{producto.estadoString}}</span>
                  </ng-container>
                </ng-container>
              </td>
              <!-- <td class="p-3"><a [routerLink]="['/producto', producto.pieId]" class="btn bg-info text-white">View  <i class="fa fa-eye"></i></a>  |  <a class="btn btn-danger text-white" [routerLink]="['/editpieza', producto.pieId]">Edit <i class="fa fa-edit"></i></a> </td> -->
              <td class="p-3"><a [routerLink]="['/producto', producto.pieId]" class="btn bg-info text-white">View <i
                    class="fa fa-eye"></i></a> | <a class="btn btn-danger text-white" (click)="irEditar(producto)">Edit
                  <i class="fa fa-edit"></i></a> </td>
            </tr>
          </tbody>
        </table>
      </ng-template>
    </div>
    <style>
      .my-pagination /deep/ .ngx-pagination .current {
        background: #008c44;
      }
    </style>
    <div class="col-12 mt-3 d-flex justify-content-center">
      <pagination-controls class="my-pagination" previousLabel="Prev" nextLabel="Next" [responsive]="true"
        (pageChange)="handlePageChange($event)">
      </pagination-controls>
    </div>
  </div>
</div>