<app-navbar></app-navbar>
<div class="wrapper mt-5">
  <nav id="sidebar">
    <div class="sidebar-header">
      <img class="fit-image rounded-circle border border-primary" src="{{usuario.usuFoto}}" alt="">
      <input resize-quality="0.7" resize-type="image/png,image/jpeg" id="fileInput" name="fileInput"
        style="height:0px;overflow:hidden" type="file" accept="image/png,image/jpeg" (change)="readUrl($event)">
      <div *ngIf="loading" class="card-img-overlay text-center">
        <span class="spinner-border" style="width: 3rem; height: 3rem;" role="status" aria-hidden="true"></span>
      </div>
    </div>
    <ul class="list-unstyled CTAs text-center">
      <li>
        <button (click)="chooseFile()" class="btn btn-primary btn-lg text-white" [disabled]="loading"><i
            class="fa fa-image"></i> Update Photo <span *ngIf="loading" class="spinner-border spinner-border-sm"
            role="status" aria-hidden="true"></span></button>
      </li>
      <hr>
      <li>
        <button class="btn btn-primary btn-lg text-white" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i
            class="fa fa-unlock-alt"></i> Change password</button>
      </li>
    </ul>
  </nav>
  <div id="content">
    <div class="row ml-4">
      <h1 class="text-primary">Edit Info <i class="fa fa-user"></i></h1>
    </div>
    <hr>
    <form>
      <div class="row mb-4">
        <div class="col-md-4">
          <label class="form-label">User *</label>
          <input type="text" id="usuSesion" value="{{usuario.usuSesion}}" class="form-control" disabled />
        </div>
        <div class="col-md-4">
          <label class="form-label">Names *</label>
          <input type="text" id="usuNombres" value="{{usuario.usuNombres}}" class="form-control" />
        </div>
        <div class="col-md-4">
          <label class="form-label">Last names *</label>
          <input type="text" id="usuApellidos" value="{{usuario.usuApellidos}}" class="form-control" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="form-label">Email *</label>
          <input type="text" id="usuEmail" value="{{usuario.usuEmail}}" class="form-control" disabled />
        </div>
      </div>
      <h1 class="text-primary mt-5">Address Bill</h1>
      <hr>
      <div class="row">
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label">Address *</label>
          <input type="text" id="Streetbill" value="{{usuario.usuDireccionBill}}" class="form-control" />
        </div>
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label">Address 2</label>
          <input type="text" id="Streetbill2" value="{{usuario.usuDireccion2Bill}}" class="form-control" />
        </div>
        <div class="col-md-3 mt-3">
          <label for="inputEmail4" class="form-label">Phone *</label>
          <input type="text" id="Phonebill" value="{{usuario.usuTelefonoBill}}" class="form-control" />
        </div>


        <div class="col-md-3 mt-3">
          <label for="inputEmail4" class="form-label">Postal/Zip code *</label>
          <input type="text" id="Zipbill" value="{{usuario.usuZipCodeBill}}" class="form-control" />
        </div>
        <div class="col-md-3 mt-3">
          <label for="inputEmail4" class="form-label">State *</label>
          <input type="text" id="Cityshipp" value="{{usuario.usuStateBill}}" class="form-control" />
        </div>

        <div class="col-md-3 mt-3">
          <label for="inputEmail4" class="form-label">City *</label>
          <input type="text" id="Citybill" value="{{usuario.usuCiudadBill}}" class="form-control" />
        </div>
      </div>
      <h1 class="text-primary mt-5">Address Shipping</h1>
      <hr>
      <div class="row">
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label">Address *</label>
          <input type="text" id="Streetshipp" value="{{usuario.usuDireccionShip}}" class="form-control" />
        </div>
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label">Address 2</label>
          <input type="text" id="Streetshipp2" value="{{usuario.usuDireccion2Ship}}" class="form-control" />
        </div>
        <div class="col-md-3 mt-3">
          <label for="inputEmail4" class="form-label">Phone *</label>
          <input type="text" id="Phoneshipp" value="{{usuario.ususTelefonoShip}}" class="form-control" />
        </div>

        <div class="col-md-3 mt-3">
          <label for="inputEmail4" class="form-label">Postal/Zip code *</label>
          <input type="text" id="Zipshipp" value="{{usuario.usuZipCodeShip}}" class="form-control" />
        </div>
        <div class="col-md-3 mt-3">
          <label for="inputEmail4" class="form-label">State *</label>
          <input type="text" id="Cityshipp" value="{{usuario.usuStateShip}}" class="form-control" />
        </div>
        <div class="col-md-3 mt-3">
          <label for="inputEmail4" class="form-label">City *</label>
          <input type="text" id="Cityshipp" value="{{usuario.usuCiudadShip}}" class="form-control" />
        </div>
      </div>

      <div class="d-flex flex-row-reverse bd-highlight p-5">
        <div class="p-2 bd-highlight"><button type="submit" class="btn btn-primary btn-lg text-white"
            (click)="updateUser()"><i class="fa fa-upload"></i> | Update <span *ngIf="loading"
              class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button></div>
        <div class="p-2 bd-highlight"><button type="button" class="btn btn-danger btn-lg text-white"
            [routerLink]="['/profile']">Cancel</button></div>
      </div>
    </form>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Change password</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row mb-4">
          <div class="col-md-12">
            <label for="inputEmail4" class="form-label">Actual pass</label>
            <input type="password" id="Actual" class="form-control" />
          </div>
          <div class="col-md-12">
            <label for="inputEmail4" class="form-label">New Pass</label>
            <input type="password" id="New" class="form-control" />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary text-white" (click)="changePass()">Change <span *ngIf="loading"
            class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
      </div>
    </div>
  </div>
</div>