<app-navbar></app-navbar>
<div class="bg-gray">

    <div class="PaddingDiv">
        <nav class="d-flex justify-content-between" style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
            aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/Dashboard">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page">Invoices </li>
            </ol>

            <h4>Total: {{total | currency}}</h4>
        </nav>

        <div class="d-flex flex-row">
            <div class="input-group mb-3">
                <span class="input-group-text">From</span>
                <input [(ngModel)]="dateFrom" class="form-control" type="date" />
            </div>

            <div class="input-group mb-3 mx-2">
                <span class="input-group-text">To</span>
                <input [(ngModel)]="dateTo" class="form-control" type="date" />
            </div>

            <button (click)="getInvoce()" class="mb-3 btn btn-custon text-white"> Search </button>
        </div>



        <div *ngIf="loading; else cont" class="row p-5">
            <div class="d-flex justify-content-center">
                <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
        <ng-template #cont>
            <div *ngIf="noData;else invoices" class="row p-5">
                <div class="d-flex justify-content-center">
                    <h3>
                        <span> oh! You have no invoices.</span>
                    </h3>
                    <img src="../../../assets/img/phvp.png" width="500" height="500" alt="">
                </div>
            </div>
            <ng-template #invoices>
                <div class="overflow-hidden rounded bg-white shadow pt-4">

                    <div class="d-flex justify-content-between">
                        <h4 class="Padding-Left">Graphics</h4>

                        <div class="input-group Padding-Right mb-2" style="width: 200px;">
                            <label class="input-group-text" for="inputGroupSelect01">Type</label>
                            <select class="form-select" id="typeGraf_s" [(ngModel)]="typeGraf" (change)="UpdateGraf()">
                                <option selected value="0">Days</option>
                                <option value="1">Months</option>
                                <option value="2">Years</option>
                            </select>
                        </div>
                    </div>

                    <div id="chartDonut" class="chart-size">
                        <apx-chart [series]="ChartOptions.series" [chart]="ChartOptions.chart"
                            [dataLabels]="ChartOptions.dataLabels" [plotOptions]="ChartOptions.plotOptions"
                            [yaxis]="ChartOptions.yaxis" [xaxis]="ChartOptions.xaxis" [fill]="ChartOptions.fill"
                            [title]="ChartOptions.title"></apx-chart>
                    </div>

                </div>

                <div>
                    <h4 class="mt-4 mb-3">Details</h4>
                </div>
                <div class="overflow-hidden rounded bg-white shadow">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th class="textHead Padding-Left" scope="col"># Invoice</th>
                                <th class="textHead" scope="col">Total</th>
                                <th class="textHead" scope="col">Date</th>
                                <th class="textHead toMovil" scope="col">Customer</th>
                                <th class="textHead" scope="col">From</th>
                                <th class="textHead Padding-Right" scope="col">View</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let factura of facturas; let i = index">
                                <th class="textRow Padding-Left" scope="row">{{factura.facID}}</th>
                                <td class="textRow">{{factura.facTotal | currency}}</td>
                                <td class="textRow">{{factura.facFecha | date}}</td>
                                <td class="textRow toMovil">{{factura.facEmail}}</td>
                                <td class="textRow" scope="col">
                                    <img style="margin-left: 10px;"  src="https://vehiclepart.shop/assets/img/LogovehiclesParts.jpg" height="20"
                                    width="20" alt="..." *ngIf="factura.facTipoFactura == 1">
                                  <img class="my-0 align-middle" src="https://ir.ebaystatic.com/cr/v/c1/ebay-logo-1-1200x630-margin.png"
                                    height="30" width="60" alt="..." *ngIf="factura.facTipoFactura == 2">
                                </td>
                                <td class="textRow Padding-Right"> <button (click)="Reportefactura(factura.facID)"
                                        class="btn btn-custon text-white"> View
                                        <div *ngIf="loadingDetail;" class="spinner-border text-primary" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-template>
        </ng-template>
    </div>


</div>