import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { ProductosService, brrMultipleUse} from '../../../services/productos.service'
import { Router } from '@angular/router';


@Component({
  selector: 'app-multiple-uses',
  templateUrl: './multiple-uses.component.html',
  styleUrls: ['./multiple-uses.component.scss']
})
export class MultipleUsesComponent implements OnInit {
  currentEstados: any[] =[];
  multipleuse: any[] =[];

  tableStatus: any;
  estListo= false;
  loading = false;

  eliminarrMultipleUse: brrMultipleUse = {
    estTabla: '',
    estEstado: 0,
    estDescripcion:'',
    estFechaActualizacion:'',
    usuInicioSesion:''
  };


  myForm: FormGroup;
  constructor(private _fb: FormBuilder, private service: ProductosService,private _router: Router) { }
   
  ngOnInit(): void {
    this.myForm = this._fb.group({
      multipleS: this.currentEstados,
    });
    this.getUsosMultiplesGroup();
  }

  getUsosMultiplesGroup() {
    this.loading = true;
     this.service.getUsosMultiplesGroup().subscribe(
       (res) => {
        this.tableStatus = res;
        this.currentEstados = res.filter((x) => x == res[0])
        this.getUsosMultiples(this.currentEstados[0])
        console.log(res)
       },
       (err) => {
        this.loading = false;
        if(err.error.includes('You do not have permissions to access to ')){
          Swal.fire({
            icon: 'error',
            title:err.error,
            showConfirmButton: true
          }).then(()=>{
            this._router.navigateByUrl('/Dashboard');
          })
         }
         console.error(err.error);
       }
     );
   }

   getUsosMultiples(item: any) {
     this.loading = true;
     console.log(item);
      this.service.getUsosMultiples(item).subscribe(
        (res) => {
          this.loading = false;
         console.log(res)
         this.multipleuse = res;
        },
        (err) => {
          this.loading = false;
          console.error(err.error);
          if(err.error.includes('You do not have permissions to access to ')){
            Swal.fire({
              icon: 'error',
              title:err.error,
              showConfirmButton: true
            }).then(()=>{
              this._router.navigateByUrl('/Dashboard');
            })
           }
        }
      );
    }

    borrarMultipleUse(eliminarrMultipleUse: any) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.service.borrarMultipleUse(eliminarrMultipleUse).subscribe(
            (res) => {
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Your part has been deleted.',
                showConfirmButton: true,
              }).then((result) => {
                window.location.reload();
              });
      
            },
            (err) => {
              console.error(err);
              if(err.error.includes('You do not have permissions to access to ')){
                Swal.fire({
                  icon: 'error',
                  title:err.error,
                  showConfirmButton: true
                }).then(()=>{
                  this._router.navigateByUrl('/Dashboard');
                })
              }else{
                Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: err.error,
                  showConfirmButton: false,
                  timer: 1500,
                });   
              }
            }
          );
        }
      })
  
    }
}
