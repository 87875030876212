import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable} from 'rxjs';
import { Url } from '../ApiUrl/url';


export interface paraMensajes{

  pieId: number,
  grupId: number

}

 export interface sendMensaje{
   
  pieId: number,
  menSecuencia: number,
  menFecha: Date,
  menMensaje: string,
  usuFromId: number,
  usuToId: number,
  grupId: number
  
}

export interface dataCall {

  To: string,
  Title: string,
  Body: string,
  Tipo: string,
  GrupId: string

}

@Injectable({
  providedIn: 'root'
})

export class ChatService {

  public url : string;

  constructor(
    private _http: HttpClient
  ) { 
    this.url = Url.url; 
  }
  
  getMensajes(params: paraMensajes):Observable<any>{
   return this._http.get(this.url + 'Mensaje?PieId=' + params.pieId + '&grupId=' + params.grupId, { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
  }

  getGrupo():Observable<any>{  
    return this._http.get(this.url + 'MensajesGrupo', {headers: { 'Content-Type': 'application/json-patch+json','Authorization': "Bearer " + localStorage.getItem("token") }});
  }
 
  Sedmensaje( params: sendMensaje):Observable<any>{
    return this._http.post(this.url + 'Mensaje',params, { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
  }

   MensajeLeido(params: any):Observable<any>{
    return this._http.post(this.url + 'MensajeLeido',params, {responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token")}});
   }
 
 callVideo(params: dataCall):Observable<any>{
  return this._http.post(this.url + 'Notificaciones',params, { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
 }

}
