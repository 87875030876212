import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../../services/admin.service';
import {ProductosService} from '../../../services/productos.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {

  constructor(
    private _fb: FormBuilder,
    private service: ProductosService,
    private _router: Router,
    private adService: AdminService
  ) { }

  currentStatus :any[] =[];
  currentRol:any[] =[];
  usuarios: any[] =[];
  Status: any[] =[];
  roles: any[] =[];

  loading = false;
  estListo= false;
  myForm: FormGroup;

  ngOnInit(): void {
    if(this.loading == false){
      this.myForm = this._fb.group({
        statusS: this.currentStatus,
        role :  this.currentRol,
    });    
  }

  this.getStatus();
  this.getRoles();
  this.buscarUsuarios();
  }

  getStatus() {
    this.loading = true;
     this.service.getStatus("User").subscribe(
       (res) => {
        this.loading = false;
        console.log(res)
         this.Status = res;
         this.currentStatus = [res[0]];
       },
       (err) => {
         console.error(err.error);
       }
     );
   }

   getRoles() {
    this.loading = true;
     this.adService.getRoles().subscribe(
       (res) => {
        this.loading = false;
        console.log(res)
         this.roles = res;
         //this.currentStatus = [res[0]];
       },
       (err) => {
        this.loading = false;
         console.error(err.error);
       }
     );
   }


   SuspenderUser(user){
    Swal.fire({
      title: 'Do you want to suspend ' + user.usuNombres +'?',
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      denyButtonText: `Yes, Suspend`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isDenied) {
        this.loading = true;
        this.adService.deleteCompaniUsers(user).subscribe(
          (res) => {
            Swal.fire(user.usuNombres+ ' Suspended!', '', 'success').then((th) => {
              location.reload();
            })
          },
          (err) => {
            this.loading = false;
            Swal.fire(err.error, '', 'error')
            console.error(err.error);
            if(err.status == 400){
              Swal.fire({
                icon: 'error',
                title:err.error,
                showConfirmButton: true
              }).then(()=>{
                this._router.navigateByUrl('/Dashboard');
              })
             }
          }
        );
      } 
    })
  }
  
  ReActiveUser(usu){
    Swal.fire({
      title: 'Do you want to reActive ' + usu.usuNombres + '?',
      showDenyButton: false,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Yes ReActive',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.loading = true;
        this.adService.reActiveCompaniUsers(usu).subscribe(
          (res) => {
            Swal.fire(usu.usuNombres+ ' Activated!', '', 'success').then((th) => {
              location.reload();
            })
          },
          (err) => {
            this.loading = false;
            Swal.fire(err.error, '', 'error')
            console.error(err.error);
          }
        );
      } 
    })
  }


   buscarUsuarios() {
    try {
      this.loading = true;
      var statusID, rolID =0
      
      if(this.currentStatus.length == 0){
        statusID = 1;
        this.currentStatus = [this.Status[0]]
      }
      else
      statusID = this.currentStatus[0].estEstado;

      if(this.currentRol.length == 0){
        rolID = 0;
      }
      else
        rolID = this.currentRol[0].rolId;

      this.adService.getUsersCompany((<HTMLOptionElement>document.getElementById('username')).value, statusID,rolID ).subscribe(
        (res) => {
          this.loading = false;
          this.usuarios = res;
          console.log(res)
        },
        (err) => {
          this.loading = false;
          console.error(err);
          if(err.error.includes('You do not have permissions to access to ')){
            Swal.fire({
              icon: 'error',
              title:err.error,
              showConfirmButton: true
            }).then(()=>{
              this._router.navigateByUrl('/Dashboard');
            })
           }else{
              Swal.fire({
              position: 'center',
              icon: 'error',
              title: err.error,
              showConfirmButton: false,
              timer: 1500,
            });          
           }


        }
      );
    } catch (error) {
      this.loading = false;
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: error,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }
}

    
