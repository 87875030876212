export class Carro {

  carId: number;
  marcaId: number;
  modeloId: number;
  añoFabricacion: string;
  carColor: string;
  carColorCode: string;
  carVIN: string;
  carKilometraje: string;
  carVersion: string;
  carCosto: number;
  empId: number;
  carFechaCreacion: Date;
  carFechaActualizacion: Date;
  carStatus: number;
  carModeloMotor: string;
  carCilindros: string;
  carTranmicionInfo: string;
  carUsuID: number;
  usuID: number;
  modeloString: string;
  marcaString: string;
  estadoString: string;
  carNombreCompleto: string;
  fechaFiltro: string;
  carUsuario: string;
  usuario: string;
  imagen1: string;
  carCostoRemolque: number;
  carCostoDesmantelamiento: number;
  carCostoExtra: number;
  carCostoTotal: number;
  carTipo: number;
  carTipoDescripcion: string;
  totalGanancia: number;
  carImagenes: string[];
  defaultsPiezas: any[]

  constructor() {

    this.carId= 0;
    this.marcaId= 0;
    this.modeloId= 0;
    this.añoFabricacion= '';
    this.carColor= '';
    this.carColorCode ='';
    this.carVIN= '';
    this.carKilometraje= '';
    this.carVersion= '';
    this.carCosto= 0;
    this.empId= 0;
    this.carFechaCreacion= new Date();
    this.carFechaActualizacion= new Date();
    this.carStatus= 0;
    this.carModeloMotor= '';
    this.carCilindros= '';
    this.carTranmicionInfo= '';
    this.carUsuID= 0;
    this.usuID= 0;
    this.modeloString= '';
    this.marcaString= '';
    this.estadoString= '';
    this.carNombreCompleto= '';
    this.fechaFiltro= '';
    this.carUsuario= '';
    this.usuario= '';
    this.imagen1= '';
    this.carCostoRemolque= 0;
    this.carCostoDesmantelamiento= 0;
    this.carCostoExtra= 0;
    this.carCostoTotal= 0;
    this.carTipo= 0;
    this.carTipoDescripcion= '';
    this.totalGanancia= 0;
    this.carImagenes= [];
    this.defaultsPiezas= []
  }

}
