import { Component, Input, OnInit } from '@angular/core';
import { ProductosService, brrCarrito, datosPedido } from '../../services/productos.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import * as braintree from 'braintree-web';


@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss']
})
export class PayComponent implements OnInit {

  public productos: any;
  public totalItems: number;
  public subTotal: number;
  public payPalConfig: any;
  public tokenClient: string;
  public UsuId: number;
  public payNone: any; 
  card: FormGroup;
  submitted = false;

  DatosPedido: datosPedido = {
    
    PedID: 0,
    PedUsuID: 0,
    //PedFecha: "2021-12-30T18:32:03.429Z",
    PedEstatus: 0,
    PedTotal: 0,
    PedTotalEnvio: 0,
    PedTotalDescuento: 0,
    PedSubTotal: 0,
    PedTotalImpuesto: 0,
    PedTipoPedido: 0,
    PaymentId: '',
    PaiIDBill: 0,
    UsuCiudadBill: '',
    UsuDireccionBill: '',
    UsuDireccion2Bill: '',
    UsuZipCodeBill: '',
    PaiIDShip: 0,
    UsuCiudadShip: '',
    UsuDireccionShip: '',
    UsuDireccion2Ship: '',
    UsuZipCode2Ship: '',
    UsusTelefonoShip: '',
    //PedFechaActualizacion": "2021-12-30T18:32:03.429Z",
    UsuID: 0

  }
  
  constructor(private formBuilder: FormBuilder, private _productosService: ProductosService, private _router: Router ) { }

  ngOnInit(): void { 
    this.UsuId= Number(localStorage.getItem('usuId')); 
    this.gettokenPaypal();

    this.card = this.formBuilder.group({
      _name: ['', Validators.required],
      number: ['', Validators.required],
      ccv: ['', Validators.required],
      expirationDate: ['', Validators.required]
    });
  }

  get f() { return this.card.controls }

  gettokenPaypal(){
      this._productosService.getTokenpaypal().subscribe(
        res =>{
          this.tokenClient= res
        },err =>{
          console.log(err);
          
        }
      )
      this._productosService.getPedidosTemp().subscribe(
        res =>{ 
          console.log(this.totalItems);
          this.productos = res;
          this.totalItems = this.productos.length;
          this.subTotal = res.reduce((sum, item) => sum + item.pedMontoTotal, 0);
          console.log (this.subTotal);
  
        },
        err =>{
          console.log(err);
        }
      )
  }

  bntCreditcard(){
    this.submitted = true;
    var _name = (<HTMLInputElement>document.getElementById('cc-name')).value;
    var _number = Number((<HTMLInputElement>document.getElementById('cc-number')).value);
    var _ccv = Number((<HTMLInputElement>document.getElementById('ccv-number')).value);
    var _expirationDate = (<HTMLInputElement>document.getElementById('exp-number')).value;

    
  if (this.card.invalid) {

    return;

     }else{
      braintree.client.create({
        authorization: this.tokenClient
      },  (err, client) => {
        client.request({
          endpoint: 'payment_methods/credit_cards',
          method: 'post',
          data: {
            creditCard: {
              number: _number,
              expirationDate: _expirationDate,
              cvv: _ccv,
              billingAddress: {
                postalCode: '12345'
              }
            }
          }
        },  (err, response) => {
            //console.log("card" + response);
            console.log(response.creditCards[0].nonce); 
            localStorage.setItem('paynone',response.creditCards[0].nonce);
            console.log(response.creditCards[0].nonce);
            this.postPedido(response.creditCards[0].nonce);
        });
      });
     }
   }

  bntPaypal(){
    console.error(this.tokenClient);
    window.localStorage.removeItem('paynone');
    var total =  this.subTotal;
    braintree.client.create({
      authorization: this.tokenClient
    },  (clientErr, clientInstance) => {
      if (clientErr) {
        // Handle error in client creation
        return;
      }
    
      braintree.hostedFields.create({
        client: clientInstance,
        fields: { /* ... */ }
      }, function (hostedFieldsErr, hostedFieldsInstance) {
      });
    
      braintree.paypal.create({
        client: clientInstance
      },  (paypalErr, paypalInstance) => {
        paypalInstance.tokenize({
          flow: 'checkout', // Required
          amount: total, // Required
          currency: 'USD' // Required
          // Any other tokenization options
        },  (tokenizeErr, payload) => {
          if (tokenizeErr) {
            // Handle tokenization errors or premature flow closure  
            switch (tokenizeErr.code) {
              case 'PAYPAL_POPUP_CLOSED':
                console.error('Customer closed PayPal popup.');
                break;
              case 'PAYPAL_ACCOUNT_TOKENIZATION_FAILED':
                console.error('PayPal tokenization failed. See details:', tokenizeErr.details);
                break;
              case 'PAYPAL_FLOW_FAILED':
                console.error('Unable to initialize PayPal flow. Are your options correct?', tokenizeErr.details);
                break;
              default:
                console.error('Error!', tokenizeErr);
            }
           
          } else{
            console.log(payload.nonce);
            this.postPedido(payload.nonce);
          }
        })
      });
    });
  }
  
  postPedido(payload: any){
      //console.log("Aquioiiiiiii");
      this.DatosPedido.UsuID = this.UsuId;
      this.DatosPedido.PaymentId = payload;
      this._productosService.postPedido(this.DatosPedido).subscribe(
        res=>{
          Swal.fire({
            icon: 'success',
            title: 'Thanks',
            text: 'Thanks for your purchase!',
            confirmButtonText: 'Listo'
          }).then((result) => {
            if (result.isConfirmed) {
              this._router.navigateByUrl('/myorders');
            }
          })
        },err=>{
          if (err.status == 401) {
            // window.localStorage.removeItem('token');
            this._router.navigateByUrl('/Relogin')
          }
           console.log(err);
        }
  )}  
}
