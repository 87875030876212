import { Component, OnInit } from '@angular/core';
import { UsuarioService} from '../../services/usuario.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-recuperarpass',
  templateUrl: './recuperarpass.component.html',
  styleUrls: ['./recuperarpass.component.scss']
})
export class RecuperarpassComponent implements OnInit {

  Passform: FormGroup;
  loading = false;
  CredencialUsu: string;
  params: any;
  submitted = false;

  constructor(private auth: UsuarioService, private router: Router, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
     
    this.Passform = this.formBuilder.group({
      
      CredencialUsu: ['', Validators.required]

    })
  }

  get f() { return this.Passform.controls }

  Recuperar() {
    
    this.submitted = true;
    this.params = JSON.stringify(this.CredencialUsu);

    if (this.Passform.invalid) {
      return;
  }else{
    this.loading = true;
    this.auth.SentRecuperarPass(this.params).subscribe( res => { 
     
    }, (err) => {
      this.loading = false;
      if(err.status == 200){
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: "Mail sent successfully.",
          showConfirmButton: false,
          timer: 4000
        })
        this.router.navigateByUrl('/cambiarpass');
      }else{
       Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: err.error,
        showConfirmButton: false,
        timer: 2500
      })       
      }

    }); 
  }
 }
}
