import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { InicioComponent } from './components/inicio/inicio.component';
import { ProductoComponent } from './components/producto/producto.component';
import { LoginComponent } from './components/login/login.component';
import { RegistrarComponent } from './components/registrar/registrar.component';
import { RecuperarpassComponent } from './components/recuperarpass/recuperarpass.component';
import { CambiarpassComponent } from './components/cambiarpass/cambiarpass.component';
import { BuscarComponent } from './components/buscar/buscar.component';
import { ResultadosComponent } from './components/resultados/resultados.component';
import { CarritofullComponent } from './components/carritofull/carritofull.component';
import { ProfileComponent } from './components/profile/profile.component';
import { PayComponent } from './components/pay/pay.component';
import { GuardadosComponent } from './components/guardados/guardados.component';
import { AddpiezaComponent } from './components/addpieza/addpieza.component';
import { EditprofileComponent } from './components/editprofile/editprofile.component';
import { AuthGuardGuard } from './components/guard/auth-guard.guard';
import { CarroComponent } from './components/carro/carro.component';
import { RelacionadosComponent } from './components/relacionados/relacionados.component';
import { AllmessegeComponent } from './components/allmessege/allmessege.component';
import { OrdenesComponent } from './components/ordenes/ordenes.component';
import { RedirectGuardGuard } from './components/redirect-guard.guard/redirect-guard.guard';
import { EditPiezaComponent } from './components/edit-pieza/edit-pieza.component';
import { OwnerComponent } from './components/owner/owner.component';
import { PlanesComponent } from './components/planes/planes.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { ReloginComponent } from './components/relogin/relogin.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CrearCarroComponent } from './components/admin/crear-carro/crear-carro.component';
import { CrearParteComponent } from './components/admin/crear-parte/crear-parte.component';
import { AdminCarroComponent } from './components/admin/admin-carro/admin-carro.component';
import { AdminPartesComponent } from './components/admin/admin-partes/admin-partes.component';
import { InicioAdminComponent } from './components/admin/inicio-admin/inicio-admin.component';
import { BusquedaComponent } from './components/busqueda/busqueda.component';
import { EditarCarroComponent } from './components/admin/editar-carro/editar-carro.component';
import { DefaultsComponent } from './components/admin/defaults/defaults.component';
import { DefaultsCrearComponent } from './components/admin/defaults-crear/defaults-crear.component';
import { CompaniesComponent } from './components/admin/companies/companies.component';
import { CompaniesCrearComponent } from './components/admin/companies-crear/companies-crear.component';
import { CompaniesEditarComponent } from './components/admin/companies-editar/companies-editar.component';
import { MultipleUsesComponent } from './components/admin/multiple-uses/multiple-uses.component';
import { MultipleusesCrearComponent } from './components/admin/multipleuses-crear/multipleuses-crear.component';
import { EstadosComponent } from './components/admin/estados/estados.component';
import { CrearEstadosComponent } from './components/admin/crear-estados/crear-estados.component';
import { EbayComponent } from './components/admin/ebay/ebay.component';
import { UsuariosComponent } from './components/admin/usuarios/usuarios.component';
import { UsersCrearComponent } from './components/admin/users-crear/users-crear.component';
import { UsersEditarComponent } from './components/admin/users-editar/users-editar.component';
import { RolesComponent} from './components/admin/roles/roles.component';
import { RolesCrearComponent} from './components/admin/roles-crear/roles-crear.component';
import { RolesEditarComponent} from './components/admin/roles-editar/roles-editar.component';
import { PermisosComponent} from './components/admin/permisos/permisos.component';
import { PermisosCrearComponent} from './components/admin/permisos-crear/permisos-crear.component';
import { PartLocationComponent } from './components/admin/part-location/part-location.component';
import { TiresComponent } from './components/admin/tires/tires.component';
import { TiresCrearComponent } from './components/admin/tires-crear/tires-crear.component';
import { TiresEditarComponent } from './components/admin/tires-editar/tires-editar.component';
import { StoreSaleComponent } from './components/admin/store-sale/store-sale.component';
import { InvoceDetailComponent } from './components/invoce-detail/invoce-detail.component';
import { AdminInvoicesComponent } from './components/admin/admin-invoices/admin-invoices.component';



const routes: Routes = [
  {path: '', component: InicioComponent},
  {path: 'producto/:id', component: ProductoComponent},
  {path: 'carro/:id/:pieNombre', component: CarroComponent},
  {path: 'login', component: LoginComponent},
  {path: 'registrar', component: RegistrarComponent},
  {path: 'recuperarPass', component: RecuperarpassComponent},
  {path: 'cambiarpass', component: CambiarpassComponent},
  {path: 'buscar', component: BuscarComponent},
  {path: 'resultado', component: BusquedaComponent},
  {path: 'infoVendedor/:pieId', component: OwnerComponent},
  {path: 'chat/:pieId/:usuId/:PieNombre/:PieImg/:grupoId', component: RelacionadosComponent, canActivate : [AuthGuardGuard]},
  {path: 'carritof', component: CarritofullComponent, canActivate : [AuthGuardGuard]},
  {path: 'profile', component: ProfileComponent, canActivate : [AuthGuardGuard]},
  {path: 'pago', component: PayComponent, canActivate : [AuthGuardGuard]},
  {path: 'guardados', component: GuardadosComponent, canActivate : [AuthGuardGuard]},
  {path: 'addpieza', component: AddpiezaComponent, canActivate : [AuthGuardGuard]},
  {path: 'editpieza/:id', component: EditPiezaComponent, canActivate : [AuthGuardGuard]},
  {path: 'editprofile', component: EditprofileComponent, canActivate : [AuthGuardGuard]},
  {path: 'allmessege', component: AllmessegeComponent, canActivate : [AuthGuardGuard]},
  {path: 'myorders', component: OrdenesComponent, canActivate : [AuthGuardGuard]},
  {path: 'invoices', component: InvoicesComponent, canActivate : [AuthGuardGuard]},
  {path: 'planes', component: PlanesComponent},
  {path: 'Relogin', component: ReloginComponent},
  {path: 'invocesDetail/:facId', component: InvoceDetailComponent, canActivate : [AuthGuardGuard]},
  {path: 'Dashboard', component: DashboardComponent, canActivate : [AuthGuardGuard],
   children: [
    {path: '', component: InicioAdminComponent},
    {path: 'CrearCar', component: CrearCarroComponent},
    {path: 'CrearPart', component: CrearParteComponent},
    {path: 'Cars', component: AdminCarroComponent},
    {path: 'Parts', component: AdminPartesComponent},
    {path: 'editarCarro/:id', component: EditarCarroComponent},
    {path: 'Defaults', component: DefaultsComponent},
    {path: 'Defaultscrear', component: DefaultsCrearComponent},
    {path: 'Companies', component: CompaniesComponent},
    {path: 'CrearCompanies', component: CompaniesCrearComponent},
    {path: 'CompaniesEditar/:id', component: CompaniesEditarComponent},
    {path: 'MultipleUses', component: MultipleUsesComponent},
    {path: 'CrearMultipleUses', component: MultipleusesCrearComponent},
    {path: 'Estados', component: EstadosComponent},
    {path: 'CrearEstados', component: CrearEstadosComponent},
    {path: 'Ebay', component: EbayComponent},
    {path: 'Usuarios', component: UsuariosComponent},
    {path: 'UsuariosCrear', component: UsersCrearComponent},
    {path: 'UsuariosEditar/:id', component: UsersEditarComponent},
    {path: 'roles', component: RolesComponent},
    {path: 'rolesCrear', component: RolesCrearComponent},
    {path: 'rolesEditar/:id', component: RolesEditarComponent},
    {path: 'permit', component: PermisosComponent},
    {path: 'permitCrear', component: PermisosCrearComponent},
    {path: 'partLocations', component: PartLocationComponent},
    {path: 'tires', component: TiresComponent},
    {path: 'tiresCrear', component: TiresCrearComponent},
    {path: 'tiresEditar/:id', component: TiresEditarComponent},
    {path: 'storeSales', component: StoreSaleComponent},
    {path: 'invoices', component: AdminInvoicesComponent, canActivate : [AuthGuardGuard]},


  ]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true,
    scrollPositionRestoration: 'top',
  },),],
  exports: [RouterModule]
})
export class AppRoutingModule { }
