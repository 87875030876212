export class Usuario {

  usuId: number;
  usuSesion: string;
  usuPass: string;
  usuNombres: string;
  usuApellidos: string;
  paiIDBill: number;
  usuCiudadBill: string;
  usuDireccionBill: string;
  usuDireccion2Bill: string;
  usuZipCodeBill: string;
  usuTelefonoBill: string;
  usuStateBill: string;
  usuStateShip: string;
  usuCountry: string;
  paiIDShip: number;
  usuCiudadShip: string;
  usuDireccionShip: string;
  usuDireccion2Ship: string;
  usuZipCodeShip: string;
  ususTelefonoShip: string;
  usuEmail: string;
  usuEstatus: number;
  rolId: number;
  usuCodePass: string;
  usuFoto: string;
  empID: number;
  usuTipo: number;

  usuType: number;
  empName: string;
  empTaxID: string;
  empSalesTaxLicID: string;
  empBusinessLinc: string;
  paymentId: string;

  constructor() {
    this.usuId= 0;
    this.usuSesion= '';
    this.usuStateBill= '';
    this.usuStateShip= '';
    this.usuPass= '';
    this.usuNombres= '';
    this.usuApellidos= '';
    this.paiIDBill= 1;
    this.usuCiudadBill= '';
    this.usuDireccionBill= '';
    this.usuDireccion2Bill= '';
    this.usuZipCodeBill= '';
    this.usuTelefonoBill= '';
    this.usuCountry='';
    this.paiIDShip=1;
    this.usuCiudadShip= '';
    this.usuDireccionShip= '';
    this.usuDireccion2Ship= '';
    this.usuZipCodeShip= '';
    this.ususTelefonoShip= '';
    this.usuEmail= '';
    this.usuEstatus= 1;
    this.rolId= 1;
    this.usuCodePass= '';
    this.usuFoto= '';
    this.empID= 0;
    this.usuTipo= 0;

    this.usuType= 0;
    this.empName= '';
    this.empTaxID= '';
    this.empSalesTaxLicID= '';
    this.empBusinessLinc= '';
    this.paymentId= '';
  }

};


