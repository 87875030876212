import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommunicationServiceService {

  messege: any;
  private componentMethodCallSource = new Subject<any>();
  componentMethodCalled = this.componentMethodCallSource.asObservable();

  constructor() { }

  callComponentMethod( messege: any) {
    this.messege = messege;
    this.componentMethodCallSource.next(messege);
  }
}
