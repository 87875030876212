<app-navbar></app-navbar>
<div class="pt-5 px-2 mt-2 mb-2">
  <h3 class=" text-center">Messages</h3>
  <div class="inbox_msg">
    <div class="headind_srch">
      <div class="recent_heading">
        <h3>Recently</h3>
      </div>
      <div class="srch_bar">
        <div class="stylish-input-group">
          <input type="text" placeholder="Search" [(ngModel)]="query">
          <span class="input-group-addon">
            <button type="button"> <i class="fa fa-search" aria-hidden="true"></i> </button>
          </span>
        </div>
      </div>
    </div>
    <div class="inbox_chat">
      <div class="d-flex justify-content-center" *ngIf="loadingchat; else elseBlock">
        <div class="spinner-border text-primary  m-5" role="status" style="width: 3rem; height: 3rem;" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div *ngIf="grupos.length == 0" class="p-2 px-0 d-flex justify-content-center">
        <div class="d-flex flex-column justify-content-center">
          <h3 class="d-flex justify-content-center">Data not found.</h3>
          <img class="d-flex justify-content-center" src="../../../assets/img/phvp.png" width="200" height="200" alt="">
        </div>
      </div>
      <ng-template #elseBlock>

        <div class="chat_list" *ngFor="let grupo of grupos | searchFilter: query">
          <a [routerLink]="['/chat', grupo.pieId, 0, grupo.pieNombre, grupo.pieFoto, grupo.menGrupId]">
            <div class="d-flex align-items-center flex-row chat_people py-2">
              <img src="{{grupo.pieFoto}}" class="chat_img rounded-circle border border-primary" alt="sunil">
              <div class="chat_ib">
                <h5>{{grupo.pieNombre}}</h5>
                <p class="mensaje"> {{grupo.lastMensaje}}</p>
                <p class="date"> {{grupo.menGrupLastDate | date: 'shortTime' }} | {{grupo.menGrupLastDate | date: 'fullDate'}}</p>
              </div>
              
              <div>    
                <div style="color: #008c44;"  *ngIf="grupo.menGrupLeido == false && grupo.lastTo == myEmpId" ><i class="fa fa-circle"></i></div>
                <div style="color: #008c44;"  *ngIf="grupo.menGrupLeido && grupo.lastTo != myEmpId" class="d-flex flex-row"><i class="fa fa-check" aria-hidden="true"></i><i class="fa fa-check" aria-hidden="true"></i></div>
                <div style="color: #008c44;"  *ngIf="grupo.menGrupLeido == false && grupo.lastTo != myEmpId"><i class="fa fa-check" aria-hidden="true"></i></div>
              </div>  
              
            </div>
          </a>

        </div>
      </ng-template>
    </div>

  </div>