import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AdminService, CompaniesPost } from '../../../services/admin.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-companies-crear',
  templateUrl: './companies-crear.component.html',
  styleUrls: ['./companies-crear.component.scss']
})
export class CompaniesCrearComponent implements OnInit {

  loading = false;
  urls: string[] = [];
  postUrl = new Array<string>();

  postCompany: CompaniesPost = {
    empID: 0,
    empNombre: '',
    empEmail: '',
    empEstatus: 0,
    empTelefono: '',
    empFax: '',
    empDireccion: '',
    empDireccion2: '',
    empZipCode: '',
    empEstado: '',
    empCiudad: '',
    empLogoPath: '',

  };


  constructor(private _fb: FormBuilder, private _adminService: AdminService, private _router: Router
  ) { }

  ngOnInit(): void {

  }


  removeItemFromArr(item) {
    var arr = this.urls;
    var i = arr.indexOf(item);
    if (i !== -1) {
      arr.splice(i, 1);
    }
    console.log(arr);
    arr = this.postUrl;
  }

  detectFiles(event: any) {
    this.urls = [];
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.urls.push(e.target.result);
        }
        reader.readAsDataURL(file);
      }
    }
    console.log(this.urls);
  }
  
  chooseFile() {
    document.getElementById("fileInput").click();
  }

  Submit() {

    try {
      this.loading = true;

      if ((<HTMLOptionElement>document.getElementById('empName')).value == "")
        throw new TypeError("Company name is necesary");
      if ((<HTMLOptionElement>document.getElementById('empEmail')).value == "")
        throw new TypeError("Email is necesary");
      if ((<HTMLOptionElement>document.getElementById('empPhone')).value == "")
        throw new TypeError("Phone is necesary");
      if ((<HTMLOptionElement>document.getElementById('empAddress')).value == "")
        throw new TypeError("Address is necesary");
      if ((<HTMLOptionElement>document.getElementById('empZipCode')).value == "")
        throw new TypeError("ZipCode is necesary");
      if ((<HTMLOptionElement>document.getElementById('empState')).value == "")
        throw new TypeError("State is necesary");
      if ((<HTMLOptionElement>document.getElementById('empCity')).value == "")
        throw new TypeError("City is necesary");

      if (this.urls.length == 0)
        throw new TypeError("Logo is necesary");

      this.postCompany.empNombre = ((<HTMLOptionElement>document.getElementById('empName')).value);
      this.postCompany.empEmail = ((<HTMLOptionElement>document.getElementById('empEmail')).value);
      this.postCompany.empTelefono = ((<HTMLOptionElement>document.getElementById('empPhone')).value);
      this.postCompany.empFax = ((<HTMLOptionElement>document.getElementById('empFax')).value);
      this.postCompany.empDireccion = ((<HTMLOptionElement>document.getElementById('empAddress')).value);
      this.postCompany.empDireccion2 = ((<HTMLOptionElement>document.getElementById('empAddress2')).value);
      this.postCompany.empZipCode = ((<HTMLOptionElement>document.getElementById('empZipCode')).value);
      this.postCompany.empEstado = ((<HTMLOptionElement>document.getElementById('empState')).value);
      this.postCompany.empCiudad = ((<HTMLOptionElement>document.getElementById('empCity')).value);
      this.postCompany.empLogoPath = this.urls[0];


      this._adminService.postCompanies(this.postCompany).subscribe(
        res => {

          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Done',
            showConfirmButton: true,
            timer: 1500
          })
          this._router.navigateByUrl('/Dashboard/Companies');
        }, err => {
          this.loading = false;
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: err.error,
            showConfirmButton: true,
          })
          console.log(err);

        })
    } catch (error) {
      this.loading = false;
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: error,
        showConfirmButton: true,
      })
      console.log(error);
    }

  }
}



