<app-navbar></app-navbar>
<div class="row p-2">
    <div class="d-flex align-items-center">
        <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a  routerLink="/Dashboard/Defaults">Defaults</a></li>
            <li class="breadcrumb-item active" aria-current="page">Add Defaults</li>
          </ol>
        </nav>
      </div>
    <h1 class="text-primary">Add Defaults
    </h1>
    <hr>
    

    <form [formGroup]="myForm" class="row g-3">
    
        <div  class="col-md-3" >
            <label for="inputEmail4" class="form-label">Part Name</label>
            <input id="Partname_default"   placeholder="Part Name" type="text" class="form-control">
        </div>
        <div class="col-md-2">
          <label for="inputEmail4" class="form-label">Category</label>
         
      <ng-multiselect-dropdown
      name="catf"
      formControlName="catf"
      [(ngModel)]="this.currentCategory"
      [placeholder]="'Select Category'"
      [settings]="{      
        closeDropDownOnSelection: true, 
        singleSelection: true,
        idField: 'catID',
        textField: 'catDescripcion',
        itemsShowLimit: 1,
        allowSearchFilter: true}"
      [data]="categorias"
      (onDeSelect)="UnSelectObject($event)"
     ></ng-multiselect-dropdown> 
      </div>



      

                <div class="d-flex flex-row-reverse bd-highlight p--0">
               
                    <div  class="p-2 bd-highlight"><button  type="submit" (click)="Submit()" class="btn btn-primary btn-lg text-white ">
                        Save Default
    
                        <i class="fa fa-floppy-o"></i></button></div>
                            
                        <div class="p-2 bd-highlight">
                          <button class="btn btn-danger btn-lg text-white" routerLink="/Dashboard/Defaults">
                              <i class="fa fa-times"></i> Cancel</button>
                      </div>
      
                  </div>
        </form>
