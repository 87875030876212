import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { Url } from '../ApiUrl/url';
import { piezasBuscar } from '../Entities/Pieza';

export interface datosBuscar {

  buscar: string,
  cat: number, 
  mo: any,
  mar: any,
  anos: string,
  freeshipping: boolean,
  ubicacion: string,
  pageNumber: number

}

export interface buscarCompanies {

  buscar: any
  pageNumber: number

}

export interface mar{

  marID: number,
  marDescripcion: string,
  marFechaUltimaActualizacion: string,
  usuInicioSesion: string,
  marEstatus: number

}

export interface Modelo{

  idMarca: number,
  marcastring: string

} 


export interface Default{

  piePreNombre: string,
  piePreCatId: number

} 

export interface brrEstado{

  multipleS: string,
    estEstado: number,
    usuInicioSesion:string,
    estFechaActualizacion:string
} 

export interface brrMultipleUse{

  estTabla: string,
  estEstado: number,
    estDescripcion:string,
    estFechaActualizacion:string,
    usuInicioSesion:string

} 

export interface detalleCarrito {
   
  PedPosicion: number,
  PieID: number,
  PedUsuID: number,
  EmpID: number,
  PedCantidad: number,
  PedPrecio: number,
  PedPorcientoIImpuestos: number,
  PedMontoImpuestos: number,
  PedDescuentoPorciento: number,
  PedMontoDescuento: number,
  PedMontoSubTotal: number,
  PedMontoTotal: number,
  PedMontoEnvio: number,
  //PedFecha: string,
  PedNumTracking: string,
  UsuID: number,
  PedEstatus: number,
  PedNota: string,
  PedImagen: string,
  ProNombre: string

}

export interface brrCarrito {
   
    PedPosicion: number,
    PieID: number,
    PedUsuID: number,
    EmpID: number,
    PedCantidad: number,
    PedPrecio: number,
    PedPorcientoIImpuestos: number,
    PedMontoImpuestos: number,
    PedDescuentoPorciento: number,
    PedMontoDescuento: number,
    PedMontoSubTotal: number,
    PedMontoTotal: number,
    PedMontoEnvio: number,
    //PedFecha: string,
    PedImagen: string,
    ProNombre: string,
    PedEstatus: number,
    PedNumTracking: string,
    UsuID: number,
    PedNota: string

}

export interface datosPedido{
  
  PedID: number,
  PedUsuID: number,
  //PedFecha: "2021-12-30T18:32:03.429Z",
  PedEstatus: number,
  PedTotal: number,
  PedTotalEnvio: number,
  PedTotalDescuento: number,
  PedSubTotal: number,
  PedTotalImpuesto: number,
  PedTipoPedido: number,
  PaymentId: string,
  PaiIDBill: number,
  UsuCiudadBill: string,
  UsuDireccionBill: string,
  UsuDireccion2Bill: string,
  UsuZipCodeBill: string,
  PaiIDShip: number,
  UsuCiudadShip: string,
  UsuDireccionShip: string,
  UsuDireccion2Ship: string,
  UsuZipCode2Ship: string,
  UsusTelefonoShip: string,
  //PedFechaActualizacion": "2021-12-30T18:32:03.429Z",
  UsuID: number

  }

export interface datosGuardar { 

  guardId: number,
  pieId: number,
  userId: number

}

export interface datosPieza {

  PieId: number;
  CarId?: number;
  PieUsuID?: number;
  PieNombre: string;
  PieDescripcion: string;
  PiePrecio: number;
  PieCosto?: number | null;
  PieFechaCreacion: Date;
  PieFechaActualizacion?: Date | null;
  CatId?: number | null;
  ModelId?: number | null;
  MarcaID?: number | null;
  EmpId?: number | null;
  PieEstado: number;
  PieLocation: string;
  PieCondicion: string;
  PieVIN: string;
  PieCostoEnvio?: number | null;
  PieSeñalUso: string;
  PieLatitud: string;
  PieLongitud: string;
  PieMarcaFabricacion: string;
  PieGarantia: string;
  PieTipoProducto?: number | null;
  UsuId?: number | null;
  PiePeso?: number | null;
  PieTipoIntegracion: number;
  PieIntegracionReferencia: string;
  PieIntegracionReferencia2: string;
  PieIntegracionReferencia3: string;
  pieAnoDesde?: number | null;
  pieAnoHasta?: number | null;
  PieColor: string;
  PieTipoCarro?: number | null;
  PieMaterial: string;
  PiePlacementonVehicle: string;
  PieFinish: string;
  PieItemsIncluded: string;
  PieFeatures: string;
  PiePartType: string;
  PtypeDes: string;
  PFeaturesDes: string;
  PFinishDes: string;
  PItemsInDes: string;
  PMaterialDes: string;
  PPlacementDes: string;


  PieDisponible: number,
  PieTipoProductoString: string,
  PieFoto: string,
  CategoriaString: string,
  ModeloString: string,
  MarcaString: string,
  MarId: number,
  EstadoString: string,
  MainCarString: string,
  CarFoto: string,
  anoCarro: string,
  PieImagenes: string[]

}


export interface pieVendida {
    
  cantidad: number,
  pieId: number

} 

 export interface ByOwner{
   
  ownerId: number,
  tipoOwner: number,
  nombrePart: string,
  pageNumber: number,
  pageSize: number  


 }

 export interface Modelo{
   
  idMarca : number,
  marcastring: string
 
 }

@Injectable({
  providedIn: 'root'
})
export class ProductosService {
  public url : string;

  constructor(
    private _http: HttpClient
  ) { 
    this.url = Url.url; 
  }
  
  getProductos(params: number):Observable<any>{
    return this._http.get<any>(this.url + 'Piezas/GetToHome?pageNumber='+ params +'&pageSize=20'); 
  }
 
  getProducto(id):Observable<any>{ 
      return this._http.get(this.url + 'Piezas/id?id=' + id,{ headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
  }

  deletePart(partId: Number) {
    return this._http.post(this.url + 'Piezas/Delete', partId, { responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  getMiProducto(id):Observable<any>{ 
    return this._http.get(this.url + 'Piezas/PieId?id=' + id, { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
}

  getcarro(id):Observable<any>{ 
    return this._http.get(this.url + 'Piezas/GetByCarro?carId=' + id, { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
}

  getPedidosTemp():Observable<any>{
    return this._http.get(this.url + 'Pedidos/DetalleTemp',{ headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
  }

  getRecomendadosNoLogin():Observable<any>{
    return this._http.get(this.url + 'Piezas/GetRecomendadosNoLogin', { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
  }

  getRecomendado():Observable<any>{
    return this._http.get(this.url + 'Piezas/GetRecomendados',{ headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
  }
 
  getBuscar(params :any):Observable<any>{
    return this._http.get(this.url + 'Piezas/GetByFiltros', { params } ); 
  }

  postCarrito(params :detalleCarrito):Observable<any>{
    return this._http.post(this.url + 'Pedidos/DetalleTemp', params ,{responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
  }
  
  borrartCarrito(params :brrCarrito):Observable<any>{
    return this._http.post(this.url + 'Pedidos/DeleteDetalleTemp', params, {responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
  }

  postGuardar(params :datosGuardar):Observable<any>{
    return this._http.post(this.url + 'UsosMultiples/Guardados', params, {responseType: 'text',  headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
  }

  getGuardados():Observable<any>{
     return this._http.get(this.url + 'Piezas/GetGuardados', { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer "+ localStorage.getItem("token") }});

  }
  getCompanies(nombre?: any, statusID?: any):Observable<any>{
    return this._http.get(this.url + 'Empresas?status='+statusID +'&nombre=' +nombre, { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " +  localStorage.getItem("token") }});
  }

  getStatus(tableName?: any):Observable<any>{
    return this._http.get(this.url + 'UsosMultiples/Estados?tabla='+ tableName, { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " +  localStorage.getItem("token") }});
  }
  getUsosMultiples(groupName?: any):Observable<any>{
    return this._http.get(this.url + 'UsosMultiples?grupo='+ groupName, { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " +  localStorage.getItem("token") }});
  }

  getStatusTables():Observable<any>{
    return this._http.get(this.url + 'UsosMultiples/EstadosTables', { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " +  localStorage.getItem("token") }});
  }
  getUsosMultiplesGroup():Observable<any>{
    return this._http.get(this.url + 'UsosMultiples/UsosMultiplesGroup', { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " +  localStorage.getItem("token") }});
  }

  getMarca():Observable<mar[]>{
    return this._http.get<mar[]>(this.url + 'UsosMultiples/Marcas',{ headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
  }
  getMarcaID():Observable<any>{
    return this._http.get(this.url + 'UsosMultiples/Marcas/marID',{ headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
  }
  getCategoria():Observable<any>{
    return this._http.get(this.url + 'UsosMultiples/Categorias',{ headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
  }

  postDefaults(params: Default):Observable<any>{
    return this._http.post(this.url + 'UsosMultiples/Default', params, { responseType: 'text', headers: {  'Authorization': "Bearer " + localStorage.getItem("token") }});
 }
 
 borrarDefault(params :Default):Observable<any>{
  return this._http.post(this.url + 'UsosMultiples/Default/Detele', params,  { responseType: 'text', headers: {  'Authorization': "Bearer " + localStorage.getItem("token") }});
}
borrarEstado(params :brrEstado):Observable<any>{
  return this._http.post(this.url + 'UsosMultiples/Estados/Detele', params,  { responseType: 'text', headers: {  'Authorization': "Bearer " + localStorage.getItem("token") }});
}
borrarMultipleUse(params :brrMultipleUse):Observable<any>{
  return this._http.post(this.url + 'UsosMultiples/UsosMultiplesDelete', params,  { responseType: 'text', headers: {  'Authorization': "Bearer " + localStorage.getItem("token") }});
}
  getPart(params: any, nombre?:string):Observable<any>{
    var url ='UsosMultiples/Default?catid=' + params ;
    if(nombre !=null)
      url ='UsosMultiples/Default?catid=' + params + '&nombre=' + nombre;
  console.log(params);
    return this._http.get(this.url + url, { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
  }

  getModelo(params: Modelo):Observable<any>{
    return this._http.get(this.url + 'UsosMultiples/Modelos/' + params.idMarca, { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
  }

  getTokenpaypal():Observable<any>{
    return this._http.get(this.url + 'UsosMultiples/TokenPaypal',{responseType: 'text',  headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
  } 

  postPedido(params: datosPedido):Observable<any>{
     return this._http.post(this.url + 'Pedidos', params, {responseType: 'text',  headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
  }

  postPieza(params: datosPieza){
    return this._http.post(this.url + 'Piezas', params, {responseType:'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
  }
  
  Mipiezas(params: piezasBuscar){
    return this._http.get<any>(this.url + 'Piezas/GetMisPiezas?maincar=' + params.maincar + '&status=' + params.status + '&cat=' + params.cat +'&mo=' + params.mo +'&mar=' + params.mar +'&anos=' + params.anos + '&nombre=' + params.nombre + '&pageNumber=' + params.pageNumber + '&pageSize=20',{ headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
  }
  
  MisOrdenes():Observable<any>{
    return this._http.get(this.url + 'Pedidos',{ headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
  }

  PiezaOwner(pieId: any):Observable<any>{
    console.log(pieId);
    return this._http.get(this.url + 'UsosMultiples/PiezaOwner?PieId=' + pieId);
  }

  Vendido(params: pieVendida):Observable<any>{
    return this._http.post(this.url + 'Piezas/MarcarVendido?pieId='+params.pieId+'&cantidad=' + params.cantidad ,params ,{responseType: 'text',  headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
  }

  piezasOwner(params: ByOwner){
    return this._http.get(this.url + 'Piezas/GetByOwner?ownerId=' + params.ownerId + '&tipoOwner=' + params.tipoOwner +'&nombrePart='+params.nombrePart +'&pageNumber=' + params.pageNumber +'&pageSize=20', { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
  }

  updatepie(params: datosPieza){
    return this._http.post(this.url + 'Piezas/Put', params,{responseType:'text', headers: {'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }})
  }

  Misfacturas():Observable<any>{
    return this._http.get(this.url + 'Facturas', { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
  }

  Reportefactura(params: any):Observable<any>{
    return this._http.get(this.url + 'Facturas/GetFacturasReport?id=' + params, {responseType:'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
  }
}
 