<app-navbar></app-navbar>
<div class="row p-2 d-flex align-items-center " *ngIf="!loading; ">
  <div class="d-flex align-items-center">
    <nav
      style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
      aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/Dashboard/roles"> Roles</a></li>
        <li class="breadcrumb-item active" aria-current="page">Edit Role</li>
      </ol>
    </nav>
  </div>
  <h3> <span>Edit Role: </span> <span class="text-primary"> {{currentRol.rolDescripcion}}</span>
  </h3>
  <hr>

  <form [formGroup]="myForm">
    <div class="row">
      <!-- <div class="col-6 col-sm-6 col-md-3 col-lg-3 nav-item">
        <h6 class="form-label"> Rol Description</h6>
        <input placeholder="Description" type="text" class="form-control" id="RDescription">
      </div> -->

      <div class="col-6 col-sm-6 col-md-4 col-lg-4 nav-item">
        <h6 class="form-label">Permission</h6>
        <ng-multiselect-dropdown 
            name="permisosS" 
            [disabled]="estListo" 
            formControlName="permisosS"
            [(ngModel)]="this.currenPermisos" [placeholder]="'Permission'" 
            [settings]="{      
                    closeDropDownOnSelection: true,
                    singleSelection: true,
                    idField: 'perID',
                    textField: 'permisoString',
                    itemsShowLimit: 5,
                    allowSearchFilter: true}" 
            [data]="rolePermisos">
        </ng-multiselect-dropdown>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-4 nav-item ">
        <h6 class="text-white"> Add</h6>
        <button class="btn btn-success text-white mb-2" (click)="addRolPermiso()">
            <i class="fa fa-plus"></i> Add Permission
        </button>
      </div>

    </div>
  </form>

  <h5 class="mt-3 mb-2">Added Permission: </h5>
  <div class="card p-2" *ngFor="let permiso of currentRol.rolPermisos">
    <div class="d-flex justify-content-between align-items-center p-2">
        <h4> {{permiso.permisoString}} </h4>  
        <button class="btn btn-danger" (click)="borrarRolPermiso(permiso)"> <i class="fa fa-trash"></i></button>
    </div>
  </div>

</div>

<div class="d-flex justify-content-center" *ngIf="loading;">
  <div class="spinner-border text-primary  m-3" role="status" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
