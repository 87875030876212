import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ph',
  templateUrl: './ph.component.html',
  styleUrls: ['./ph.component.scss']
})
export class PHComponent implements OnInit {
  @Input()
  tipo: any;

  constructor( private router: Router) { }

  ngOnInit(): void {
    (<HTMLInputElement>document.getElementById('home')).style.display = "block";  
    switch(this.tipo){
    case "home":
         (<HTMLInputElement>document.getElementById('home')).style.display = "block";   
    break
    case "detail":
      (<HTMLInputElement>document.getElementById('detail')).style.display = "block";   
 break
    }
  }

}
