<app-navbar></app-navbar>
<app-buscar></app-buscar>
<div class="p-5">
   <h1>Piezas of Car <a href="javascript: history.go(-1)">{{pieNombre}}</a></h1>
</div>
<div class="card m-2 p-3" *ngFor="let pie of carros" >
  <a [routerLink]="['/producto', pie.pieId]" class="col-md-3 col-sm-6 portfolio-item my-2 rounded-lg">
    <div class="card card-pie p-3 rounded-3">
      <a [routerLink]="['/producto', pie.pieId]">
        <img class="card-img-top fit-image img-fluid" src="{{pie.pieFoto}}">
        <div class="card-img-overlay m-2 rounded-3">
          <h5><span class="badge bg-primary" *ngIf="pie.pieDisponible > 0">{{pie.pieCondicion}}</span></h5>
          <h5><span *ngIf="pie.pieDisponible <= 0" class="badge bg-danger">unavailable</span></h5>
        </div>
      </a>
      <div class="card-body d-inline-block text-truncate">
        <h4 class="card-title ">{{pie.pieNombre}}</h4>
        <p class="card-text" style="font-size: 25px;">{{pie.piePrecio | currency}}</p>
      </div>
    </div>
  </a> 
  
  </div>