import { Component, OnInit } from '@angular/core';
import { ProductosService, Default,} from '../../../services/productos.service';
import { AdminService } from '../../../services/admin.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-defaults',
  templateUrl: './defaults.component.html',
  styleUrls: ['./defaults.component.scss'],
})
export class DefaultsComponent implements OnInit {
  currentCategory: any[] = [];
  currentPart: any[] = [];
  catListo = true;
  categorias: any[] = [];
  parts: any[] = [];
  token: any;
  totalDefault = 0;

  loading = false;
  noData = false;

  eliminarDefault: Default = {
    piePreNombre: '',
    piePreCatId: 0,
  };

  myForm: FormGroup;
  constructor(
    private service: ProductosService,
    private _fb: FormBuilder,
    private _router: Router,
    private adService: AdminService
  ) {}

  ngOnInit(): void {
    this.myForm = this._fb.group({
      catf: this.currentCategory,
      partf: this.currentPart,
    });
    this.loading = true;
    this.adService.getAdminHome().subscribe(
      (res) => {
       this.loading = false;
        if(res.permisos.some((x) => x.permisoString == "Defaults")){
          this.getCategoria();
        }else{
          Swal.fire({
            icon: 'error',
            title: 'You do not have permissions to access to Defaults',
            showConfirmButton: true
          }).then(()=>{
            this._router.navigateByUrl('/Dashboard');
          })
        }
      },
      (err) => {
        this.loading = false;
        console.log(err.error);
        if(err.error.includes('You do not have permissions to access to ')){
          Swal.fire({
            icon: 'error',
            title:err.error,
            showConfirmButton: true
          }).then(()=>{
            this._router.navigateByUrl('/Dashboard');
          })
         }
      }
    );

    this.getCategoria();
  }

  getCategoria() {
    this.service.getCategoria().subscribe(
      (res) => {
        this.catListo = false;
        this.categorias = res;
        console.log(res);
      },
      (err) => {
        console.log(err.error);
      }
    );
  }
  cargarPart() {
    this.loading = true;
    var busqueda = <HTMLOptionElement>document.getElementById('partName_ip');
    console.log(busqueda.value);
    this.service
      .getPart(this.currentCategory[0].catID, busqueda.value)
      .subscribe(
        (res: any) => {
          this.parts = res;
          console.log(res);
          this.totalDefault = res.length
          this.loading = false;

          if (res.length == 0) this.noData = true;
          else this.noData = false;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  UnSelectObject(item: any) {
    if (this.currentCategory.length == 0) {
      this.currentPart = [];
      this.parts = null;
      this.noData = true;
    }
  }

  borrarDefault(eliminarDefault: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true;
        this.service.borrarDefault(eliminarDefault).subscribe(
          (res) => {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Your part has been deleted.',
              showConfirmButton: true,
            }).then((result) => {
              this.loading = false;
              window.location.reload();
            });
    
          },
          (err) => {
            this.loading = false;
            if(err.error.includes('You do not have permissions to access to ')){
              Swal.fire({
                icon: 'error',
                title:err.error,
                showConfirmButton: true
              }).then(()=>{
                this._router.navigateByUrl('/Dashboard');
              })
             }
            console.error(err);
          }
        );
      }
    })

  }
}
