<app-navbar></app-navbar>
<div class="row p-2">
  <div class="d-flex align-items-center">
    <nav
      style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
      aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/Dashboard/MultipleUses"> Multiple Uses</a></li>
        <li class="breadcrumb-item active" aria-current="page">Add Multiple Uses</li>
      </ol>
    </nav>
  </div>
  <h1 class="text-primary">Add Multiple Uses
  </h1>
  <hr>

  <form [formGroup]="myForm">
    <div class="row">
      <div class="col-6 col-sm-6 col-md-3 col-lg-3 nav-item">
        <h6 class="text-primary">Group Code</h6>

        <ng-multiselect-dropdown name="multipleS" [disabled]="estListo" formControlName="multipleS"
          [(ngModel)]="this.currentMUses" [placeholder]="'Select Group code'" [settings]="{      
                closeDropDownOnSelection: true,
                singleSelection: true,
                idField: 'multipleS',
                textField: 'multipleS',
                itemsShowLimit: 1,
                allowSearchFilter: true}" [data]="usosMultiples"></ng-multiselect-dropdown>


      </div>

      <div class="col-6 col-sm-6 col-md-3 col-lg-3 nav-item">
        <label class="form-label">Value</label>
        <input placeholder="Value" type="text" class="form-control" id="Value">
      </div>

      <div class="col-6 col-sm-6 col-md-3 col-lg-3 nav-item">
        <label class="form-label">Description</label>
        <input placeholder="Description" type="text" class="form-control" id="Description">
      </div>


      <div class="d-flex flex-row-reverse bd-highlight p-0">

        <div class="p-2 bd-highlight"><button type="submit" (click)="Submit()"
            class="btn btn-primary btn-lg text-white ">
            Save Status

            <i class="fa fa-floppy-o"></i></button></div>

        <div class="p-2 bd-highlight">
          <button class="btn btn-danger btn-lg text-white" routerLink="/Dashboard/MultipleUses">
            <i class="fa fa-times"></i> Cancel</button>
        </div>

      </div>