import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Url } from '../ApiUrl/url';
import { Usuario } from '../Entities/Usuario';


export interface DatosLogin {

  Usuario: string;
  Password: string;

};

export interface DatoschangePass {

  userId: number,
  CurrentPassword: string,
  NewPassword: string

};

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  public url : string;
  public validEmail: boolean = false;
  tokenCofig = "AIzaSyA5J2t8k65U21ezEKjQ1uprzBsyoWEK7zM";

  
  constructor( 
    private _http: HttpClient
    ) {  
      this.url = Url.url;
      }

    public login(usuario: DatosLogin): Observable<any> {
      return this._http.post(this.url + 'Login', usuario, { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token")}});
      
    }
    
    register(usuario: Usuario): Observable<any> {
      return this._http.post(this.url + 'Register', usuario);
    }
    registerCheck(usuario: Usuario): Observable<any> {
      return this._http.post(this.url + 'CheckRegister', usuario, {responseType: 'text'});
    }

    CheckregisterUser(usuario: string):  Observable<any> {
      return this._http.get(this.url + 'Register/' + usuario, {responseType: 'text'});
    }

    SentRecuperarPass(params: any): Observable<any> {
      return this._http.post(this.url + 'SentRecuperarPass', params, {responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token")}});
    }

    RecuperarPass(params: DatoschangePass): Observable<any> {
      return this._http.post(this.url + 'RecuperarPass', params, {responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token")}});
    }

    getInfo(): Observable<any>{
       return this._http.get(this.url + 'GetUser', { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token")}});
    }

    getUserStore(busqueda:string): Observable<any>{
      return this._http.get(this.url + 'Usuarios/GetUserStore?busqueda='+busqueda, { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token")}});
   }

    changePass(params: DatoschangePass): Observable<any>{
      return this._http.post(this.url + 'Usuarios/ChangePassword', params, {responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token")}});
   }

   updateinfo(params: Usuario): Observable<any>{
    return this._http.post(this.url + 'Usuarios/Put', params,{ headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
   }

desubcribir( usuId: any, token: any): Observable<any>{
   return this._http.post('https://iid.googleapis.com/iid/v1:batchRemove',{ headers: { 'Content-Type': ' application/json-patch+json', 
    'Authorization': 'key=	AAAAT1KAlGQ:APA91bGvrfbigxFHavqrdchdwalo29hsGlLsGIXS5GLwZcY63KSjQu0KXWA9ETF4NpyhjhjgF9UHmUIb_wePwh2w3AipEwGyNi5PDO0Wc3kaWd3qKQUjUaSorzz9GRz_Q2nHqMmceBFx',
    'to': '/topics/'+ usuId,
    'registration_tokens':'["fjkWWrZiRX9BolEyXHtz2A:APA91bEewznrMK3urTPjZT6zyyhLL4ccfI0KlxTzi1AGI5bu994aBG4R2sCn-Gg6DLGLj6qOiJ3pPn7VR1ylzu5AdShYpvi6gFZjDK_wo2xlUoWV7NZlG1MnlYmTeIYxsCX587ZvnxUr"]',
   }
   
  });
  
 }
 
}
