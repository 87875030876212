import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { Url } from '../ApiUrl/url';
import { PostFacturaViewModel } from '../Entities/Factura';
import { FacturasDetalleTemp } from '../Entities/FacturaDetalle';
import { Carro } from '../Entities/Carro';
import { Usuario } from '../Entities/Usuario';

export interface filtros {

  vin: string,
  mod: string,
  mar: string,
  anos: string,
  status: number,
  pageNumber: number,
  pageSize: number

}

export interface filtrosCompanies {
  empID: number,
  empNombre: string,
  empEstatus: number,
  empTelefono: string,
  empFax: string,
  empDireccion: string,
  empDireccion2: string,
  empZipCode: string,
  empEstado: string,
  empCiudad: string,
  empLogoPath: string,
  pageNumber: number,
  buscar: string

}
export interface EstadosPost {

  estTabla: string,
  estEstado: number,
  estDescripcion: string,
  usuInicioSesion: string
}

export interface UsosMultiplesPost {

  usoCodGrupo: string,
  usoValor: number,
  usoDescripcion: string,

}

export interface filtrosMultipleEstados {

  estTabla: string,
  estEstado: number,
  estDescripcion: string,
  usuInicioSesion: string,
  estFechaActualizacion: string

}

export interface CompaniesPost {
  empID: number,
  empEmail: string,
  empNombre: string,
  empEstatus: number,
  empTelefono: string,
  empFax: string,
  empDireccion: string,
  empDireccion2: string,
  empZipCode: string,
  empEstado: string,
  empCiudad: string,
  empLogoPath: string,
}

export interface Rol {

  rolDescripcion: string,
  rolPermisos: any[],
  rolId: number
}

export interface Permisos {
  permisoString: string
  rolId: number,
  perID: number,
}

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  public url: string;

  constructor(
    private _http: HttpClient
  ) {
    this.url = Url.url;
  }

  getBuscar(params: filtros): Observable<any> {
    return this._http.get(this.url + 'Carros/GetByEmp?vin=' + params.vin + '&mod=' + params.mod + '&mar=' + params.mar + '&status=' + params.status + '&anos=' + params.anos + '&pageNumber=' + params.pageNumber + '&pageSize=' + params.pageSize, { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  postCarros(params: Carro) {
    console.log(params);
    return this._http.post(this.url + 'Carros', params, { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  postCarrosToPart(params: number) {
    console.log(params);
    return this._http.post(this.url + 'Piezas/PostByCar', params, {responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  getCompanyid(id: any): Observable<any> {
    return this._http.get(this.url + 'Empresas/GetById?empId=' + id, { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  postCompanies(params: any) {
    return this._http.post(this.url + 'Empresas', params, { responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  editCompanies(params: any) {
    return this._http.post(this.url + 'Empresas/Edit', params, { responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  suspendCompanies(params: any) {
    return this._http.post(this.url + 'Empresas/Suspend', params, { responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  reActiveCompanies(params: any) {
    return this._http.post(this.url + 'Empresas/Active', params, { responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  postEstados(params: any) {
    console.log(params);
    return this._http.post(this.url + 'UsosMultiples/Estados', params, { responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }
  postUsosMultiples(params: any) {
    console.log(params);
    return this._http.post(this.url + 'UsosMultiples', params, { responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  getCarroid(id: any): Observable<any> {
    return this._http.get(this.url + 'Carros/GetById?carId=' + id, { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  putCarros(params: Carro) {
    return this._http.post(this.url + 'Carros/Put', params, { responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  deleteCar(carId: Number) {
    return this._http.post(this.url + 'Carros/Delete', carId, { responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }
  addAndPrintLabels(carId: Number, quantity: Number): Observable<any>  {
    return this._http.get(this.url + `Carros/PostLabelPart?carId=${carId}&cantidad=${quantity}`, { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }
  //Ebay
  getLoginEbay(): Observable<any> {
    return this._http.get(this.url + 'Ebay/GetSesionID', { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  PostLogOutEbay(): Observable<any> {
    return this._http.post(this.url + 'Ebay/DeleteEbayToken', null, { responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  PostCancelarJob(): Observable<any> {
    return this._http.post(this.url + 'Ebay/DeleteJob', null, { responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  postSesionID(params: any) {
    return this._http.post(this.url + 'Ebay/SaveEbayToken', params, { responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  postImporParts() {
    return this._http.post(this.url + 'Ebay', null, { responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  getEmpresa(): Observable<any> {
    return this._http.get(this.url + 'Empresas/GetMyEmpresa', { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  getTotalToExport(justMiParts: boolean = false): Observable<any> {
    return this._http.get(this.url + 'Ebay/GetTotalToExport?justMiParts=' + justMiParts, { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }
  //

  getUsosMultiples(grupo: string): Observable<any> {
    return this._http.get(this.url + 'UsosMultiples?grupo=' + grupo, { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  deleteIMG(model: any) {
    console.log(model);
    return this._http.post(this.url + 'Carros/PutBorrarFoto', JSON.stringify(model), { responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  deleteProIMG(model: any) {
    console.log(model);
    return this._http.post(this.url + 'Piezas/PutBorrarFoto', JSON.stringify(model), { responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  getAdminHome(): Observable<any> {
    return this._http.get(this.url + 'Empresas/GetAdminHome', { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  getUsersCompany(nombre?: string, statusID?: number, rolID?: number): Observable<any> {
    return this._http.get(`${this.url}Empresas/GetUser?status=${statusID}&nombre=${nombre}&rol=${rolID}`, { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }


  //Roles
  getRoleID(id: number): Observable<any> {
    return this._http.get(this.url + 'Roles/GetByID?id=' + id, { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  getRoles(): Observable<any> {
    return this._http.get(this.url + 'Roles', { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  postRoles(params: any) {
    console.log(params);
    return this._http.post(this.url + 'Roles', params, { responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  borrarRol(params: Rol): Observable<any> {
    return this._http.post(this.url + 'Roles/Delete', params, { responseType: 'text', headers: { 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  //Permisos
  getPermisos(): Observable<any> {
    return this._http.get(this.url + 'Roles/GetPermisos', { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  postPermisos(params: any) {
    console.log(params);
    return this._http.post(this.url + 'Roles/PostPermisos', params, { responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  borrarRolPermiso(params: any): Observable<any> {
    return this._http.post(this.url + 'Roles/DeleteRolPermiso', params, { responseType: 'text', headers: { 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  addRolPermiso(params: any): Observable<any> {
    return this._http.post(this.url + 'Roles/PostRolPermiso', params, { responseType: 'text', headers: { 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  //UserCompanies
  postCompaniUsers(params: any) {
    return this._http.post(this.url + 'Empresas/PostUser', params, { responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }
  getCompaniUsersID(id: number): Observable<any> {
    return this._http.get(this.url + 'Empresas/GetUserByID?usuId=' + id, { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }
  editCompaniUsers(params: any) {
    return this._http.post(this.url + 'Empresas/EditUser', params, { responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }
  deleteCompaniUsers(params: any): Observable<any> {
    return this._http.post(this.url + 'Empresas/DeleteUser', params, { responseType: 'text', headers: { 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }
  reActiveCompaniUsers(params: any): Observable<any> {
    return this._http.post(this.url + 'Empresas/ReActiveUser', params, { responseType: 'text', headers: { 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }


  //StoreSales
  getTerminosDePagos(): Observable<any> {
    return this._http.get(this.url + 'Facturas/GetTerminosPago', { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  PostFactura(params: PostFacturaViewModel) {
    return this._http.post(this.url + 'Facturas/PostFactura', params, { responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  getFacturaDetail(params: number): Observable<any> {
    return this._http.get(this.url + 'Facturas/GetByID?id=' + params, { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }

  Adminfacturas(desde:string, hasta:string):Observable<any>{
    return this._http.get(this.url + `Facturas/GetByEmpID?desde=${desde}&hasta=${hasta}`, { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") }});
  }

  PostFacturaDetalleTemp(params: FacturasDetalleTemp) {
    return this._http.post(this.url + 'Facturas/DetalleTemp', params, { responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }
  DeleteFacturaDetalleTemp(params: FacturasDetalleTemp) {
    return this._http.post(this.url + 'Facturas/DeleteDetalleTemp', params, { responseType: 'text', headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }
  getFacturaDetalleTemp(): Observable<any> {
    return this._http.get(this.url + 'Facturas/DetalleTemp', { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }
  getFacturaDetalleTempByParts(): Observable<any> {
    return this._http.get(this.url + 'Facturas/DetalleTempParts', { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }
  PostCustomer(params: Usuario): Observable<any> {
    return this._http.post(this.url + 'Usuarios/PostUserStore', params, { headers: { 'Content-Type': ' application/json-patch+json', 'Authorization': "Bearer " + localStorage.getItem("token") } });
  }
}
