
    <div class="d-flex flex-row m-0">
        <div>
            <nav
            style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
            aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a routerLink="/Dashboard">Dashboard</a></li>
              <li class="breadcrumb-item active" aria-current="page">eBay</li>
            </ol>
          </nav>
            <h2 class="pb-2 border-bottom">Ebay Settings : {{userEbay}}</h2>

            <div *ngIf="isConfig == false"  class="alert alert-warning alert-dismissible fade show" role="alert">
                <strong>Not configured yet!</strong> 
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
            
            <div *ngIf="isConfig == true" class="alert alert-info" role="alert">
                <p></p>
                <p>{{info}}</p>
            </div>

            <div id="root">

                <div class="row justify-content-center mt-4">

                    <div *ngIf="isConfig == false" id="login_d" class="card col-12 col-sm-12 col-md-5 col-lg-5 mx-3" >
                        <img src="https://ir.ebaystatic.com/cr/v/c1/ebay-logo-1-1200x630-margin.png" class="card-img-top" alt="...">
                        <div class="card-body">
                        <h5 class="card-title">Configure</h5>
                        <p class="card-text">Log in with your ebay account to be able to import your car parts to our platform in a safe way.</p>
                        <a (click)="getLogin()" class="btn btn-primary text-white">Login with EBAY</a>
                        </div>
                    </div>

                    <div *ngIf="isConfig == true" id="import_d" class="card col-12 col-sm-12 col-md-5 col-lg-5 mx-3">
                        <img src="https://ir.ebaystatic.com/cr/v/c1/ebay-logo-1-1200x630-margin.png" class="card-img-top" alt="...">
                        <div class="card-body">
                        <h5 class="card-title">Import car parts</h5>
                        <p class="card-text">Import all your car parts from Ebay (they will be imported in batches of 200 in 200)</p>
                        <div>
                            <a (click)="StarImport()"  class="btn btn-primary text-white">{{importText}}</a>
                            <a (click)="LogOut()"  class="btn btn-danger text-white ms-3">LogOut</a>
                        </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>  
        
        <div *ngIf="loading" id="loader" class="Divloader">
            <div class="loader">
                <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>  

        <div *ngIf="importing"  class="Divloader">
            <div class="loader">
                <div class="d-flex justify-content-center">
                    <div class="card" style="width: 30rem;">
                        <div class="card-body">
                          <h2 class="card-title">Importing parts...!</h2>
                          <p class="card-text">This may take a long time, Don't worry... you can work on other things while!</p>
                          <h5 class="card-title">{{info}}</h5>
                          <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                         </div>
                         <br/>
                          <a (click)="cancelarBtn()" class="btn btn-danger text-white">Cancel</a>
                        </div>
                      </div>
                </div>
            </div>
        </div>  
              
    </div>


