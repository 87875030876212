<app-navbar></app-navbar>
<div id="content">
  <div class="d-flex align-items-center">
    <nav
      style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
      aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/Dashboard">Dashboard</a></li>
        <li class="breadcrumb-item active" aria-current="page">Parts</li>
      </ol>
    </nav>
  </div>
</div>
<hr>
<div class="row marginX">
  <div class="d-flex flex-row justify-content-between">

    <div class="d-flex flex-row bd-highlight">
      <div class="bd-highlight">
        <h2 class="text-primary">Parts</h2>
      </div>
      <div class="bd-highlight">
        <button type="button" class="btn btn-outline-primary btn-lg ms-1" [routerLink]="['/addpieza']">add <i
            class="fa fa-plus"></i>
        </button>
      </div>
    </div>

    <div class="bd-highlight">
      <h3 class="text-primary">Total Parts: {{count | number}}</h3>
    </div>
  </div>

</div>
<div class="row marginX">

  <form [formGroup]="myForm">
    <div class="my-0 row">

      <div class="col-8 col-sm-8 col-md-10 col-lg-10 mt-4">
        <h6 class="text-primary">Search</h6>
        <div class="input-group mb-3">
          <input type="text" class="form-control" id="tbxBuscar" placeholder="Search..." aria-label="Recipient's username"
          aria-describedby="button-addon2" (keydown.enter)="handlePageChange(1)">
          <button class="btn btn-primary text-white" type="button" (click)="OpenCamera()" ><i class="fa fa-qrcode" aria-hidden="true"></i></button>
        </div>
        <button hidden id="openCamera_btn" type="button" data-bs-toggle="modal" data-bs-target="#scanPopUp"></button>

      </div>

      <div class="col col-sm col-md col-lg nav-item mt-3">
        <h6 class="text-primary">Status</h6>

        <ng-multiselect-dropdown name="statusf" formControlName="statusf" [(ngModel)]="this.currentStatus"
          [placeholder]="'Select status'" [settings]="{      
          closeDropDownOnSelection: true, 
          singleSelection: true,
          idField: 'estEstado',
          textField: 'estDescripcion',
          itemsShowLimit: 1,
          allowSearchFilter: true}" [data]="status"appSelectAutoFoucs></ng-multiselect-dropdown
          >
      </div>

      <div class="col-4 col-sm-4 col-md-2 col-lg-2 nav-item mt-3">
        <h6 class="text-primary">Year</h6>

        <ng-multiselect-dropdown name="year" formControlName="year" [(ngModel)]="this.currentYear"
          [placeholder]="'Select year'" [settings]="{      
            closeDropDownOnSelection: true,
            singleSelection: true,
            idField: 'year',
            textField: 'year',
            itemsShowLimit: 1,
            allowSearchFilter: true}" [data]="years"appSelectAutoFoucs></ng-multiselect-dropdown>


      </div>


      <div class="col-4 col-sm-4 col-md-2 col-lg-2 nav-item mt-3 ">
        <h6 class="text-primary ">Make</h6>

        <ng-multiselect-dropdown [disabled]="marListo" name="marcaf" formControlName="marcaf"
          [(ngModel)]="this.currentMarca" [placeholder]="'Select make'" [settings]="{   
          closeDropDownOnSelection: true,   
          singleSelection: true,
          idField: 'marID',
          textField: 'marDescripcion',
          itemsShowLimit: 1,
          allowSearchFilter: true}" [data]="marca" (onDeSelect)="UnSelectObject($event)"
          (onSelect)="cargarModelo($event)"appSelectAutoFoucs></ng-multiselect-dropdown>

      </div>

      <div class="col-4 col-sm-4 col-md-2 col-lg-2 nav-item mt-3">
        <h6 class="text-primary">Model</h6>

        <ng-multiselect-dropdown name="modelof" formControlName="modelof" [(ngModel)]="this.currentModelo"
          [placeholder]="'Select model'" [settings]="{      
          closeDropDownOnSelection: true, 
          singleSelection: true,
          idField: 'modID',
          textField: 'modDescripcion',
          itemsShowLimit: 1,
          allowSearchFilter: true}" [data]="modelo"appSelectAutoFoucs></ng-multiselect-dropdown>

      </div>

      <div class="col-4 col-sm-4 col-md-2 col-lg-2 nav-item mt-3">
        <h6 class="text-primary">Category</h6>

        <ng-multiselect-dropdown name="catf" formControlName="catf" [(ngModel)]="this.currentCategory"
          [placeholder]="'Select Category'" [settings]="{      
          closeDropDownOnSelection: true, 
          singleSelection: true,
          idField: 'catID',
          textField: 'catDescripcion',
          itemsShowLimit: 1,
          allowSearchFilter: true}" [data]="categoria" (onDeSelect)="UnSelectObject($event)"
          (onSelect)="cargarPart($event)"appSelectAutoFoucs></ng-multiselect-dropdown>
      </div>

      <div class="col-4 col-sm-4 col-md-2 col-lg-2 nav-item mt-3">
        <h6 class="text-primary">Part</h6>

        <ng-multiselect-dropdown name="partf" formControlName="partf" [(ngModel)]="this.currentPart"
          [placeholder]="'Select Part'" [settings]="{      
          closeDropDownOnSelection: true, 
          singleSelection: true,
          idField: 'piePreId',
          textField: 'piePreNombre',
          itemsShowLimit: 1,
          allowSearchFilter: true}" [data]="parts"appSelectAutoFoucs></ng-multiselect-dropdown>
      </div>

      <div class="col-4 col-sm-4 col-md-2 col-lg-2 nav-item mt-3">
        <h6 class="text-primary text-white">buscar</h6>
        <div class="input-group mb-3">
          <button (click)="handlePageChange(1)" class="btn btn-primary text-white" style="width: 70%;">Search </button>
          <button class="btn btn-danger text-white" style="width: 30%;" (click)="Mispiezas(true)">x</button>
        </div>

      </div>

      <hr style="border: black; border-width: 1px;" />

    </div>
  </form>

</div>

<div class="row flex-nowrap marginX">
  <div class="row">
    <div class="d-flex justify-content-center" *ngIf="cargar; else elseBlock">
      <div class="spinner-border text-primary  m-3" role="status" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div *ngIf="count == 0" class="py-2 d-flex justify-content-center">
      <div class="d-flex flex-column justify-content-center">
        <h3 class="d-flex justify-content-center">Data not found.</h3>
        <img class="d-flex justify-content-center" src="../../../assets/img/phvp.png" width="200" height="200" alt="">
      </div>
    </div>
    <ng-template #elseBlock>

      <div class="card mb-3"
        *ngFor="let piezas of productos | paginate : { itemsPerPage: pageSize, currentPage: page, totalItems: count  }; let i = index">
        <div class="row g-0">
          <div class="col-4 col-sm-5 col-md-3 col-lg-3 d-flex align-items-center">
            <img src="{{piezas.imagen1}}" class="card-img-top ml-5 mt-2 mb-2 fit-image img-fluid rounded-start"
              alt="...">
          </div>
          <div class="col-8 col-sm-8 col-md-9 col-lg-9 d-flex align-items-center">
            <div class="card-body">
              <a [routerLink]="['/editpieza',piezas.pieId]">
                <p class="Nombre-text">{{piezas.pieNombre}}</p>
              </a>
              <p class="secom-text"><small class="text-muted">{{piezas.marcaString}} {{piezas.modeloString}}
                  {{piezas.anoCarro}}, {{piezas.categoriaString}}
                  <img class="mb-1 pt-0" src="https://vehiclepart.shop/assets/img/LogovehiclesParts.jpg" height="20"
                    width="20" alt="..." *ngIf="piezas.pieTipoIntegracion ==0 || piezas.pieTipoIntegracion ==2;">
                  <img class="my-0 align-middle" src="https://ir.ebaystatic.com/cr/v/c1/ebay-logo-1-1200x630-margin.png"
                    height="30" width="60" alt="..." *ngIf="piezas.pieTipoIntegracion ==1 || piezas.pieTipoIntegracion ==2;">
                </small></p>
              <hr class="my-0">

              <div class="row mt-0">

                <div class="col-6 col-sm-6 col-md-6 col-lg-6 d-flex align-items-center my-0 py-0">
                  <div class="card-body marginY py-0">
                    <p class="secom-text my-1"><small class="text-muted">Price: {{piezas.piePrecio | currency}}</small>
                    </p>
                    <p class="secom-text my-1"><small class="text-muted">In Stoke: {{piezas.pieDisponible}}</small></p>
                    <p class="secom-text my-1"><small class="text-muted">Shelf/Location: {{piezas.pieLocation}}</small>
                    </p>
                  </div>
                </div>

                <div class="col-6 col-sm-6 col-md-6 col-lg-6 d-flex align-items-center my-0">
                  <div class="card-body marginY">
                    <p class="mb-1 marginY align-middle Nombre-text">
                      <span class="text-muted align-middle secom-text">Status: </span>
                      <small class="align-middle">
                        <ng-container [ngSwitch]="piezas.pieEstado">
                          <ng-container *ngSwitchCase="1">
                            <span class="badge rounded-pill bg-secondary">{{piezas.estadoString}}</span>
                          </ng-container>
                          <ng-container *ngSwitchCase="2">
                            <span class="badge rounded-pill bg-warning">{{piezas.estadoString}}</span>
                          </ng-container>
                          <ng-container *ngSwitchCase="3">
                            <span class="badge rounded-pill bg-primary">{{piezas.estadoString}}</span>
                          </ng-container>
                          <ng-container *ngSwitchCase="4">
                            <span class="badge rounded-pill bg-danger">{{piezas.estadoString}}</span>
                          </ng-container>
                        </ng-container>
                      </small>
                    </p>
                    <p class="secom-text my-1"><small class="text-muted">Part VIN: {{piezas.pieVIN}}</small></p>
                    <p class="secom-text my-1"><small class="text-muted">Year: {{piezas.pieAnoDesde}} -
                        {{piezas.pieAnoHasta}}</small></p>
                  </div>
                </div>

              </div>

              <div class="d-grid gap-2 d-md-flex justify-content-md-star my-0">
                <button class="btn btn-success btn-lg my-0" [routerLink]="['/editpieza',piezas.pieId]"> <i
                    class="fa fa-edit"></i></button>
                <button class="btn btn-success btn-lg my-0" (click)="imprSelec(piezas.pieId)"> <i class="fa fa-print"></i></button>
                <button *ngIf="piezas.pieEstado==3" class="btn btn-warning btn-lg my-0" (click)="AddDetalleTemp(piezas)"> <i class="fa fa-cart-arrow-down"></i></button>
                <button class="btn btn-danger btn-lg" (click)="deletePart(piezas.pieId)"> <i class="fa fa-trash"></i></button>
              </div> 

            </div>

          </div>

          <!--toPrint-->
          <div id="{{piezas.pieId}}" style="display: none; ">
              <div style="width: 100%;">
                <div>
                  <p style="font-size: 18px;">{{piezas.pieNombre}}</p>
                  <p style="font-size: 16px;">{{piezas.mainCarString}}</p>
                  <hr>

                  <div style="width: 100%;">
    
                    <div style="width: 40%; display: inline-block; zoom: 1; vertical-align: top; font-size: 17px;">
                      <p>Mark:  {{piezas.marcaString}}</p>
                      <p>Model:  {{piezas.modeloString}}</p>        
                      <p>Year:  {{piezas.pieAnoDesde}} - {{piezas.pieAnoHasta}}</p>                  
                      <p>Shelf/Location:  {{piezas.pieLocation}}</p>
                    </div>
    
                    <div style="width: 40%; display: inline-block; zoom: 1; vertical-align: top; font-size: 17px;">
                      <p>Condition:  {{piezas.pieCondicion}}</p>
                      <p>Part VIN:  {{piezas.pieVIN}}</p>
                      <p>Grade:  {{getsenalUso(piezas)}}</p>
                      <p>Color:  {{piezas.pieColor}}</p>
                    </div>

                    <div style="width: 20%; display: inline-block; zoom: 1; vertical-align: top; font-size: 12px; align-items: center;">
                      <!-- <img id="imgPrint{{piezas.pieId}}" style="width: 50%; height: 50%;" >  -->
                      <qrcode [elementType]="'img'" [qrdata]="piezas.pieId.toString()" [width]="150" [errorCorrectionLevel]="'M'" ></qrcode>
                      <p style="text-align: center; margin-left: 20px; margin-top: -15px;">{{piezas.pieId}}</p>
                    </div>
    
                  </div>
  
                </div>
              </div>
          </div>

        </div>
      </div>

    </ng-template>
    <style>
      .my-pagination /deep/ .ngx-pagination .current {
        background: #008c44;
      }
    </style>
    <div class="col-12 mt-3 d-flex justify-content-center">
      <pagination-controls class="my-pagination" previousLabel="Prev" nextLabel="Next" [responsive]="true"
        (pageChange)="handlePageChange($event)">
      </pagination-controls>
    </div>


        <!-- ModalScan -->
        <div class="modal fade modal-xl" id="scanPopUp" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5">Scan Shelf/Location</h1>
              <button type="button" class="btn-close" (click)="closeCamera()"></button>
              <button hidden id="closeCamera_btn" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
    
            <div class="modal-body">
              <div *ngIf="torchEnabled">
                <zxing-scanner 
                [enable]="torchEnabled"
                [torch]="torchEnabled" 
                [device]="deviceCurrent" 
                (deviceChange)="onDeviceChange($event)"
                (scanSuccess)="onCodeResult($event)"
                [formats]="formatsEnabled" 
                [tryHarder]="false" 
                (permissionResponse)="onHasPermission($event)"
                (camerasFound)="onCamerasFound($event)" 
                (torchCompatible)="onTorchCompatible($event)">
                </zxing-scanner>
            
            
                <button *ngIf="availableDevices.length > 1" (click)="onDeviceSelectChange()" type="button" class="btn btn-secondary"><i class="fa fa-retweet" aria-hidden="true"></i></button>
            
              </div>
    
            </div>

            <div class="d-flex justify-content-center" *ngIf="cargar;">
              <div class="spinner-border text-primary  m-3" role="status" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>

          </div>
        </div>
      </div>