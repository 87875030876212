export class vwEmpresas {
  empID: number;
  empNombre: string;
  empDireccion: string;
  empEstatus?: number;
  empEstatusString: string;
  empDireccion2: string;
  empDireccion3: string;
  empRNC: string;
  empTelefono: string;
  empFax: string;
  empEmail: string;
  campo1: string;
  campo2: string;
  campo3?: number;
  campo4?: number;
  empFechaUltimaActualizacion?: Date;
  usuInicioSesion: string;
  empZipCode: string;
  empEstado: string;
  empCiudad: string;
  paiID?: number;
  empLogoPath: string;
  empPortadaPath: string;
  empFechaCreacion?: Date;
  eBaytoken: string;
  eBaytokenExpiracion?: Date;
  eBayUser: string;

  constructor() {
    this.empID = 0;
    this.empNombre = '';
    this.empDireccion = '';
    this.empEstatus = 0;
    this.empEstatusString = '';
    this.empDireccion2 = '';
    this.empDireccion3 = '';
    this.empRNC = '';
    this.empTelefono = '';
    this.empFax = '';
    this.empEmail = '';
    this.campo1 = '';
    this.campo2 = '';
    this.campo3 = 0;
    this.campo4 = 0;
    this.empFechaUltimaActualizacion = new Date();
    this.usuInicioSesion = '';
    this.empZipCode = '';
    this.empEstado = '';
    this.empCiudad = '';
    this.paiID = 0;
    this.empLogoPath = '';
    this.empPortadaPath = '';
    this.empFechaCreacion = new Date();
    this.eBaytoken = '';
    this.eBaytokenExpiracion = new Date();
    this.eBayUser = '';
  }
}
