import { Component, OnInit,ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { LocationService } from '../../services/location.service';
import { ProductosService, datosPieza, Modelo } from '../../services/productos.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router,ActivatedRoute } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AdminService, filtros } from '../../services/admin.service';

import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { BehaviorSubject } from 'rxjs';
import { BarcodeFormat } from '@zxing/library';

@Component({
  selector: 'app-addpieza',
  templateUrl: './addpieza.component.html',
  styleUrls: ['./addpieza.component.scss']
})
export class AddpiezaComponent implements OnInit {

  @ViewChild('scanner', { static: false })
  scanner: ZXingScannerComponent;

    //scam
    availableDevices: MediaDeviceInfo[] = [];
    deviceCurrent: MediaDeviceInfo;
    deviceSelected: string;
    formatsEnabled: BarcodeFormat[] = [
      BarcodeFormat.CODE_128,
      BarcodeFormat.DATA_MATRIX,
      BarcodeFormat.EAN_13,
      BarcodeFormat.QR_CODE,
    ];
    hasDevices: boolean;
    hasPermission: boolean;
    torchEnabled = false;
    torchAvailable$ = new BehaviorSubject<boolean>(false);

  datosBuscar: filtros = {

    vin: '',
    mod: '',
    mar: '',
    status: 1,
    anos: '',
    pageNumber: 1,
    pageSize: 20


  };

  autoSelecCar = false;
  carros: any;
  Currentcarro: any;
  currentMarcaCar: any[] = [];
  currentModeloCar: any[] = [];
  currentYearCar: any[] = [];

  currentStatus: any[] = [];
  isStatusPost = false;
  currentYearTo: any[] = [];
  currentYearFrom: any[] = [];
  currentMarca: any[] = [];
  currentModelo: any[] = [];
  currentCategory: any[] = [];
  currentPart: any[] = [];
  currentCondition: any[] = [];
  currentCarType: any[] = [];
  currentWarranty: any[] = [];
  currentProdType: any[] = [];
  currentPlacement: any[] = [];
  currentMaterial: any[] = [];
  currentFinish: any[] = [];
  currentIncluded: any[] = [];
  currentFeatures: any[] = [];

  conditions: any[] = [];
  carTypes: any[] = [];
  warranty: any[] = [];
  prodType: any[] = [];
  placements: any[] = [];
  materials: any[] = [];
  finishes: any[] = [];
  includedes: any[] = [];
  features: any[] = [];
  status: any[] = [];

  isAdmin = false;
  loadingup = false;

  years: number[] = [];
  pieza: FormGroup;
  myFormCar: FormGroup;
  parts: any;
  urls: string[];
  postUrl = new Array<string>();
  latitude: string;
  longitude: string;
  categoria: any;
  marca: any;
  modelo: any;
  modeloCar: any;
  marListo = true;
  catListo = true;
  loadingmark = true;
  imgResultBeforeCompress: string;
  imgResultAfterCompress: string


  datosPieza: datosPieza = {
    PieId: 0,
    CarId: 0,
    PieUsuID: 0,
    PieNombre: '',
    PieDescripcion: '',
    PiePrecio: 0,
    PieCosto: 0,
    PieFechaCreacion: new Date(),
    PieFechaActualizacion: new Date(),
    CatId: 0,
    ModelId: 0,
    EmpId: 0,
    PieEstado: 0,
    PieDisponible: 0,
    PieLocation: '',
    PieCondicion: '',
    PieVIN: '',
    PieCostoEnvio: 0,
    PieSeñalUso: '',
    PieLatitud: '',
    PieLongitud: '',
    PieMarcaFabricacion: '',
    PieGarantia: '',
    PieTipoProducto: 0,
    PieTipoProductoString: '',
    UsuId: 0,
    PieFoto: '',
    CategoriaString: '',
    ModeloString: '',
    MarcaString: '',
    MarId: 0,
    EstadoString: '',
    MainCarString: '',
    CarFoto: '',
    anoCarro: '',
    PiePeso: 0,
    PieImagenes: [],
    PieTipoIntegracion: 0,
    PieIntegracionReferencia: '',
    PieIntegracionReferencia2: '',
    PieIntegracionReferencia3: '',
    PieColor: '',
    PieMaterial: '',
    PiePlacementonVehicle: '',
    PieFinish: '',
    PieItemsIncluded: '',
    PieFeatures: '',
    PiePartType: '',
    PtypeDes: '',
    PFeaturesDes: '',
    PFinishDes: '',
    PItemsInDes: '',
    PMaterialDes: '',
    PPlacementDes: ''
  }

  model: Modelo = {
    idMarca: 0,
    marcastring: ''
  }
  htmlContent = '';
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '400px',
    sanitize: false,
    minHeight: '5rem',

    placeholder: 'Part description',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: 'Quote',
        class: 'quoteClass',
      },
      {
        name: 'Title Heading',
        class: 'titleHead',
        tag: 'h1',
      },
    ],
    toolbarHiddenButtons: [
      [
      ],
      [
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };

  constructor(private formBuilder: FormBuilder,
    private _productosService: ProductosService,
    private _locationService: LocationService,
    private location: Location,
    private _adminService: AdminService,
    private _activeRoute: ActivatedRoute,
    private _router: Router) { }

  ngOnInit(): void {
    var parametro = this._activeRoute.snapshot.queryParamMap.get('carVin');
    if (parametro != null){
      this.autoSelecCar = true;
      (<HTMLInputElement>document.getElementById('vinCar')).value = parametro;
      this.buscarcarro(true);
    }

    this.Currentcarro =  {
      carId: 0,
      carVIN : "",
      modeloString: "",
      marcaString: "",
      imagen1: ""
    }
    this.urls = [];
    this.getLocation();
    this.getMarca();
    this.getCategoria();
    this.selectYear();
    this.getStatus();
    this.getUsosMultiples("Condition");
    this.getUsosMultiples("TipoCarros");
    this.getUsosMultiples("Warranty");
    this.getUsosMultiples("Ptype");
    this.getUsosMultiples("PPlacement");
    this.getUsosMultiples("PMaterial");
    this.getUsosMultiples("PFinish");
    this.getUsosMultiples("PItemsIn");
    this.getUsosMultiples("PFeatures");

    this.pieza = this.formBuilder.group({
      marcaf: this.currentMarca,
      modelof: this.currentModelo,
      catf: this.currentCategory,
      conditionf: this.currentCondition,
      partf: this.currentPart,
      cartypef: this.currentCarType,
      warrantyf: this.currentWarranty,
      proTypef: this.currentProdType,
      Placementf: this.currentPlacement,
      materialf: this.currentMaterial,
      finishesf: this.currentFinish,
      includedf: this.currentIncluded,
      featuresf: this.currentFeatures,
      statusf: this.currentStatus,
      yearto: this.currentYearTo,
      yearfrom: this.currentYearFrom,
      htmlContent: this.htmlContent
    });

    this.myFormCar = this.formBuilder.group({
      marcaCar: this.currentMarcaCar,
      modeloCar: this.currentModeloCar,
      yearCar: this.currentYearCar
    });
    if (localStorage.getItem('empID') == null || localStorage.getItem('empID') == "0") {
      this.isAdmin = false;
    }
    else {
      this.isAdmin = true;
    }

    this.buscarcarro(true)
  }

  SelectCarro(carro) {
    this.Currentcarro = carro;

    console.log(this.Currentcarro);
    (<HTMLInputElement>document.getElementById('cloceSearc_btn')).click();
  }

  limpiarCar(){
    this.Currentcarro =  {
      carId: 0,
      carVIN : "",
      modeloString: "",
      marcaString: "",
      imagen1: ""
    }
  }

  buscarcarro(limpiar){

    if(limpiar){
      this.currentMarcaCar =[];
      this.currentModeloCar  =[];
      this.currentYearCar =[];
      (<HTMLInputElement>document.getElementById('vinCar')).value = "";
      this.datosBuscar.pageNumber = 1;
    }

    this.datosBuscar.vin = (<HTMLInputElement>document.getElementById('vinCar')).value;
    if (this.currentMarcaCar.length == 0){
      this.datosBuscar.mar="";
    } else{
      this.datosBuscar.mar= this.currentMarcaCar[0].marDescripcion;
    }

    if (this.currentModeloCar.length == 0){
      this.datosBuscar.mod="";
    } else{
      this.datosBuscar.mod= this.currentModeloCar[0].modDescripcion;
    }
    if (this.currentYearCar.length == 0){
      this.datosBuscar.anos="";
    }else{
      this.datosBuscar.anos= this.currentYearCar[0].toString(); 
    }

    this.loadingup = true;

    this._adminService.getBuscar(this.datosBuscar).subscribe(
      res =>{ 
        this.loadingup = false;
        
        const { results, totalRecordCount} = res;

        if(this.autoSelecCar){
          this.Currentcarro = results[0];
        }
        else{
          this.carros = results;
        }

        this.autoSelecCar = false;
      },
      err =>{
        if (err.error == 'Carga Completa') {
          this.carros = [];
        }
        else{
          if(err.error == 'You do not have permissions to access to Cars'){
            Swal.fire({
              icon: 'error',
              title:err.error,
              showConfirmButton: true
            }).then(()=>{
             // this.router.navigateByUrl('/Dashboard');
            })
           }
           else{
              this.carros = [];
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: err.error,
              showConfirmButton: true
            })          
           }
        }
        this.loadingup = false;
        console.log(err);
      }
    )

  }

  onPhotoCaptured(photo: any) {
    //this.urls.push(photo);
    //this.showCaptureComponent = false;
  }
  showCaptureComponent: boolean = false;

  onCaptureCancelled(photos: any) {
    photos.forEach((x) => {
      if(!this.urls.includes(x)){
        this.urls.push(x);
      }
    })
    this.showCaptureComponent = false;
  }

  startPhotoCapture() {
    this.showCaptureComponent = true;
  }


  detectFiles(event: any) {
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.urls.push(e.target.result);
        }
        reader.readAsDataURL(file);
      }
    }
    console.log(this.urls);
  }

  goBack(): void {
    this.location.back();
  }

  selectYear() {
    var fully = new Date().getFullYear();
    for (let y = fully; y >= 1970; y--) {
      this.years.push(y);
    }
  }

  removeItemFromArr(item) {
    var arr = this.urls;
    var i = arr.indexOf(item);
    if (i !== -1) {
      arr.splice(i, 1);
    }
    console.log(arr);
    arr = this.postUrl;
  }

  chooseFile() {
    document.getElementById("fileInput").click();
  }

  getLocation() {
    this._locationService.getPosition().then(pos => {
      this.latitude = String(pos.lat);
      this.longitude = String(pos.lng);
    });
  }

  getMarca() {
    this._productosService.getMarca().subscribe(
      res => {
        this.loadingmark = false;
        this.marListo = false;
        this.marca = res;
      }, err => {
        console.log(err);
      })
  }

  getCategoria() {
    this._productosService.getCategoria().subscribe(
      res => {
        this.catListo = false;
        this.categoria = res;
      }, err => {
        console.log(err);
      })
  }

  cargarModelo(item: any, isCar) {
    this.model.idMarca = item.marID;
    this._productosService.getModelo(this.model).subscribe(
      res => {
        if (isCar) {
          this.currentModeloCar = [];
          this.modeloCar = res;
        }
        else {
          this.currentModelo = [];
          this.modelo = res;
        }

      }, err => {
        this.currentModelo = [];
        this.currentModeloCar = [];
        console.log(err);
      }
    )
  }

  getUsosMultiples(grupo: string): any {
    this._adminService.getUsosMultiples(grupo).subscribe(
      (res: any) => {

        if (grupo == "Condition") {
          this.conditions = res;
        }
        else if (grupo == "TipoCarros") {
          this.carTypes = res;
        }
        else if (grupo == "Warranty") {
          this.warranty = res;
        }
        else if (grupo == "Ptype") {
          this.prodType = res;
        }
        else if (grupo == "PPlacement") {
          this.placements = res;
        }
        else if (grupo == "PMaterial") {
          this.materials = res;
        }
        else if (grupo == "PFinish") {
          this.finishes = res;
        }
        else if (grupo == "PItemsIn") {
          this.includedes = res;
        }
        else if (grupo == "PFeatures") {
          this.features = res;
        }
      }, err => {
        console.log(err);
      })

  }

  getStatus() {
    this._productosService.getStatus("Piezas").subscribe(
      (res: any) => {
        this.status = res;
      }, err => {
        console.log(err);
      })

  }

  verImagen(img): void {
    Swal.fire({
      imageUrl: img,
      imageWidth: '100%',
      imageHeight: '100%',
    })
  }

  UnSelectObject(item: any) {
    if (this.currentMarca.length == 0) {
      this.currentModelo = [];
      this.modelo = null;
    }

    if (this.currentMarcaCar.length == 0) {
      this.currentModeloCar = [];
      this.modeloCar = null;
    }

    if (this.currentCategory.length == 0) {

    }
  }
  

  Submit() {
    
    try {
      var loa = Swal.fire({
        title: 'Loading',
        showConfirmButton: false,
        allowOutsideClick: false,
        html: 'Please wait',
        
        timerProgressBar: true,
        didOpen: () => {
          //Swal.showLoading()
        },
        willClose: () => {
         
        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          console.log('I was closed by the timer')
        }
      })

      this.loadingup = true;
      this.datosPieza.PieLatitud = this.latitude;
      this.datosPieza.PieLongitud = this.longitude;

      var imagenes = [];
      this.urls.forEach((element) => {
        if (!element.includes("http")) {
          imagenes.push(element);
        }
      })
      this.datosPieza.PieImagenes = imagenes;
      //this.datosPieza.CarId = this.producto.carId;

      var nombre = String((<HTMLInputElement>document.getElementById('PieNombre')).value);
      if (nombre == "") {
        throw new TypeError("Part Name is required");
      }


      this.datosPieza.PieNombre = nombre;
      this.datosPieza.PieVIN = String((<HTMLInputElement>document.getElementById('PartNumber')).value);
      this.datosPieza.PieDescripcion = this.htmlContent;

      if (this.currentYearFrom.length == 0) {
        throw new TypeError("Select the year from of the part");
      } else {
        this.datosPieza.pieAnoDesde = Number(this.currentYearFrom[0]);
      }
      if (this.currentYearTo.length == 0) {
        this.datosPieza.pieAnoHasta = this.datosPieza.pieAnoDesde;
      } else {
        this.datosPieza.pieAnoHasta = this.currentYearTo[0];
      }

      if (this.currentMarca.length == 0) {
        throw new TypeError("Select mark of the part");
      } else {
        this.datosPieza.MarcaID = this.currentMarca[0].marID;
        this.datosPieza.MarId = this.currentMarca[0].marID;
      }
      if (this.currentModelo.length == 0) {
        throw new TypeError("Select model of the part");
      } else {
        this.datosPieza.ModelId = this.currentModelo[0].modID;
      }
      if (this.currentCategory.length == 0) {
        throw new TypeError("Select category of the part");
      } else {
        this.datosPieza.CatId = this.currentCategory[0].catID;
      }
      if (this.currentStatus.length == 0) {
        throw new TypeError("Select status of the part");
      } else {
        this.datosPieza.PieEstado = this.currentStatus[0].estEstado;
      }
      this.datosPieza.PiePrecio = Number((<HTMLInputElement>document.getElementById('PiePrecio')).value);

      this.datosPieza.PieCosto = Number((<HTMLInputElement>document.getElementById('pieCosto')).value);
      this.datosPieza.PieDisponible = Number((<HTMLInputElement>document.getElementById('PieDisponible')).value);
      this.datosPieza.PieLocation = String((<HTMLInputElement>document.getElementById('pieLocation')).value);
      if (this.currentCondition.length == 0) {
        throw new TypeError("Select the condition of the part");
      } else {
        this.datosPieza.PieCondicion = this.currentCondition[0].usoDescripcion;
      }
      this.datosPieza.PieColor = String((<HTMLOptionElement>document.getElementById('pieColor')).value);

      if (this.currentCarType.length == 0) {
        this.datosPieza.PieTipoCarro = 0;
      } else {
        this.datosPieza.PieTipoCarro = this.currentCarType[0].usoValor;
      }
      this.datosPieza.PieMarcaFabricacion = String((<HTMLOptionElement>document.getElementById('pieMarcaFabricacion')).value);
      this.datosPieza.PieSeñalUso = String((<HTMLOptionElement>document.getElementById('pieSeñalUso')).value);
      if (this.currentWarranty.length == 0) {
        this.datosPieza.PieGarantia = "";
      } else {
        this.datosPieza.PieGarantia = this.currentWarranty[0].usoDescripcion;
      }

      this.datosPieza.PiePartType = null;
      if (this.currentProdType.length == 0) {
        this.datosPieza.PiePartType = null;
      } else {
        this.currentProdType.forEach(element => {
          if (this.datosPieza.PiePartType == null) {
            this.datosPieza.PiePartType = `${element.usoValor}`;
          } else {
            this.datosPieza.PiePartType += `~${element.usoValor}`;;
          }
        });
      }

      this.datosPieza.PiePlacementonVehicle = null;
      if (this.currentPlacement.length == 0) {
        this.datosPieza.PiePlacementonVehicle = null;
      } else {
        this.currentPlacement.forEach(element => {
          if (this.datosPieza.PiePlacementonVehicle == null) {
            this.datosPieza.PiePlacementonVehicle = `${element.usoValor}`;
          } else {
            this.datosPieza.PiePlacementonVehicle = `${this.datosPieza.PiePlacementonVehicle}~${element.usoValor}`;
          }
        });
       // console.log(this.datosPieza.PiePlacementonVehicle);
      }

      this.datosPieza.PieMaterial = null;
      if (this.currentMaterial.length == 0) {
        this.datosPieza.PieMaterial = null;
      } else {
        this.currentMaterial.forEach(element => {
          if (this.datosPieza.PieMaterial == null) {
            this.datosPieza.PieMaterial = `${element.usoValor}`;
          } else {
            this.datosPieza.PieMaterial += `~${element.usoValor}`;;
          }
        });
      }

      this.datosPieza.PieFinish = null;
      if (this.currentFinish.length == 0) {
        this.datosPieza.PieFinish = null;
      } else {
        this.currentFinish.forEach(element => {
          if (this.datosPieza.PieFinish == null) {
            this.datosPieza.PieFinish = `${element.usoValor}`;
          } else {
            this.datosPieza.PieFinish += `~${element.usoValor}`;;
          }
        });
      }

      this.datosPieza.PieItemsIncluded = null;
      if (this.currentIncluded.length == 0) {
        this.datosPieza.PieItemsIncluded = null;
      } else {
        this.currentIncluded.forEach(element => {
          if (this.datosPieza.PieItemsIncluded == null) {
            this.datosPieza.PieItemsIncluded = `${element.usoValor}`;
          } else {
            this.datosPieza.PieItemsIncluded += `~${element.usoValor}`;;
          }
        });
      }

      this.datosPieza.PieFeatures = null;
      if (this.currentFeatures.length == 0) {
        this.datosPieza.PieFeatures = null;
      } else {
        this.currentFeatures.forEach(element => {
          if (this.datosPieza.PieFeatures == null) {
            this.datosPieza.PieFeatures = `${element.usoValor}`;
          } else {
            this.datosPieza.PieFeatures += `~${element.usoValor}`;
          }
        });
      }

      this.datosPieza.CarId = this.Currentcarro.carId;
      
      if(this.isAdmin && this.isStatusPost){
        if((document.getElementById("isEbay") as HTMLInputElement).checked){
        this.datosPieza.PieTipoIntegracion = 9;
        }else{
          this.datosPieza.PieTipoIntegracion = 0;
        }       
      }else{
        this.datosPieza.PieTipoIntegracion = 0;
      }


console.log(this.datosPieza)

      this._productosService.postPieza(this.datosPieza).subscribe(
        res => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Success',
            showConfirmButton: true,
          }).then(e => {
            if (this.isAdmin) {
              this._router.navigateByUrl('/Dashboard/Parts');
            }
            else {
              this._router.navigateByUrl('/profile');
            }

          })
        }, err => {
          console.error(err);
          this.loadingup = false;
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: err.error,
            showConfirmButton: true
          })
        })

    } catch (error) {
      console.error(error);
      this.loadingup = false;
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: error.message,
        showConfirmButton: true
      })

    }
  }

  UnSelectStatus(item: any) {
    this.isStatusPost = false;
  }

  SelectStatus(item: any) {
    if (item.estDescripcion == "Posted") {
        this.isStatusPost = true;
    }
    else{
      this.isStatusPost = false;
    }
  }

  OpenCamera() {
    this.torchEnabled = true;
    (<HTMLAnchorElement>document.getElementById("openCamera_btn")).click();
    console.log("open");   
  }

  closeCamera() {
    this.torchEnabled = false;
    (<HTMLAnchorElement>document.getElementById("closeCamera_btn")).click();
    console.log("close");  
  }
  
  onCamerasFound(devices: any): void {
    console.log(devices)
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);

    if(this.hasDevices == false){
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Unsupported device',
        showConfirmButton: true
      }).then(() =>{
        this.closeCamera()
      })
    }
  }

  onCodeResult(resultString: any) {
    
    (<HTMLInputElement>document.getElementById("pieLocation")).value =  resultString
    this.closeCamera();
  }

  onDeviceSelectChange() {
    if( this.availableDevices.length > 1){
      const device = this.availableDevices.filter(x => x.deviceId != this.deviceCurrent.deviceId)[0];
      this.deviceCurrent = device || undefined;
    }
  }

  onDeviceChange(device: any) {
    const selectedStr = device?.deviceId || '';
    if (this.deviceSelected === selectedStr) { return; }
    this.deviceSelected = selectedStr;
    this.deviceCurrent = device || undefined;
  }


  onHasPermission(has: any) {
    this.hasPermission = has;
    console.log(has)
    if(has == false){
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'We do not have permission to open the camera',
        showConfirmButton: true
      }).then(() =>{
        this.closeCamera()
      })
    }
  }

  onTorchCompatible(isCompatible: any): void {
    this.torchAvailable$.next(isCompatible || false);
    console.log("compati"+isCompatible)
  }
  

}
