<app-navbar></app-navbar>
<div class="p-5">
    <div>
        <h2 class="mt-4 mb-3">My orders</h2>
        </div>
        
        <div *ngIf="loading" class="row p-5">
            <div class="d-flex justify-content-center">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>

          <div *ngIf="noData" class="row p-5">
            <div class="d-flex justify-content-center">
              <h3>
                <span> oh! You have no orders.</span>
              </h3>
              <img src="../../../assets/img/phvp.png" width="500" height="500" alt="">
            </div>
          </div>


    <div class="accordion" id="accordionExample">
        <div class="accordion-item" *ngFor="let orden of ordenes;index as i">
          <h2 class="accordion-header" id="headingOne">
            <a class="accordion-button" type="button" data-bs-toggle="collapse" href="#collapse{{i}}" aria-expanded="true" aria-controls="collapseOne">
            ORDER #.{{orden.pedID}}&nbsp;&nbsp;&nbsp;&nbsp;TOTAL {{orden.pedTotal | currency}}&nbsp;&nbsp;&nbsp;&nbsp;ORDER PLACED.{{orden.pedFecha | date: 'shortDate' }}
            </a>
          </h2>
          <div id="collapse{{i}}" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <div class="card mb-3" *ngFor="let detalle of orden.detalle">
                <div class="row g-0">
                  <div class="col-md-2">
                    <img src="{{detalle.pedImagen}}" class="img-fluid rounded-start fit-image p-2" alt="...">
                  </div>
                  <div class="col-md-10">
                    <div class="card-body">
                      <h2 class="card-title">{{detalle.proNombre}}</h2>
                      <h4 class="card-text"><span>Quantity:</span> {{detalle.pedCantidad}}</h4> 
                      <h4 class="card-text"><span>Total:</span> {{detalle.pedMontoTotal | currency}}</h4> 
                      <hr>
                      <div class="progress" *ngIf="detalle.pedEstatusString == 'Ordered'">
                        <div class="progress-bar" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">Ordered</div>
                      </div>
                      <div class="progress" *ngIf="detalle.pedEstatusString == 'Shipped'">
                        <div class="progress-bar" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">Ordered</div>
                        <div class="progress-bar" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">Shipped</div>
                      </div>
                      <div class="progress" *ngIf="detalle.pedEstatusString == 'Process'">
                        <div class="progress-bar" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">Ordered</div>
                        <div class="progress-bar" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">Shipped</div>
                        <div class="progress-bar" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">Process</div>
                      </div>
                      <div class="progress" *ngIf="detalle.pedEstatusString == 'Delivered'">
                        <div class="progress-bar" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">Ordered</div>
                        <div class="progress-bar" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">Shipped</div>
                        <div class="progress-bar" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">Process</div>
                        <div class="progress-bar" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">Delivered</div>
                      </div>
                      <h4 class="card-text"><span>Traking Number:</span> {{detalle.pedNumTracking}}</h4> 
                      <p class="card-text"><small class="text-muted">Last updated {{orden.pedFecha | date: 'shortTime' }}, {{orden.pedFecha | date: 'shortDate' }}</small></p>
                      <hr>
                      <h5 class="text-center">More details</h5>
                      <h5 class="card-text"><span>Shipping Cost:</span></h5> 
                      <h5 class="card-text"><span>-----:</span> </h5> 
                    </div>
                  </div>
                </div>
              </div>

          
            </div>
          </div>
        </div>
      </div>
</div>


