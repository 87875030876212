import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AdminService, Rol } from '../../../services/admin.service'
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-roles-crear',
  templateUrl: './roles-crear.component.html',
  styleUrls: ['./roles-crear.component.scss'],
})
export class RolesCrearComponent implements OnInit {
  rolePermisos: any[] = [];
  currenPermisos: any[] = [];
  estListo = false;
  loading = false;
  myForm: FormGroup;

  buscarRoles: Rol = {
    rolPermisos: [],
    rolDescripcion: '',
    rolId:0
  };
  constructor(
    private _fb: FormBuilder,
    private service: AdminService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.myForm = this._fb.group({
      permisosS: this.currenPermisos,
    });
    this.getPermisos();
  }
  getPermisos() {
    this.loading = true;
    this.service.getPermisos().subscribe(
      (res) => {
        this.rolePermisos = res;
        this.loading = false;

        console.log(res);
      },
      (err) => {
        if(err.error.includes('You do not have permissions to access to ')){
          Swal.fire({
            icon: 'error',
            title:err.error,
            showConfirmButton: true
          }).then(()=>{
            this._router.navigateByUrl('/Dashboard');
          })
         }else{
            Swal.fire({
            icon: 'error',
            title:err.error,
            showConfirmButton: true
          })         
         }

         this.loading = false;
        console.error(err.error);
      }
    );
  }

  Submit() {
    try {
      this.loading = true;

      if (this.currenPermisos.length == 0) {
        throw new Error('Selet any permit');
      }
      if (
        (<HTMLOptionElement>document.getElementById('RDescription')).value == ''
      ) {
        throw new Error('Description is necesary');
      }

      this.buscarRoles.rolPermisos = this.currenPermisos;

      this.buscarRoles.rolDescripcion = (<HTMLOptionElement>(
        document.getElementById('RDescription')
      )).value;

      this.service.postRoles(this.buscarRoles).subscribe(
        (res) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Done',
            showConfirmButton: true,
          }).then((th) => {
            this._router.navigateByUrl('/Dashboard/roles');
          });
        },
        (err) => {
          this.loading = false;
          if(err.error.includes('You do not have permissions to access to ')){
            Swal.fire({
              icon: 'error',
              title:err.error,
              showConfirmButton: true
            }).then(()=>{
              this._router.navigateByUrl('/Dashboard');
            })
           }else{
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: err.error,
              showConfirmButton: false,
              timer: 1500,
            });           
           }

        }
      );
    } catch (error) {
      this.loading = false;
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: error,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }
}
