export class Pieza {

  pieId: number;
  carId?: number;
  pieUsuID?: number;
  pieNombre: string;
  pieDescripcion: string;
  piePrecio: number;
  pieCosto = 0;
  pieFechaCreacion: Date;
  pieFechaActualizacion?: Date | null;
  catId: number;
  modelId: number;
  marcaID: number;
  empId: number;
  pieEstado: number;
  pieLocation: string;
  pieCondicion: string;
  pieVIN: string;
  pieCostoEnvio: number;
  pieSeñalUso: string;
  pieLatitud: string;
  pieLongitud: string;
  pieMarcaFabricacion: string;
  pieGarantia: string;
  pieTipoProducto: number;
  usuId: number;
  piePeso: number;
  pieTipoIntegracion: number;
  pieIntegracionReferencia: string;
  pieIntegracionReferencia2: string;
  pieIntegracionReferencia3: string;
  pieAnoDesde: number;
  pieAnoHasta: number;
  pieColor: string;
  pieTipoCarro: number;
  pieMaterial: string;
  piePlacementonVehicle: string;
  pieFinish: string;
  pieItemsIncluded: string;
  pieFeatures: string;
  piePartType: string;
  ptypeDes: string;
  pFeaturesDes: string;
  pFinishDes: string;
  pItemsInDes: string;
  pMaterialDes: string;
  pPlacementDes: string;
  pieDisponible: number;
  pieTipoProductoString: string;
  pieFoto: string;
  categoriaString: string;
  modeloString: string;
  marcaString: string;
  marId: number;
  estadoString: string;
  mainCarString: string;
  carFoto: string;
  anoCarro: string;
  pieImagenes: string[]
  facTotal: number;
  facCantidad: number;
  constructor() {

    this.pieId = 0;
    this.carId = 0;
    this.pieUsuID = 0;
    this.pieNombre = '';
    this.pieDescripcion = '';
    this.piePrecio = 0;
    this.pieCosto = 0;
    this.pieFechaCreacion = new Date();
    this.pieFechaActualizacion = new Date();
    this.catId = 0;
    this.modelId = 0;
    this.marcaID = 0;
    this.empId = 0;
    this.pieEstado = 0;
    this.pieLocation = '';
    this.pieCondicion = '';
    this.pieVIN = '';
    this.pieCostoEnvio = 0;
    this.pieSeñalUso = '';
    this.pieLatitud = '';
    this.pieLongitud = '';
    this.pieMarcaFabricacion = '';
    this.pieGarantia = '';
    this.pieTipoProducto = 0;
    this.usuId = 0;
    this.piePeso = 0;
    this.pieTipoIntegracion = 0;
    this.pieIntegracionReferencia = '';
    this.pieIntegracionReferencia2 = '';
    this.pieIntegracionReferencia3 = '';
    this.pieAnoDesde = 0;
    this.pieAnoHasta = 0;
    this.pieColor = '';
    this.pieTipoCarro = 0;
    this.pieMaterial = '';
    this.piePlacementonVehicle = '';
    this.pieFinish = '';
    this.pieItemsIncluded = '';
    this.pieFeatures = '';
    this.piePartType = '';
    this.ptypeDes = '';
    this.pFeaturesDes = '';
    this.pFinishDes = '';
    this.pItemsInDes = '';
    this.pMaterialDes = '';
    this.pPlacementDes = '';
    this.pieDisponible = 0;
    this.pieTipoProductoString = '';
    this.pieFoto = '';
    this.categoriaString = '';
    this.modeloString = '';
    this.marcaString = '';
    this.marId = 0;
    this.estadoString = '';
    this.mainCarString = '';
    this.carFoto = '';
    this.anoCarro = '';
    this.pieImagenes = [];
    this.facTotal = 0;
    this.facCantidad =0;
  }

}

export class piezasBuscar {
  maincar: number;
  status: number;
  cat: number;
  mo: string;
  mar: string;
  anos: string;
  nombre: string;
  pageNumber: number;
  pageSize: number

  constructor() {

    this.maincar = 0;
    this.status = 0;
    this.cat = 0;
    this.mo = '';
    this.mar = '';
    this.anos = '';
    this.nombre = '';
    this.pageNumber = 1;
    this.pageSize = 20

  }
}
