import { Component, OnInit,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ProductosService, Modelo } from '../../../services/productos.service';
import { AdminService } from '../../../services/admin.service';
import { piezasBuscar } from '../../../Entities/Pieza';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { BehaviorSubject } from 'rxjs';
import { BarcodeFormat } from '@zxing/library';
import { FacturasDetalleTemp } from '../../../Entities/FacturaDetalle';

import { CommunicationServiceService } from '../../../services/communication-service.service';

@Component({
  selector: 'app-admin-partes',
  templateUrl: './admin-partes.component.html',
  styleUrls: ['./admin-partes.component.scss'],
})


export class AdminPartesComponent implements OnInit {

  @ViewChild('scanner', { static: false })
  scanner: ZXingScannerComponent;
  
  //scam
  availableDevices: MediaDeviceInfo[] = [];
  deviceCurrent: MediaDeviceInfo;
  deviceSelected: string;
  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];
  hasDevices: boolean;
  hasPermission: boolean;
  torchEnabled = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);

  currentStatus: any[] =[];
  currentYear: string[] = [];
  currentMarca: any[] =[];
  currentModelo: any[] =[];
  currentCategory: any[] = [];
  currentPart: any[] =[];

  myForm:FormGroup;

  noData = false;

  years: number[]= [];
  marListo=true;
  catListo=true;
  categoria:any;
  marca:any;
  modelo:any;
  parts: any;

  productos: any[] = [];
  status: any[] = [];
  cargar = true;
  title = '';
  page = 1;
  count = 0;
  pageSize = 20;
  loadrecom = true;
  token: any;


  mispiezas = new piezasBuscar();

  Modelo: Modelo ={

    idMarca : 0,
    marcastring: ""
   
  }
  
  constructor(
    private _productosService: ProductosService,
    private _adminService: AdminService,
    private router: Router, private _fb: FormBuilder,
    private communicationService: CommunicationServiceService, 
  ) {

   }

  ngOnInit(): void { 
    this.myForm = this._fb.group({
      year:this.currentYear,
      marcaf: this.currentMarca,
      modelof: this.currentModelo,
      catf: this.currentCategory,
      partf: this.currentPart,
      statusf: this.currentStatus
  });
    (<HTMLOptionElement>document.getElementById('tbxBuscar')).focus();
    window.scroll(0, 0); 
    this.Mispiezas(false);
    this.getMarca();
    this.getCategoria();
    this.getStatus();
    this.selectYear();
  }

  getRequestParams(page: number, pageSize: number): any {
    let params: any = {};

    if (page) {
      params['pageNumber'] = page;
    }
    return params;
  }


  Mispiezas(limpiar) {
    this.productos = null; 
    this.noData = false;
    this.cargar = true;
    if(limpiar){
      this.currentMarca =[];
      this.currentModelo  =[];
      this.currentYear =[];
      this.currentStatus =[];
      this.currentPart =[];
      this.currentCategory =[];
      (<HTMLInputElement>document.getElementById('tbxBuscar')).value = "";
      this.mispiezas.pageNumber = 1;
    }

    this.mispiezas.nombre = (<HTMLInputElement>document.getElementById('tbxBuscar')).value;
    if (this.currentMarca.length == 0){
      this.mispiezas.mar="";
    } else{
      this.mispiezas.mar= this.currentMarca[0].marDescripcion;
    }

    if (this.currentModelo.length == 0){
      this.mispiezas.mo="";
    } else{
      this.mispiezas.mo= this.currentModelo[0].modDescripcion;
    }

    if (this.currentYear.length == 0){
      this.mispiezas.anos="";
    }else{
      this.mispiezas.anos= this.currentYear[0]; 
    }

    if (this.currentStatus.length == 0){
      this.mispiezas.status= 0;
    }else{
      this.mispiezas.status= this.currentStatus[0].estEstado; 
    }

    if (this.currentCategory.length == 0){
      this.mispiezas.cat= 0;
    }else{
      this.mispiezas.cat= this.currentCategory[0].catID; 
    }
    
    this._productosService.Mipiezas(this.mispiezas).subscribe(
      response => {
        console.log(response)
        const { results, totalRecordCount } = response;
        this.productos = results;
        this.count = totalRecordCount;
        this.cargar = false;
      }, (err: any) => {
        if (err.error == 'Carga Completa') {
          // this.cargar = false;
          if(this.page == 1){
            this.productos = [];
            this.count = 0;
          }
       }
       else{
        
        if(err.error == 'You do not have permissions to access to Parts'){
          Swal.fire({
            icon: 'error',
            title:err.error,
            showConfirmButton: true
          }).then(()=>{
            this.router.navigateByUrl('/Dashboard');
          })
         }
        
        this.productos = [];
        this.count = 0;
        console.log(err)
        this.noData = true;

       }
        this.cargar = false;

      })
  }

  selectYear(){

    var fully = new Date().getFullYear();
    for(let y = fully; y >= 1970; y--){
    this.years.push(y);

  }
  
}

getCategoria(){
  this.catListo=true;
  this._productosService.getCategoria().subscribe(
    res =>{
      this.catListo=false;
      this.categoria=res;
     // console.log(res);
    }, err =>{
     console.log(err);
    })
}

AddDetalleTemp(part: any){
  var loa = Swal.fire({
    title: 'Loading',
    showConfirmButton: false,
    allowOutsideClick: false,
    html: 'Please wait',
    timerProgressBar: true,
  })
  var temp = new FacturasDetalleTemp();
  temp.pieID = part.pieId;
  temp.facCantidad = 1;;
  this._adminService.PostFacturaDetalleTemp(temp).subscribe(
    res =>{
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Done',
        showConfirmButton: false,
        timer: 1000
      })
      this.communicationService.callComponentMethod("si");
    }, err =>{
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: err.error,
        showConfirmButton: true
      })
    })
}

getStatus(){
  this._productosService.getStatus("Piezas").subscribe(
    res =>{
      this.status=res;

    }, err =>{
     console.log(err);
    })
}

  getMarca(){
    this._productosService.getMarca().subscribe(
      res =>{
        this.marListo=false;
        this.marca=res;
        console.log(this.marca)
      }, err =>{
       console.log(err);
      })
  }
  
  cargarModelo(item: any){
    
    this.Modelo.idMarca = item.marID;
    this.currentModelo = [];
    this._productosService.getModelo(this.Modelo).subscribe(
      res =>{
        this.modelo=res;
         console.log(this.modelo);
      }, err =>{
       console.log(err);
      }
    )
  }

  cargarPart(item: any){
    this.currentPart = [];
   this._productosService.getPart(item.catID).subscribe(
     (res: any) =>{
       this.parts =res;
       console.log(res);
  
     }, err =>{
      console.log(err);
     })
  }


  UnSelectObject(item: any){

    console.log(this.currentMarca)
    if(this.currentMarca.length == 0){
      this.currentModelo = [];
      this.modelo =  null;
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.mispiezas.pageNumber = event;
    this.startup();
    this.Mispiezas(false);
  }

  startup() {
    window.scroll(0, 500);
    this.cargar = true;
  }

  imprSelec(nombre) {
    //https://api.qrserver.com/v1/create-qr-code/?size=150x150&data={{piezas.pieId}}
	  var ficha = document.getElementById(nombre);
    document.getElementById(nombre).style.display = "block"
	  var ventimp = window.open(' ', 'popimpr');
	  ventimp.document.write( ficha.innerHTML );
	  ventimp.document.close();
	  ventimp.print( );
    document.getElementById(nombre).style.display = "none"
	  ventimp.close();
  }

  getsenalUso(producto: any): any {
    return producto['pieSeñalUso'];
  }

  OpenCamera() {
    this.torchEnabled = true;
    (<HTMLAnchorElement>document.getElementById("openCamera_btn")).click();
    console.log("open");   
  }

  closeCamera() {
    this.torchEnabled = false;
    (<HTMLAnchorElement>document.getElementById("closeCamera_btn")).click();
    console.log("close");  
  }
  
  onCamerasFound(devices: any): void {
    console.log(devices)
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);

    if(this.hasDevices == false){
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Unsupported device',
        showConfirmButton: true
      }).then(() =>{
        this.closeCamera()
      })
    }
  }

  onCodeResult(resultString: any) {
    this.cargar = true;
    this.torchEnabled = false;

    this._productosService.getMiProducto(resultString).subscribe(
      (res: any) =>{
        this.closeCamera();
        this.router.navigateByUrl('/editpieza/' + resultString);
      }, err =>{
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'No found',
          showConfirmButton: true
        }).then(() =>{
          this.cargar = false;
          this.torchEnabled = true;
        })
      })
  }

  onDeviceSelectChange() {
    if( this.availableDevices.length > 1){
      const device = this.availableDevices.filter(x => x.deviceId != this.deviceCurrent.deviceId)[0];
      this.deviceCurrent = device || undefined;
    }
  }

  onDeviceChange(device: any) {
    const selectedStr = device?.deviceId || '';
    if (this.deviceSelected === selectedStr) { return; }
    this.deviceSelected = selectedStr;
    this.deviceCurrent = device || undefined;
  }


  onHasPermission(has: any) {
    this.hasPermission = has;
    console.log(has)
    if(has == false){
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'We do not have permission to open the camera',
        showConfirmButton: true
      }).then(() =>{
        this.closeCamera()
      })
    }
  }

  onTorchCompatible(isCompatible: any): void {
    this.torchAvailable$.next(isCompatible || false);
    console.log("compati"+isCompatible)
  }

  deletePart(partId: Number) {

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        var loa = Swal.fire({
          title: 'Loading',
          showConfirmButton: false,
          allowOutsideClick: false,
          html: 'Please wait',

          timerProgressBar: true,
        })

        this._productosService.deletePart(partId).subscribe(
          res => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: "Removed",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              location.reload();
            });
          }, err => {
            console.log(err);
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: err,
              showConfirmButton: true
            })
          })
      }
    });

  }

}
