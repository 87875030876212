import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductosService } from '../../../services/productos.service'
import { AdminService, UsosMultiplesPost } from '../../../services/admin.service'

@Component({
  selector: 'app-multipleuses-crear',
  templateUrl: './multipleuses-crear.component.html',
  styleUrls: ['./multipleuses-crear.component.scss']
})
export class MultipleusesCrearComponent implements OnInit {

  currentMUses = [];
  multipleuse: any[] = [];
  submitted = false;
  token: any;

  usosMultiples: any;
  estListo = false;
  loading = false;
  myForm: FormGroup;

  buscarUsosMultiples: UsosMultiplesPost = {
    usoCodGrupo: '',
    usoValor: 0,
    usoDescripcion: '',
  };

  constructor(private _fb: FormBuilder, private service: ProductosService, private _adminService: AdminService, private _router: Router) { }

  ngOnInit(): void {
    this.myForm = this._fb.group({
      multipleS: this.currentMUses,
    });
    this.getUsosMultiplesGroup();
  }

  getUsosMultiplesGroup() {
    this.loading = true;
    this.service.getUsosMultiplesGroup().subscribe(
      (res) => {
        this.usosMultiples = res;
        this.currentMUses = res.filter((x) => x == res[0])
        console.log(res)
      },
      (err) => {
        this.loading = false;
        console.error(err.error);
        if (err.error.includes('You do not have permissions to access to ')) {
          Swal.fire({
            icon: 'error',
            title: err.error,
            showConfirmButton: true
          }).then(() => {
            this._router.navigateByUrl('/Dashboard');
          })
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: err.error,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    );
  }

  Submit() {
    try {
      this.submitted = true;

      if (this.currentMUses.length == 0) {
        throw new TypeError("Group is necesary");
      }

      if ((<HTMLOptionElement>document.getElementById('Value')).value == "")
        throw new TypeError("Value is necesary");

      if ((<HTMLOptionElement>document.getElementById('Description')).value == "")
        throw new TypeError("Description name is necesary");

      this.buscarUsosMultiples.usoCodGrupo = this.currentMUses[0];
      this.buscarUsosMultiples.usoValor = Number((<HTMLOptionElement>document.getElementById('Value')).value);
      this.buscarUsosMultiples.usoDescripcion = ((<HTMLOptionElement>document.getElementById('Description')).value);

      this._adminService.postUsosMultiples(this.buscarUsosMultiples).subscribe(
        res => {
          this.loading = false;
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Done',
            showConfirmButton: false,
            timer: 1500
          })
          this._router.navigateByUrl('/Dashboard/MultipleUses');
        }, err => {
          this.loading = false;
          console.log(err);
          if (err.error.includes('You do not have permissions to access to ')) {
            Swal.fire({
              icon: 'error',
              title: err.error,
              showConfirmButton: true
            }).then(() => {
              this._router.navigateByUrl('/Dashboard');
            })
          } else {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: err.error,
              showConfirmButton: false,
              timer: 1500,
            });
          }

        })


    } catch (error) {
      this.loading = false;
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: error,
        showConfirmButton: true,
      })
      console.log(error);
    }
  }

}
