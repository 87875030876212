import { Component, OnInit } from '@angular/core';
import { ChatService, paraMensajes, sendMensaje, dataCall } from '../../services/chat.service';
import { ProductosService} from '../../services/productos.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { getMessaging, getToken, MessagePayload } from 'firebase/messaging';
import { CommunicationServiceService } from '../../services/communication-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-relacionados',
  templateUrl: './relacionados.component.html',
  styleUrls: ['./relacionados.component.scss']
})
export class RelacionadosComponent implements OnInit {

  messege:any;
  msg1 = getMessaging();
  message: MessagePayload | undefined;
  FCMtoken: string | undefined;
  
  public token: any;
  public grupos: any;
  public mensajes: any;
  elemento: any;
  public usuId:any;
  public pieId: any;
  public usuIdfrom: any;
  public pieNombre: any;
  public grupoId: any;
  public pieImg: any;
  public loadingsed = false;
  public loadingchat = true;
  public usuFoto: any;
  public producto: any;
  public EmpId: any;
  public PieUsuID: any;
  
  paraMensajes: paraMensajes ={

    pieId: 0,
    grupId: 0

  }

  sendMensaje: sendMensaje={
     
    pieId: 0,
    menSecuencia: 0,
    menFecha: new Date(),
    menMensaje: '',
    usuFromId: 0,
    usuToId: 0,
    grupId: 0

  }

  dataCall: dataCall ={
 
   To: '',
   Title: '',
   Body: '',
   Tipo: '',
   GrupId: ''

  }

  constructor( 
    private _productosService: ProductosService,
    private communicationService: CommunicationServiceService, 
    private _chatService: ChatService, 
    private _router: Router,
    private _route: ActivatedRoute) { }

  ngOnInit(): void {

    this.usuFoto = localStorage.getItem('usuFoto');
    this.EmpId = localStorage.getItem('empID');
    window.scroll(0, 0); 
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
       // console.log('Notification permission granted.');
      } else {
        console.log('Unable to get permission to notify.');
      }
    })
    
    getToken(this.msg1, { vapidKey: 'BPnYnGTqFZLjO1SwazSm9JkfwR-3KtuKK1LjeabNmrbrkt_mu3RhqR9H0A0NL9zgMjPdnzjzStvcH_1XWKL_0kk' }).then((tok) => {
    })
   /*  onMessage(this.msg1, (payload) => {
      //window.location.reload();
      console.log('Msg', payload);
      this.getMesanje(this.pieId,this.grupoId);
    })*/
    this.token = localStorage.getItem('token');  
    this.usuId = localStorage.getItem('usuId'); 
    this._route.params.subscribe(params =>{
      let pieId = params.pieId;
      let usuIdfrom = params.usuId;
      let pieImg = params.PieImg;
      let  pieNombre = params.PieNombre;
      let grupoid = params.grupoId;
      this.pieNombre = pieNombre;
      this.pieImg = pieImg;
      this.usuIdfrom = usuIdfrom;
      this.pieId = pieId;
      this.grupoId = grupoid;
      console.log(this.grupoId)
      this.MensajeLeido(this.grupoId, this.token);
    })
     //  this.getGrupo();
     this.communicationService.componentMethodCalled.subscribe( 
      res =>{
        console.log('res');
        this.getMesanje(this.pieId, res.data.grupId);
      })
    
      this._productosService.getProducto(this.pieId).subscribe(
        response =>{
          this.producto = response;
          this.PieUsuID = this.producto.pieUsuID;
        },
        error =>{
            console.log(<any>error); 
        }) 
  }

  MensajeLeido(idgrupo: any, token: any){
    this._chatService.MensajeLeido(idgrupo).subscribe(
      res =>{
        console.log(res);
        this.getMesanje(this.pieId,this.grupoId);
      },err =>{
            console.log(err);
            this.getMesanje(this.pieId,this.grupoId);
      })
  }
  
   getMesanje(pieId: any, grupoId: any){
    this.elemento = document.getElementById('chatbox');
     this.paraMensajes.pieId=pieId;
     this.paraMensajes.grupId=grupoId;
     this._chatService.getMensajes(this.paraMensajes).subscribe(
         res =>{

          console.log(res)
          this.mensajes = res;
          this.loadingchat = false;
          this.grupoId = this.mensajes[0].grupId;
         // console.log(this.mensajes[0]);
          setTimeout(() => {
            this.elemento.scrollTop = this.elemento.scrollHeight;
            this.loadingsed = false;
          }, 100)
       }, (err: any) =>{
        if (err.status == 401) {
          // window.localStorage.removeItem('token');
          this._router.navigateByUrl('/Relogin')
        }
        console.log(err);
       }
     )
   }
  /* getGrupo(){
  this._chatService.getGrupo(this.token).subscribe(
    res =>{
      console.log(res);
      this.grupos = res;
      },err =>{ 
        console.log(err);
      })
   }*/

   videoCall(){
     if( this.grupoId == 0){
      Swal.fire({
        title: 'Info',
        text: "For the privacy of users, you must start the chat with the seller first",
        icon: 'warning'
      })
     }else{
      this.dataCall.Tipo = "4";
      this.dataCall.Title = "Incoming call";
      this.dataCall.Body = "Incoming call";
      this.dataCall.GrupId = String(this.grupoId);
       if(this.usuId == this.EmpId || this.usuId == this.PieUsuID ){
           return 0;
       }else{
        if(this.EmpId == null || this.EmpId == 0){
          this.dataCall.To ='/topics/' + this.PieUsuID ;
          }
          this.dataCall.To ='/topics/emp' + this.EmpId;
  
          this._chatService.callVideo(this.dataCall).subscribe(
            res =>{
            }, err =>{
               console.log(err);
            })
            window.open('https://videocall.vehiclepart.shop/?' + this.grupoId ,'','width=500,height=800,status=no,toolbar=no,menubar=no');
         }
       }
     }
 
    sendMesanje(){
     this.sendMensaje.menMensaje =String((<HTMLInputElement>document.getElementById(String('message'))).value)
     this.sendMensaje.usuToId = Number(this.usuId);
     this.sendMensaje.pieId = Number(this.pieId);
     this.sendMensaje.usuFromId = Number(this.usuIdfrom);
     this.sendMensaje.grupId=this.grupoId;
     this.loadingsed = true;
     
     if(this.sendMensaje.menMensaje == ""){
      this.loadingsed = false;
       return;
     }

     this._chatService.Sedmensaje( this.sendMensaje).subscribe(       
       (res: any) =>{ 

        }, (err: any)=>{
          if (err.status == 401) {
            // window.localStorage.removeItem('token');
            this._router.navigateByUrl('/Relogin')
          }
            console.log(err);
            (<HTMLInputElement>document.getElementById(String('message'))).value='';
            //this.ngOnInit();
            this.getMesanje(this.pieId, this.grupoId);
        })
       }
      
}
