import { Component, OnInit} from '@angular/core';
import Swal from 'sweetalert2';
import { ProductosService} from '../../services/productos.service';
import { BsModalService} from 'ngx-bootstrap/modal';  
import { Router} from '@angular/router';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {


  facturas: any;
  result=false;
  loading = false;
  noData = false;
  constructor(private _producto: ProductosService,private modalService: BsModalService,private _router: Router,) { }

  ngOnInit(): void {
    this.getfactura();
  }

  getfactura(){

    this.loading = true;
    this._producto.Misfacturas().subscribe(
      res=>{
       this.loading = false;
          this.facturas = res;
          if (this.facturas.length  == 0){
           this.noData = true;
          }
      }, err =>{
           this.loading = false;
           this.noData = true;
          console.log(err);
      })
  }

  Reportefactura(factid: number){
    this._producto.Reportefactura( factid).subscribe(
      res=>{
      }, err =>{
        console.log(err);
        if(err.status == 200){
          const url = err.error.text;
          //Ajustar horizontalmente
	        var x=(screen.width/2)-(1000/2);
	        //Ajustar verticalmente
	        var y=(screen.height/2)-(800/2);
          window.open(url, 'popup', 'width=1000 , height=800,location=no,resizable=no,' + 'left=' + x + ', top=' + y +'');
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
         
          })
        }
      })
  }
}
