import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AdminService, CompaniesPost } from '../../../services/admin.service';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-companies-editar',
  templateUrl: './companies-editar.component.html',
  styleUrls: ['./companies-editar.component.scss']
})
export class CompaniesEditarComponent implements OnInit {

  loading = false;
  urls: string[] = [];
  postUrl = new Array<string>();

  Company : CompaniesPost = {
    empID : 0,
    empNombre : '',
    empEmail : '',
    empEstatus: 0,
    empTelefono: '',
    empFax: '',
    empDireccion: '',
    empDireccion2: '',
    empZipCode: '',
    empEstado: '',
    empCiudad: '',
    empLogoPath: '',
  
  };

  constructor(private _fb: FormBuilder, 
    private _adminService: AdminService, 
    private _router: Router,
    private _activeRoute: ActivatedRoute
    ) { }

  ngOnInit(): void {

    this._activeRoute.params.subscribe(params => {
      this.getCurrentCompany(params.id);
    });

  }

  getCurrentCompany(id) {
    this.loading = true;
    this._adminService.getCompanyid(id).subscribe(
      (res) => {
        this.Company = res;
        this.urls.push(res.empLogoPath)
        this.loading = false;
        console.log(res);
      },
      (err) => {
        if (err.error.includes('You do not have permissions to access to ')) {
          Swal.fire({
            icon: 'error',
            title: err.error,
            showConfirmButton: true
          }).then(() => {
            this._router.navigateByUrl('/Dashboard');
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: err.error,
            showConfirmButton: true
          })
        }
        this.loading = false;
        console.error(err.error);
      }
    );
  }

  removeItemFromArr(item) {
    var arr = this.urls;
    var i = arr.indexOf( item );
    if ( i !== -1 ) {
        arr.splice( i, 1 );
    }
    console.log(arr);
    arr = this.postUrl;
}

  detectFiles(event: any) {
    this.urls = [];
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.urls.push(e.target.result);
        }
        reader.readAsDataURL(file);
      }
    }
    console.log(this.urls);
  }
  chooseFile() {
    document.getElementById("fileInput").click();
}

Submit() {

  try {
    this.loading = true;

    if ((<HTMLOptionElement>document.getElementById('empName')).value == "")
      throw new TypeError("Company name is necesary");
    if ((<HTMLOptionElement>document.getElementById('empEmail')).value == "")
      throw new TypeError("Email is necesary");
    if ((<HTMLOptionElement>document.getElementById('empPhone')).value == "")
      throw new TypeError("Phone is necesary");
    if ((<HTMLOptionElement>document.getElementById('empAddress')).value == "")
      throw new TypeError("Address is necesary");
    if ((<HTMLOptionElement>document.getElementById('empZipCode')).value == "")
      throw new TypeError("ZipCode is necesary");
    if ((<HTMLOptionElement>document.getElementById('empState')).value == "")
      throw new TypeError("State is necesary");
    if ((<HTMLOptionElement>document.getElementById('empCity')).value == "")
      throw new TypeError("City is necesary");

    if (this.urls.length == 0)
      throw new TypeError("Logo is necesary");

    this.Company.empNombre = ((<HTMLOptionElement>document.getElementById('empName')).value);
    this.Company.empEmail = ((<HTMLOptionElement>document.getElementById('empEmail')).value);
    this.Company.empTelefono = ((<HTMLOptionElement>document.getElementById('empPhone')).value);
    this.Company.empFax = ((<HTMLOptionElement>document.getElementById('empFax')).value);
    this.Company.empDireccion = ((<HTMLOptionElement>document.getElementById('empAddress')).value);
    this.Company.empDireccion2 = ((<HTMLOptionElement>document.getElementById('empAddress2')).value);
    this.Company.empZipCode = ((<HTMLOptionElement>document.getElementById('empZipCode')).value);
    this.Company.empEstado = ((<HTMLOptionElement>document.getElementById('empState')).value);
    this.Company.empCiudad = ((<HTMLOptionElement>document.getElementById('empCity')).value);
    var filtrado = this.urls.filter((filtro)=> filtro.includes("http") == false);
    if(filtrado.length == 0)
      this.Company.empLogoPath =  ""
    else
    this.Company.empLogoPath = filtrado[0]

    console.log(this.Company);
    this._adminService.editCompanies(this.Company).subscribe(
      res => {

        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Done',
          showConfirmButton: true,
          timer: 1500
        })
        this._router.navigateByUrl('/Dashboard/Companies');
      }, err => {
        this.loading = false;
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: err.error,
          showConfirmButton: true,
        })
        console.log(err);

      })
  } catch (error) {
    this.loading = false;
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: error,
      showConfirmButton: true,
    })
    console.log(error);
  }

}

}
