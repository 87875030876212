<div class="modal-dialog modal-lm ounded shadow">
  <div class="modal-content">
    <section>
      <h3 style="color: #008c44;" class="px-3">User Registration</h3>

      <ul class="nav nav-pills p-3" id="pills-tab" role="tablist" hidden>
        <li class="nav-item" role="presentation" class="active">
          <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
            type="button" role="tab" aria-controls="pills-home" aria-selected="true">Login</button>
        </li>
        <li class="nav-item" role="presentation" class="disabled">
          <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
            type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Account</button>
        </li>
        <li class="nav-item" role="presentation" class="disabled">
          <button class="nav-link" id="payment-method-tab" data-bs-toggle="pill" data-bs-target="#payment-method"
            type="button" role="tab" aria-controls="payment-method" aria-selected="false" style="display: none;">Payment
            Method</button>
        </li>
      </ul>

      <div class="position-relative m-4">
        <div class="progress" role="progressbar" aria-label="Progress" aria-valuenow="0" aria-valuemin="0"
          aria-valuemax="100" style="height: 2px;">
          <div class="progress-bar" style="width: 0%" id="progress_barr"></div>
        </div>
        <button type="button"
          class="position-absolute top-0 start-0 translate-middle btn btn-sm btn-primary rounded-pill text-white"
          style="width: 2rem; height:2rem;">1</button>
        <button type="button"
          class="position-absolute top-0 start-50 translate-middle btn btn-sm btn-secondary rounded-pill text-white"
          style="width: 2rem; height:2rem;">2</button>
        <button type="button"
          class="position-absolute top-0 start-100 translate-middle btn btn-sm btn-secondary rounded-pill text-white"
          style="width: 2rem; height:2rem;">3</button>
      </div>

      <div class="tab-content px-3" id="pills-tabContent">
        <!--Start Register Loging Information -->
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

          <div class="container">
            <h3>Login Data</h3>

            <form>
              <div class="form-group space icon-relative">
                <label class="mb-2 form-label" style="color: #008c44;" for="UsuSesion"> Username <span
                    style="color: red">*</span></label>
                <input type="text" [(ngModel)]="DatosRegistro.usuSesion" name="UsuSesion"
                  (change)="ValidateCurrentImput('UsuSesion')" class="form-control" size="20" placeholder="Username"
                  [ngClass]="{ 'is-invalid': errorForm.UsuSesion !=''}" />
                <div *ngIf="errorForm.UsuSesion != ''" class="invalid-feedback">
                  <p style="color: red">{{errorForm.UsuSesion}}</p>
                </div>
              </div>

              <div class="form-group space icon-relative">
                <label class="mb-2 form-label" style="color: #008c44;" for="usuPass"> Password <span
                    style="color: red">*</span></label>
                <input type="password" [(ngModel)]="DatosRegistro.usuPass" name="usuPass"
                  (change)="ValidateCurrentImput('UsuPass')" class="form-control" size="20" placeholder="Password"
                  [ngClass]="{'is-invalid': errorForm.UsuPass !=''}" />
                <div *ngIf="errorForm.UsuPass !=''" class="invalid-feedback">
                  <p style="color: red">{{errorForm.UsuPass}}</p>
                </div>
              </div>

              <div class="form-group space icon-relative">
                <label class="mb-2 form-label" style="color: #008c44;" for="user_pass">
                  Confirm Password <span style="color: red">*</span></label>
                <input type="password" name="confirmarUsuPass" class="form-control" id="confirmarUsuPass"
                  (change)="ValidateCurrentImput('confirmarUsuPass')" size="20" placeholder="Confirm Password"
                  [ngClass]="{'is-invalid': errorForm.confirmarUsuPass !=''}" />
                <div *ngIf="errorForm.confirmarUsuPass !=''" class="invalid-feedback">
                  <p style="color: red">{{errorForm.confirmarUsuPass}}</p>
                </div>
              </div>

              <div class="form-group space icon-relative">
                <label class="mb-2 form-label" style="color: #008c44;" for="user_pass">Email <span
                    style="color: red">*</span></label>
                <input type="email" class="form-control" [(ngModel)]="DatosRegistro.usuEmail"
                  (change)="ValidateCurrentImput('UsuEmail')" name="UsuEmail"
                  [ngClass]="{'is-invalid': errorForm.UsuEmail !=''}" placeholder="Email" />
                <div *ngIf="errorForm.UsuEmail !=''" class="invalid-feedback">
                  <p style="color: red">{{errorForm.UsuEmail}}</p>
                </div>
              </div>

            </form>

            <div class="card-grp space">
              <a class="btn btn-lg  btn-danger text-white" [routerLink]="['/login']"><i class="fa fa-times-circle"></i>
                Cancel</a>
              <button class="btn btn-lg  btn-primary text-white" (click)="Next(0)" type="button"> Next <i
                  class="fa fa-arrow-circle-right" aria-hidden="true"></i></button>
            </div>
          </div>
        </div>
        <!--End Register Login Information-->
        <!--Start Register Account Information-->
        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
          <div class="container">
            <h3>Account Data</h3>
            <form>
              <div class="ml-4 text-left">
                <label class="mb-2 form-label" style="color: #008c44;" for="user_login">Profile Piture <span
                    style="color: red">*</span></label>
              </div>
              <div class="text-center ">
                <img *ngIf="imageURL" class="img-fluid rounded-circle fit-image" id="preview" [src]="imageURL"
                  [alt]="errorForm.usuFoto" />
              </div>
              <div class="input-group mt-3 mb-3">
                <label class="input-group-text" for="inputGroupFile01">
                  Image</label>
                <input type="file" class="form-control" value="" id="inputGroupFile" accept="image/*"
                  [(ngModel)]="DatosRegistro.usuFoto" name="usuFoto"
                  [ngClass]="{ 'is-invalid': errorForm.usuFoto !='' }" placeholder="image" (change)="readUrl($event)" />
                <div *ngIf="errorForm.usuFoto !='' " class="invalid-feedback">
                  <p style="color: red">{{errorForm.usuFoto}}</p>
                </div>
              </div>
              <div class="form-group mb-3">
                <div class="ml-4 text-left">
                  <label class="mb-2 form-label" style="color: #008c44;" for="user_login">First Name <span
                      style="color: red">*</span></label>
                </div>
                <input type="text" id="UsuNombres" class="form-control" size="20"
                  (change)="ValidateCurrentImput('UsuNombres')" [(ngModel)]="DatosRegistro.usuNombres" name="UsuNombres"
                  [ngClass]="{ 'is-invalid': errorForm.UsuNombres !=''}" placeholder="First Name" />
                <div *ngIf="errorForm.UsuNombres !=''" class="invalid-feedback">
                  <p style="color: red">{{errorForm.UsuNombres}}</p>
                </div>

              </div>
              <div class="form-group mb-3">
                <div class="ml-4 text-left">
                  <label class="mb-2 form-label" style="color: #008c44;" for="user_login">
                    Last name <span style="color: red">*</span></label>
                </div>
                <input type="text" id="UsuApellidos" class="form-control" size="20"
                  [(ngModel)]="DatosRegistro.usuApellidos" name="UsuApellidos"
                  (change)="ValidateCurrentImput('UsuApellidos')"
                  [ngClass]="{ 'is-invalid':errorForm.UsuApellidos !=''}" placeholder="Last Name" />
                <div *ngIf="errorForm.UsuApellidos !=''" class="invalid-feedback">
                  <p style="color: red">{{errorForm.UsuApellidos}}</p>
                </div>
              </div>
              <div class="mb-3">
                <div class="ml-4 text-left">
                  <label class="mb-2 form-label" style="color: #008c44;" for="UsuTelefono">
                    Cell phone <span style="color: red">*</span></label>
                </div>
                <input type="text" class="form-control" [(ngModel)]="DatosRegistro.usuTelefonoBill"
                  (change)="ValidateCurrentImput('UsuTelefono')" name="UsuTelefono"
                  [ngClass]="{ 'is-invalid': errorForm.UsuTelefono !='' }" placeholder="Cell phone" />
                <div *ngIf="errorForm.UsuTelefono !=''" class="invalid-feedback">
                  <p style="color: red">{{errorForm.UsuTelefono}}</p>
                </div>
              </div>

              <div class="mb-3">
                <div class="ml-4 text-left">
                  <label class="mb-2 form-label" style="color: #008c44;" for="user_pass">Address <span
                      style="color: red">*</span></label>
                </div>
                <input type="text" class="form-control" id="UsuDireccion" [(ngModel)]="DatosRegistro.usuDireccionBill"
                  (change)="ValidateCurrentImput('UsuDireccion')" name="UsuDireccion"
                  [ngClass]="{ 'is-invalid': errorForm.UsuDireccion !='' }" placeholder="Street Address" />
                <div *ngIf="errorForm.UsuDireccion !=''" class="invalid-feedback">
                  <p style="color: red">{{errorForm.UsuDireccion}}</p>
                </div>

                <input type="text" class="form-control" id="UsuCiudadBill" [(ngModel)]="DatosRegistro.usuCiudadBill"
                  (change)="ValidateCurrentImput('UsuCiudadBill')" name="UsuCiudadBill"
                  [ngClass]="{ 'is-invalid': errorForm.UsuCiudadBill !=''}" placeholder="City" />
                <div *ngIf="errorForm.UsuCiudadBill !=''" class="invalid-feedback">
                  <p style="color: red">{{errorForm.UsuCiudadBill}}</p>
                </div>

                <input type="text" class="form-control" id="UsuStateBill" [(ngModel)]="DatosRegistro.usuStateBill"
                  (change)="ValidateCurrentImput('UsuStateBill')" name="UsuStateBill"
                  [ngClass]="{ 'is-invalid': errorForm.UsuStateBill !='' }" placeholder="State" />
                <div *ngIf="errorForm.UsuStateBill !=''" class="invalid-feedback">
                  <p style="color: red">{{errorForm.UsuStateBill}}</p>
                </div>

                <input type="text" class="form-control" id="UsuZipCodeBill" [(ngModel)]="DatosRegistro.usuZipCodeBill"
                  (change)="ValidateCurrentImput('UsuZipCodeBill')" name="UsuZipCodeBill"
                  [ngClass]="{ 'is-invalid': errorForm.UsuZipCodeBill !='' }" placeholder="Postal/Zip Code" />
                <div *ngIf="errorForm.UsuZipCodeBill !=''" class="invalid-feedback">
                  <p style="color: red">{{errorForm.UsuZipCodeBill}}</p>
                </div>
              </div>
              <div class="form-group mb-3">
                <div class="ml-4 text-left">
                  <label class="mb-2 form-label" style="color: #008c44;" for="UsuCountry">Country <span
                      style="color: red">*</span></label>
                </div>

                <select class="form-select" name="UsuCountry" (change)="ValidateCurrentImput('UsuCountry')"
                  [(ngModel)]="DatosRegistro.paiIDBill" [ngClass]="{ 'is-invalid': errorForm.UsuCountry !=''}">
                  <option value="1" selected>United States</option>
                </select>
                <div *ngIf="errorForm.UsuCountry !=''" class="invalid-feedback">
                  <p style="color: red">{{errorForm.UsuCountry}}</p>
                </div>
              </div>

              <div class="form-group mb-3">
                <div class="ml-4 text-left">
                  <label class="mb-2 form-label" style="color: #008c44;" for="user_login">Account Type <span
                      style="color: red">*</span></label>
                </div>
                <label for="us1">Select your account type:&nbsp;&nbsp;</label>

                <div>
                  <div class="columns" (click)="userSelected('1')">
                    <i [ngClass]="{'checkIcon': usertype == '1', 'checkIconTransparent': usertype != '1'}"
                      class="fa fa-check-circle-o" aria-hidden="true"></i>
                    <ul class="price">
                      <li class="header">Normal</li>
                      <li class="grey">USD$ 0.0<br>Free</li>
                      <li class="grey">
                        <button type="button" class="button" data-bs-toggle="modal" data-bs-target="#normalUserModal">Info.</button>
                      </li>
                    </ul>
                  </div>

                  <div class="columns" (click)="userSelected('2')">
                    <i [ngClass]="{'checkIcon': usertype == '2', 'checkIconTransparent': usertype != '2'}"
                      class="fa fa-check-circle-o" aria-hidden="true"></i>
                    <ul class="price">
                      <li class="header">Business</li>
                      <li class="grey">USD$ 150.0<br>Monthly</li>
                      <li class="grey">
                        <button type="button" class="button" data-bs-toggle="modal" data-bs-target="#bussinesUserModal">Info.</button>
                      </li>
                    </ul>
                  </div>
                </div>


              </div>

              <div>
                <label id="account-cost" style="color: red"></label>
              </div>

              <div *ngIf="usertype == '2'" class="business-1">

                <div class="mb-3">

                  <div class="ml-4 text-left">
                    <label class="mb-2 form-label" id="business-credentials" style="color: #008c44;"
                      for="user_pass">Business Details <span style="color: red">*</span></label>
                  </div>


                  <input type="text" class="BusinessName" id="business-name" [(ngModel)]="DatosRegistro.empName"
                    (change)="ValidateCurrentImput('empName')" name="BusinessName"
                    [ngClass]="{ 'is-invalid':errorForm.empName !=''}" placeholder="Business Name" />
                  <div *ngIf="errorForm.empName !=''" class="invalid-feedback">
                    <p style="color: red">{{errorForm.empName }}</p>
                  </div>

                  <input type="text" class="BusinessEin" id="business-ein" [(ngModel)]="DatosRegistro.empTaxID"
                    name="empTaxID" [ngClass]="{ 'is-invalid': errorForm.empTaxID !=''}"
                    (change)="ValidateCurrentImput('empTaxID')" placeholder="Employer Identification Number (EIN)" />
                  <div *ngIf="errorForm.empTaxID !=''" class="invalid-feedback">
                    <p style="color: red">{{errorForm.empTaxID}}</p>
                  </div>

                  <input type="text" class="BusinessSalesTax" id="business-sales-tax"
                    (change)="ValidateCurrentImput('empSalesTaxLicID')" [(ngModel)]="DatosRegistro.empSalesTaxLicID"
                    name="empSalesTaxLicID" [ngClass]="{ 'is-invalid': errorForm.empSalesTaxLicID !=''}"
                    placeholder="Sales Tax ID" />
                  <div *ngIf="errorForm.empSalesTaxLicID !=''" class="invalid-feedback">
                    <p style="color: red">{{errorForm.empSalesTaxLicID}}</p>
                  </div>

                  <input type="text" class="BusinessLicenses" id="business-licenses"
                    (change)="ValidateCurrentImput('empBusinessLinc')" [(ngModel)]="DatosRegistro.empBusinessLinc"
                    name="empBusinessLinc" [ngClass]="{ 'is-invalid': errorForm.empBusinessLinc !='' }"
                    placeholder="Business License" />
                  <div *ngIf="errorForm.empBusinessLinc !=''" class="invalid-feedback">
                    <p style="color: red">{{errorForm.empBusinessLinc}}</p>
                  </div>

                </div>
              </div>

              <div class="ml-4  form-check text-left">
                <input class="form-check-input" type="checkbox" value="" id="Condiciones"
                  (change)="ValidateCurrentImput('Condiciones')" [ngClass]="{ 'is-invalid': errorForm.Condiciones !=''}"
                  required />
                <label class="form-check-label" for="invalidCheck"> I have read and accepted <a
                    href="https://vehiclepart.shop/terminos/" target="_blank">Terms and Conditions</a></label>
                <div *ngIf="errorForm.Condiciones !=''" class="invalid-feedback">
                  <p style="color: red"> Please accept Terms and Conditions.</p>
                </div>
              </div>

            </form>

            <div class="card-grp space">
              <button class="btn btn-lg btn-danger text-white" (click)="Back(1)" type="button"> <i
                  class="fa fa-arrow-circle-left" aria-hidden="true"></i> Back </button>
              <button *ngIf="usertype == '2'" class="btn btn-lg btn-primary text-white" (click)="Next(1)"
                type="button">Next <i class="fa fa-arrow-circle-right" aria-hidden="true"></i></button>
              <button *ngIf="usertype != '2'" class="btn btn-lg btn-primary text-white" (click)="Next(1)" type="button">
                <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status"
                  aria-hidden="true"></span>Sign up
                <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
              </button>
            </div>
          </div>

        </div>
        <!--End Register Account Information-->
        <!--Start Payment Method-->
        <div class="tab-pane fade" id="payment-method" role="tabpanel" aria-labelledby="payment-method-tab">
          <div class="container">

            <h1>Payment</h1>

            <form>
              <div class="form-group space icon-relative">
                <label for="billing-address">Billing Address</label>
                <i class="fa fa-home" aria-hidden="true"></i>
                <input type="text" class="payment" id="billing-street-address" name="billing-address"
                  placeholder="Street address" required>
              </div>
              <div class="card-grp space">
                <div class="form-group space icon-relative">
                  <input class="payment" type="text" id="billing-street-city" name="billing-city" placeholder="City"
                    required>
                </div>
                <div class="form-group space icon-relative">
                  <input type="text" class="payment" id="billing-street-state" name="billing-state" placeholder="State"
                    required>
                </div>
                <div class="form-group space icon-relative">
                  <input type="text" class="payment" id="billing-street-zip" name="billing-zip" placeholder="Zip Code"
                    required>
                </div>
              </div>
              <div class="form-group space icon-relative">
                <label for="billing-card-info">Billing Debit/Credit Card Details</label>
                <i class="fa fa-user" aria-hidden="true"></i>
                <input type="text" class="payment" id="cardholder-name" name="cardholder-name"
                  placeholder="Cardholder Name" [ngClass]="{ 'is-invalid': errorForm.nameCard !='' }" required>
                <div *ngIf="errorForm.nameCard !=''" class="invalid-feedback">
                  <p style="color: red">{{errorForm.nameCard}}</p>
                </div>
              </div>
              <div class="form-group space icon-relative">
                <!-- <label for="card-number">Card Number</label>-->
                <i class="fa fa-credit-card" aria-hidden="true"></i>
                <input type="text" id="card-number" class="payment" name="card-number" mask="0000 0000 0000 0000"
                  placeholder="Card Number" maxlength="19" [ngClass]="{ 'is-invalid': errorForm.CardNumber !='' }"
                  required>
                <div *ngIf="errorForm.CardNumber !=''" class="invalid-feedback">
                  <p style="color: red">{{errorForm.CardNumber}}</p>
                </div>

              </div>
              <div class="card-grp space">
                <div class="form-group space icon-relative">
                  <!--<label for="expiry-date">Expiry Date</label>-->
                  <i class="fa fa-calendar" aria-hidden="true"></i>
                  <input type="text" class="payment" id="expiry-date" name="expiry-date" mask="00/00"
                    placeholder="MM/YY" maxlength="5" [ngClass]="{ 'is-invalid': errorForm.CardExpiryDate !='' }">
                  <div *ngIf="errorForm.CardExpiryDate !=''" class="invalid-feedback">
                    <p style="color: red">{{errorForm.CardExpiryDate}}</p>
                  </div>
                </div>
                <div class="form-group spac icon-relative">
                  <!--<label for="cvv">CVV</label>-->
                  <i class="fa fa-lock" aria-hidden="true"></i>
                  <input type="text" class="payment" id="cvv" name="cvv" mask="000" placeholder="CVV" maxlength="3"
                    [ngClass]="{ 'is-invalid': errorForm.cvv !='' }" required>
                  <div *ngIf="errorForm.cvv !=''" class="invalid-feedback">
                    <p style="color: red">{{errorForm.cvv}}</p>
                  </div>
                </div>
              </div>

            </form>

            <div class="card-grp space">
              <button class="btn btn-lg btn-danger text-white" (click)="Back(2)" type="button">
                <i class="fa fa-arrow-circle-left" aria-hidden="true"></i> Back
              </button>
              <button class="btn btn-lg btn-primary text-white" (click)="Next(2)" type="button">
                <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status"
                  aria-hidden="true"></span>Sign up <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
              </button>
            </div>
          </div>

        </div>
        <!--End Payment Method-->
      </div>

    </section>
  </div>


  <!-- Scrollable Nomal User-->
  <div class="modal fade" id="normalUserModal" tabindex="-1" aria-labelledby="normalUserModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Normal Account Features</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

            <div class="feature">
                <h4>1. Buying</h4>
                <p>Users can browse through listings and purchase items from sellers.</p>
            </div>
            <div class="feature">
                <h4>2. Selling</h4>
                <p>Users can list items for sale on the platform, set prices, and manage their listings.</p>
            </div>
            <div class="feature">
                <h4>3. Leaving Feedback</h4>
                <p>Both buyers and sellers can leave feedback for each other after a transaction, helping to build trust within the community.</p>
            </div>
            <div class="feature">
                <h4>4. Managing Listings</h4>
                <p>Sellers can edit, relist, or remove their listings as needed.</p>
            </div>
            <div class="feature">
                <h4>5. Communicating</h4>
                <p>Users can communicate with each other through eBay's messaging system to ask questions, negotiate terms, or resolve issues.</p>
            </div>
            <div class="feature">
                <h4>6. Searching</h4>
                <p>Users can search for specific items using keywords, filters, and categories.</p>
            </div>
            <div class="feature">
                <h4>7. Reviewing Seller Profiles</h4>
                <p>Buyers can review seller profiles to check their ratings, feedback, and selling history before making a purchase.</p>
            </div>
            <div class="feature">
                <h4>8. Resolving Disputes</h4>
                <p>VP provides a support center where users can escalate disputes or report issues with transactions.</p>
            </div>
            <div class="feature">
                <h4>9. Managing Account Settings</h4>
                <p>Users can update their personal information, payment methods, and notification preferences within their account settings.</p>
            </div>

        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Send message</button>
        </div> -->
      </div>
    </div>
  </div>


  <!-- Scrollable Bussines User-->
  <div class="modal fade" id="bussinesUserModal" tabindex="-1" aria-labelledby="bussinesUserModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Business Account Features</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <div class="feature">
              <h4>1. Bulk Listing Tools</h4>
              <p>Business accounts often have access to tools that allow for efficient listing of multiple vehicles or parts at once, streamlining the selling process for businesses with large inventories.</p>
          </div>
          <div class="feature">
              <h4>2. Marketing and Promotions</h4>
              <p>Business users may have access to promotional tools to help increase visibility for their listings, such as running sales, creating special offers, or utilizing eBay's advertising options.</p>
          </div>
          <div class="feature">
              <h4>3. Inventory Management</h4>
              <p>Enhanced inventory management tools enable businesses to keep track of their listings, monitor sales performance, and easily relist or manage items that haven't sold.</p>
          </div>
          <div class="feature">
              <h4>4. Customer Service Support</h4>
              <p>Business users may receive dedicated customer service support tailored to their needs, ensuring quick resolution of any issues that arise during transactions.</p>
          </div>
          <div class="feature">
              <h4>5. Integration with Business Systems</h4>
              <p>Some business accounts may have the ability to integrate eBay Motors with their existing business systems, such as inventory management software or accounting tools, to streamline operations. (Additional Fee $50.00)</p>
          </div>
          <div class="feature">
              <h4>6. Analytics and Reporting</h4>
              <p>Access to detailed analytics and reporting features allows businesses to track sales metrics, understand customer behavior, and make informed decisions to optimize their selling strategies.</p>
          </div>
          <div class="feature">
              <h4>7. Custom Branding</h4>
              <p>Business accounts may have options for custom  branding on their listings, helping to establish a professional identity and build trust with potential buyers.</p>
          </div>

        </div>

      </div>
    </div>
  </div>

</div>