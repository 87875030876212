<app-navbar></app-navbar>
<app-buscar></app-buscar>

<div *ngIf="loading" class="p-5">
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</div>


<div *ngIf="noData" class="row p-5">
  <div class="d-flex justify-content-center">
    <h3>
      <span>No se encontro datos</span>
    </h3>
  </div>
</div>

<div *ngIf="result" class="p-5">
  <div class="col-md-4 mt-4  mr-2 mb-5" >
    <h4 class=" text-left font-weight-light"><strong >SubTotal: {{ subTotal | currency }}</strong></h4>
    <h6 class=" text-left font-weight-light"><strong >Total parts: {{totalItems}}</strong></h6>
    <hr>
    <button class="btn btn-primary text-white" [disabled]="!habilitado" [routerLink]="['/pago']" href="">Process payment</button>
</div>

    <table class="table table-hover">
        <thead>
            <tr> 
              <th scope="col" class="text-primary">image</th>
              <th scope="col" class="text-primary">Name</th>
              <th scope="col" class="text-primary">Quantity</th>
              <th scope="col" class="text-primary">Price</th>
              <th scope="col" class="text-primary">Total amount</th>
              <th scope="col" class="text-primary">Update</th>
              <th scope="col" class="text-primary">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let producto of productos; let i = index">
              <td><img src="{{producto.pedImagen}}" alt="" class="fit-image"></td>
              <td class="p-4" >{{producto.proNombre}}</td>
              <td class="p-4" >
              <input type="number"  name="{{producto.proNombre}}" id="{{producto.pieID}}" min="1" value="{{producto.pedCantidad}}" >
              </td>
              <td class="p-4">{{producto.pedPrecio | currency }}</td>
              <td class="p-4">{{producto.pedMontoTotal | currency }}</td>
              <td class="p-3"><button class="btn btn-success btn-lg" (click)="itemUpdate(producto)"><i class="fa fa-retweet"></i></button></td>
              <td class="p-3"><button class="btn btn-danger btn-lg" (click)="borrarItem(producto)"><i class="fa fa-trash"></i></button></td>
            </tr>
          </tbody>
      </table>
</div>
