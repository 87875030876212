<app-navbar></app-navbar>
<div class="row p-2">
  <div class="d-flex align-items-center">
    <nav
      style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
      aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/Dashboard/Usuarios">Users</a></li>
        <li class="breadcrumb-item active" aria-current="page">Add Users</li>
      </ol>
    </nav>
  </div>
  <h1 class="text-primary">Add Users
  </h1>
  <hr>

  <form [formGroup]="myForm">
    <div class="row">
    
      <div class="col-6 col-sm-6 col-md-3 col-lg-3 nav-item">
        <label class="form-label">First Name *</label>
        <input placeholder="First Name" type="text" class="form-control" id="UsuNombres">
      </div>

      <div class="col-6 col-sm-6 col-md-3 col-lg-3 nav-item">
        <label class="form-label">Last Name *</label>
        <input placeholder="Last Name" type="text" class="form-control" id="UsuApellidos">
      </div>

      <div class="col-6 col-sm-6 col-md-3 col-lg-3 nav-item">
        <label class="form-label">Phone Number *</label>
        <input placeholder="Phone Number" type="text" class="form-control" id="UsuTelefonoBill">
      </div>

      <div class="col-6 col-sm-6 col-md-3 col-lg-3 nav-item">
        <label class="form-label">Email</label>
        <input placeholder="example@gmail.com" type="text" class="form-control" id="UsuEmail">
      </div>

      <div class="col-6 col-sm-6 col-md-3 col-lg-3 nav-item my-3">
        <label class="form-label">Address *</label>
        <input placeholder="Address" type="text" class="form-control" id="UsuDireccion2Bill">
      </div>

      <div class="col-6 col-sm-6 col-md-3 col-lg-3 nav-item my-3">
        <label class="form-label">Address 2</label>
        <input placeholder="Address" type="text" class="form-control" id="UsuDireccion2Bill">
      </div>

      <div class="col-6 col-sm-6 col-md-3 col-lg-3 nav-item my-3">
        <label class="form-label">City *</label>
        <input placeholder="City" type="text" class="form-control" id="UsuCiudadBill">
      </div>
      <div class="col-6 col-sm-6 col-md-3 col-lg-3 nav-item my-3">
        <label class="form-label">State *</label>
        <input placeholder="state" type="text" class="form-control" id="UsuStateBill">
      </div>

      <div class="col-6 col-sm-6 col-md-3 col-lg-3 nav-item my-3">
        <label class="form-label">ZIP Code *</label>
        <input placeholder="ZIP CODE" type="text" class="form-control" id="UsuZipCodeBill">
      </div>


      <div class="col-6 col-sm-5 col-md-3 col-lg-3 my-3">
        <label class="tform-label">Role *</label>
        <ng-multiselect-dropdown name="role" [disabled]="estListo" formControlName="role" [(ngModel)]="this.currentRol"
          [placeholder]="'Select Role'" [settings]="{      
                closeDropDownOnSelection: true,
                singleSelection: true,
                idField: 'rolId',
                textField: 'rolDescripcion',
                itemsShowLimit: 1,
                allowSearchFilter: true}" [data]="roles"></ng-multiselect-dropdown>

      </div>

      <div class="d-flex flex-row-reverse bd-highlight p-0">

        <div class="p-2 bd-highlight"><button type="submit" (click)="Submit()"
            class="btn btn-primary btn-lg text-white ">
            Save User

            <i class="fa fa-floppy-o"></i></button></div>

        <div class="p-2 bd-highlight">
          <button class="btn btn-danger btn-lg text-white" routerLink="/Dashboard/Usuarios">
            <i class="fa fa-times"></i> Cancel</button>
        </div>
      </div>
    </div>
  </form>
</div>