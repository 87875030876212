import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService, DatosLogin } from '../../services/usuario.service';
import { ChatService } from '../../services/chat.service';
import { getMessaging, getToken } from 'firebase/messaging';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { FireBaseCredencials } from '../../helpers/Util';


@Component({
  selector: 'app-relogin',
  templateUrl: './relogin.component.html',
  styleUrls: ['./relogin.component.scss']
})
export class ReloginComponent implements OnInit {

  loading = false;
  public totalItems: any;
  usuId: any;
  empID: any;
  usuFoto: any;
  msg = getMessaging();

  credentials: DatosLogin = {

    Usuario: '',
    Password: ''

  };

  constructor(private _http: HttpClient, private auth: UsuarioService, private _chatService: ChatService, private router: Router, private _location: Location) { }


  ngOnInit(): void {
    this.usuFoto = localStorage.getItem('usuFoto');
    this.getGrupo();
  }


  getGrupo() {
    this.loading = true;
    this._chatService.getGrupo().subscribe(
      res => {
        this.loading = false;
        this.router.navigateByUrl('/')
      }, err => {
        if (localStorage.getItem("token") == null) {
          //window.localStorage.removeItem('token');
          //window.location.reload();
          this.router.navigateByUrl('/')

        }
        this.loading = false;
        console.log(err);
      })
  }

  login() {
    this.loading = true;
    this.logout(false)
    window.localStorage.removeItem('token');
    this.credentials.Usuario = localStorage.getItem('usuSesion');
    this.auth.login(this.credentials).subscribe(res => {
      localStorage.setItem('usuId', res.usuId);
      localStorage.setItem('usuSesion', res.usuSesion);
      localStorage.setItem('usuFoto', res.usuFoto);
      localStorage.setItem('token', res.token);
      if (res.empID != 0) {
        localStorage.setItem('empID', res.empID);
      }
      this.loading = false;
      this._location.back();
    }, (err) => {
      this.loading = false;
      console.error(err);
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: err.error,
        showConfirmButton: false,
        timer: 1500
      })
    });
  }

  logout(toReload): void {
    try {

      //first try to remove usuId topic
      getToken(this.msg, { vapidKey: FireBaseCredencials.vapidKeyToken }).then((tok) => {
        this.usuId = localStorage.getItem('usuId');
        fetch('https://iid.googleapis.com/iid/v1/' + tok + '/rel/topics/' + this.usuId, {
          method: 'DELETE',
          headers: new Headers({
            'Authorization': FireBaseCredencials.vpauth
          })
        }).then(async (resUsu) => {
          if (resUsu.status == 200) {
            console.log("TopicUser Unsubscribed");
          } else {
            throw 'Error Unsubscribing to Usu: ' + resUsu.status + ' - ' + await resUsu.json();
          }
        }).catch(error => {
          console.error(error);
        })

        //check if is a company and remove empId topic
        if (localStorage.getItem('empID')) {

          this.empID = localStorage.getItem('empID');
          fetch('https://iid.googleapis.com/iid/v1/' + tok + '/rel/topics/emp' + this.empID, {
            method: 'DELETE',
            headers: new Headers({
              'Authorization': FireBaseCredencials.vpauth
            })
          }).then(async (resEmp) => {
            if (resEmp.status == 200) {
              console.log("TopicEmp Unsubscribed");
            }
            else {
              throw 'Error Unsubscribing to Emp: ' + resEmp.status + ' - ' + await resEmp.json();
            }
          }).catch(error => {
            console.error(error);
          })

        }
      })

    } catch (error) {

      console.error(error);
    }

    window.localStorage.removeItem('token');

    if (toReload) {
      window.localStorage.removeItem('usuId');
      window.localStorage.removeItem('usuSesion');
      window.localStorage.removeItem('usuFoto');
      window.localStorage.removeItem('firebaseToken');
      window.localStorage.removeItem('empID');
      window.location.reload();
    }

  }


}
