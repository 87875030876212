import { Component, OnInit } from '@angular/core';
import { ProductosService, brrCarrito, detalleCarrito } from '../../services/productos.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-carritofull',
  templateUrl: './carritofull.component.html',
  styleUrls: ['./carritofull.component.scss']
})
export class CarritofullComponent implements OnInit {

  public productos: any;
  public totalItems: number;
  public subTotal: number;
  public cant: any;
  public MontoTotal: any;

  result = false;
  loading = false;
  noData = false;
  habilitado = true;

  datoBorrar: brrCarrito = {

    PedPosicion: 0,
    PieID: 0,
    PedUsuID: 0,
    EmpID: 0,
    PedCantidad: 0,
    PedPrecio: 0,
    PedPorcientoIImpuestos: 0,
    PedMontoImpuestos: 0,
    PedDescuentoPorciento: 0,
    PedMontoDescuento: 0,
    PedMontoSubTotal: 0,
    PedMontoTotal: 0,
    PedMontoEnvio: 0,
    //PedFecha: string,
    PedImagen: '',
    ProNombre: '',
    PedEstatus: 0,
    PedNumTracking: '',
    UsuID: 0,
    PedNota: ''

  }

  datosCarrito: detalleCarrito = {

    PedPosicion: 0,
    PieID: 0,
    PedUsuID: 0,
    EmpID: 0,
    PedCantidad: 0,
    PedPrecio: 0,
    PedPorcientoIImpuestos: 0,
    PedMontoImpuestos: 0,
    PedDescuentoPorciento: 0,
    PedMontoDescuento: 0,
    PedMontoSubTotal: 0,
    PedMontoTotal: 0,
    PedMontoEnvio: 0,
    //PedFecha: string,
    PedNumTracking: '',
    UsuID: 0,
    PedEstatus: 0,
    PedNota: '',
    PedImagen: '',
    ProNombre: ''
  };

  constructor(private _productosService: ProductosService, private router: Router) { }

  ngOnInit(): void {
    this.getPedidosTemp();
  }

  getPedidosTemp(): void {
    this.loading = true;
    this._productosService.getPedidosTemp().subscribe(
      response => {
        this.loading = false;
        this.result = true;
        this.productos = response;
        this.totalItems = this.productos.length;
        this.subTotal = response.reduce((sum, item) => sum + item.pedMontoTotal, 0);
        if (this.totalItems == 0) {
          this.habilitado = false;
        }
      },
      err => {
        console.log(err);
      })
  }

  borrarItem(item: any) {
    //this.datoBorrar.ProID = item;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this._productosService.borrartCarrito(item).subscribe(
          res => {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            window.location.reload();
          }, err => {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: err.error,
              showConfirmButton: false,
              timer: 1500
            })
            console.log(err);
          })
      }
    })
  }

  itemUpdate(item: any) {
    this.cant = Number((<HTMLInputElement>document.getElementById(String(item.pieID))).value);
    item.PedMontoTotal = this.cant * item.pedPrecio;
    item.pedCantidad = Number((<HTMLInputElement>document.getElementById(String(item.pieID))).value);
    this._productosService.postCarrito(item).subscribe(
      response => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'updated',
          showConfirmButton: false,
          timer: 1500
        })
        window.location.reload();

      }, err => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: err.error,
          showConfirmButton: false,
          timer: 1500
        })
        console.log(err);
      })
  }
}
