import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from './services/chat.service';
import { getMessaging, onMessage, getToken, MessagePayload, deleteToken} from 'firebase/messaging';
import {ToastrService} from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { CommunicationServiceService } from './services/communication-service.service'
import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit{

  title = 'Vehicle-Parts';
  public token: any;
  messege: string;
  msg = getMessaging();
  message: MessagePayload | undefined;
  FCMtoken: string | undefined;
  toastRef;
  public grupId: any;
  public tiponoti: any;
  public data: any;
  public body: any;
  public Title: any;
  
  constructor(private communicationService: CommunicationServiceService, private router: Router, private afMessaging: ChatService, private toastr: ToastrService) {
      
   }

  ngOnInit(): void {
    this.communicationService.componentMethodCalled.subscribe(
      messege =>{


      })

    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
      } else {
      }
    })
    onMessage(this.msg, (payload) => {
      this.tiponoti = payload.data.tiponoti;
      this.grupId = payload.data.grupId;
      this.body = payload.notification.body;
      this.Title = payload.notification.title;

      if(this.tiponoti == 4){
        Swal.fire({
          position: 'top-end',
          title: 'Incoming call',
          text: "Incoming call!",
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonText: 'ignore',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Take call!'
        }).then((result) => {
          if (result.isConfirmed) {
            window.open('https://videocall.vehiclepart.shop/?' + this.grupId ,'','width=500,height=800,status=no,toolbar=no,menubar=no');
          }
        })    
      }else{
        if(this.tiponoti == 1){
        Swal.fire({
          position: 'top-end',
          title: this.Title,
          text: this.body,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'ignore',
          confirmButtonText: 'Go to notifications',
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },                           
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigateByUrl('/allmessege');
          }
        })  
      }else{
        Swal.fire({
          position: 'top-end',
          title: this.Title,
          text: this.body,
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        })
        }  
      }
    })
  }
}

