import { Component, OnInit } from '@angular/core';
import { ProductosService } from '../../services/productos.service';
import { piezasBuscar } from '../../Entities/Pieza';
import { Router} from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  public usuSesion: any;
  public usuFoto: any;
  public UsuId: any;
  public totalItems: any;
  public payPalConfig: any;
  public productos: any;
  public totalPiezas: number = 0;
  public cantGuard: any = 0;

  count = 0;
  cargar = true;
  page = 1;
  pageSize = 20;

  mispiezas = new piezasBuscar();

  constructor(private _productoService: ProductosService, private _router: Router,) { }

  ngOnInit(): void {

    this.usuSesion = localStorage.getItem('usuSesion');
    this.usuFoto = localStorage.getItem('usuFoto');
    this.UsuId = localStorage.getItem('usuId');
  
    this.Mispiezas();
    this.geGuardadas();
    this.totalPieza();
  }

  getRequestParams(page: number, pageSize: number): any {
    let params: any = {};

    if (page) {
      params['pageNumber'] = page;
    }
    return params;
  }

  totalPieza() {
    this.mispiezas.status = 0;
    this._productoService.Mipiezas(this.mispiezas).subscribe(
      response => {
        const { results, totalRecordCount } = response;
        this.totalPiezas = totalRecordCount;
      }, (err: any) => {
         console.log(err);
      })
  }

  Mispiezas() {
    this.mispiezas.maincar = 0;
    this.mispiezas.nombre = (<HTMLInputElement>document.getElementById('namepart')).value;
    this.mispiezas.status = Number((<HTMLOptionElement>document.getElementById('status')).value);
    this.mispiezas.pageNumber = this.page;
    this.mispiezas.pageSize = this.pageSize;
    this._productoService.Mipiezas(this.mispiezas).subscribe(
      response => {
        const { results, totalRecordCount } = response;
        this.productos = results;
        this.count = totalRecordCount;
        this.cargar = false;
      }, (err: any) => {
        this.cargar = false;
        console.error(err);
        if (err.error == 'Carga Completa') {
          if (this.page == 1) {
            this.productos = [];
            this.count = 0;
          }
        }else{
          if (err.status == 401) {
            // window.localStorage.removeItem('token');
            this._router.navigateByUrl('/Relogin')
          }
          else{
            Swal.fire({
              icon: 'error',
              title:err.error,
              showConfirmButton: true
            })
          }
        }
      })
  }

  geGuardadas() {
    this._productoService.getGuardados().subscribe(
      res => {
        this.cantGuard = res.length;
      }, err => {
        console.log(err);
      })

  }

  handlePageChange(event: number): void {
    this.page = event;
    this.startup();
    this.Mispiezas();
  }

  startup() {
    window.scroll(0, 500);
    this.cargar = true;
  }

  irEditar(producto: any) {
      this._router.navigateByUrl('/editpieza/' + producto.pieId);
  }

  irAdd() {
      this._router.navigateByUrl('/addpieza');
    }
}
