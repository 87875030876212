<div class="modal-dialog modal-lg text-center ounded shadow">
    <div class="modal-content">  
    <div class="container container--mini">
        <a [routerLink]="['/']"><img class="img-fluid mx-auto d-block mb-3  mt-5" src="/assets/img/LogovehiclesParts.jpg" height="200"  width="200" alt=""></a> 
            <p>Enter your details to log in.</p>
            <form name="loginform"(submit)="login()">
                <div class="form-group">
                    <label for="user_login"></label>
                    <input type="text" name="log" id="user_login" class="form-control" value="" size="20" placeholder="&#xf007; Username" style="font-family:Arial, FontAwesome" name="UsuSesion" [(ngModel)]="credentials.Usuario"/>
                </div>
                <div class="form-group">
                   <label for="user_pass"></label>
                   <input type="password" name="pwd" id="user_pass" class="form-control" value="" size="20" placeholder="&#xf023; Password" style="font-family:Arial, FontAwesome" name="UsuPass" [(ngModel)]="credentials.Password"/>
                 </div>
               <div class="form-group">
                 <button type="submit" class="btn btn-primary mb-1 mt-3 text-white">Login <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                 <hr>
                 <a class="form-sublink" href=""   [routerLink]="['/recuperarPass']">Did you forget your password?</a>
               </div>
           </form>
        <p class="text-gray-soft text-right mt-5"> You do not have an account?<a [routerLink]="['/registrar']">Sign up</a></p>
       </div>
   </div>
</div>
