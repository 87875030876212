<body class="sBody">

    <!-- Complete Invoce-->
    <div class="modal" id="setCompleteModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Complete Sale</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <h3>TOTAL: {{total | currency}}</h3>
                    <div class="d-flex align-items-center">
                        <div class="m-2">
                            <div class="col col-sm col-md col-lg nav-item mt-3">
                                <h6 class="text-primary">Select the payment method:</h6>

                                <ng-multiselect-dropdown [(ngModel)]="this.terminoPago" [placeholder]="'Select method'"
                                    [settings]="{      
                                  closeDropDownOnSelection: true, 
                                  singleSelection: true,
                                  idField: 'terID',
                                  textField: 'terDescripcion',
                                  itemsShowLimit: 1,
                                  allowSearchFilter: true}" [data]="terminosPagoList"appSelectAutoFoucs></ng-multiselect-dropdown>
                            </div>
                            <br>
                            <div class="col col-sm col-md col-lg nav-item mt-3">
                                <h6 class="text-primary">Paid: </h6>
                                <div class="input-group">
                                    <button class="btn btn-success" type="button">$</button>
                                    <input type="text" class="form-control" value="{{total}}" (keyup)="changePaid()"
                                        placeholder="0" id="paidTerminar_ip">
                                </div>
                            </div>

                            <div class="col col-sm col-md col-lg nav-item mt-3">
                                <h6 class="text-primary">Returned: </h6>
                                <div class="input-group">
                                    <div class="input-group">
                                        <button class="btn btn-success" type="button">$</button>
                                        <input type="text" class="form-control" value="{{total}}" placeholder="0"
                                            disabled id="retornedTerminar_ip">
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="d-flex justify-content-end mt-3">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-success mx-3 text-white" (click)="PostFactura()">Complete
                            invoce
                            <div *ngIf="loading" class="row p-5">
                                <div class="d-flex justify-content-center">
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </button>
                        <button type="button" hidden id="openTermino_btn" data-bs-toggle="modal"
                            data-bs-target="#setCompleteModal">Add</button>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <!-- Modal customer-->
    <div class="modal fade modal-xl" id="setCustomerModal" data-bs-backdrop="static" data-bs-keyboard="false"
        tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="customerTitle">Set Customer</h1>
                    <button id="closeCustumer_btn" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div id="searchCustumer_dv" style="display:block">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 mt-0 mb-2">
                           
                            <h6 class="text-primary">Search</h6>
                            <div class="d-flex flex-row">
                                <input type="text" class="form-control" id="customerSearch_im"
                                    placeholder="Customer ID, Phone or email" (keyup)="SearchMainEnter($event,2)">
                                <div *ngIf="loading" class="d-flex justify-content-center">
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                <button type="button" class="btn btn-primary ms-2 text-white"
                                    (click)="searchUser()">Search</button>
                            </div>
                            
                            <button type="button" class="btn btn-primary my-2 text-white" (click)="openNewCustomer()" > New Customer </button>
                        </div>
                    </div>


                    <div id="addCustumer_dv" class="p-1" style="display:none">
                        <div class="row p-1">
                            <div class="col-md-3">
                                <label class="form-label">First Name <span style="color: red;">*</span></label>
                                <input type="text" placeholder="First Name" class="form-control" id="firstNameC_ip">
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">Last Name <span style="color: red;">*</span></label>
                                <input type="text" placeholder="Last Name" class="form-control" id="lastNameC_ip">
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">Telephone <span style="color: red;">*</span></label>
                                <input type="tel" placeholder="Telephone" class="form-control" id="phoneC_ip">
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">Email  <span style="color: red;">*</span></label>
                                <input type="email" placeholder="Email" class="form-control" id="emailC_ip">
                            </div>
                        </div>

                        <div class="row p-1">
                            <div class="col-md-3">
                                <label class="form-label">Address</label>
                                <input type="text" placeholder="Address" class="form-control" id="addressC_ip">
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">City</label>
                                <input type="text" placeholder="City" class="form-control" id="cityC_ip">
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">State</label>
                                <input type="text" placeholder="State" class="form-control" id="stateC_ip">
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">ZipCode</label>
                                <input type="text" placeholder="ZipCode" class="form-control" id="zipCodeC_ip">
                            </div>
                        </div>

                        <div style="margin-top: 20px;" class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="openNewCustomer()" > Cancel </button>
                            <button type="button" class="btn btn-primary ms-2 text-white" (click)="newCustomer()">Save</button>
                        </div>
                    </div>


                    <button type="button" class="btn btn-danger" style="display: none;" id="closeCustomerModal"
                        data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal EditProduct-->
    <div class="modal" id="setEditModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Edit Part</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <h4>{{currentPieza.pieNombre}}</h4>
                    <div class="d-flex align-items-center">
                        <img class="mb-0 pt-0 me-0 align-items-center" src={{currentPieza.pieFoto}}
                            (click)="verImagen(currentPieza.pieFoto)" width="250px" alt="..."
                            style="cursor: pointer; max-height: 300px;">
                        <div class="m-2">
                            <h5>Stock:</h5>
                            <input type="number" value={{currentPieza.pieDisponible}} disabled />
                            <br>
                            <br>
                            <h5>Quantity:</h5>
                            <input type="number" id="editQty_ip" value={{currentPieza.facCantidad}} />
                            <br>
                            <br>
                            <h5>Price:</h5>
                            <input type="number" id="editPrice_ip" value={{currentPieza.piePrecio}} />
                            <br>
                        </div>
                    </div>

                    <div class="d-flex justify-content-end mt-3">
                        <button type="button" class="btn btn-danger " id="closeEditModal"
                            data-bs-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-success mx-3" (click)="UpdatePartClick()">Update</button>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <!-- Modal SoliCantidad-->
    <div class="modal fade odal-xl" id="setSoliCantModal" data-bs-backdrop="static" data-bs-keyboard="false"
        tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Set Quantity</h1>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 mt-0 mb-2">
                            <h6 class="text-primary">Quantity: </h6>
                            <div class="d-flex flex-row">
                                <input type="number" class="form-control" id="quantity_ip"
                                    (keyup)="SearchMainEnter($event,1)">
                                <button type="button" class="btn btn-primary ms-2 text-white"
                                    (click)="SendRequestQuantity()">Add</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" id="closeSoliCantModal" hidden
                        data-bs-dismiss="modal">Cancel</button>
                    <button type="button" hidden id="openSoli_btn" data-bs-toggle="modal"
                        data-bs-target="#setSoliCantModal">Add</button>

                </div>
            </div>
        </div>
    </div>

    <!-- Modal -->
    <div class="modal fade modal-xl" id="SearchPopUp" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Advanced Search</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <form [formGroup]="myForm">
                        <div class="my-0 row">

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mt-4">
                                <h6 class="text-primary">Search</h6>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" id="tbxBuscar" placeholder="Search..."
                                        aria-label="Recipient's username" aria-describedby="button-addon2"
                                        (keydown.enter)="BuscarMispiezas(false)" autocomplete="off">
                                    <button class="btn btn-primary text-white" type="button"><i class="fa fa-qrcode"
                                            aria-hidden="true"></i></button>
                                </div>
                                <button hidden id="openCamera_btn" type="button" data-bs-toggle="modal"
                                    data-bs-target="#scanPopUp"></button>

                            </div>

                            <!-- <div class="col col-sm col-md col-lg nav-item mt-3">
                                <h6 class="text-primary">Status</h6>

                                <ng-multiselect-dropdown name="statusf" formControlName="statusf"
                                    [(ngModel)]="this.currentStatus" [placeholder]="'Select status'" [settings]="{      
                  closeDropDownOnSelection: true, 
                  singleSelection: true,
                  idField: 'estEstado',
                  textField: 'estDescripcion',
                  itemsShowLimit: 1,
                  allowSearchFilter: true}" [data]="status"appSelectAutoFoucs></ng-multiselect-dropdown>
                            </div> -->

                            <div class="col-4 col-sm-4 col-md-2 col-lg-2 nav-item mt-3">
                                <h6 class="text-primary">Year</h6>

                                <ng-multiselect-dropdown name="year" formControlName="year"
                                    [(ngModel)]="this.currentYear" [placeholder]="'Select year'" [settings]="{      
                    closeDropDownOnSelection: true,
                    singleSelection: true,
                    idField: 'year',
                    textField: 'year',
                    itemsShowLimit: 1,
                    allowSearchFilter: true}" [data]="years"appSelectAutoFoucs></ng-multiselect-dropdown>


                            </div>


                            <div class="col-4 col-sm-4 col-md-2 col-lg-2 nav-item mt-3 ">
                                <h6 class="text-primary ">Make</h6>

                                <ng-multiselect-dropdown [disabled]="marListo" name="marcaf" formControlName="marcaf"
                                    [(ngModel)]="this.currentMarca" [placeholder]="'Select make'" [settings]="{   
                  closeDropDownOnSelection: true,   
                  singleSelection: true,
                  idField: 'marID',
                  textField: 'marDescripcion',
                  itemsShowLimit: 1,
                  allowSearchFilter: true}" [data]="marca" (onDeSelect)="UnSelectObject($event)"
                                    (onSelect)="cargarModelo($event)"appSelectAutoFoucs></ng-multiselect-dropdown>

                            </div>

                            <div class="col-4 col-sm-4 col-md-2 col-lg-2 nav-item mt-3">
                                <h6 class="text-primary">Model</h6>

                                <ng-multiselect-dropdown name="modelof" formControlName="modelof"
                                    [(ngModel)]="this.currentModelo" [placeholder]="'Select model'" [settings]="{      
                  closeDropDownOnSelection: true, 
                  singleSelection: true,
                  idField: 'modID',
                  textField: 'modDescripcion',
                  itemsShowLimit: 1,
                  allowSearchFilter: true}" [data]="modelo"appSelectAutoFoucs></ng-multiselect-dropdown>

                            </div>

                            <div class="col-4 col-sm-4 col-md-2 col-lg-2 nav-item mt-3">
                                <h6 class="text-primary">Category</h6>

                                <ng-multiselect-dropdown name="catf" formControlName="catf"
                                    [(ngModel)]="this.currentCategory" [placeholder]="'Select Category'" [settings]="{      
                  closeDropDownOnSelection: true, 
                  singleSelection: true,
                  idField: 'catID',
                  textField: 'catDescripcion',
                  itemsShowLimit: 1,
                  allowSearchFilter: true}" [data]="categoria"
                                    (onDeSelect)="UnSelectObject($event)"appSelectAutoFoucs></ng-multiselect-dropdown>
                            </div>

                            <div class="col-4 col-sm-4 col-md-2 col-lg-2 nav-item mt-3">
                                <h6 class="text-primary">Part</h6>

                                <ng-multiselect-dropdown name="partf" formControlName="partf"
                                    [(ngModel)]="this.currentPart" [placeholder]="'Select Part'" [settings]="{      
                  closeDropDownOnSelection: true, 
                  singleSelection: true,
                  idField: 'piePreId',
                  textField: 'piePreNombre',
                  itemsShowLimit: 1,
                  allowSearchFilter: true}" [data]="parts"appSelectAutoFoucs></ng-multiselect-dropdown>
                            </div>

                            <div class="col-4 col-sm-4 col-md-2 col-lg-2 nav-item mt-3">
                                <h6 class="text-primary text-white">buscar</h6>
                                <div class="input-group mb-3">
                                    <button (click)="BuscarMispiezas(false)" class="btn btn-primary text-white"
                                        style="width: 70%;">Search </button>
                                    <button class="btn btn-danger text-white" style="width: 30%;"
                                        (click)="BuscarMispiezas(true)">x</button>
                                </div>

                            </div>

                            <hr style="border: black; border-width: 1px;" />

                        </div>
                    </form>


                    <div *ngIf="loading == false" class="scrollable-list">
                        <table class="table table-bordered">
                            <thead>
                                <tr style="background-color: gainsboro; color: #607d8b; padding-bottom: -100px;"
                                    class="p-0 m-0">
                                    <th class="tabletHead" width="5%">
                                        <p class="fs-5 fw-light align-items-centert">Picture</p>
                                    </th>
                                    <th class="tabletHead">
                                        <p class="fs-5 fw-light">Description</p>
                                    </th>
                                    <th width="20px" style="background-color: gainsboro;">
                                        <p class="fs-5 fw-light">Stock</p>
                                    </th>
                                    <th width="20px" style="background-color: gainsboro;">
                                        <p class="fs-5 fw-light">Price</p>
                                    </th>
                                    <th width="100px" class="tabletHead">
                                        <p class="fs-5 fw-light">Actions</p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let pieza of productos | paginate : { itemsPerPage: pageSize, currentPage: page, totalItems: count  }; let i = index">

                                    <td><img class="mb-0 pt-0 me-0 align-items-center" src={{pieza.pieFoto}} height="50"
                                            (click)="verImagen(pieza.pieFoto)" width="50" alt="..."
                                            style="cursor: pointer;">
                                    </td>
                                    <td>{{pieza.pieNombre}}<br>


                                        <small class="text-muted">{{pieza.marcaString}} {{pieza.modeloString}}
                                            {{pieza.pieAnoDesde}} - {{pieza.pieAnoHasta}}, {{pieza.categoriaString}}
                                            <img class="mb-0 pt-0 me-2"
                                                src="https://vehiclepart.shop/assets/img/LogovehiclesParts.jpg"
                                                height="20" width="20" alt="..."
                                                *ngIf="pieza.pieTipoIntegracion ==0 || pieza.pieTipoIntegracion ==2;">
                                            <img class="my-0 align-middle"
                                                src="https://ir.ebaystatic.com/cr/v/c1/ebay-logo-1-1200x630-margin.png"
                                                height="30" width="60" alt="..."
                                                *ngIf="pieza.pieTipoIntegracion ==1 || pieza.pieTipoIntegracion ==2;">

                                            <span class="badge bg-primary mx-2" *ngIf="pieza.pieCondicion != null">
                                                {{pieza.pieCondicion}}
                                            </span>
                                            <span class="badge bg-primary mx-2" *ngIf="pieza.pieGarantia != ''">
                                                {{pieza.pieGarantia}}
                                            </span>
                                            <span *ngIf="getsenalUso(pieza) != null" class="badge bg-primary">
                                                Grade: {{getsenalUso(pieza)}}
                                            </span>
                                        </small>

                                        <br>
                                        <span class="badge bg-warning">
                                            Part ID: {{pieza.pieId}}
                                        </span>
                                        <span class="badge bg-info mx-2" *ngIf="pieza.pieLocation != null">
                                            Shelf/Location: {{pieza.pieLocation}}
                                        </span>
                                        <ng-container [ngSwitch]="pieza.pieEstado">
                                            <ng-container *ngSwitchCase="1">
                                                <span class="badge bg-danger mx-2">{{pieza.estadoString}}</span>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="2">
                                                <span class="badge bg-warning mx-2">{{pieza.estadoString}}</span>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="3">
                                                <span class="badge bg-primary mx-2">{{pieza.estadoString}}</span>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="4">
                                                <span class="badge bg-secondary mx-2">{{pieza.estadoString}}</span>
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                    <td>{{pieza.pieDisponible}} </td>
                                    <td>{{pieza.piePrecio | currency}}</td>
                                    <td>
                                        <div class="d-flex flex-row">
                                            <span style="height:35px; width:40px;" class="btn btn-success mx-2"
                                                (click)="AddPieza(pieza)">
                                                <i class="fa fa-cart-plus" aria-hidden="true"></i>
                                            </span>
                                        </div>

                                    </td>

                                </tr>

                            </tbody>
                        </table>

                        <style>
                            .my-pagination /deep/ .ngx-pagination .current {
                                background: #008c44;
                            }
                        </style>
                        <div class="col-12 mt-3 d-flex justify-content-center">
                            <pagination-controls class="my-pagination" previousLabel="Prev" nextLabel="Next"
                                [responsive]="true" (pageChange)="handlePageChange($event)">
                            </pagination-controls>
                        </div>
                    </div>


                    <div *ngIf="loading" class="row p-5">
                        <div class="d-flex justify-content-center">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="productos.length == 0" class="row p-5">
                        <div class="d-flex justify-content-center">
                            <h3>
                                <span>
                                    Data not found.</span>
                            </h3>
                            <img src="../../../assets/img/phvp.png" width="200" height="200" alt="">
                        </div>
                    </div>


                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" hidden id="openAdvanceBuscar_btn" data-bs-toggle="modal"
                        data-bs-target="#SearchPopUp">Add</button>
                </div>
            </div>
        </div>
    </div>


    <header id="header_div">
        <div class="d-flex justify-content-between align-self-start mx-2">
            <nav class="d-flex justify-content-between"
                style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
                aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/Dashboard">Dashboard</a></li>
                    <li class="breadcrumb-item active" aria-current="page">New Sale</li>
                </ol>
            </nav>
            <button id="ws_btn" (click)="GetTerminosPagos()" class="btn btn-primary ms-1 text-white">Complete
                sale</button>
        </div>

        <div class="d-flex mx-2 my-1 py-2 px-3 rounded rounded-2" style="background-color: #e3e3e3;">

            <div class="d-flex justify-content-start">
                <div class="d-flex flex-column justify-content-start" style="font-size: 14px">
                    <span class="text-start" style="color: #607d8b;">SALES ORDER FOR:</span>
                    <span class="text-start">User code: {{cliente.usuId}}</span>
                    <span class="text-start">{{cliente.usuNombres}} {{cliente.usuApellidos}}</span>
                    <span class="text-start">Email: {{cliente.usuEmail}}</span>
                    <span class="text-start">Phone: {{cliente.usuTelefonoBill}}</span>
                </div>

                <div class="d-flex flex-column justify-content-start mx-4" style="font-size: 14px">
                    <span class="text-start" style="color: #607d8b;">
                        CUSTOMER ADDRESS:
                    </span>
                    <span class="text-start">{{cliente.usuDireccionShip}} {{cliente.usuDireccion2Ship}}</span>
                    <span class="text-start">{{cliente.usuCiudadShip}}, {{cliente.usuStateShip}}
                        {{cliente.usuZipCodeShip}}</span>
                    <span class="text-start">United States</span>
                </div>

                <a id="borrarCli_btn" style="height:35px; width:40px; display: none;" (click)="RemoveCustomer()"
                    class="btn btn-danger mx-2">
                    <i class="fa fa-trash"></i>
                </a>

                <div style="background-color:black; height: 90px; width:1px"></div>
            </div>

            <div class="mx-2 flex-grow-1">

                <div class="d-flex flex-row justify-content-start mt-1 mx-2">
                    <button class="btn btn-success text-white d-flex flex-row" data-bs-toggle="modal"
                        data-bs-target="#SearchPopUp" style="width: 200px;" id="advanced_btn">
                        <i class="fa fa-search me-2 mt-1"></i>Advanced Search
                    </button>

                    <div class="input-group ms-2 me-1">
                        <input type="text" class="form-control" placeholder="Part LookUp" id="SearchMain_ip"
                            (keyup)="SearchMainEnter($event,0)" autocomplete="off">
                        <button class="btn btn-success" type="button" (click)="CheckRequestQuantity()">
                            <i class="fa fa-sign-in" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>

                <div class="input-group mx-2 mt-2">
                    <input class="form-check-input" type="checkbox" id="soliCant_ck"
                        style="height:30px; width: 30px; margin-top: 0px; border: 1px solid #008c44;">
                    <button class="btn btn-primary text-white" style="height:30px; padding-top: 3px;"
                        (click)="CheckClick()" for="soliCant_ck">Request Quantity</button>
                </div>


                <div class="d-flex flex-row justify-content-end">
                    <button class="my-0 btn btn-primary text-white" onclick="setComment()">
                        <i class="fa fa-comment-o"></i> Comment
                    </button>
                    <h6 class="mx-2">|</h6>

                    <button class="my-0 btn btn-primary text-white" data-bs-toggle="modal"
                        data-bs-target="#setCustomerModal">
                        <i class="fa fa-user"></i> Set Customer
                    </button>

                </div>
            </div>

            <div>

            </div>
        </div>

    </header>

    <main id="main_div" class="m-3">
        <!-- Contenido principal aquí -->
        <div *ngIf="loading == false" class="scrollable-list">
            <table class="table table-bordered">
                <thead>
                    <tr style="background-color: gainsboro; color: gainsboro; padding-bottom: -100px;" class="p-0 m-0">
                        <th style="background-color: gainsboro; padding-bottom: -10px; color: gainsboro;" width="5%">
                            <p class="fs-5 fw-light align-items-centert">Picture</p>
                        </th>
                        <th class="tabletHead">
                            <p class="fs-5 fw-light">Description</p>
                        </th>
                        <th width="15px" style="background-color: gainsboro;">
                            <p class="fs-5 fw-light">Qty</p>
                        </th>
                        <th width="20px" style="background-color: gainsboro;">
                            <p class="fs-5 fw-light">Stock</p>
                        </th>
                        <th width="20px" style="background-color: gainsboro;">
                            <p class="fs-5 fw-light">Price</p>
                        </th>
                        <th width="25px" style="background-color: gainsboro;">
                            <p class="fs-5 fw-light">Total</p>
                        </th>
                        <th width="100px" class="tabletHead">
                            <p class="fs-5 fw-light">Actions</p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let pieza of piezasAgregadas">

                        <td><img class="mb-0 pt-0 me-0 align-items-center" src={{pieza.pieFoto}} height="50"
                                (click)="verImagen(pieza.pieFoto)" width="50" alt="..." style="cursor: pointer;">
                        </td>
                        <td>{{pieza.pieNombre}}<br>


                            <small class="text-muted">{{pieza.marcaString}} {{pieza.modeloString}}
                                {{pieza.pieAnoDesde}} - {{pieza.pieAnoHasta}}, {{pieza.categoriaString}}
                                <img class="mb-0 pt-0 me-2"
                                    src="https://vehiclepart.shop/assets/img/LogovehiclesParts.jpg" height="20"
                                    width="20" alt="..."
                                    *ngIf="pieza.pieTipoIntegracion ==0 || pieza.pieTipoIntegracion ==2;">
                                <img class="my-0 align-middle"
                                    src="https://ir.ebaystatic.com/cr/v/c1/ebay-logo-1-1200x630-margin.png" height="30"
                                    width="60" alt="..."
                                    *ngIf="pieza.pieTipoIntegracion ==1 || pieza.pieTipoIntegracion ==2;">

                                <span class="badge bg-primary mx-2" *ngIf="pieza.pieCondicion != null">
                                    {{pieza.pieCondicion}}
                                </span>
                                <span class="badge bg-primary mx-2" *ngIf="pieza.pieGarantia != ''">
                                    {{pieza.pieGarantia}}
                                </span>
                                <span *ngIf="getsenalUso(pieza) != null" class="badge bg-primary">
                                    Grade: {{getsenalUso(pieza)}}
                                </span>
                            </small>

                            <br>
                            <span class="badge bg-warning">
                                Part ID: {{pieza.pieId}}
                            </span>
                            <span class="badge bg-info mx-2" *ngIf="pieza.pieLocation != null">
                                Shelf/Location: {{pieza.pieLocation}}
                            </span>
                        </td>
                        <td>{{pieza.facCantidad}} </td>
                        <td>{{pieza.pieDisponible}} </td>
                        <td>{{pieza.piePrecio | currency}}</td>
                        <td>{{pieza.facTotal | currency}}</td>
                        <td>
                            <div class="d-flex flex-row">
                                <span style="height:35px; width:40px;" class="btn btn-danger mx-2"
                                    (click)="DeletePart(pieza)">
                                    <i class="fa fa-trash"></i>
                                </span>
                                <span style="display: none;" data-bs-toggle="modal" data-bs-target="#setEditModal"
                                    id="openEditModal_btn">
                                    <i class="fa fa-pencil-square-o"></i>
                                </span>
                                <span style="height:35px; width:40px;" class="btn btn-success mx-2"
                                    (click)="EditPartClick(pieza)">
                                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                </span>
                            </div>

                        </td>

                    </tr>

                </tbody>
            </table>
        </div>


        <div *ngIf="loading" class="row p-5">
            <div class="d-flex justify-content-center">
                <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>

        <div *ngIf="piezasAgregadas.length == 0" class="row p-5">
            <div class="d-flex justify-content-center">
                <h3>
                    <span>
                        Data not found.</span>
                </h3>
                <img src="../../../assets/img/phvp.png" width="200" height="200" alt="">
            </div>
        </div>


    </main>

    <footer id="footer_div" class="p-0 mx-3 mb-1">
        <div class="d-flex flex-row justify-content-between align-items-center my-0 py-0 bg-primary rounded rounded-2">

            <div class="d-flex align-items-center pt-2 ms-2" data-bs-toggle="modal" data-bs-target="#RecallModal">

            </div>

            <div class="d-flex flex-row align-items-center p-2 ">
                <div class="d-flex flex-row text-bg-primary mx-1  pt-2 px-3 text-white">
                    <h5> SubTotal: </h5>
                    <h5 class="ms-1" style="text-align:end; font-weight:bold"> {{subTotal | currency}}</h5>
                </div>

                <div class="d-flex flex-row text-bg-primary mx-1 pt-2   px-3 text-white">
                    <h5> Sales Tax: </h5>
                    <h5 id="tax_lb" class="ms-1" style="text-align: end; font-weight: bold">$0</h5>
                </div>

                <div class="d-flex flex-row text-bg-primary mx-1 pt-2 px-3 text-white">
                    <h5> Total: </h5>
                    <h5 class="ms-1" style="text-align: end; font-weight: bold">{{total | currency}} </h5>
                </div>
            </div>

        </div>
    </footer>

</body>