import { Component, OnInit } from '@angular/core';
import { UsuarioService, DatoschangePass} from '../../services/usuario.service';
import { Usuario } from '../../Entities/Usuario';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-editprofile',
  templateUrl: './editprofile.component.html',
  styleUrls: ['./editprofile.component.scss']
})
export class EditprofileComponent implements OnInit {

  urls: any;
  loading = false;

  datoschangePass: DatoschangePass ={

    userId: 0,
    CurrentPassword: '',
    NewPassword: ''
  
  }

  usuario = new Usuario();

  constructor( private _usuarioService: UsuarioService) { }

  ngOnInit(): void {
    this.getUser();
  }

  getUser(){
    this.loading = true;
    this._usuarioService.getInfo().subscribe(
      res =>{
        this.loading = false;
        this.usuario = res;
        console.log(this.usuario);
      }, err =>{
        this.loading = false;
        if (err.status == 401) {
          window.localStorage.removeItem('token');
          window.location.reload();
          
        }
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: err.error,
          showConfirmButton: false,
         timer: 1500
       })
      }
    )}

    changePass(){
      this.loading = true;
      this.datoschangePass.userId = this.usuario.usuId;
      this.datoschangePass.CurrentPassword = String((<HTMLInputElement>document.getElementById(String('Actual'))).value)
      this.datoschangePass.NewPassword = String((<HTMLInputElement>document.getElementById(String('New'))).value)
      this._usuarioService.changePass(this.datoschangePass).subscribe(
        res=>{
          this.loading = false;
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Done',
            showConfirmButton: false,
           timer: 1500
          }).then( ()=>{
            window.location.reload();
           })
        },err =>{
          this.loading = false;
          if (err.status == 401) {
            window.localStorage.removeItem('token');
            window.location.reload();
            
          }
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: err.error,
            showConfirmButton: false,
           timer: 1500
         })
        })
    }

    readUrl(event:any) {
      if (event.target.files && event.target.files[0]) {
       var reader = new FileReader();   
        reader.onload = (event: ProgressEvent) => {
          this.loading = true;
            this.urls = (<FileReader>event.target).result;
            this.loading = true;
            var oldPhoto =  this.usuario.usuFoto
            this.usuario.usuFoto = this.urls;
            this._usuarioService.updateinfo(this.usuario).subscribe(
             res =>{
              console.log(res);
              window.localStorage.removeItem('usuFoto');
              localStorage.setItem('usuFoto',res.usuFoto);
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Done',
                showConfirmButton: false,
               timer: 1500
             })
             this.loading = false;
             },err=>{
              this.usuario.usuFoto = oldPhoto;
              this.loading = false;
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: err.error,
                showConfirmButton: false,
               timer: 1500
             })
            })
        } 
        reader.readAsDataURL(event.target.files[0]);
      }
    }

    chooseFile() {
      document.getElementById("fileInput").click();
    }

    updateUser(){
      this.loading = true;
      var oldpfoto = this.usuario.usuFoto;
      this.usuario.usuSesion=(<HTMLInputElement>document.getElementById(String('usuSesion'))).value;
      this.usuario.usuNombres=(<HTMLInputElement>document.getElementById(String('usuNombres'))).value;
      this.usuario.usuApellidos=(<HTMLInputElement>document.getElementById(String('usuApellidos'))).value;
      //this.usuario.PaiIDBill=Number((<HTMLInputElement>document.getElementById(String(item.proID))).value);
      this.usuario.usuCiudadBill=(<HTMLInputElement>document.getElementById(String('Citybill'))).value;
      this.usuario.usuDireccion2Bill=(<HTMLInputElement>document.getElementById(String('Streetbill2'))).value;
      this.usuario.usuDireccionBill=(<HTMLInputElement>document.getElementById(String('Streetbill'))).value;
   //this.DatosRegistro.UsuDireccion2Bill=(<HTMLInputElement>document.getElementById(String(item.proID))).value;
      this.usuario.usuZipCodeBill=(<HTMLInputElement>document.getElementById(String('Zipbill'))).value;
      this.usuario.usuTelefonoBill=(<HTMLInputElement>document.getElementById(String('Phonebill'))).value;
    //this.DatosRegistro.PaiIDShip=Number((<HTMLInputElement>document.getElementById(String('usuTelefonoBill'))).value);
      this.usuario.usuCiudadShip=(<HTMLInputElement>document.getElementById(String('Cityshipp'))).value;
      this.usuario.usuDireccionShip=(<HTMLInputElement>document.getElementById(String('Streetshipp'))).value;
      this.usuario.usuDireccion2Ship=(<HTMLInputElement>document.getElementById(String('Streetshipp2'))).value;
      this.usuario.usuZipCodeShip=(<HTMLInputElement>document.getElementById(String('Zipshipp'))).value;
      this.usuario.ususTelefonoShip=(<HTMLInputElement>document.getElementById(String('Phoneshipp'))).value;
      this.usuario.usuEmail=(<HTMLInputElement>document.getElementById(String('usuEmail'))).value;
      this.usuario.usuFoto= '';

        this.loading = true;

        this._usuarioService.updateinfo(this.usuario).subscribe(
          res =>{
            this.loading = false;
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Done',
              showConfirmButton: false,
             timer: 1500
           }).then( ()=>{
            window.location.reload();
           })
          },err=>{
            this.usuario.usuFoto= oldpfoto;
            this.loading = false;
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: err.error,
              showConfirmButton: false,
             timer: 1500
           })
        })
     }
}
