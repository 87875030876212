import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AdminService } from '../../../services/admin.service';
import { Usuario } from '../../../Entities/Usuario';

import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-users-editar',
  templateUrl: './users-editar.component.html',
  styleUrls: ['./users-editar.component.scss']
})
export class UsersEditarComponent implements OnInit {
  loading = false;
  currentRol:any[] =[];
  roles: any[] =[];
  
  estListo = false;

  myForm: FormGroup;

  CompaniesUser = new Usuario();

  constructor(private _fb: FormBuilder, 
    private _adminService: AdminService, 
    private _router: Router,
    private _activeRoute: ActivatedRoute
    ) { }

  ngOnInit(): void {
    this.myForm = this._fb.group({
      role :  this.currentRol,
  });  
    this._activeRoute.params.subscribe(params => {
      this.getCurrentCompanyUser(params.id);

    });

  }

  getRoles() {
    this.loading = true;
     this._adminService.getRoles().subscribe(
       (res) => {
        this.loading = false;
        console.log(res)
         this.roles = res;
         this.currentRol = [res.filter((x)=>x.rolId==this.CompaniesUser.rolId)[0]];
       },
       (err) => {
        this.loading = false;
         console.error(err.error);
         if (err.error.includes('You do not have permissions to access to ')) {
          Swal.fire({
            icon: 'error',
            title: err.error,
            showConfirmButton: true
          }).then(() => {
            this._router.navigateByUrl('/Dashboard/Usuarios');
          })
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: err.error,
            showConfirmButton: false,
            timer: 1500,
          });
        }
       }
     );
   }

   getCurrentCompanyUser(iduser) {
    this.loading = true;
     this._adminService.getCompaniUsersID(iduser).subscribe(
       (res) => {
        this.loading = false;
        console.log(res)
         this.CompaniesUser = res;
         this.getRoles()
       },
       (err) => {
        this.loading = false;
         console.error(err.error);
         if (err.error.includes('You do not have permissions to access to ')) {
          Swal.fire({
            icon: 'error',
            title: err.error,
            showConfirmButton: true
          }).then(() => {
            this._router.navigateByUrl('/Dashboard/Usuarios');
          })
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: err.error,
            showConfirmButton: false,
            timer: 1500,
          });
        }
       }
     );
   }

  

  Submit() {
    try {
      this.loading = true;

      if (this.currentRol.length == 0) {
        throw new TypeError("Role is necesary");
      }

      if ((<HTMLOptionElement>document.getElementById('UsuNombres')).value == "")
        throw new TypeError(" First Name is necesary");

      if ((<HTMLOptionElement>document.getElementById('UsuApellidos')).value == "")
        throw new TypeError("Last Name is necesary");    
        
      if ((<HTMLOptionElement>document.getElementById('UsuCiudadBill')).value == "")
      throw new TypeError(" City necesary");

    if ((<HTMLOptionElement>document.getElementById('UsuStateBill')).value == "")
      throw new TypeError("State is necesary");
      
      if ((<HTMLOptionElement>document.getElementById('UsuZipCodeBill')).value == "")
        throw new TypeError(" Zip Code is necesary");

      if ((<HTMLOptionElement>document.getElementById('UsuTelefonoBill')).value == "")
        throw new TypeError("Phone number is necesary");
      

    if ((<HTMLOptionElement>document.getElementById('UsuDireccionBill')).value == "")
      throw new TypeError("Address is necesary");
      
    
        this.CompaniesUser.rolId = this.currentRol[0].rolId;

      this.CompaniesUser.usuNombres = ((<HTMLOptionElement>document.getElementById('UsuNombres')).value);
      this.CompaniesUser.usuApellidos = ((<HTMLOptionElement>document.getElementById('UsuApellidos')).value);
      this.CompaniesUser.usuCiudadBill = ((<HTMLOptionElement>document.getElementById('UsuCiudadBill')).value);
      this.CompaniesUser.usuStateBill = ((<HTMLOptionElement>document.getElementById('UsuStateBill')).value);
      this.CompaniesUser.usuZipCodeBill = ((<HTMLOptionElement>document.getElementById('UsuZipCodeBill')).value);
      this.CompaniesUser.usuTelefonoBill = ((<HTMLOptionElement>document.getElementById('UsuTelefonoBill')).value);
      //this.CompaniesUser.usuEmail = ((<HTMLOptionElement>document.getElementById('UsuEmail')).value);
      this.CompaniesUser.usuDireccionBill = ((<HTMLOptionElement>document.getElementById('UsuDireccionBill')).value);
      this.CompaniesUser.usuDireccion2Bill = ((<HTMLOptionElement>document.getElementById('UsuDireccion2Bill')).value);
      this._adminService.editCompaniUsers(this.CompaniesUser).subscribe(
        res => {
          this.loading = false;
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Done',
            showConfirmButton: false,
            timer: 1500
          })
          this._router.navigateByUrl('/Dashboard/Usuarios');
        }, err => {
          this.loading = false;
          console.log(err);
          if (err.error.includes('You do not have permissions to access to ')) {
            Swal.fire({
              icon: 'error',
              title: err.error,
              showConfirmButton: true
            }).then(() => {
              this._router.navigateByUrl('/Dashboard/Usuarios');
            })
          } else {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: err.error,
              showConfirmButton: false,
              timer: 1500,
            });
          }

        })

    } catch (error) {
      this.loading = false;
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: error,
        showConfirmButton: true,
      })
      console.log(error);
    }
  }

}
