import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductosService, datosBuscar } from '../../services/productos.service';

@Component({
  selector: 'app-buscar',
  templateUrl: './buscar.component.html',
  styleUrls: ['./buscar.component.scss']
})
export class BuscarComponent implements OnInit {

  resultados: any;
  result=false
  loading = false;

  constructor( private auth: ProductosService, private router: Router ) { }

  ngOnInit(): void {

  }
   
  focusOut(){
    this.router.navigateByUrl('/resultado');
  }

}
