import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AdminService, Permisos } from '../../../services/admin.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-permisos-crear',
  templateUrl: './permisos-crear.component.html',
  styleUrls: ['./permisos-crear.component.scss'],
})
export class PermisosCrearComponent implements OnInit {
  
  loading = false;
  myForm: FormGroup;

  buscarPermisos: Permisos = {
    permisoString: '',
    rolId:  0,
    perID: 0,
  };
  constructor(
    private _fb: FormBuilder,
    private _adminService: AdminService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.myForm = this._fb.group({});
   
  }

  Submit() {
    try {
      this.loading = true;

      if (
        (<HTMLOptionElement>document.getElementById('PDescription')).value == ''
      ) {
        throw new Error('Description is necesary');
      }

      this.buscarPermisos.permisoString = (<HTMLOptionElement>(
        document.getElementById('PDescription')
      )).value;

      this._adminService.postPermisos(this.buscarPermisos).subscribe(
        (res) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Done',
            showConfirmButton: true,
          }).then((th) => {
            this._router.navigateByUrl('/Dashboard/permit');
          });
        },
        (err) => {
          this.loading = false;
          if(err.error.includes('You do not have permissions to access to ')){
            Swal.fire({
              icon: 'error',
              title:err.error,
              showConfirmButton: true
            }).then(()=>{
              this._router.navigateByUrl('/Dashboard');
            })
          }else{
              Swal.fire({
              position: 'center',
              icon: 'error',
              title: err.error,
              showConfirmButton: false,
              timer: 1500,
            });          
          }

        }
      );
    } catch (error) {
      this.loading = false;
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: error,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }
}
