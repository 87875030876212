export class TerminosDePagos {
  terID: number;
  terReferencia: string;
  terDescripcion: string;
  terMontoMaximo?: number;
  terOrden?: number;
  terEstatus?: number;
  campo1: string;
  campo2: string;
  campo3?: number;
  campo4?: number;
  usuInicioSesion: string;
  terFechaUltimaActualizacion?: Date;
  // rowguid?: string;

  constructor() {
      this.terID = 0;
      this.terReferencia = '';
      this.terDescripcion = '';
      this.terMontoMaximo = 0;
      this.terOrden = 0;
      this.terEstatus = 0;
      this.campo1 = '';
      this.campo2 = '';
      this.campo3 = 0;
      this.campo4 = 0;
      this.usuInicioSesion = '';
      //this.terFechaUltimaActualizacion = terFechaUltimaActualizacion;
      // this.rowguid = rowguid;
  }
}
