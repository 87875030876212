<footer class="section-footer border-top">
          <div class="container-fluid">
              <section class="footer-top padding-y">
                  <div class="row">
                      <aside class="col-md-4">
                         
                      </aside>
                <aside class="col-sm-3 col-md-2">
                          <h5 class="title text-primary"></h5>
                          <!--  <ul class="list-unstyled">
                              <li> <a href="#" data-abc="true">About us</a></li>
                              <li> <a href="#" data-abc="true">Services</a></li>
                              <li> <a href="#" data-abc="true">Terms & Condition</a></li>
                          </ul>-->  
                          <article class="ml-5 text-center"> <img src="/assets/img/LogovehiclesParts.jpg" class="logo-footer">
                            <p class="mt-3 description">
                              Company dedicated to the commercialization of pieces of vehicles in general.</p>
                        <!--  <div> <a class="btn btn-icon btn-light" title="Facebook" target="_blank" href="#" data-abc="true">  <i class="fa fa-facebook-f"></i></a>   <a class="btn btn-icon btn-light" title="Instagram" target="_blank" href="#" data-abc="true"><i class="fa fa-instagram"></i></a> <a class="btn btn-icon btn-light" title="Youtube" target="_blank" href="#" data-abc="true"><i class="fab fa-youtube"></i></a> <a class="btn btn-icon btn-light" title="Twitter" target="_blank" href="#" data-abc="true"><i class="fa fa-twitter"></i></a> </div>--> 
                        </article>
                      </aside>
                      <aside class="col-sm-3 col-md-2">
                          <h5 class="title" class="title text-primary"></h5>
                         <!-- <ul class="list-unstyled">
                              <li> <a href="#" data-abc="true">Help center</a></li>
                              <li> <a href="#" data-abc="true">User Admin</a></li>
                              <li> <a href="#" data-abc="true">Terms and Policy</a></li>
                              <li> <a href="#" data-abc="true">Open dispute</a></li>
                           </ul>-->  
                          <!-- <h5 class="title" class="title text-primary mb-3">Our app</h5>--> <a href="https://vehiclepart.shop/infoApp/" class="d-block mb-2" data-abc="true"><img class="img-responsive" src="/assets/img/buttonstore.png" height="140" width="210"></a> 
                      </aside>
                      <aside class="col-sm-3 col-md-2">
                          <h5 class="title" class="title text-primary"></h5>
                        <!--  <ul class="list-unstyled">
                              <li> <a href="#" data-abc="true"> User Login </a></li>
                              <li> <a href="#" data-abc="true"> User register </a></li>
                      </ul>-->  
                      </aside> 
                      <aside class="col-sm-2 col-md-2">
                         
                        </aside>
                  </div>
              </section>
              <section class="footer-copyright">
                  <p class="float-left text-center">VEHICLE PARTS LLC © 2024</p>
              </section>
          </div>
      </footer>

  