import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MustMatch } from '../../helpers/pass-match.validator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsuarioService, DatoschangePass } from '../../services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cambiarpass',
  templateUrl: './cambiarpass.component.html',
  styleUrls: ['./cambiarpass.component.scss']
})
export class CambiarpassComponent implements OnInit {
  
  CambiarPassform: FormGroup;
  loading = false;
  submitted = false;
  DatoschangePass: DatoschangePass ={

    userId: 0,
    CurrentPassword: '',
    NewPassword: ''
  
  }

  constructor( private auth: UsuarioService, private router: Router, private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.CambiarPassform = this.formBuilder.group({
      CurrentPassword:['', Validators.required ],
      NewPassword: ['', [Validators.required, Validators.minLength(6)]],
      confpass: ['', Validators.required]
    },
    {
      validator: MustMatch('NewPassword', 'confpass')
    })
  }

  get f() { return this.CambiarPassform.controls }
   
  CambiarPass() {
    this.submitted = true;
    if (this.CambiarPassform.invalid) {
      return;
  }else{
    this.loading = true;
    this.auth.RecuperarPass(this.DatoschangePass).subscribe( res => { 
      this.loading = false;
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: "Mail sent successfully.",
        showConfirmButton: false,
        timer: 4000
      })
      this.router.navigateByUrl('/login');
    }, (err) => {
      if(err.status == 200){
        this.loading = false;
         Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'password changed',
          showConfirmButton: false,
          timer: 2500
        })       
        this.router.navigateByUrl('/login');
      }else{
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Something is wrong',
          showConfirmButton: false,
          timer: 2500
        })       
      }
    }); 
 }
 }
}
