<app-navbar></app-navbar>
<div class="px-2 py-5">
  <div class="messaging">
    <div class="inbox_msg">
      <div class="mesgs" >
        <div class="card border-1 p-0 m-0">
              <div class="card-body">
                <div class="d-flex align-items-center flex-row bd-highlight">
                  <div class="bd-highlight">
                    <img src="{{pieImg}}"  class="fit-image rounded-circle border border-primary" alt="..."></div>
                  <div class=" bd-highlight p-0 ms-2">
                    <h4 class="card-title ">{{pieNombre}}</h4>
                    <a [routerLink]="['/producto', pieId]" type="button" class="btn btn-outline-primary btn-lg m-2">Go to Post</a>
                    <a type="button" class="btn btn-outline-primary btn-lg" (click)="videoCall()"><i class="fa fa-video-camera"></i></a>
                  </div>
                </div>
          </div>
        </div>
        <div class="msg_history" id="chatbox">
          <div class="d-flex justify-content-center" *ngIf="loadingchat; else elseBlock">
            <div class="spinner-border text-primary  m-5" role="status" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
              </div>  
        </div>
        <ng-template #elseBlock>
          
          <div id="boxchat" *ngFor="let mensaje of mensajes" [ngClass]="mensaje.usuToId == usuId || mensaje.usuToId == EmpId  ? 'incoming_msg' : 'outgoing_msg' " >
            <div class="incoming_msg_img" [style.visibility]="mensaje.usuToId == usuId || mensaje.usuToId == EmpId ? 'visible' : 'hidden'"> 
              <img class="fit-imagechat" src="{{mensaje.usuFoto}}" alt="sunil"> 
            </div>
            <div class="received_msg">
              
              <div [ngClass]="mensaje.usuToId == usuId || mensaje.usuToId == EmpId ? 'received_withd_msg' : 'sent_msg'">
                <span class="time_date">{{mensaje.usuNombre}}</span>
                <p [ngStyle]="{'background': mensaje.usuToId == usuId || mensaje.usuToId == EmpId ? '#646e66' : '#008c44' }" class="text-white">
                  {{mensaje.menMensaje}}
                 </p>
                 <span class="time_date">{{mensaje.menFecha | date: 'shortTime'}} | {{mensaje.menFecha | date: 'fullDate'}} </span>
              </div>
            </div>
          </div>
        </ng-template>
          </div>
        <div class="type_msg">
          <div class="input_msg_write">
            <!--<input type="text" class="write_msg" placeholder="Type a message" />-->
            <input type="tex" class="form-control write_msg border border-primary" (keydown.enter)="sendMesanje()" id="message" placeholder="Type a message">
            <button class="msg_send_btn" type="button" (click)="sendMesanje()"> <i [ngClass]="loadingsed ?  'spinner-border spinner-border' : 'fa fa-paper-plane-o' " aria-hidden="true"></i></button>
          </div>
        </div>
      </div>
</div>
   