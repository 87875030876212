import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductosService, Default } from '../../../services/productos.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-defaults-crear',
  templateUrl: './defaults-crear.component.html',
  styleUrls: ['./defaults-crear.component.scss']
})



export class DefaultsCrearComponent implements OnInit {

  currentCategory: any[] = [];
  catListo = true;
  categorias: any[] = [];

  loading = false;
  noData = false;


  myForm: FormGroup;
  constructor(private service: ProductosService, private _fb: FormBuilder, private _router: Router) { }

  ngOnInit(): void {
    this.myForm = this._fb.group({

      catf: this.currentCategory,
    });
    this.getCategoria();
  }

  MyDefault: Default = {
    piePreNombre: '',
    piePreCatId: 0


  }
  getCategoria() {
    this.service.getCategoria().subscribe(
      res => {
        this.catListo = false;
        this.categorias = res;
        console.log(res)
      }, err => {
        console.log(err.error);
      })
  }



  UnSelectObject(item: any) {

    if (this.currentCategory.length == 0) {
      this.noData = true;
    }

  }
  Submit() {
    var PartCategory = (<HTMLOptionElement>document.getElementById('Partname_default')).value;
    if (this.currentCategory.length == 0) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Select a category',
        showConfirmButton: false,
        timer: 1500
      })

    }
    this.MyDefault.piePreCatId = this.currentCategory[0].catID;
    this.MyDefault.piePreNombre = PartCategory;
    this.service.postDefaults(this.MyDefault).subscribe(
      res => {
        console.log(res);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Done',
          showConfirmButton: false,
          timer: 2000
        })
        this._router.navigateByUrl('/Dashboard/Defaults');
      }, err => {
        if (err.error.includes('You do not have permissions to access to ')) {
          Swal.fire({
            icon: 'error',
            title: err.error,
            showConfirmButton: true
          }).then(() => {
            this._router.navigateByUrl('/Dashboard');
          })
        } else {
          var miError = err.error.message == null ? err.error : err.error.message;
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: miError,
            showConfirmButton: false,
            timer: 1500
          })
          console.error(err);
        }

      }

    );

  }
}
