<div id="content">
    <div class="d-flex align-items-center">
      <nav
        style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
        aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/Dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">Users</li>
        </ol>
      </nav>
    </div>
  </div>
  <hr>
  <div class="row px-3">
    <div class="d-flex flex-row  justify-content-between bd-highlight mb-0">
      <div class="px-2 bd-highlight d-flex flex-row">
        <h2 class="text-primary">Users</h2>
        <button type="button" class="mx-2 btn btn-outline-primary btn-lg"
        [routerLink]="['/Dashboard/UsuariosCrear']">add <i class="fa fa-plus"></i></button>    
      </div>
  
      <div class="px-2 py-0 m-0 bd-highlight">
        <h3 class="text-primary">Total users: {{usuarios.length}}</h3>
      </div>
  
  
    </div>
  </div>
  <div class="row">
    <form [formGroup]="myForm">
      <div class="m-2 mt-4 row">
  
        <div class="col-6 col-sm-5 col-md-3 col-lg-3">
          <h6 class="text-primary">Name</h6>
          <input type="text" class="form-control" id="username" placeholder="User Name">
        </div>

        <div class="col-6 col-sm-5 col-md-3 col-lg-3">
          <h6 class="text-primary">Status</h6>
  
            <ng-multiselect-dropdown 
            name="statusS"
            [disabled]="estListo"
            formControlName="statusS"
            [(ngModel)]="this.currentStatus"
            [placeholder]="'Select status'"
            [settings]="{      
              closeDropDownOnSelection: true,
              singleSelection: true,
              idField: 'estEstado',
              textField: 'estDescripcion',
              itemsShowLimit: 1,
              allowSearchFilter: true}"
            [data]="Status"
           
          ></ng-multiselect-dropdown>  
       
    
        </div>

        <div class="col-6 col-sm-5 col-md-3 col-lg-3">
            <h6 class="text-primary">Role</h6>
    
              <ng-multiselect-dropdown 
              name="rolS"
              [disabled]="estListo"
              formControlName="rolS"
              [(ngModel)]="this.currentRol"
              [placeholder]="'Select status'"
              [settings]="{      
                closeDropDownOnSelection: true,
                singleSelection: true,
                idField: 'rolId',
                textField: 'rolDescripcion',
                itemsShowLimit: 1,
                allowSearchFilter: true}"
              [data]="roles"            
            ></ng-multiselect-dropdown>  
         
      
          </div>
    
        <div class="col-2 col-sm-2 col-md-2 col-lg-2 nav-item">
          <h6 class="text-white">search</h6>
          <button class="btn btn-primary text-white" (click)="buscarUsuarios()" >
            Search </button>
        </div>
      </div>
  
      <div class="d-flex justify-content-center" *ngIf="loading;">
        <div class="spinner-border text-primary  m-3" role="status" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div *ngIf="usuarios.length == 0" class="p-2 px-0 d-flex justify-content-center">
        <div class="d-flex flex-column justify-content-center">
            <h3 class="d-flex justify-content-center">Data not found.</h3>
            <img class="d-flex justify-content-center" src="../../../assets/img/phvp.png" width="200" height="200" alt="">
          </div>
      </div>

      <div class="card mb-3" *ngFor="let user of usuarios let i = index">
        <div class="row g-0">
          <div class="col-4 col-sm-5 col-md-3 col-lg-3 d-flex align-items-center">
            <img src="{{user.usuFoto}}" class="card-img-top ml-5 mt-2 mb-2 fit-image img-fluid rounded-start" alt="..." >
          </div>
          <div class="col-8 col-sm-8 col-md-9 col-lg-9 d-flex align-items-center">
            <div class="card-body">
              <a> 
                <p class="Nombre-text">#{{user.usuId}}, {{user.usuNombres}} {{user.usuApellidos}}</p> 
              </a>
              <hr class="my-0">

              <div class="row mt-0">
  
                <div class="col-6 col-sm-6 col-md-6 col-lg-6 d-flex align-items-center my-0 py-0">
                  <div class="card-body my-auto py-0">
                    <p class="secom-text my-1"><small class="text-muted">UserName: {{user.usuSesion}}</small></p>
                    <p class="secom-text my-1"><small class="text-muted">Phone Number: {{user.usuTelefonoBill}}</small></p>
                    <p class="secom-text my-1"><small class="text-muted">Email: {{user.usuEmail}}</small></p>                       
                  </div>        
                </div>
  
                <div class="col-6 col-sm-6 col-md-6 col-lg-6 d-flex align-items-center my-0">
                  <div class="card-body my-auto">
                    <p class="secom-text mb-1 mt-3 "><small class="text-muted">Role: {{user.rolString}}</small></p>
                    <p class="secom-text my-1"><small class="text-muted">Status: {{user.usuEstatusString}}</small></p>
                    <p class="secom-text my-1"><small class="text-muted">Type: {{user.usuTipoString}}</small></p>
                  </div>
                </div>
  
              </div>
  
              <div class="d-grid gap-2 d-md-flex justify-content-md-star my-0">
                <button class="btn btn-success btn-lg my-0" [routerLink]="['/Dashboard/UsuariosEditar',user.usuId]"> <i class="fa fa-edit"></i></button>
                <button *ngIf="user.usuEstatus == 1" class="btn btn-danger btn-lg my-0" (click)="SuspenderUser(user)"> <i class="fa fa-trash"></i></button>
                <button *ngIf="user.usuEstatus == 3 || user.usuEstatus == 2"  (click)="ReActiveUser(user)"class="btn btn-primary btn-lg my-0" > <i class="text-white fa fa-refresh"></i></button>
              </div>
  
            </div>
  
          </div>
       
        </div>