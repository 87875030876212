import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate,Router ,RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuardGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
  
  window.location.href = route.data['https://vehiclepart.shop/infoApp'];
  return true;
  
  }
}
