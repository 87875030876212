<div id="content">
    <div class="d-flex align-items-center">
      <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a  routerLink="/Dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">Role</li>
        </ol>
      </nav>
    </div>
  </div>
  <hr>
  <div class="row p-3">
    <div class="d-flex flex-row bd-highlight">
      <div class="p-2 bd-highlight">
        <h2 class="text-primary">Role</h2>
      </div>
      <div class="p-2 bd-highlight"> <button type="button" class="btn btn-outline-primary btn-lg"
          [routerLink]="['/Dashboard/rolesCrear']">add <i class="fa fa-plus"></i></button></div>
    </div>
  </div>
  
  <div class="row flex-nowrap pb-">
    <div class="row">
      <div class="d-flex justify-content-center" *ngIf="loading; ">
        <div class="spinner-border text-primary  m-3" role="status" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div *ngIf="roles.length == 0" class="p-2 px-0 d-flex justify-content-center">
        <div class="d-flex flex-column justify-content-center">
          <h3 class="d-flex justify-content-center">Data not found.</h3>
          <img class="d-flex justify-content-center" src="../../../assets/img/phvp.png" width="200" height="200" alt="">
        </div>
      </div>

      <div *ngIf="!loading; ">
        <div class="card mb-2" *ngFor="let rol of roles">
          <div class="row g-0">
       
            <div class="col-8 col-sm-8 col-md-8 col-md-8 d-flex align-items-center">
              <div class="card-body">
                <a> 
                  <p class="Nombre-text"> {{rol.rolDescripcion}} </p>
               
                </a>

                <hr>
  
                <div class="d-grid gap-2 d-md-flex justify-content-md-star">
                  <button class="btn btn-danger btn-md"  (click)="borrarRol(rol)"> <i  class="fa fa-trash"></i></button>
                  <button class="btn btn-success btn-md my-0" [routerLink]="['/Dashboard/rolesEditar/',rol.rolId]"> <i class="fa fa-edit"></i></button>
                </div>
  
              </div>
  
            </div>
  
            
          </div>
        </div>
  
      </div>
    </div>
  </div>
