import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductosService } from '../../../services/productos.service'
import { AdminService } from '../../../services/admin.service'
import { Usuario } from '../../../Entities/Usuario';

@Component({
  selector: 'app-users-crear',
  templateUrl: './users-crear.component.html',
  styleUrls: ['./users-crear.component.scss']
})
export class UsersCrearComponent implements OnInit {

  currentRol: any[] = [];
  roles: any[] = [];

  estListo = false;
  loading = false;
  myForm: FormGroup;

  CompaniesUser = new Usuario();

  constructor(private _fb: FormBuilder, private service: ProductosService, private _adminService: AdminService, private _router: Router) { }

  ngOnInit(): void {
    this.myForm = this._fb.group({
      rolS: this.currentRol,
    });
    this.getRoles();
  }

  getRoles() {
    this.loading = true;
    this._adminService.getRoles().subscribe(
      (res) => {
        this.loading = false;
        this.roles = res;
      },
      (err) => {
        this.loading = false;
        console.error(err.error);
        if (err.error.includes('You do not have permissions to access to ')) {
          Swal.fire({
            icon: 'error',
            title: err.error,
            showConfirmButton: true
          }).then(() => {
            this._router.navigateByUrl('/Dashboard/Usuarios');
          })
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: err.error,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    );
  }

  Submit() {
    try {
      this.loading = true;

      if (this.currentRol.length == 0) {
        throw new TypeError("Role is necesary");
      }

      if ((<HTMLOptionElement>document.getElementById('UsuNombres')).value == "")
        throw new TypeError(" First Name is necesary");

      if ((<HTMLOptionElement>document.getElementById('UsuApellidos')).value == "")
        throw new TypeError("Last Name name is necesary");


      if ((<HTMLOptionElement>document.getElementById('UsuCiudadBill')).value == "")
        throw new TypeError(" City necesary");

      if ((<HTMLOptionElement>document.getElementById('UsuStateBill')).value == "")
        throw new TypeError("State is necesary");

      if ((<HTMLOptionElement>document.getElementById('UsuZipCodeBill')).value == "")
        throw new TypeError(" Zip Code is necesary");

      if ((<HTMLOptionElement>document.getElementById('UsuTelefonoBill')).value == "")
        throw new TypeError("Phone number is necesary");

      if ((<HTMLOptionElement>document.getElementById('UsuEmail')).value == "")
        throw new TypeError(" Email is necesary");

      if ((<HTMLOptionElement>document.getElementById('UsuDireccion2Bill')).value == "")
        throw new TypeError("Address is necesary");


      this.CompaniesUser.rolId = this.currentRol[0].rolId;

      this.CompaniesUser.usuNombres = ((<HTMLOptionElement>document.getElementById('UsuNombres')).value);
      this.CompaniesUser.usuApellidos = ((<HTMLOptionElement>document.getElementById('UsuApellidos')).value);
      this.CompaniesUser.usuCiudadBill = ((<HTMLOptionElement>document.getElementById('UsuCiudadBill')).value);
      this.CompaniesUser.usuStateBill = ((<HTMLOptionElement>document.getElementById('UsuStateBill')).value);
      this.CompaniesUser.usuZipCodeBill = ((<HTMLOptionElement>document.getElementById('UsuZipCodeBill')).value);
      this.CompaniesUser.usuTelefonoBill = ((<HTMLOptionElement>document.getElementById('UsuTelefonoBill')).value);
      this.CompaniesUser.usuEmail = ((<HTMLOptionElement>document.getElementById('UsuEmail')).value);
      this.CompaniesUser.usuDireccion2Bill = ((<HTMLOptionElement>document.getElementById('UsuDireccion2Bill')).value);

      this._adminService.postCompaniUsers(this.CompaniesUser).subscribe(
        res => {
          this.loading = false;
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Done',
            showConfirmButton: false,
            timer: 1500
          })
          this._router.navigateByUrl('/Dashboard/Usuarios');
        }, err => {
          this.loading = false;
          console.log(err);
          if (err.error.includes('You do not have permissions to access to ')) {
            Swal.fire({
              icon: 'error',
              title: err.error,
              showConfirmButton: true
            }).then(() => {
              this._router.navigateByUrl('/Dashboard');
            })
          } else {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: err.error,
              showConfirmButton: false,
              timer: 1500,
            });
          }

        })

    } catch (error) {
      this.loading = false;
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: error,
        showConfirmButton: true,
      })
      console.log(error);
    }
  }

}
