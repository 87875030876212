import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { AdminService } from '../../../services/admin.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

@Component({
  selector: 'app-ebay',
  templateUrl: './ebay.component.html',
  styleUrls: ['./ebay.component.scss']
})
export class EbayComponent implements OnInit {

  private connection: HubConnection;
  constructor(private _adminService: AdminService, private route: ActivatedRoute, private _router: Router) { 

  }

  connectionId: string;
  loading = false;
  isConfig = false;
  importing = false;
  totalToImport = 0;
  info = "About 0 batches added from 0"
  importText = "Continue"
  userEbay = ""

   interval :any

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.loading = true;
      this.importing = false;
      if (params.username != null) {

        var sesionObj = {
          sesionID: localStorage.getItem("sesionID"),
          expiration: new Date(params.tknexp),
          userName: params.username
        }

        this._adminService.postSesionID(sesionObj).subscribe(
          res => {
            //console.log(res);
            localStorage.setItem("sesionID", "");
            //this._router.navigateByUrl('/Dashboard/Ebay'); 
            this.loading = false;
            window.location.reload()
          }, err => {
            localStorage.setItem("sesionID", "");
            console.error(err);
            this.loading = false;
            this.getEmpresa();
          });
      }
      else {
        this.loading = false;
        this.getEmpresa();
      }

    }
    );
  }

  getEmpresa() {
    this.loading = true;
    this._adminService.getEmpresa().subscribe(
      res => {
        //console.log(res);
        if (!res.eBaytoken || !res.eBayUser || !res.eBaytokenExpiracion) {
          this.isConfig = false;
          this.loading = false;
        }
        else {
          this.isConfig = true;
          this.userEbay = res.eBayUser;


          if(res.campo2 == "Procesando"){

            this.importing = true;
            this.interval = setInterval(() => {
              this.CheckImport()
            }, 20000);

          }
          else{
            this.info = `About ${res.campo3} batches added from 0`;

            this._adminService.getTotalToExport(false).subscribe(
              resT => {
                //console.log(resT);
                this.loading = false;
                this.totalToImport = resT.totalNumberEbay;
                this.info = `${resT.totalNumberImported} car parts added out of ${resT.totalNumberEbay}`;

                if (res.campo3 > 0) {
                  this.importText = "Continue imports"
                }
              }, errT => {
                console.error(errT);
                this.loading = false;

                if (errT.error == 'You do not have permissions to access to Companies') {
                  Swal.fire({
                    icon: 'error',
                    title: errT.error,
                    showConfirmButton: true
                  }).then(() => {
                    this._router.navigateByUrl('/Dashboard');
                  })
                }
                else {
                  this.info = errT.error;
                  Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: errT.error,
                    showConfirmButton: true
                  })
                }


              }
            );           
          }



        }

        //this.loading = false;
      }, err => {
        this.loading = false;
        console.error(err);
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: err.error.error.message,
          showConfirmButton: false,
          timer: 1500
        })

        this.loading = false;
      })
    //this.loading = false;
  }


  CheckImport() {
    if (this.importing == true) {
      this._adminService.getTotalToExport(true).subscribe(
        resT => {
          console.log(resT)
          // this.loading = false;
          if(resT.currentStatus == "Procesando"){
            this.importing = true;
          }
          else if (resT.currentStatus == "Completo"){
            Swal.fire({
              icon: 'success',
              title: "The parts import process has already been completed.",
              showConfirmButton: true
            }).then(() => {
              this.loading = false;
              this.importing = false;
            })
          }
          else{
            this.loading = false;
            this.importing = false;
          }
          this.info = `${resT.totalNumberImported} car parts added out of ${this.totalToImport}`;
        }, errT => {
          this.loading = false;
          console.error(errT);
          if (errT.error == 'You do not have permissions to access to eBay') {
            Swal.fire({
              icon: 'error',
              title: errT.error,
              showConfirmButton: true
            }).then(() => {
              this._router.navigateByUrl('/Dashboard');
            })
          }
          //this.loading = false;
        }
      );
    }

  }

  LogOut() {
    try {
      this.loading = true;
      this._adminService.PostLogOutEbay().subscribe(
        res => {
          console.log(res);
          localStorage.setItem("sesionID", null);
          window.location.reload();
          this.loading = true;
        }, err => {
          console.error(err);
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: err.error,
            showConfirmButton: true,

          })
          this.loading = false;
        })
    } catch (error) {
      console.log(error);
      if (error.error == 'You do not have permissions to access to eBay') {
        Swal.fire({
          icon: 'error',
          title: error.error,
          showConfirmButton: true
        }).then(() => {
          this._router.navigateByUrl('/Dashboard');
        })
      }
      else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: error,
          showConfirmButton: true,

        })
        this.loading = false;
      }

    }



  }

  getLogin() {
    this.loading = true;
    try {
      this._adminService.getLoginEbay().subscribe(
        res => {
          console.log(res);
          localStorage.setItem("sesionID", res.sesionID);

          window.location.href = res.url;
          this.loading = true;
        }, err => {
          console.error(err);

          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: err.error,
            showConfirmButton: false,
            timer: 1500

          })

          this.loading = false;
        })

    } catch (erro) {

      console.log(erro)
      if (erro.error == 'You do not have permissions to access to eBay') {
        Swal.fire({
          icon: 'error',
          title: erro.error,
          showConfirmButton: true
        }).then(() => {
          this._router.navigateByUrl('/Dashboard');
        })
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: erro,
          showConfirmButton: false,
          timer: 1500

        })
      }

    }


  }

  cancelarBtn() {

    Swal.fire({
      title: "Importing parts",
      text: "Are you sure you want to stop the import of parts?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Stop it!"
    }).then((result) => {
      if (result.isConfirmed) {
    
        this._adminService.PostCancelarJob()
        .subscribe(
          res => {
            console.log(res)
            clearInterval(this.interval)
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: "Proccess was stoped",
              showConfirmButton: true,
            }).then((result) => {
              this.loading = false;
              this.importing = false;
              window.location.reload()
            })
          }, err => {
            console.error(err);
            this.importing = false;
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: err.error,
                showConfirmButton: true,
              }).then((result) => {
                this.loading = false;
                this.importing = false;
                window.location.reload()
              })
            //}
  
          })
      }
    });
  }

  StarImport() {
    this.loading = true;
    this._adminService.postImporParts()
      .subscribe(
        res => {
          console.log(res)
          this.importing = true;
          this.interval = setInterval(() => {
            this.CheckImport()
          }, 20000);
        }, err => {
          console.error(err);
          this.importing = false;
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: err.error,
              showConfirmButton: true,
            }).then((result) => {

              this.importing = false;
              window.location.reload()
            })
          //}

        })
  }

}

