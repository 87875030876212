<app-navbar></app-navbar>
<div class="row py-4">
</div>
  <!-- <div class="w3-sidebar w3-bar-block w3-light-grey w3-card mt-2" style="width:15%">
    <div class="w3-dropdown-hover">
      <button class="w3-button">Account
        <i class="fa fa-caret-down"></i>
      </button>
      <div class="w3-dropdown-content w3-bar-block">
        <a routerLink="/Cars" class="w3-bar-item w3-button">Link</a>
        <a href="#" class="w3-bar-item w3-button">Link</a>
      </div>
    </div>
    <div class="w3-dropdown-hover">
        <button class="w3-button">Add New
          <i class="fa fa-caret-down"></i>
        </button>
        <div class="w3-dropdown-content w3-bar-block">
          <a href="#" class="w3-bar-item w3-button">Link</a>
          <a href="#" class="w3-bar-item w3-button">Link</a>
        </div>
      </div>
      <div class="w3-dropdown-hover">
        <button class="w3-button">Security
          <i class="fa fa-caret-down"></i>
        </button>
        <div class="w3-dropdown-content w3-bar-block">
          <a href="#" class="w3-bar-item w3-button">Link</a>
          <a href="#" class="w3-bar-item w3-button">Link</a>
        </div>
      </div>
  </div> -->
    <div class="w3-container mt-3">
      <router-outlet></router-outlet>
</div>
  
