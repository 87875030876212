import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import {ProductosService, brrEstado} from '../../../services/productos.service'
import { Router } from '@angular/router';

@Component({
  selector: 'app-estados',
  templateUrl: './estados.component.html',
  styleUrls: ['./estados.component.scss']
})
export class EstadosComponent implements OnInit {
  currentEstados: any[] =[];
  estados: any[] =[];

  tableStatus: any;
  estListo= false;
  loading = false;

  eliminarEstado: brrEstado = {
    multipleS: '',
    estEstado: 0,
    usuInicioSesion:'',
    estFechaActualizacion:''
  };


  myForm: FormGroup;
  constructor(private _fb: FormBuilder, private service: ProductosService,private _router: Router) { }
   
  ngOnInit(): void {
    this.myForm = this._fb.group({
      statusS: this.currentEstados,
    });
    this.getStatusTables();
  }

  getStatusTables() {
    this.loading = true;
     this.service.getStatusTables().subscribe(
       (res) => {
        this.tableStatus = res;
        this.loading = false;

        this.currentEstados = [res[0]]
        this.getStatus(this.currentEstados[0])
        console.log(res)
       },
       (err) => {
         console.error(err.error);
         if(err.error.includes('You do not have permissions to access to ')){
          Swal.fire({
            icon: 'error',
            title:err.error,
            showConfirmButton: true
          }).then(()=>{
            this._router.navigateByUrl('/Dashboard');
          })
         }
       }
     );
   }

   getStatus(item: any) {
     this.loading = true;
     console.log(item);
      this.service.getStatus(item).subscribe(
        (res) => {
          this.loading = false;
         console.log(res)
         this.estados = res;
        },
        (err) => {
          this.loading = false;
          console.error(err.error);
        }
      );
    }


    borrarEstado(eliminarEstado: any) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;

          this.service.borrarEstado(eliminarEstado).subscribe(
            (res) => {
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Your part has been deleted.',
                showConfirmButton: true,
              }).then((result) => {
                window.location.reload();
              });
      
            },
            (err) => {
              if(err.error.includes('You do not have permissions to access to ')){
                Swal.fire({
                  icon: 'error',
                  title:err.error,
                  showConfirmButton: true
                }).then(()=>{
                  this._router.navigateByUrl('/Dashboard');
                })
              }else{
                Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: err.error,
                  showConfirmButton: false,
                  timer: 1500,
                });   
              }
              this.loading = false;
              console.error(err);
            }
          );
        }
      })
  
    }
}
