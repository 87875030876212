import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {

  constructor(private _router: Router) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
      if(!window.localStorage.getItem('token')){
        Swal.fire({
          title: 'You are not logged in',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#008c44',
          cancelButtonColor: '#d33',
          confirmButtonText: 'go to login'
    }).then((result) => {
      this._router.navigateByUrl('/login');
  })
        return false;        
      }
      return true;
  }
  
}
