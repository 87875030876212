<!-- <div *ngIf="isActive" class="overlay">
    <div class="top-bar"></div>
    <video #video width="100%" height="auto" autoplay></video>
    <div class="bottom-bar">
      <button (click)="capturePhoto()" class="capture-button">Capture Photo</button>
      <button (click)="stopCapturing()" class="cancel-button">Cancel</button>
    </div>
  </div>
   -->

   <div *ngIf="isActive" class="overlay">
    <div class="top-bar">
      <button (click)="toggleCamera()" class="switch-button">Switch</button>
      <button (click)="stopCapturing()" class="cancel-button">Done</button>
    </div>
    <video #video autoplay playsinline class="video-stream"></video>
    <div class="bottom-bar">
        <div class="thumbnail-container">
            <div class="thumbnail-wrapper" *ngFor="let photo of capturedPhotos; let i = index">
              <img [src]="photo" class="thumbnail" />
              <button (click)="removePhoto(i)" class="remove-button">X</button>
            </div>
          </div>
      <div class="button-container">
        <button (click)="capturePhoto()" class="capture-button"></button>
      </div>
    </div>
  </div>
  
  