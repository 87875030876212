<app-navbar></app-navbar>
<div class="row p-3">
    <div class="d-flex align-items-center">
        <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
            aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/Dashboard">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page">Cars</li>
            </ol>
        </nav>
    </div>
    <h1>Add Parts</h1>
    <hr>
    <form class="row g-10">
        <div class="col-md-12 mt-4 ">
            <h3>INSERTING PARTS</h3>
            <hr>
        </div>
        <div class="col-12">
            <div class="row p-2" style=" display: none;">
                <div class="card  col-lg-3 col-md-4 p-2">
                    <img class="img-fluid  fit-image" [src]="" alt="">
                    <div class="card-img-overlay text-cend">
                        <a class="btn btn-danger"><i class="fa fa-trash"></i></a>
                    </div>
                </div>
            </div>
            <h3 class="mt-2">Part Images:</h3>
            <button type="button" class="btn  btn-info text-white">choose file <i class="fa fa-image"></i></button>
            <input resize-quality="0.7" resize-type="image/jpg" id="fileInput" name="fileInput"
                style="height:0px;overflow:hidden" type="file" multiple accept="image/png,image/jpeg"
                formControlName="PieImagenes">
            <div class="invalid-feedback">
                <div> Please enter at least one Image.</div>
            </div>
        </div>
        <div class="col-md-10 mt-4" style="position: relative; ">
            <label for="inputState" class="form-label">Cars</label>
            <select class="form-select" aria-label="Default select example">
                <option [value]="0" selected>select</option>
            </select>
        </div>
        <div class="col-md-10 mt-4">
            <h3>Part Data</h3>
            <hr>
        </div>
        <div class="col-md-4">
            <label for="inputEmail4" class="form-label">Part name</label>
            <input placeholder="Part name" type="text" class="form-control">
        </div>
        <div class="col-md-4">
            <label for="inputEmail4" class="form-label">Manufacturer Part Number</label>
            <input placeholder="Manufacturer Part Number" type="number" class="form-control">
        </div>
        <h3 class="mt-5">Part Description</h3>
        <hr>
        <input style="height: 20%; width: 100%;" type="text" class="form-control mt-4  mb-4">
        <div class="col-md-2" style="position: relative; ">
            <label for="inputState" class="form-label mr-2">Category</label>
            <select class="form-select" aria-label="Default select example">
                <option [value]="0" selected>Filter Parts</option>
            </select>
        </div>
        <div class="col-md-2 " style="position: relative; ">
            <label for="inputState" class="form-label">Makes</label>
            <select class="form-select" aria-label="Default select example">
                <option [value]="0" selected>Makes</option>
            </select>

        </div>
        <div class="col-md-2" style="position: relative; ">
            <label for="inputState" class="form-label">Models</label>
            <select class="form-select" aria-label="Default select example">
                <option [value]="0" selected>Models</option>
            </select>
        </div>
        <div class="col-md- mt-5">
            <h3>General information</h3>
            <hr>
        </div>
        <div class="col-12">
        </div>
        <div class="col-md-2">
            <label for="inputEmail4" class="form-label">Price </label>
            <input placeholder="$0" type="number" class="form-control">
        </div>
        <div class="col-md-2">
            <label for="inputEmail4" class="form-label">Cost </label>
            <input placeholder="$0" type="number" class="form-control">
        </div>
        <div class="col-md-2">
            <label for="inputEmail4" class="form-label">Quantity On Hand </label>
            <input placeholder="0" type="number" class="form-control">
        </div>


        <div class="col-md-">
            <label for="inputEmail4" class="form-label">Shelf/Location</label>
            <input placeholder="Shelf/Location" type="number" class="form-control">
        </div>
        <div class="col-md-2" style="position: relative; ">
            <label for="inputState" class="form-label">Condition </label>
            <select class="form-select" aria-label="Default select example">
                <option [value]="0" selected>--Select--</option>
            </select>
        </div>
        <div class="col-md-2">
            <label for="inputEmail4" class="form-label">Color</label>
            <input placeholder="Color" type="number" class="form-control">
        </div>
        <div class="col-md-2" style="position: relative; ">
            <label for="inputState" class="form-label">Year From </label>
            <select class="form-select" aria-label="Default select example">
                <option [value]="0" selected>--Select--</option>
            </select>
        </div>
        <div class="col-md-2" style="position: relative; ">
            <label for="inputState" class="form-label">Year To </label>
            <select class="form-select" aria-label="Default select example">
                <option [value]="0" selected>--Select--</option>
            </select>
        </div>
        <div class="col-md-2" style="position: relative; ">
            <label for="inputState" class="form-label">Type </label>
            <select class="form-select" aria-label="Default select example">
                <option [value]="0" selected>--Select--</option>
            </select>
        </div>
        <div class="col-md-2">
            <label for="inputEmail4" class="form-label">Shipping Cost </label>
            <input placeholder="$0" type="number" class="form-control">
        </div>

        <div class="col-md-2">
            <label for="inputEmail4" class="form-label">Weight kg</label>
            <input placeholder="0" type="number" class="form-control">
        </div>

        <div class="col-md-2">
            <label for="inputEmail4" class="form-label">Known damage</label>
            <input placeholder="Known damage" type="text" class="form-control">
        </div>
        <div class="col-md-2">
            <label for="inputEmail4" class="form-label">Part brand</label>
            <input placeholder="Part brand" type="text" class="form-control">
        </div>
        <div class="col-md-2" style="position: relative; ">
            <label for="inputState" class="form-label">Warranty </label>
            <select class="form-select" aria-label="Default select example">
                <option [value]="0" selected>--Select--</option>
            </select>
        </div>
        <div class="col-md-2" style="position: relative; ">
            <label for="inputState" class="form-label">Product Type </label>
            <select class="form-select" aria-label="Default select example">
                <option [value]="0" selected>--Select--</option>
            </select>
        </div>
        <div class="col-md-2">
            <label for="inputEmail4" class="form-label">Latitude</label>
            <input placeholder="Latitude" type="text" class="form-control">
        </div>
        <div class="col-md-2">
            <label for="inputEmail4" class="form-label">Length</label>
            <input placeholder="Length" type="text" class="form-control">
        </div>



        <div class="col-md-3" style="position: relative; ">
            <label for="inputState" class="form-label">Type </label>
            <select class="form-select" aria-label="Default select example">
                <option [value]="0" selected>--Select--</option>
            </select>
        </div>
        <div class="col-md-3" style="position: relative; ">
            <label for="inputState" class="form-label">Placement on Vehicle </label>
            <select class="form-select" aria-label="Default select example">
                <option [value]="0" selected>--Select--</option>
            </select>
        </div>
        <div class="col-md-3" style="position: relative; ">
            <label for="inputState" class="form-label">Material</label>
            <select class="form-select" aria-label="Default select example">
                <option [value]="0" selected>--Select--</option>
            </select>
        </div>
        <div class="col-md-3" style="position: relative; ">
            <label for="inputState" class="form-label">Finish </label>
            <select class="form-select" aria-label="Default select example">
                <option [value]="0" selected>--Select--</option>
            </select>
        </div>
        <div class="col-md-3" style="position: relative; ">
            <label for="inputState" class="form-label">Items Included</label>
            <select class="form-select" aria-label="Default select example">
                <option [value]="0" selected>--Select--</option>
            </select>
        </div>
        <div class="col-md-3" style="position: relative; ">
            <label for="inputState" class="form-label">Features </label>
            <select class="form-select" aria-label="Default select example">
                <option [value]="0" selected>--Select--</option>
            </select>
        </div>
        <hr class="mt-3">
        <div class="d-flex flex-row-reverse bd-highlight p--0  mt-4">

            <div class="p-2 bd-highlight"><button type="submit" class="btn btn-primary btn-lg text-white">
                    Save Car

                    <i class="fa fa-floppy-o"></i></button></div>
            <div class="p-2 bd-highlight"><button type="submit" class="btn btn-warning btn-lg text-white ">
                    Publish Part

                    <i class="fa fa-arrow-up"></i></button></div>
                    <div class="p-2 bd-highlight">
                        <button class="btn btn-danger btn-lg text-white" routerLink="/Dashboard/Parts">
                            <i class="fa fa-times"></i> Cancel</button>
                    </div>
    
                </div>



    </form>