<div id="content">
    <div class="d-flex align-items-center">
      <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a  routerLink="/Dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">Multiple Uses</li>
        </ol>
      </nav>
    </div>
  </div>
  <hr>
  <div class="row px-3">
    <div class="d-flex flex-row bd-highlight mb-3">
      <div class="p-2 bd-highlight">
        <h2 class="text-primary">Multiple Uses</h2>
      </div>
      <div class="p-2 bd-highlight"> <button type="button" class="btn btn-outline-primary btn-lg"
          [routerLink]="['/Dashboard/CrearMultipleUses']">add <i class="fa fa-plus"></i></button></div>
    </div>
  </div>
  
  <form [formGroup]="myForm">
    <div class="row my-3">
  
      <div class="col-6 col-sm-6 col-md-3 col-lg-3 nav-item">
        <h6 class="text-primary">Group Code</h6>

          <ng-multiselect-dropdown
          name="multipleS"
          [disabled]="estListo"
          formControlName="multipleS"
          [(ngModel)]="this.currentEstados"
          [placeholder]="'Select year'"
          [settings]="{      
            closeDropDownOnSelection: true,
            singleSelection: true,
            idField: 'multipleS',
            textField: 'multipleS',
            itemsShowLimit: 1,
            allowSearchFilter: true}"
          [data]="tableStatus"
        (onSelect)="getUsosMultiples($event)"></ng-multiselect-dropdown>  
     
  
      </div>
   
    </div>
  </form>
  
  <div class="row flex-nowrap">
    <div class="row">
      <div class="d-flex justify-content-center" *ngIf="loading; else elseBlock">
        <div class="spinner-border text-primary  m-3" role="group" role="group">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div *ngIf="multipleuse.length == 0" class="p-2 px-0 d-flex justify-content-center">
        <div class="d-flex flex-column justify-content-center">
          <h3 class="d-flex justify-content-center">Data not found.</h3>
          <img class="d-flex justify-content-center" src="../../../assets/img/phvp.png" width="200" height="200" alt="">
        </div>
      </div>

      <ng-template #elseBlock>
  
        <div class="card mb-3" *ngFor="let group of multipleuse">
          <div class="row g-0">
            <div class="col-4 col-sm-5 col-md-3 col-lg-3 d-flex align-items-center">
              <img src="../../../assets/img/VP_GRIS.png" class="card-img-top fit-image img-fluid rounded-start" alt="..." >
            </div>
            <div class="col-8 col-sm-8 col-md-9 col-lg-9 d-flex align-items-center">
              <div class="card-body">
                <a> 
                  <p class="Nombre-text">{{group.usoCodGrupo}}: {{group.usoDescripcion}}</p>
                </a>
                <hr>
  
                <div class="col-6 col-sm-6 col-md-6 col-lg-6 d-flex align-items-center">
                  <div class="card-body">
                    <p class="secom-text"><small class="text-muted">State Number: {{group.usoValor}}</small></p>
                    <p class="secom-text"><small class="text-muted" >Create for: {{group.usuInicioSesion}}</small></p>
                    <p class="secom-text"><small class="text-muted" >Create in: {{group.usoFechaUltimaActualizacion}}</small></p>                       
                  </div>
             
                </div>
  
                <div class="d-grid gap-2 d-md-flex justify-content-md-star">
                  <!-- <button class="btn btn-success btn-lg" [routerLink]="['/Dashboard/editarCarro',carro.estDescripcion]"> <i class="fa fa-edit"></i></button> -->
                  <button class="btn btn-danger btn-md"  (click)="borrarMultipleUse(group)"> <i  class="fa fa-trash"></i></button>
                </div>
  
              </div>
  
            </div>
  
            
          </div>
        </div>
  
      </ng-template>
  