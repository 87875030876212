<app-navbar></app-navbar>
<div class="row p-5"></div>
<div class="modal-dialog modal-lg text-center ounded shadow d-flex align-items-center">
    <div class="modal-content" *ngIf="!loading;">
        <div class="container">
            <img class="rounded-circle border border-primary img-fluid mx-auto d-block mb-3  mt-5" src="{{usuFoto}}"
                height="200" width="200" alt="">
            <p>
                session to exported.</p>
            <form name="loginform" (submit)="login()">
                <div class="form-group">
                    <label for="user_pass"></label>
                    <input type="password" name="pwd" id="user_pass" class="form-control" value="" size="20"
                        placeholder="&#xf023; Password" style="font-family:Arial, FontAwesome" name="UsuPass"
                        [(ngModel)]="credentials.Password" />
                </div>
                <div class="form-group m-3">
                    <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                        <button type="submit" class="btn btn-primary btn-lg mb-1 mt-3  text-white">Login <span
                                *ngIf="loading" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span></button>
                    </div>
                </div>
            </form>
            <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                <button class="btn btn-danger btn-lg mb-1 mt-2 mb-2 text-white" (click)="logout(true)">Log out <span
                        *ngIf="loading" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span></button>
            </div>
        </div>
    </div>

    
</div>

<div class="d-flex justify-content-center" *ngIf="loading;">
    <div class="spinner-border text-primary  m-3" role="status" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>