<div class="modal-dialog modal-lm text-center ounded shadow">
    <div class="modal-content">
        
        <div class="container container--mini">
            <h3 class="mt-3" style="color: #008c44;"> Restore password</h3>
            <hr>
            <form [formGroup]="CambiarPassform" name="CambiarPassform" (submit)="CambiarPass()">
<div class="form-group">
<label for="user_login"></label>
<input type="text" name="log" id="curretpass" class="form-control" value="" size="20" placeholder="Enter security code" style="font-family:Arial, FontAwesome" [(ngModel)]="DatoschangePass.CurrentPassword" formControlName="CurrentPassword" name="curretpass" [ngClass]="{ 'is-invalid': submitted && f.CurrentPassword.errors }"/>
<div *ngIf="submitted && f.CurrentPassword.errors" class="invalid-feedback">
  <div>
    Please enter your Code.</div>
</div>
</div>

<div class="form-group">
    <label for="user_login"></label>
    <input type="password" id="NewPassword" class="form-control" value="" size="20" placeholder="
    Enter new password" style="font-family:Arial, FontAwesome" name="NewPassword" formControlName="NewPassword" [(ngModel)]="DatoschangePass.NewPassword" [ngClass]="{ 'is-invalid': submitted && f.NewPassword.errors }"/>
    <div *ngIf="submitted && f.NewPassword.errors" class="invalid-feedback">
      <div *ngIf="f.confpass.errors.required">
        Please enter your password.</div>
      <div *ngIf="f.NewPassword.errors.minlength">
        The password must have a minimum of 6 characters.</div>
    </div> 
    </div>
    <div class="form-group">
        <label for="user_login"></label>
        <input type="password"id="confpass" class="form-control" value="" size="20" placeholder="Confirm new password" style="font-family:Arial, FontAwesome" name="confpass" formControlName="confpass" [ngClass]="{ 'is-invalid': submitted && f.confpass.errors }"/>
        <div *ngIf="submitted && f.confpass.errors" class="invalid-feedback">
          <div *ngIf="f.confpass.errors.required">
            Please enter your password.</div>
          <div *ngIf="f.confpass.errors.mustMatch">
            Password do not match</div>
        </div> 
        </div>

<div class="form-group">
    <button type="submit" class="btn btn-primary btn-block mb-3  mt-3 text-white"> <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> 
      Restore</button>
</div>
</form>
</div>
<div class="ml-3 mb-3 " >
    <a class="btn btn-danger"  [routerLink]="['/recuperarPass']"><i class="fa fa-times-circle"></i> Cancel</a>
  </div>
    </div>
</div>
