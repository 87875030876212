import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../../services/admin.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
@Component({
  selector: 'app-inicio-admin',
  templateUrl: './inicio-admin.component.html',
  styleUrls: ['./inicio-admin.component.scss']
})
export class InicioAdminComponent implements OnInit {

  constructor(private adService: AdminService,private _router: Router) { }

  loading = false;
  canCompanie = true;
  canStatus =true;
  canMU = true;
  canParts =true; 
  canCars= true;
  canStore = true;
  canDefaults = true;
  canEbay = true;
  canUser = true;
  canRol = true;
  canPermiso= true;
  canTires= true;

  openStore = false;

  nombreAdmin= "";
  sumador: number = 0;
  storeTotal= "";
  
  ngOnInit(): void {
    this.getHome();    
  }

  getHome(){
    var contador = this.contador();
    this.loading = true;
    this.adService.getAdminHome().subscribe(
      res =>{
        console.log(res);
        clearInterval(contador)
        this.nombreAdmin= res.nombreAdmin;
        if(res.permisos.some((x) => x.permisoString == "DashBoard")){
          if(res.permisos.some((x) => x.permisoString == "Cars")){
            (<HTMLInputElement>document.getElementById('carsTotal')).innerHTML =  new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(res.carsTotal);
            this.canCars = true;
          }
          else{
            this.canCars = false;
          }
          if(res.permisos.some((x) => x.permisoString == "Parts")){
            (<HTMLInputElement>document.getElementById('partsTotal')).innerHTML = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(res.partsTotal);
            this.canParts = true;
          }
          else{
            this.canParts = false;
          }

          if(res.permisos.some((x) => x.permisoString == "Defaults")){
            (<HTMLInputElement>document.getElementById('defaultsTotal')).innerHTML = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(res.defaultsTotal);
            this.canDefaults = true;
          }else{
            this.canDefaults = false;
          }

          if(res.permisos.some((x) => x.permisoString == "Companies")){
            (<HTMLInputElement>document.getElementById('companiesTotal')).innerHTML = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(res.companiesTotal);
            this.canCompanie = true;
          }else{
            this.canCompanie = false;
          }

          if(res.permisos.some((x) => x.permisoString == "Multiple Uses")){
            (<HTMLInputElement>document.getElementById('usosMultiplesTotal')).innerHTML = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(res.usosMultiplesTotal);
            this.canMU = true;
          }else{
            this.canMU = false;
          }

          if(res.permisos.some((x) => x.permisoString == "Status")){
            (<HTMLInputElement>document.getElementById('statusTotal')).innerHTML = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(res.statusTotal);
            this.canStatus = true;
          }else{
            this.canStatus = false;
          }

          if(res.permisos.some((x) => x.permisoString == "eBay Import")){
            this.canEbay = true;
          }else{
            this.canEbay = false;
          }

          if(res.permisos.some((x) => x.permisoString == "Manage Store Sales")){
            this.storeTotal = res.invoceTotal;
            //(<HTMLInputElement>document.getElementById('storeTotal')).innerHTML = "$" + new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(res.invoceTotal); 
            this.canStore = true;
          }else{
            this.canStore = false;
          }

          if(res.permisos.some((x) => x.permisoString == "Users")){
            (<HTMLInputElement>document.getElementById('userTotal')).innerHTML = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(res.usuariosTotal);
            this.canUser = true;
          }else{
            this.canUser = false;
          }

          if(res.permisos.some((x) => x.permisoString == "Roles")){
            (<HTMLInputElement>document.getElementById('rolTotal')).innerHTML = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(res.rolesTotal);
            this.canRol = true;
          }else{
            this.canRol = false;
          }

          if(res.permisos.some((x) => x.permisoString == "Permits") && res.permisos.some((x) => x.permisoString == "Admin")){
            (<HTMLInputElement>document.getElementById('permitTotal')).innerHTML = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(res.permisosTotal);
            this.canPermiso = true;
          }else{
            this.canPermiso = false;
          }

          if(res.permisos.some((x) => x.permisoString == "Tires")){
            (<HTMLInputElement>document.getElementById('tiresTotal')).innerHTML = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(res.tiresTotal);
            this.canTires = true;
          }else{
            this.canTires = false;
          }

          //console.log(res)
          this.loading = false;

        }
        else{
          this.canStore = false;
          this.canCars = false;
          this.canParts = false;
          this.canDefaults = false;
          this.canCompanie = false;
          this.canEbay = false;
          this.canStore = false;
          this.canStatus = false;
          this.canMU = false;
          this.canUser = false;
          this.canRol = false;
          this.canPermiso= false;
          this.canTires = false;
          Swal.fire({
            icon: 'error',
            title: 'You do not have permissions to access to DashBoard',
            showConfirmButton: true
          })
        }


      }, err =>{
        clearInterval(contador)
        this.nombreAdmin= "";
        this.canStore = false;
        this.canCars = false;
        this.canParts = false;
        this.canDefaults = false;
        this.canCompanie = false;
        this.canEbay = false;
        this.canStore = false;
        this.canStatus = false;
        this.canMU = false;
        this.canUser = false;
        this.canRol = false;
        this.canPermiso= false;
        this.canTires = false;
        (<HTMLInputElement>document.getElementById('partsTotal')).innerHTML = "0";
        (<HTMLInputElement>document.getElementById('carsTotal')).innerHTML = "0";
        (<HTMLInputElement>document.getElementById('defaultsTotal')).innerHTML = "0";
        (<HTMLInputElement>document.getElementById('companiesTotal')).innerHTML = "0";
        (<HTMLInputElement>document.getElementById('usosMultiplesTotal')).innerHTML = "0";
        (<HTMLInputElement>document.getElementById('statusTotal')).innerHTML = "0";
        (<HTMLInputElement>document.getElementById('userTotal')).innerHTML = "0";
        (<HTMLInputElement>document.getElementById('rolTotal')).innerHTML = "0";
        (<HTMLInputElement>document.getElementById('permitTotal')).innerHTML = "0";
        (<HTMLInputElement>document.getElementById('tiresTotal')).innerHTML = "0";
        this.storeTotal = "";
       console.error(err);
       this.loading = false;
       if(err.status == 400){
        Swal.fire({
          icon: 'error',
          title:err.error,
          showConfirmButton: true
        }).then(()=>{
          this._router.navigateByUrl('/');
        })
       }
      })
  }

  contador(){
    var sum =  0;
      var time_intres = setInterval(function(){
        sum = sum + 1;
        var sumstring = sum.toString();
        (<HTMLInputElement>document.getElementById('partsTotal')).innerHTML = sumstring;
        (<HTMLInputElement>document.getElementById('carsTotal')).innerHTML = sumstring;
        (<HTMLInputElement>document.getElementById('defaultsTotal')).innerHTML = sumstring;
        (<HTMLInputElement>document.getElementById('companiesTotal')).innerHTML = sumstring;
        (<HTMLInputElement>document.getElementById('usosMultiplesTotal')).innerHTML = sumstring;
        (<HTMLInputElement>document.getElementById('statusTotal')).innerHTML = sumstring;
        (<HTMLInputElement>document.getElementById('userTotal')).innerHTML = sumstring;
        (<HTMLInputElement>document.getElementById('tiresTotal')).innerHTML = sumstring
    }, 0.2);

    return time_intres;
  }

  MoreStore(){
    var overlay = (<HTMLElement>document.getElementById('storeMore_d'))
    if(this.openStore){
      overlay.classList.add('animateFadeOut'); 
      setTimeout(() => {
        this.openStore = false;
      }, 300);
    }
    else
      this.openStore = true;

    setTimeout(() => {
      //overlay.classList.add('animateFadeIn'); 
 
    }, 500);    
  }

  focusOut(){
    this._router.navigateByUrl('/Dashboard/Parts');
  }

  
}
