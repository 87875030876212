import { Component, OnInit } from '@angular/core';
//import { ProductosService } from '../../../services/productos.service'
import { AdminService,Rol } from '../../../services/admin.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {
  currentRol: any[] =[];
  roles: any[] =[];

  estListo= false;
  loading = false;

  eliminarRol: Rol = {
    rolDescripcion: '', 
    rolPermisos: [],
    rolId: 0
  
  };



  myForm: FormGroup;
  constructor(private _fb: FormBuilder, private service: AdminService, private _router: Router) { }

  ngOnInit(): void {
    this.myForm = this._fb.group({
      multipleS: this.currentRol,
    });
    this.getRoles();
  }

  getRoles() {
    this.loading = true;
     this.service.getRoles().subscribe(
       (res) => {
        this.roles = res;
        this.loading = false;

       },
       (err) => {
        console.error(err.error);
        if(err.error.includes('You do not have permissions to access to ')){
          Swal.fire({
            icon: 'error',
            title:err.error,
            showConfirmButton: true
          }).then(()=>{
            this._router.navigateByUrl('/Dashboard');
          })
         }
         else{
            Swal.fire({
              icon: 'error',
              title:err.error,
              showConfirmButton: true
            });
         }
        this.loading = false;
       }
     );
   }

    borrarRol(eliminarRol: any) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;

          this.service.borrarRol(eliminarRol).subscribe(
            (res) => {
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Your role has been deleted.',
                showConfirmButton: true,
              }).then((result) => {
                window.location.reload();
              });
      
            },
            (err) => {
              if(err.error.includes('You do not have permissions to access to ')){
                Swal.fire({
                  icon: 'error',
                  title:err.error,
                  showConfirmButton: true
                }).then(()=>{
                  this._router.navigateByUrl('/Dashboard');
                })
               }
               else{
                  Swal.fire({
                    icon: 'error',
                    title:err.error,
                    showConfirmButton: true
                  });
               }
              this.loading = false;
              console.error(err);
            }
          );
        }
      })
  
    }
}
