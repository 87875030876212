import { Component, OnInit } from '@angular/core';
import { ProductosService } from '../../../services/productos.service'
import { FormBuilder, FormGroup } from '@angular/forms';
import { AdminService, EstadosPost } from '../../../services/admin.service'
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-crear-estados',
  templateUrl: './crear-estados.component.html',
  styleUrls: ['./crear-estados.component.scss']
})
export class CrearEstadosComponent implements OnInit {
  currentEstados = [];

  tableStatus: any;
  estListo = false;
  loading = false;
  myForm: FormGroup;

  buscarEstados: EstadosPost = {

    estTabla: '',
    estEstado: 0,
    estDescripcion: '',
    usuInicioSesion: ''

  };

  constructor(private _fb: FormBuilder, private service: ProductosService, private _adminService: AdminService, private _router: Router) { }

  ngOnInit(): void {
    this.myForm = this._fb.group({
      statusgroupS: this.currentEstados,
    });
    this.getStatusTables();
  }

  getStatusTables() {
    this.loading = true;
    this.service.getStatusTables().subscribe(
      (res) => {
        this.loading = false;
        this.tableStatus = res;
        this.currentEstados = [res[0]]
        console.log(res)
      },
      (err) => {
        this.loading = false;
        if (err.error.includes('You do not have permissions to access to ')) {
          Swal.fire({
            icon: 'error',
            title: err.error,
            showConfirmButton: true
          }).then(() => {
            this._router.navigateByUrl('/Dashboard');
          })
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: err.error,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        console.error(err.error);
      }
    );
  }

  Submit() {
    try {
      this.loading = true;
      if (this.currentEstados.length == 0) {
        throw new TypeError("Group is necesary");
      }

      if ((<HTMLOptionElement>document.getElementById('Value')).value == "")
        throw new TypeError("Value is necesary");

      if ((<HTMLOptionElement>document.getElementById('Description')).value == "")
        throw new TypeError("Description name is necesary");

      this.buscarEstados.estTabla = this.currentEstados[0];
      this.buscarEstados.estEstado = Number((<HTMLOptionElement>document.getElementById('Value')).value);
      this.buscarEstados.estDescripcion = ((<HTMLOptionElement>document.getElementById('Description')).value);

      this._adminService.postEstados(this.buscarEstados).subscribe(
        res => {

          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Done',
            showConfirmButton: false,
            timer: 1500
          })
          this._router.navigateByUrl('/Dashboard/Estados');
        }, err => {
          this.loading = false;
          console.log(err);
          if (err.error.includes('You do not have permissions to access to ')) {
            Swal.fire({
              icon: 'error',
              title: err.error,
              showConfirmButton: true
            }).then(() => {
              this._router.navigateByUrl('/Dashboard');
            })
          } else {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: err.error,
              showConfirmButton: false,
              timer: 1500,
            });
          }

        })


    } catch (error) {
      this.loading = false;
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: error,
        showConfirmButton: true,
      })
      console.log(error);
    }


  }
}
