import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { ProductosService } from '../../../services/productos.service';
import { AdminService } from '../../../services/admin.service';
import { Router } from '@angular/router';
import { ChartComponent } from "ng-apexcharts";
import { ChartOptions } from '../../../helpers/Util';


@Component({
  selector: 'app-admin-invoices',
  templateUrl: './admin-invoices.component.html',
  styleUrls: ['./admin-invoices.component.scss'],

})

export class AdminInvoicesComponent implements OnInit {

  @ViewChild("chart") chart: ChartComponent;
  constructor(private _producto: ProductosService,
    private _adminServices: AdminService) { }

  facturas: any;
  loadingDetail = false;
  loading = false;
  noData = false;
  total = 0;
  typeGraf = 0;

  public ChartOptions!: Partial<ChartOptions> | any;

  dateFrom = "";
  dateTo = "";
  ngOnInit(): void {
    const today = new Date();
    const oneDayInMillis = 24 * 60 * 60 * 1000; // Milisegundos en un día
    this.dateFrom = new Date(today.getTime() - 7 * oneDayInMillis).toISOString().substring(0, 10);
    this.dateTo = new Date(today.getTime() + oneDayInMillis).toISOString().substring(0, 10);
    this.getInvoce();
  }

  getInvoce() {
    this.loading = true;
    console.log(this.dateFrom)
    this._adminServices.Adminfacturas(this.dateFrom, this.dateTo).subscribe(
      res => {
        this.loading = false;
        this.facturas = res;
        this.UpdateGraf()
      }, err => {
        this.loading = false;
        this.noData = true;
        console.log(err);
      })
  }

  Reportefactura(factid: number) {
    this.loadingDetail = true;
    this._producto.Reportefactura(factid).subscribe(
      res => {
        this.loadingDetail = false;
        const url = res;
        //Ajustar horizontalmente
        var x = (screen.width / 2) - (1000 / 2);
        //Ajustar verticalmente
        var y = (screen.height / 2) - (800 / 2);
        window.open(url, 'popup', 'width=1000 , height=800,location=no,resizable=no,' + 'left=' + x + ', top=' + y + '');

      }, err => {
        this.loadingDetail = false;
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',

        })
      })
  }


  UpdateGraf() {

    var toSubstring = 0;

    if (this.typeGraf == 0) {
      toSubstring = 10;
      console.log(this.typeGraf)
    }
    else if (this.typeGraf == 1) {
      toSubstring = 7;
      console.log(this.typeGraf)
    }
    else if (this.typeGraf == 2) {
      toSubstring = 4;
      console.log(this.typeGraf)
    }

    this.total = 0
    var series = []
    var seriesTempora = []
    for (let i = 0; i < this.facturas.length; i++) {
      this.total += this.facturas[i].facTotal;
      seriesTempora = series.filter(resp => resp["dates"] == this.facturas[i]["facFecha"].substring(0, toSubstring));
      if (seriesTempora.length > 0) {
        // Si ya existe la fecha en la serie, sumamos el facTotal al total de profesionales
        const index = series.indexOf(seriesTempora[0]);
        series[index]["totals"] += this.facturas[i]["facTotal"];
      } else {
        // Si la fecha no existe en la serie, creamos un nuevo objeto con la fecha y el total de facTotal
        series.push({
          "dates": this.facturas[i]["facFecha"].substring(0, toSubstring),
          "totals": this.facturas[i]["facTotal"]
        });
      }
    }
    var dates = series.map(item => item.dates);
    var totals = series.map(item => item.totals);

    if (this.facturas.length == 0) {
      this.noData = true;
    }
    else {
      this.noData = false;

      this.ChartOptions = {
        series: [
          {
            name: "Total",
            data: totals.reverse()
          }
        ],
        chart: {
          height: 350,
          type: "bar"
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top" // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return "$" + val.toFixed(2);
          },
          offsetY: -20,
          style: {
            fontSize: "13px",
            colors: ["#008c44"]
          }
        },

        xaxis: {
          categories: dates.reverse(),
          position: "top",
          labels: {
            offsetY: -18,
            colors: ["#008c44"]
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },

          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#008c44",
                colorTo: "#008c44",
                stops: [0, 100],
                opacityFrom: 0.2,
                opacityTo: 0.3
              }
            }
          },
          tooltip: {
            enabled: true,
            offsetY: -35
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: ["#008c44"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [100, 0, 0, 100]
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false,
            formatter: function (val) {
              return "$" + val.toFixed(2);
            }
          }
        },
        title: {
          text: "Monthly Inflation in Argentina, 2002",
          floating: 0,
          offsetY: 320,
          align: "center",
          style: {
            color: "#ffffff"
          }
        }
      };


    }

  }
}
