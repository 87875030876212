import { Component, OnInit } from '@angular/core';
import { ProductosService, ByOwner } from '../../services/productos.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-owner',
  templateUrl: './owner.component.html',
  styleUrls: ['./owner.component.scss']
})
export class OwnerComponent implements OnInit {

  public infoOwner: any;
  public ownerId = 0;
  public tipoOwner = 0;
  public cantp: number;
  public ownerPie: any;
  count = 0;
  cargar = true;
  page = 1;
  pageSize = 20;

  ByOwner: ByOwner = {


    ownerId: 0,
    nombrePart: '',
    tipoOwner: 0,
    pageNumber: 0,
    pageSize: 0

  }

  constructor(
    private _productosService: ProductosService,
    private _router: Router,
    private _route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this._route.params.subscribe(params => {
      let pieId = params.pieId;
      this.Owner(pieId);
    })
  }

  getRequestParams(page: number, pageSize: number): any {
    let params: any = {};

    if (page) {
      params['pageNumber'] = page;
    }
    return params;
  }

  Owner(pieId: any) {
    this._productosService.PiezaOwner(pieId).subscribe(
      (res: any) => {
        this.infoOwner = res;
        this.ownerId = this.infoOwner.ownerId;
        this.tipoOwner = this.infoOwner.tipoOwner;
        this.OwnerPiezas();
      }, err => {
        if (err.status == 401) {
          this._router.navigateByUrl('/Relogin')
        }
      })
  }

  OwnerPiezas() {
    this.ByOwner.ownerId = this.ownerId;
    this.ByOwner.tipoOwner = this.tipoOwner;
    this.ByOwner.pageNumber = this.page;
    this.ByOwner.pageSize = this.pageSize;
    this.ByOwner.nombrePart = (<HTMLInputElement>document.getElementById('search_im')).value;
    this._productosService.piezasOwner(this.ByOwner).subscribe(
      (response: any) => {
        const { results, totalRecordCount } = response;
        this.ownerPie = results;
        this.count = totalRecordCount;
        this.cargar = false;
      }, err => {
        this.cargar = false;
        console.error(err);
        if (err.error == 'Carga Completa') {
          if (this.page == 1) {
            this.ownerPie = [];
            this.count = 0;
          }
        }else{
          if (err.status == 401) {
            // window.localStorage.removeItem('token');
            this._router.navigateByUrl('/Relogin')
          }
          else{
            Swal.fire({
              icon: 'error',
              title:err.error,
              showConfirmButton: true
            })
          }
        }

      })
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.startup();
    this.OwnerPiezas();
  }

  startup() {
    window.scroll(0, 0);
    this.cargar = true;
  }


}
