import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../../services/admin.service';
import { Carro } from '../../../Entities/Carro';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductosService, Modelo } from '../../../services/productos.service';
import { LocationService } from '../../../services/location.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-crear-carro',
  templateUrl: './crear-carro.component.html',
  styleUrls: ['./crear-carro.component.scss']
})
export class CrearCarroComponent implements OnInit {

  selectedItems = []; 

  currentYear: any[] = [];
  currentMarca: any[] = [];
  currentModelo: any[] = [];
  currentType: any[] = [];

  years: number[] = [];
  carro: FormGroup;
  parts: any;
  urls: string[] = [];
  postUrl = new Array<string>();
  latitude: string;
  longitude: string;
  categoria: any;
  marca: any;
  modelo: any;
  marListo = true;
  catListo = true;
  loadingmark = true;
  submitted = false;
  imgResultBeforeCompress: string;
  imgResultAfterCompress: string
  loadingL = false;
  loadingP = false;
  loadingDP = false;
  loadingDL = false;
  usuId: any;
  selected: any;
  cartypes: any[] = [];
  results: any;
  PartPrice = 0
  costo = 0;
  empId: any;
  usuSesion: any;

  currentCategory: any[] = [];
  currentPart: any[] = [];
  catID: any;


  datosCarro = new Carro();


  model: Modelo = {
    idMarca: 0,
    marcastring: ''
  }


  constructor(private _adminService: AdminService, private formBuilder: FormBuilder, private _productoService: ProductosService, private _locationService: LocationService, private _router: Router) { }

  ngOnInit(): void {
    this.getLocation();
    this.getMarca();
    this.getCategoria();
    this.selectYear();
    this.getUsosMultiples()

    this.carro = this.formBuilder.group({
      Vin: ['', Validators.required],
      Color: ['', Validators.required],
      Millage: ['', Validators.required],
      Costo: ['', Validators.required],
      marcaf: this.currentMarca,
      modelof: this.currentModelo,
      catf: this.currentCategory,
      partf: this.currentPart,

      year: this.currentYear,
      cartipof: this.currentType,
    });


  }

  UnSelectObject(item: any) {

    console.log(this.currentMarca)
    if (this.currentMarca.length == 0) {
      this.currentModelo = [];
      this.modelo = null;
    }

    if (this.currentCategory.length == 0) {
      this.currentPart = [];
      this.parts = null;
    }

  }
  get f() { return this.carro.controls }

  onPhotoCaptured(photo: any) {
    //this.urls.push(photo);
    //this.showCaptureComponent = false;
  }
  showCaptureComponent: boolean = false;

  onCaptureCancelled(photos: any) {
    photos.forEach((x) => {
      if(!this.urls.includes(x)){
        this.urls.push(x);
      }
    })
    this.showCaptureComponent = false;
  }

  startPhotoCapture() {
    this.showCaptureComponent = true;
  }

  detectFiles(event: any) {
    //this.urls = [];
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.urls.push(e.target.result);
          // console.log(file);
          // if (file.webkitRelativePath == "") {
          //   console.log('Foto capturada con la cámara:', file.name);
          //   document.getElementById('fileInput').click();
          // } else {
          //   console.log('Foto seleccionada desde la biblioteca:', file.name);
          // }
        }
        reader.readAsDataURL(file);      
      }
    }
  }

  verImagen(img): void {
    Swal.fire({
      imageUrl: img,
      imageWidth: '100%',
      imageHeight: '100%',
    })
  }

  selectYear() {
    var fully = new Date().getFullYear();
    for (let y = fully; y >= 1970; y--) {
      this.years.push(y);
    }
  }

  removeItemFromArr(item) {
    var arr = this.urls;
    var i = arr.indexOf(item);
    if (i !== -1) {
      arr.splice(i, 1);
    }
    console.log(arr);
    arr = this.postUrl;
  }

  chooseFile() {
    document.getElementById("fileInput").click();
  }

  getLocation() {
    console.log('aqui');
    this._locationService.getPosition().then(pos => {
      this.latitude = pos.lat;
      this.longitude = pos.lng;
    });
  }

  getMarca() {
    this._productoService.getMarca().subscribe(
      res => {
        this.loadingmark = false;
        this.marListo = false;
        this.marca = res;
      },
      err => {

        console.log(err);
      })
  }

  getCategoria() {
    this._productoService.getCategoria().subscribe(
      res => {

        this.catListo = false;
        this.categoria = res;
      }, err => {
        console.log(err);
      })
  }

  cargarModelo(item: any) {

    this.model.idMarca = item.marID;
    this.currentModelo = [];
    this._productoService.getModelo(this.model).subscribe(
      res => {
        this.modelo = res;
        if(item.modDescripcion == "vin"){
             this.currentModelo = res.filter((element) => element.modID == item.modID);       
        }

      }, err => {
        console.log(err);
      }
    )
  }

  partSelect(item: any) {
    this.currentPart = [];
    item.piePreCatId = this.catID;
    item.piePreUsuSesion = this.usuSesion;
    item.piePreFecha = new Date();
    if(!this.selectedItems.some((x) => x.piePreId == item.piePreId))
      this.selectedItems.push(item);

    this.calcularCost();

  }
  
  getUsosMultiples() {
    this._adminService.getUsosMultiples("TipoCarros").subscribe(
      response => {
        this.cartypes = response
      },
      error => {
        console.log(<any>error);
      })
  }

  partDeselect(item: any) {
    var indexBorrar = this.selectedItems.indexOf( this.selectedItems.find((x)=> x == item))
    this.selectedItems.splice(indexBorrar, 1);
    this.calcularCost();
  }

  partSelectAll(item: any) {

    this.currentPart = [];
    console.log(item)
    item.forEach((x) => {
      this.partSelect(x);
    })

    this.calcularCost();
  }

  onUnSelectAll() {
    this.selectedItems = [];
    this.currentPart = [];
    this.calcularCost();
  }

  cargarPart(item: any) {
    this.catID = item.catID;
    this.currentPart = [];
    console.log(item.catID);
    this._productoService.getPart(item.catID).subscribe(
      (res: any) => {
        this.parts = res;
        var indexBorrar = res.indexOf( res.find((x)=> x.piePreCatId == 0))
        this.parts.splice(indexBorrar, 1);

      }, err => {
        console.log(err);
      })
  }



  buscarVin() {
    if ((<HTMLInputElement>document.getElementById('Vin')).value != '') {
      let buscar = (<HTMLInputElement>document.getElementById('Vin')).value;
      this.submitted = true;
      fetch('https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/' + buscar + '?format=json', {
        method: "GET",
        headers: { "dataType": "application/json" }
      })
        .then(response => response.json()

        )
        .then(json => {
          this.results = json.Results;
         // console.log(this.results);

          this.currentMarca = this.marca.filter((element) => element.marID == this.results[0].MakeID);
          this.currentYear = this.years.filter((element) => element == this.results[0].ModelYear);
          var myCar = {
            marID: this.results[0].MakeID,
            modDescripcion: "vin",
            modID: this.results[0].ModelID,
          };
          this.cargarModelo(myCar);
          (<HTMLInputElement>document.getElementById('carversion')).value = this.results[0].Series;
          (<HTMLInputElement>document.getElementById('Transmissioninfo')).value = this.results[0].TransmissionStyle;
          (<HTMLInputElement>document.getElementById('Enginecylinders')).value = this.results[0].EngineCylinders;
          (<HTMLInputElement>document.getElementById('EngineModel')).value = this.results[0].EngineModel;
          this.submitted = false;
        })
        .catch(err =>  { console.log(err);  this.submitted = false;});
    } else {
      Swal.fire('enter vin');
      this.submitted = false;
    }
  }

  handleCarouselEvents(event: any) {
  }


  calcularCost() {
   var cos =  Number((<HTMLInputElement>document.getElementById('Costo')).value)
   var coTowCostosto =  Number((<HTMLInputElement>document.getElementById('TowCosto')).value)
   var DismantleCosto =  Number((<HTMLInputElement>document.getElementById('DismantleCosto')).value)
   var MiscellaneousCosto =  Number((<HTMLInputElement>document.getElementById('MiscellaneousCosto')).value)

   this.costo = cos + coTowCostosto+ DismantleCosto+MiscellaneousCosto;
   this.PartPrice = this.costo / this.selectedItems.length
  }

  Submit() {

    try{

     var loa = Swal.fire({
        title: 'Loading',
        showConfirmButton: false,
        allowOutsideClick: false,
        html: 'Please wait',
        
        timerProgressBar: true,
        didOpen: () => {
          //Swal.showLoading()
        },
        willClose: () => {
         
        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          console.log('I was closed by the timer')
        }
      })

          this.submitted = true;
        
        // if (this.carro.invalid) {

        //   throw new TypeError("All fields with * are required");

        // } else {
          if (this.currentYear.length == 0) {
            throw new TypeError("Years is necesary");
          } else {
            this.datosCarro.añoFabricacion = this.currentYear[0].toString();;
          }
    
          if (this.currentMarca.length == 0) {
            throw new TypeError("Mark is necesary");
          } else {
            this.datosCarro.marcaId = this.currentMarca[0].marID;
          }
    
          if (this.currentModelo.length == 0) {
            throw new TypeError("Model is necesary");
          } else {
            this.datosCarro.modeloId = this.currentModelo[0].modID;
          }
    
          if (this.currentType.length == 0) {
            throw new TypeError("Type is necesary");
          } else {
            this.datosCarro.carTipo = this.currentType[0].usoValor;
          }

          if (Number((<HTMLInputElement>document.getElementById('Costo')).value)== 0) {
            throw new TypeError("Cost is necesary");
          }

          if (Number((<HTMLInputElement>document.getElementById('Millage')).value)== 0) {
            throw new TypeError("Millage is necesary");
          }

          if ((<HTMLInputElement>document.getElementById('Color')).value == '') {
            throw new TypeError("Color is necesary");
          }



          this.datosCarro.carImagenes = this.urls.slice();
          this.datosCarro.carVIN = (<HTMLInputElement>document.getElementById('Vin')).value;
          this.datosCarro.carStatus = Number((<HTMLOptionElement>document.getElementById('status')).value);

          this.datosCarro.carVersion = (<HTMLInputElement>document.getElementById('carversion')).value;
          this.datosCarro.carColor = (<HTMLInputElement>document.getElementById('Color')).value;
          this.datosCarro.carColorCode = (<HTMLInputElement>document.getElementById('ColorCode')).value;
          this.datosCarro.carKilometraje = (<HTMLInputElement>document.getElementById('Millage')).value;
          this.datosCarro.carCosto = Number((<HTMLInputElement>document.getElementById('Costo')).value);
          this.datosCarro.carCostoRemolque = Number((<HTMLInputElement>document.getElementById('TowCosto')).value);
          this.datosCarro.carCostoDesmantelamiento = Number((<HTMLInputElement>document.getElementById('DismantleCosto')).value);
          this.datosCarro.carCostoExtra = Number((<HTMLInputElement>document.getElementById('MiscellaneousCosto')).value);
          this.datosCarro.carTranmicionInfo = (<HTMLInputElement>document.getElementById('Transmissioninfo')).value;
          this.datosCarro.carCilindros = (<HTMLInputElement>document.getElementById('Enginecylinders')).value;
          this.datosCarro.carModeloMotor = (<HTMLInputElement>document.getElementById('EngineModel')).value;

          this.datosCarro.carCostoTotal = Number((<HTMLInputElement>document.getElementById('Costo')).value);
          this.datosCarro.defaultsPiezas = this.selectedItems;

          this._adminService.postCarros(this.datosCarro).subscribe(
            res => {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Done',
                showConfirmButton: false,
                timer: 1500
              })
              this._router.navigateByUrl('/Dashboard/Cars');

            }, err => {
              
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: err.error,
                showConfirmButton: true
              })
              this.submitted = false;
            })
        //}    
    }catch ({ name, message }) {

      Swal.fire({
        position: 'center',
        icon: 'error',
        title: message,
        showConfirmButton: true
      })
      this.submitted = false;
    }
  }

}
