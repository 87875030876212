import { Component, OnInit , } from '@angular/core';
import {ProductosService} from '../../../services/productos.service';
import { AdminService, filtrosCompanies } from '../../../services/admin.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {

  currentStatus :any[] =[];
  empresas: any[] =[];
  empresaStatus: any[] =[];

  loading = false;
  estListo= false;

  myForm: FormGroup;
  constructor(
    private service: ProductosService,
    private _fb: FormBuilder,
    private _router: Router,
    private adService: AdminService
  ) {}
  buscarCompanies : filtrosCompanies = {
    empID : 0,
    empNombre : '',
    empEstatus: 0,
    empTelefono: '',
    empFax: '',
    empDireccion: '',
    empDireccion2: '',
    empZipCode: '',
    empEstado: '',
    empCiudad: '',
    empLogoPath: '',
    pageNumber: 0,
  buscar: ''
  
  
  };

  ngOnInit(): void {
    if(this.loading == false){
        this.myForm = this._fb.group({
        companiS: this.currentStatus,
      });    
    }

    this.getStatus();
    this.getCompanies(1,'');
  }
  
  getCompanies  (status:any, nombre:string) {
   this.loading = true;
    this.service.getCompanies(nombre, status).subscribe(
      (res) => {
        this.empresas = res;
        console.log(res);
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        console.log(err.error);

        if(err.status == 400){
          Swal.fire({
            icon: 'error',
            title:err.error,
            showConfirmButton: true
          }).then(()=>{
            this._router.navigateByUrl('/Dashboard');
          })
         }
      }
    );
  }

  getStatus() {
    this.loading = true;
     this.service.getStatus("company").subscribe(
       (res) => {
        this.loading = false;
        console.log(res)
         this.empresaStatus = res;
         this.currentStatus = [res[0]];
         console.log(this.currentStatus)
       },
       (err) => {
         console.error(err.error);
       }
     );
   }

  buscarcompanies(){
    this.buscarCompanies.empNombre = (<HTMLInputElement>document.getElementById('empname')).value;
    if( this.buscarCompanies.empNombre.length ==0){    
      this.buscarCompanies.empNombre = '';
     
    }
    if( this.currentStatus.length ==0){    
      Swal.fire({
        icon: 'error',
        title: 'Select a Status',
        showConfirmButton: true
      })
    }
  
    this.getCompanies(this.currentStatus[0].estEstado,this.buscarCompanies.empNombre);
    
  }

  SuspenderCompany(nameCompany){
    Swal.fire({
      title: 'Do you want to suspend ' + nameCompany.empNombre +'?',
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      denyButtonText: `Yes, Suspend`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isDenied) {
        this.loading = true;
        this.adService.suspendCompanies(nameCompany).subscribe(
          (res) => {
            Swal.fire(nameCompany.empNombre+ ' Suspended!', '', 'success').then((th) => {
              //this.loading = false;
              this.ngOnInit();
             // this._router.navigateByUrl('/Dashboard/Companies');
            })
           console.log(res)
          },
          (err) => {
            this.loading = false;
            Swal.fire(err.error, '', 'error')
            console.error(err.error);
            if(err.status == 400){
              Swal.fire({
                icon: 'error',
                title:err.error,
                showConfirmButton: true
              }).then(()=>{
                this._router.navigateByUrl('/Dashboard');
              })
             }
          }
        );
      } 
    })
  }
  
  ReActiveCompany(nameCompany){
    Swal.fire({
      title: 'Do you want to reActive ' + nameCompany.empNombre + '?',
      showDenyButton: false,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Yes ReActive',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.loading = true;
        this.adService.reActiveCompanies(nameCompany).subscribe(
          (res) => {
            Swal.fire(nameCompany.empNombre+ ' Activated!', '', 'success').then((th) => {
              //this.loading = false;
              this.ngOnInit();
            })
           console.log(res)
          },
          (err) => {
            this.loading = false;
            Swal.fire(err.error, '', 'error')
            console.error(err.error);
          }
        );
      } 
    })
  }

  // UnSelectObject(item: any){

  //   console.log(this.currentStatus)
  //   if(this.currentStatus.length == 0){
  //     this.currentStatus = [];
  //     this.empresas =  null;
  //   }

  // }
}
