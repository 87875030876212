import { Component, ViewChild, ElementRef, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-photo-capture',
  templateUrl: './photo-capture.component.html',
  styleUrls: ['./photo-capture.component.scss']
})
export class PhotoCaptureComponent implements OnInit, OnDestroy {
  @ViewChild('video') video: ElementRef<HTMLVideoElement>;
  @Output() photoCaptured = new EventEmitter<string>();
  @Output() captureCancelled = new EventEmitter<any>();
  capturing: boolean = false;
  isActive: boolean = false;
  capturedPhotos: string[] = [];
  currentStream: MediaStream;
  usingFrontCamera: boolean = true;

  constructor() {}

  ngOnInit(): void {
    this.activate();
  }

  ngOnDestroy(): void {
    this.stopCapturing();
    this.enableScroll();
  }

  async startCamera() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const constraints = {
          video: {
            facingMode: this.usingFrontCamera ? 'user' : 'environment'
          }
        };
        this.currentStream = await navigator.mediaDevices.getUserMedia(constraints);
        const videoElement = this.video.nativeElement;

        // Para dispositivos iOS Safari
        if (navigator.userAgent.match(/iPhone|iPad|iPod/)) {
          videoElement.setAttribute('playsinline', 'true'); // Esto es crucial para iOS
        }

        videoElement.srcObject = this.currentStream;
        await videoElement.play();
      } catch (error) {
        console.error('Error accessing the camera', error);
      }
    }
  }

  activate() {
    this.isActive = true;
    this.disableScroll();
    this.startCamera();
  }

  startCapturing() {
    this.capturing = true;
  }

  stopCapturing() {
    this.capturing = false;
    this.isActive = false;
    if (this.video.nativeElement.srcObject) {
      const stream = this.video.nativeElement.srcObject as MediaStream;
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
      this.video.nativeElement.srcObject = null;
    }
    this.captureCancelled.emit(this.capturedPhotos);
    this.enableScroll();
  }

  capturePhoto() {
    const videoElement = this.video.nativeElement;
    const canvas = document.createElement('canvas');
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;
    const context = canvas.getContext('2d');
    context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
    const photoDataUrl = canvas.toDataURL('image/png');
    this.capturedPhotos.push(photoDataUrl);
    this.photoCaptured.emit(photoDataUrl);
  }

  toggleCamera() {
    this.usingFrontCamera = !this.usingFrontCamera;
    this.stopCurrentStream();
    this.startCamera();
  }

  stopCurrentStream() {
    if (this.currentStream) {
      this.currentStream.getTracks().forEach(track => track.stop());
    }
  }

  removePhoto(index: number) {
    this.capturedPhotos.splice(index, 1);
  }

  disableScroll() {
    document.body.classList.add('no-scroll');
  }

  enableScroll() {
    document.body.classList.remove('no-scroll');
  }
}