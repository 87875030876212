import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService} from '../../services/chat.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-allmessege',
  templateUrl: './allmessege.component.html',
  styleUrls: ['./allmessege.component.scss']
})
export class AllmessegeComponent implements OnInit {

  public grupos:  any[] = [];
  public loadingchat = true;
  public searchFilter: any = '';
  public query: any;
  empID: any;
  myEmpId: any;

  constructor(private _chatService: ChatService,  private router: Router) { }

  ngOnInit(): void {
    this.empID = localStorage.getItem('empID');
    if(localStorage.getItem('empID') == null || localStorage.getItem('empID')== "0"){
      this.empID = 0;
    }
    this.myEmpId = this.empID == 0 ?  localStorage.getItem('usuId') : this.empID;
    this.getGrupo();
  }

  getGrupo(){
    this.loadingchat = true;
    this._chatService.getGrupo().subscribe(
      res =>{  
        this.loadingchat = false;
        this.grupos = res;
        },err =>{ 
          this.loadingchat = false;
          Swal.fire({
            icon: 'error',
            title: err.error,
            showConfirmButton: true
          });
        })
     }

}
