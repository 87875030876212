import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ProductosService, datosBuscar,Modelo } from '../../services/productos.service';
import Swal from 'sweetalert2';
import { LocationService } from '../../services/location.service';


@Component({
  selector: 'app-resultados',
  templateUrl: './resultados.component.html',
  styleUrls: ['./resultados.component.scss']
})

export class ResultadosComponent implements OnInit {

  years: number[]= [];
  marListo=true;
  catListo=true;
  idmodelo:any;
  categoria:any;
  marca:any;
  modelo:any;
  parts: any;
  resultados: any;
  result=false
  loading = false;
  noData = false;
  latitude: string;
  longitude: string;

  page = 1;
  count = 0;
  pageSize = 20;
 
  datosBuscar : datosBuscar = {

    buscar: '',
    cat: 0, 
    mo: '',
    mar: '',
    anos: '',
    freeshipping: false,
    ubicacion: '',
    pageNumber: 1
  

  };

  Modelo: Modelo ={

    idMarca : 0,
    marcastring: ""
   
  }

  constructor( private auth: ProductosService, private _locationService: LocationService) { }

  ngOnInit(): void {

       let elementReference = <HTMLOptionElement>document.getElementById('tbxBuscar');
        elementReference.focus();
       this.startup();
       this.getMarca();
       this. getCategoria();
       this.selectYear();
       this.getLocation();

  }
 
  getLocation(){

    this._locationService.getPosition().then(pos => {
      this.latitude = pos.lat;
      this.longitude = pos.lng; });

      }

  getRequestParams(page: number): any {
    let params: any = {};

    if (page) {
      params['pageNumber'] = page ;
    }
    
    return params;

  }

 selectYear(){

      var fully = new Date().getFullYear();
      for(let y = fully; y >= 1970; y--){
      this.years.push(y);
      console.log(y);
    }
    
 }

  buscar(): void{
    this.startup();
    this.result = false;
    this.loading = true;
    this.noData = false;
    if ((<HTMLInputElement>document.getElementById('CheckFreeshp')).checked)
    {
      this.datosBuscar.freeshipping= true;
    }else{
      this.datosBuscar.freeshipping= false;
    }
    if((<HTMLInputElement>document.getElementById('CheckNearby')).checked)
    {
      this.datosBuscar.ubicacion = String(this.latitude +',' + this.longitude);
    }else{
      this.datosBuscar.ubicacion = '';
    }

    this.datosBuscar.buscar = (<HTMLInputElement>document.getElementById('tbxBuscar')).value;
    if ((<HTMLOptionElement>document.getElementById('marca')).value == "null"){
      this.datosBuscar.mar="";
    } else{
      this.datosBuscar.mar= (<HTMLOptionElement>document.getElementById('marca')).value;
    }
    if ((<HTMLOptionElement>document.getElementById('modelo')).value == "null"){
      this.datosBuscar.mo="";
    } else{
      this.datosBuscar.mo= (<HTMLOptionElement>document.getElementById('modelo')).value; 
    }
    if ((<HTMLOptionElement>document.getElementById('year')).value == "null"){
      this.datosBuscar.anos="";
    }else{
      this.datosBuscar.anos= (<HTMLOptionElement>document.getElementById('year')).value; 
    }
    
    this.datosBuscar.pageNumber = this.page;
    if (this.datosBuscar.buscar == '' && this.datosBuscar.mar == '' && this.datosBuscar.mo == '' &&  this.datosBuscar.freeshipping == false && this.datosBuscar.ubicacion == ''){ 
    /*  
      this.noData = true;
       Swal.fire({
         icon: 'error',
         title: 'Oops...',
        text: 'Ingrese algun parametro de busqueda!',
       }) */
       this.loading = false;
       return;
    }else{
      this.startup();
      this.auth.getBuscar(this.datosBuscar).subscribe( ( res: any )=>{
        this.loading = false;
        this.result = true;
        const { results, totalRecordCount} = res;
        this.resultados = results;
        this.count = totalRecordCount;
        (<HTMLInputElement>document.getElementById('pDespc')).innerHTML = results.pieDescripcion;
      },(err) =>{
        this.loading = false;
        this.noData = true;
        console.log(err); 
      });
    }
  }

  getMarca(){
    this.auth.getMarca().subscribe(
      res =>{
        this.marListo=false;
        this.marca=res;
        console.log(this.marca[1])
      }, err =>{
       console.log(err);
      })
  }
  

  getCategoria(){
    this.auth.getCategoria().subscribe(
      res =>{
        this.catListo=false;
        this.categoria=res;
        console.log(res);
      }, err =>{
       console.log(err);
      })
}

cargarPart(idCat: any){
 this.auth.getPart(idCat).subscribe(
   (res: any) =>{
     this.parts =res;
     console.log(res);

   }, err =>{
    console.log(err);
   })
}

  cargarModelo(idMarca: any){
    this.Modelo.marcastring = idMarca;
    this.auth.getModelo(this.Modelo).subscribe(
      res =>{
        this.modelo=res;

      }, err =>{
       console.log(err);
      }
    )
  }

  handleCarouselEvents(event:any) {
		console.log(event);
	}

  handlePageChange(event: number): void {
    this.page = event;
    this.buscar();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.buscar();
  }

  startup(){
    window.scroll(0, 0); 

  }
  clean(){
    window.location.reload(); 

  }

}
