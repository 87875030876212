<app-navbar></app-navbar>
<app-buscar></app-buscar>
<div style="position: relative;" class="d-flex mt-3 border rounded-3">
  <img src="../../../assets/img/banner.jpg" class="fit-bg border rounded-3">
  <div  class="container p-3">
    <p class="lead p-2"></p>
    <div class="card p-3 shadow-sm" style="opacity: 0.9;  background-color: rgb(255, 255, 255);">
      <div class="d-flex align-items-center">
        <div class="image px-2"> <img class="border rounded-5 fit-image"
            src="{{infoOwner.fotoOwner}}"> </div>
        <div class="ml-3 w-100">
          <h2 class="mb-0 mt-0"> <strong>{{infoOwner.nombreOwner}}</strong> </h2> <span>
            <strong>{{infoOwner.tipoOwnerString}}</strong> </span>
          <div class="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white ">
            <div class="d-flex flex-column"> <span class="articles"> Ranking <i class="fa fa-star"></i></span> <span
                class="number1"> {{infoOwner.tipoOwnerString}}</span> </div>
            <div class="d-flex flex-column"> <span class="followers">Parts <i class="fa fa-cube"></i></span> <span
                class="number2">{{infoOwner.totalParts | number}}</span> </div>
            <div class="d-flex flex-column"> <span class="rating">Seller from</span> <span
                class="number3">{{infoOwner.dateCreateOwner | date: 'dd/MM/yyyy'}}</span> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container mt-3">
</div>

<div class="row p-5">
  <div class="d-flex justify-content-between flex-row">
      <h3 class="text-primary ml-4">Items for sale: <i class="fa fa-arrow-right"></i></h3>
      
      <div class="input-group srch_bar">
        <input id="search_im" type="text" class="form-control" placeholder="Search Parts">
        <button class="btn btn-primary text-white" type="button" (click)="handlePageChange(1)">Button</button>
      </div>
      
  </div>


  <div class="d-flex justify-content-center" *ngIf="cargar; else elseBlock">
    <div class="spinner-border text-primary  m-5" role="status" style="width: 3rem; height: 3rem;" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div *ngIf="count == 0" class="p-2 px-0 d-flex justify-content-center">
    <div class="d-flex flex-column justify-content-center">
      <h3 class="d-flex justify-content-center">Data not found.</h3>
      <img class="d-flex justify-content-center" src="../../../assets/img/phvp.png" width="200" height="200" alt="">
    </div>
  </div>
  
  <ng-template #elseBlock>
    <div
      *ngFor="let pie of ownerPie | paginate : { itemsPerPage: pageSize, currentPage: page, totalItems: count  }; let i = index "
      class="col-md-3 col-sm-6 portfolio-item my-2 rounded-lg">
      <div class="card card-pie p-3 rounded-3">
        <a [routerLink]="['/producto', pie.pieId]">
          <img style="background-image: url(../../../assets/img/VP_GRIS.png);"
            class="card-img-top fit-image-pie img-fluid" src="{{pie.pieFoto}}">
          <div class="card-img-overlay m-2 rounded-3">
            <h5><span class="badge bg-primary" *ngIf="pie.pieDisponible > 0">{{pie.pieCondicion}}</span></h5>
            <h5><span *ngIf="pie.pieDisponible <= 0" class="badge bg-danger">unavailable</span></h5>
          </div>
        </a>
        <div class="card-body d-inline-block text-truncate">
          <h4 class="card-title ">{{pie.pieNombre}}</h4>
          <p class="card-text" style="font-size: 25px;">{{pie.piePrecio | currency}}</p>
        </div>
      </div>
    </div>
  </ng-template>
  <style>
    .my-pagination /deep/ .ngx-pagination .current {
      background: #008c44;
    }
  </style>
  <div class="col-12 mt-3 d-flex justify-content-center">
    <pagination-controls class="my-pagination" previousLabel="Prev" nextLabel="Next" [responsive]="true"
      (pageChange)="handlePageChange($event)">
    </pagination-controls>
  </div>
</div>