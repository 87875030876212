<nav class="navbar navbar-expand-lg mb-3 navbar-light bg-light shadow-sm p-3 mb-5 bg-white rounded fixed-top">
  <div class="container-fluid">
    <a class="navColor navbar-brand hvr-grow" href="#"> <img src="/assets/img/LogovehiclesParts.jpg" alt="" width="50"
        height="50" class="mr-2 "> Vehicle Parts</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
      style="color: #008c44;"><span class="navColor navbar-toggler-icon"></span></button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <!--  <li class="nav-item"><a class="navColor nav-link active" aria-current="page" href="#"><i class="fa fa-search"></i> Buscar</a></li> -->
        <!-- <li class="nav-item"><a class="navColor nav-link active" aria-current="page" href="#"><i class="fa fa-home" aria-hidden="true"></i> Sobre Nosotros</a></li> -->
        <li class="nav-item"><a class="navColor nav-link" href="#" data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" *ngIf="isAdmin == false"><i
              class="fa fa-shopping-cart" aria-hidden="true"></i> Shopping cart <span
              class="badge bg-secondary">{{totalItems}}</span> </a></li>
        <li class="nav-item"><a class="navColor nav-link" routerLink="/Dashboard" *ngIf="isAdmin == true"><i
              class="fa fa-tachometer"></i> Dashboard</a></li>
        <li class="nav-item"><a class="navColor nav-link" href="#" data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasStore" aria-controls="offcanvasStore" *ngIf="isAdmin == true"><i
              class="fa fa-shopping-cart" aria-hidden="true"></i> Store <span
              class="badge bg-secondary">{{totalItemsStore}}</span> </a></li>
        <li class="nav-item"><a class="navColor nav-link" *ngIf="token != undefined"><i class="fa fa-bell-o"
              aria-hidden="true"></i></a></li>
        <li class="nav-item dropdown" *ngIf="token">
          <a class="navColor nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button"
            data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-paper-plane"></i>
            <span [hidden]="!Badges"
              class="position-absolute top-0 start-0  p-2 bg-danger border border-light rounded-circle">
              <span
                class="position-absolute top-0 start-0 translate-middle m-2 badge rounded-pill bg-danger">{{countNtf}}</span>
            </span>
          </a>

          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
            <li class="chat_list active_chat" *ngFor="let grupo of grupos;">
              <a [routerLink]="['/chat', grupo.pieId, 0, grupo.pieNombre, grupo.pieFoto, grupo.menGrupId]">
                <div class="d-flex align-items-center flex-row chat_people py-1">
                  <img src="{{grupo.pieFoto}}" class="chat_img rounded-circle border border-primary" alt="sunil">
                  <div class="chat_ib">
                    <h5>{{grupo.pieNombre}}</h5>
                    <p class="mensaje"> {{grupo.lastMensaje}}</p>
                    <p class="date"> {{grupo.menGrupLastDate | date: 'shortTime' }} | {{grupo.menGrupLastDate | date:
                      'fullDate'}}</p>
                  </div>

                  <div>
                    <div class="navColor" *ngIf="grupo.menGrupLeido == false && grupo.lastTo == myEmpId"><i
                        class="fa fa-circle"></i></div>
                    <div class="navColor" *ngIf="grupo.menGrupLeido && grupo.lastTo != myEmpId" class="d-flex flex-row">
                      <i class="fa fa-check" aria-hidden="true"></i><i class="fa fa-check" aria-hidden="true"></i></div>
                    <div class="navColor" *ngIf="grupo.menGrupLeido == false && grupo.lastTo != myEmpId"><i
                        class="fa fa-check" aria-hidden="true"></i></div>
                  </div>

                </div>
              </a>

            </li>
            <li><a class="navColor dropdown-item text-center" [routerLink]="['/allmessege']"><i
                  class="fa fa-th-list"></i> All my messages</a></li>
          </ul>
          <!-- <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                      <li *ngFor="let grupo of grupos;" >
                        <a  class="dropdown-item" [routerLink]="['/chat', grupo.pieId, 0, grupo.pieNombre, grupo.pieFoto, grupo.menGrupId]">
                          <div class="d-flex align-items-center flex-row">
                            <div class="py-2 mt-1  pe-2 bd-highlight">
                              <img class="fit-image1 rounded-circle border border-primary"  src="{{grupo.pieFoto}}" alt="" >
                            </div>   
                            <div class="chat_ib py-3 bd-highlight text-black">
                             <span class="nombre"> {{grupo.pieNombre}} </span><br>
                             <span class="mensaje">{{grupo.lastMensaje}}</span><br>
                            <span class="date">{{grupo.menGrupLastDate | date: 'fullDate'}}</span>
                            </div>    
                              <div style="color: #008c44;"  *ngIf="grupo.menGrupLeido == false && grupo.lastTo == myEmpId" class="mt-4 bd-highlight"><i class="fa fa-circle"></i></div>
                              <div style="color: #008c44;"  *ngIf="grupo.menGrupLeido && grupo.lastTo != myEmpId" class="mt-4 bd-highlight"><i class="fa fa-check" aria-hidden="true"></i><i class="fa fa-check" aria-hidden="true"></i></div>
                              <div style="color: #008c44;"  *ngIf="grupo.menGrupLeido == false && grupo.lastTo != myEmpId" class="mt-4 bd-highlight"><i class="fa fa-check" aria-hidden="true"></i></div>
                            </div>                          
                        </a>
                      </li>
                      <li><a class="navColor dropdown-item text-center" [routerLink]="['/allmessege']"><i class="fa fa-th-list"></i> All my messages</a></li>
                     </ul> -->
        </li>
        <li class="nav-item dropdown" *ngIf="token != undefined; else nologin">
          <a class="navColor nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button"
            data-bs-toggle="dropdown" aria-expanded="false">

            <img *ngIf="token != undefined" src="{{usuFoto}}" class="rounded-circle border border-primary" alt="User"
              width="30" height="30">
          </a>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
            <li [hidden]="!empID"><a class="navColor dropdown-item" routerLink="/Dashboard"><i
                  class="fa  fa-tachometer"></i> Dashboard</a></li>
            <li><a class="navColor dropdown-item" routerLink="/profile"><i class="fa fa-address-card"></i> Profile</a>
            </li>
            <li><a class="navColor dropdown-item" routerLink="/guardados"><i class="fa fa-heart"></i> Saved parts</a>
            </li>
            <li><a class="navColor dropdown-item" routerLink="/invoices"><i class="fa fa-file-text"></i> Invoices</a>
            </li>
            <li><a class="navColor dropdown-item" routerLink="/myorders"><i class="fa fa-cube"></i> Orders</a></li>
            <!--Settings Option-->
            <li> <a class="navColor  nav-item dropdown dropdown-toggle dropdown-item" id="settingBarDropdown"
                role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fa fa-cog"></i> Settings</a>
              <ul class="settings-dropdown-menu" id="settingsDropdown" aria-labelledby="settingBarDropdown">
                <!-- <li><a class="navColor dropdown-item" routerLink="/policy"><i class="fa fa-file-text"></i> Privacy
                    Policy</a></li> -->
                <li><a class="navColor dropdown-item" href="https://legal.vehiclepart.shop/"><i class="fa fa-lock"></i> Privacy Center</a></li>
                <li><a class="navColor dropdown-item" routerLink="/support"><i class="fa fa-question"></i> Contact
                    Support</a></li>
              </ul>
            </li>

            <hr>
            <li><a class="navColor dropdown-item" (click)="logout()"> <i class="fa fa-sign-out" aria-hidden="true"></i>
                Log out</a></li>
          </ul>
        </li>
        <!-- <li *ngIf="token != undefined">
                    <a [routerLink]="['/profile']">
                      <div class="d-flex justify-content-center">
                        <img src="{{usuFoto}}" class="rounded-circle border border-primary" alt="Cinque Terre" width="40" height="40" >
                    </div>
                    </a> 
                   </li> -->
        <ng-template #nologin>
          <li class="nav-item dropdown">
            <a class="navColor nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
              aria-expanded="false"><i class="fa fa-user-o" aria-hidden="true"></i> My account</a>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
              <li><a class="navColor dropdown-item" [routerLink]="['/login']"> <i class="fa fa-sign-out"
                    aria-hidden="true"></i>Log in</a></li>
            </ul>
          </li>
        </ng-template>
      </ul>
    </div>
  </div>
</nav>

<div class="container">
  <div class="offcanvas offcanvas-end" tabindex="-1" data-bs-toggle="offcanvas" id="offcanvasRight"
    aria-labelledby="offcanvasRight Label">
    <div class="offcanvas-header">
      <a [routerLink]="['/carritof']">
        <h3 id="offcanvasRightLabel" data-bs-dismiss="offcanvas" style="color: #008c44;">My Shopping cart</h3>
      </a>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <div class="row mt-2 ml-2 mr-2 mb-2">
        <h4 class=" text-left font-weight-light"><strong>SubTotal: {{ subTotal | currency }}</strong></h4>
        <h6 class=" text-left font-weight-light"><strong>Total parts: {{totalItems}}</strong></h6>
      </div>
      <div *ngIf="!carEmpty; else elseBlock3">
        <img src="../../../assets/img/empty.jpg" width="350" height="350" alt="">
        <h1 class="text-center">Empty cart</h1>
      </div>
      <ng-template #elseBlock3>
        <div class="row">
          <div class="card mb-3" *ngFor="let producto of productos; let i = index">
            <div class="row g-0">
              <div class="col-md-4 col-4">
                <img src="{{producto.pedImagen}}" class="ml-2 mt-2 fit-image img-fluid rounded-start" alt="...">
                <div class="card-img-overlay text-end">
                  <a href="#" class="btn btn-danger" (click)="borrarItem(producto)"><i class="fa fa-trash"></i></a> <br>
                  <a href="#" class="btn btn-info mt-2 text-white" [routerLink]="['/producto', producto.pieID]"><i
                      class="fa fa-eye"></i></a>
                </div>
              </div>
              <div class="col-md-8 col-8">
                <div class="card-body">
                  <h5 class="card-title text-left resumen-text">{{producto.proNombre}}</h5>
                  <h5 class=" text-left font-weight-light"><strong style="color: #008c44;">{{producto.pedPrecio |
                      currency }}</strong></h5>
                  <h6 class=" text-left font-weight-light">Quantity: {{producto.pedCantidad}}</h6>
                  <p class="text-left"><small class="text-muted">SubTotal: {{producto.pedMontoTotal | currency
                      }}</small></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

    </div>
  </div>


  <div class="offcanvas offcanvas-end" tabindex="-1" data-bs-toggle="offcanvas" id="offcanvasStore"
  aria-labelledby="offcanvasStore Label">
  <div class="offcanvas-header">
    <a [routerLink]="['/Dashboard/storeSales']"  [queryParams]="{ routeId: 0 }"class="detalleTempDes">
      <h3 id="offcanvasStoreLabel" data-bs-dismiss="offcanvas" style="color: #008c44;">Store Cart</h3>
    </a>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div class="row mt-2 ml-2 mr-2 mb-2">
      <h4 class=" text-left font-weight-light"><strong>SubTotal: {{ subTotalTemp | currency }}</strong></h4>
      <h6 class=" text-left font-weight-light"><strong>Total parts: {{totalItemsStore}}</strong></h6>
    </div>
    <div *ngIf="!carEmpty;">
      <img src="../../../assets/img/empty.jpg" width="350" height="350" alt="">
      <h1 class="text-center">Empty cart</h1>
    </div>
    <div *ngIf="carEmpty;" class="row">
      <div class="card mb-3" *ngFor="let producto of detalleTemp; let i = index">
        <a class="row g-0 detalleTempDes" routerLink="/Dashboard/storeSales" [queryParams]="{ routeId: producto.pieID }">
          <div class="col-md-4 col-4">
            <img src="{{producto.proImagen}}" class="ml-2 mt-2 fit-image img-fluid rounded-start" alt="...">
          </div>
          <div class="col-md-8 col-8">
            <div class="card-body">
              <h5 class="card-title text-left resumen-text">{{producto.proNombre}}</h5>
              <h5 class=" text-left font-weight-light"><strong style="color: #008c44;">{{producto.facPrecio |
                  currency }}</strong></h5>
              <h6 class=" text-left font-weight-light">Quantity: {{producto.facCantidad}}</h6>
              <p class="text-left"><small class="text-muted">SubTotal: {{producto.facSubMontoTotal | currency
                  }}</small></p>
            </div>
          </div>
        </a>

        <div class="card-img-overlay1 text-end">
          <a class="btn btn-danger" (click)="borrarFacturaTemp(producto)"><i class="fa fa-trash"></i></a> <br>
        </div>
      </div>
    </div>

  </div>
</div>
</div>