import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { LocationService } from '../../../services/location.service';
import { ProductosService, datosPieza } from '../../../services/productos.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AdminService } from '../../../services/admin.service';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { BehaviorSubject } from 'rxjs';
import { BarcodeFormat } from '@zxing/library';

@Component({
  selector: 'app-tires-editar',
  templateUrl: './tires-editar.component.html',
  styleUrls: ['./tires-editar.component.scss']
})
export class TiresEditarComponent implements OnInit {

  @ViewChild('scanner', { static: false })
  scanner: ZXingScannerComponent;
  
    //scam
    availableDevices: MediaDeviceInfo[] = [];
    deviceCurrent: MediaDeviceInfo;
    deviceSelected: string;
    formatsEnabled: BarcodeFormat[] = [
      BarcodeFormat.CODE_128,
      BarcodeFormat.DATA_MATRIX,
      BarcodeFormat.EAN_13,
      BarcodeFormat.QR_CODE,
    ];
    hasDevices: boolean;
    hasPermission: boolean;
    torchEnabled = false;
    torchAvailable$ = new BehaviorSubject<boolean>(false);

    isStatusPost = false;

    currentStatus: any[] = [];
    currentCondition: any[] = [];
    currentWarranty: any[] = [];
    
    PWidth = "";
    PHeight ="";
    PRing= "";
  
    //public producto: any;

    conditions: any[] = [];
    warranty: any[] = [];
    status: any[] = [];
  
    isAdmin = false;
    loadingup = false;
  
    pieza: FormGroup;
    urls: string[];
    postUrl = new Array<string>();
    latitude: string;
    longitude: string;
  
    imgResultBeforeCompress: string;
    imgResultAfterCompress: string
  
  
    datosPieza: datosPieza = {
      PieId: 0,
      CarId: 0,
      PieUsuID: 0,
      PieNombre: '',
      PieDescripcion: '',
      PiePrecio: 0,
      PieCosto: 0,
      PieFechaCreacion: new Date(),
      PieFechaActualizacion: new Date(),
      CatId: 0,
      ModelId: 0,
      EmpId: 0,
      PieEstado: 0,
      PieDisponible: 0,
      PieLocation: '',
      PieCondicion: '',
      PieVIN: '',
      PieCostoEnvio: 0,
      PieSeñalUso: '',
      PieLatitud: '',
      PieLongitud: '',
      PieMarcaFabricacion: '',
      PieGarantia: '',
      PieTipoProducto: 0,
      PieTipoProductoString: '',
      UsuId: 0,
      PieFoto: '',
      CategoriaString: '',
      ModeloString: '',
      MarcaString: '',
      MarId: 0,
      EstadoString: '',
      MainCarString: '',
      CarFoto: '',
      anoCarro: '',
      PiePeso: 0,
      PieImagenes: [],
      PieTipoIntegracion: 0,
      PieIntegracionReferencia: '',
      PieIntegracionReferencia2: '',
      PieIntegracionReferencia3: '',
      PieColor: '',
      PieMaterial: '',
      PiePlacementonVehicle: '',
      PieFinish: '',
      PieItemsIncluded: '',
      PieFeatures: '',
      PiePartType: '',
      PtypeDes: '',
      PFeaturesDes: '',
      PFinishDes: '',
      PItemsInDes: '',
      PMaterialDes: '',
      PPlacementDes: ''
    }
  
    htmlContent = '';
    config: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: '200px',
      sanitize: false,
      minHeight: '5rem',
  
      placeholder: 'Tire description',
      defaultParagraphSeparator: 'p',
      defaultFontName: 'Arial',
      customClasses: [
        {
          name: 'Quote',
          class: 'quoteClass',
        },
        {
          name: 'Title Heading',
          class: 'titleHead',
          tag: 'h1',
        },
      ],
      toolbarHiddenButtons: [
        [
        ],
        [
          'customClasses',
          'insertImage',
          'insertVideo',
          'insertHorizontalRule',
          'removeFormat',
          'toggleEditorMode'
        ]
      ]
    };

    
  constructor(private formBuilder: FormBuilder,
    private _productosService: ProductosService,
    private _locationService: LocationService,
    private location: Location,
    private _adminService: AdminService,
    private _router: Router,
    private _route: ActivatedRoute) { }

  ngOnInit(): void {

    this.pieza = this.formBuilder.group({
      conditionf: this.currentCondition,
      warrantyf: this.currentWarranty,
      statusf: this.currentStatus,
      htmlContent: this.htmlContent
    });

    this._route.params.subscribe(params => {
      let id = params.id;
      this.getProducto(id);
    });


    if (localStorage.getItem('empID') == null || localStorage.getItem('empID') == "0") {
      this.isAdmin = false;
    }
    else {
      this.isAdmin = true;
    }
  }


  goBack(): void {
    this.location.back();
  }

  verImagen(img): void {
    Swal.fire({
      imageUrl: img,
      imageWidth: '100%',
      imageHeight: '100%',
    })
  }


  detectFiles(event: any) {
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.urls.push(e.target.result);
        }
        reader.readAsDataURL(file);
      }
    }
    //(this.urls);
  }

  removeItemFromArr(item, ask = false) {
    if (item.includes("http")) {
      Swal.fire({
        title: 'Are you sure? this cannot be returned',
        showDenyButton: true,
        showCancelButton: true,
        denyButtonText: 'Yes, delete it',
        showConfirmButton: false
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isDenied) {
          this.loadingup = true;
          this._adminService.deleteProIMG(item).subscribe(
            res => {
              this.removeLocalImg(item);
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Done',
                showConfirmButton: false,
                timer: 1500
              })
              this.loadingup = false;
            }, err => {
              console.log(err);
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: err.error,
                showConfirmButton: true
              })
              this.loadingup = false;
            });
        }
      })
    }
    else {
      this.removeLocalImg(item);
    }
  }

  removeLocalImg(item) {
    var arr = this.urls;
    var i = arr.indexOf(item);
    if (i !== -1) {
      arr.splice(i, 1);
    }
    //console.log(arr);
    arr = this.postUrl;
  }

  chooseFile() {
    document.getElementById("fileInput").click();
  }



  getLocation() {
    this._locationService.getPosition().then(pos => {
      this.latitude = String(pos.lat);
      this.longitude = String(pos.lng);
    });
  }

  getProducto(id: any) {
    this.loadingup=true;
    this._productosService.getMiProducto(id).subscribe(
      response => {
        //this.producto = response;

        this.datosPieza.PieId =response.pieId;
        this.datosPieza.PieUsuID =response.pieUsuID;
        this.datosPieza.PieNombre=response.pieNombre;
        this.datosPieza.PieDescripcion=response.pieDescripcion;
        this.datosPieza.PiePrecio=response.piePrecio;
        this.datosPieza.PieCosto=response.pieCosto;
        this.datosPieza.CatId=response.catId;
        this.datosPieza.EmpId=response.empId;
        this.datosPieza.PieEstado=response.pieEstado;
        this.datosPieza.PieDisponible=response.pieDisponible
        this.datosPieza.PieLocation=response.pieLocation;
        this.datosPieza.PieCondicion=response.pieCondicion;
        this.datosPieza.PieVIN=response.pieVIN;
        this.datosPieza.PieCostoEnvio=response.pieCostoEnvio;
        this.datosPieza.PieSeñalUso=response.pieSeñalUso;
        this.datosPieza.PieLatitud=response.pieLatitud;
        this.datosPieza.PieLongitud=response.pieLongitud;
        this.datosPieza.PieMarcaFabricacion=response.pieMarcaFabricacion;
        this.datosPieza.PieGarantia=response.pieGarantia;
        this.datosPieza.PieFoto=response.pieFoto;
        this.datosPieza.PiePeso=response.piePeso;
        this.datosPieza.PieImagenes = response.pieImagenes;
        this.datosPieza.PieTipoProducto = response.pieTipoProducto;


        this.PWidth = response.pieNombre.split('/')[0];
        this.PHeight = response.pieNombre.split('/')[1];
        this.PRing = response.pieNombre.split('/')[2];

        console.log(response);
        this.urls = this.datosPieza.PieImagenes;
        this.htmlContent = response.pieDescripcion;

        this.getLocation();
        this.getStatus();
        this.getUsosMultiples("Condition");
        this.getUsosMultiples("Warranty");

        this.loadingup = false;
      },
      error => {
        this.loadingup = false;
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: error.error,
          showConfirmButton: true
        })
        console.log(<any>error);
      })
  }

  UnSelectStatus(item: any) {
    this.isStatusPost = false;
  }

  getStatus() {
    this._productosService.getStatus("Piezas").subscribe(
      (res: any) => {
        this.status = res;
        this.currentStatus = res.filter((element) => element.estEstado == this.datosPieza.PieEstado);
      }, err => {
        console.log(err);
      })

  }

  SelectStatus(item: any) {
    if (item.estDescripcion == "Posted") {
        this.isStatusPost = true;
    }
    else{
      this.isStatusPost = false;
    }
      console.log(this.isStatusPost);
      console.log(this.isAdmin);
      console.log(this.datosPieza.PieTipoIntegracion);
  }

  getUsosMultiples(grupo: string): any {
    this._adminService.getUsosMultiples(grupo).subscribe(
      (res: any) => {

        if (grupo == "Condition") {
          this.conditions = res;
          this.currentCondition = res.filter((element) => element.usoDescripcion == this.datosPieza.PieCondicion);
        }

        else if (grupo == "Warranty") {
          this.warranty = res;
          this.currentWarranty = res.filter((element) => element.usoDescripcion == this.datosPieza.PieGarantia);
        }

      }, err => {
        console.log(err);
      })

  }

  getsenalUso(producto: any): any {
    return producto['PieSeñalUso'];
  }

  OpenCamera() {
    this.torchEnabled = true;
    (<HTMLAnchorElement>document.getElementById("openCamera_btn")).click();
    console.log("open");   
  }

  closeCamera() {
    this.torchEnabled = false;
    (<HTMLAnchorElement>document.getElementById("closeCamera_btn")).click();
    console.log("close");  
  }
  
  onCamerasFound(devices: any): void {
    console.log(devices)
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);

    if(this.hasDevices == false){
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Unsupported device',
        showConfirmButton: true
      }).then(() =>{
        this.closeCamera()
      })
    }
  }

  onCodeResult(resultString: any) {
    this.datosPieza.PieLocation = resultString
    this.closeCamera();
  }

  onDeviceSelectChange() {
    if( this.availableDevices.length > 1){
      const device = this.availableDevices.filter(x => x.deviceId != this.deviceCurrent.deviceId)[0];
      this.deviceCurrent = device || undefined;
    }
  }

  onDeviceChange(device: any) {
    const selectedStr = device?.deviceId || '';
    if (this.deviceSelected === selectedStr) { return; }
    this.deviceSelected = selectedStr;
    this.deviceCurrent = device || undefined;
  }


  onHasPermission(has: any) {
    this.hasPermission = has;
    console.log(has)
    if(has == false){
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'We do not have permission to open the camera',
        showConfirmButton: true
      }).then(() =>{
        this.closeCamera()
      })
    }
  }

  onTorchCompatible(isCompatible: any): void {
    this.torchAvailable$.next(isCompatible || false);
    console.log("compati"+isCompatible)
  }


  Submit() {

    try {
      var loa = Swal.fire({
        title: 'Loading',
        showConfirmButton: false,
        allowOutsideClick: false,
        html: 'Please wait',

        timerProgressBar: true,
        didOpen: () => {
          //Swal.showLoading()
        },
        willClose: () => {

        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          console.log('I was closed by the timer')
        }
      })

      this.loadingup = true;
      this.datosPieza.PieLatitud = this.latitude;
      this.datosPieza.PieLongitud = this.longitude;

      var imagenes = [];
      this.urls.forEach((element) => {
        if (!element.includes("http")) {
          imagenes.push(element);
        }
      })
      this.datosPieza.PieImagenes = imagenes;
      //this.datosPieza.CarId = this.producto.carId;

      var nombreWidth = String((<HTMLInputElement>document.getElementById('PieNombreWidth')).value);
      if (nombreWidth == "") {
        throw new TypeError("Tire Width is required");
      }

      var nombreHeight = String((<HTMLInputElement>document.getElementById('PieNombreHeight')).value);
      if (nombreHeight == "") {
        throw new TypeError("Tire Height is required");
      }

      var nombreRing = String((<HTMLInputElement>document.getElementById('PieNombreRing')).value);
      if (nombreRing == "") {
        throw new TypeError("Tire Ring size is required");
      }


      this.datosPieza.PieNombre = `${nombreWidth}/${nombreHeight}/${nombreRing}`;

      this.datosPieza.PieVIN = String((<HTMLInputElement>document.getElementById('PartNumber')).value);
      this.datosPieza.PieDescripcion = this.htmlContent;


      this.datosPieza.pieAnoHasta = 0;
      this.datosPieza.pieAnoDesde = 0;
      this.datosPieza.MarcaID = null;
      this.datosPieza.MarId = null;
      this.datosPieza.ModelId = null;
      this.datosPieza.CatId = 14;

      //this.datosPieza.PieId;


      if (this.currentStatus.length == 0) {
        throw new TypeError("Select status of the part");
      } else {
        this.datosPieza.PieEstado = this.currentStatus[0].estEstado;
      }
      this.datosPieza.PiePrecio = Number((<HTMLInputElement>document.getElementById('PiePrecio')).value);

      this.datosPieza.PieCosto = Number((<HTMLInputElement>document.getElementById('pieCosto')).value);
      this.datosPieza.PieDisponible = Number((<HTMLInputElement>document.getElementById('PieDisponible')).value);
      this.datosPieza.PieLocation = String((<HTMLInputElement>document.getElementById('pieLocation')).value);
      if (this.currentCondition.length == 0) {
        throw new TypeError("Select the condition of the part");
      } else {
        this.datosPieza.PieCondicion = this.currentCondition[0].usoDescripcion;
      }


      this.datosPieza.PieMarcaFabricacion = String((<HTMLOptionElement>document.getElementById('pieMarcaFabricacion')).value);
      this.datosPieza.PieSeñalUso = String((<HTMLOptionElement>document.getElementById('pieSeñalUso')).value);
      if (this.currentWarranty.length == 0) {
        this.datosPieza.PieGarantia = "";
      } else {
        this.datosPieza.PieGarantia = this.currentWarranty[0].usoDescripcion;
      }


      this.datosPieza.PiePartType = null;
      this.datosPieza.PiePlacementonVehicle = null;
      this.datosPieza.PiePlacementonVehicle = null;
      this.datosPieza.PieMaterial = null;
      this.datosPieza.PieFinish = null;
      this.datosPieza.PieItemsIncluded = null;
      this.datosPieza.PieFeatures = null;
      this.datosPieza.CarId = 0;
      this.datosPieza.PieTipoIntegracion = 0;


      console.log(this.datosPieza)

      this._productosService.updatepie(this.datosPieza).subscribe(
        res => {
          this.loadingup = false;
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Success',
            showConfirmButton: true,
          }).then(e => {
            this._router.navigateByUrl('/Dashboard/tires');

          })
        }, err => {
          console.error(err);
          this.loadingup = false;
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: err.error,
            showConfirmButton: true
          })
        })

    } catch (error) {
      console.error(error);
      this.loadingup = false;
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: error.message,
        showConfirmButton: true
      })

    }
  }

}
