<app-navbar></app-navbar>
<div class="p-5">
    <div>
        <h2 class="mt-4 mb-3">My invoices</h2>
        </div>
        
        <div *ngIf="loading; else cont" class="row p-5">
            <div class="d-flex justify-content-center">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
          <ng-template #cont>
            <div  *ngIf="noData;else invoices" class="row p-5">
              <div class="d-flex justify-content-center">
                <h3>
                  <span> oh! You have no invoices.</span>
                </h3>
                <img src="../../../assets/img/phvp.png" width="500" height="500" alt="">
              </div>
            </div>
            <ng-template #invoices>
              <table class="table table-hover">
                <thead>
                    <tr>
                      <th scope="col"># Invoice Number</th>
                      <th scope="col">Total</th>
                      <th scope="col">Delivery date</th>
                      <th scope="col">view</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let factura of facturas; let i = index"> 
                      <th scope="row">{{factura.facID}}</th>
                      <td>{{factura.facTotal}}</td>
                      <td>{{factura.facFecha}}</td>
                    <td>  <button (click)="Reportefactura(factura.facID)" class="btn btn-info mt-2 text-white"><i class="fa fa-eye"></i></button></td> 
                    </tr>  
                  </tbody>
              </table>
            </ng-template>
          </ng-template>  
</div>

