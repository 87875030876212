<app-navbar></app-navbar>
<div class="row p-3">
    <div class="d-flex align-items-center">
        <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
            aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/Dashboard/Companies">Companies</a></li>
                <li class="breadcrumb-item active" aria-current="page">Add Company </li>
            </ol>
        </nav>
    </div>

    <h1>Add Companies</h1>
    <hr>

    <form class="row g-10">
        <div class="col-md-12 ">
            <h4 class="text-primary">Company Logo:</h4>
        </div>
        <div class="col-12">
            <div class="row p-2">
                <div class="card border border-light col-lg-3 col-md-4 p-2" *ngFor="let url of urls">
                    <img class="img-fluid  fit-image" [src]="url" alt="">
                    <div class="card-img-overlay text-cend">
                        <a (click)="removeItemFromArr(url)" class="btn btn-danger"><i class="fa fa-trash"></i></a>
                    </div>
                </div>
            </div>

            <button type="button" class="btn  btn-info text-white" (click)="chooseFile()">choose file <i
                    class="fa fa-image"></i></button>
            <input resize-quality="0.7" resize-type="image/jpg" id="fileInput" name="fileInput"
                style="height:0px;overflow:hidden" type="file" multiple accept="image/png,image/jpeg"
                (change)="detectFiles($event)">
            <div class="invalid-feedback">
                <div> Please enter at least one Image.</div>
            </div>
        </div>

        <div class="col-md- mt-5">
            <h3>General information</h3>
            <hr>
        </div>
        <div class="col-12">
        </div>
        <div class="col-md-3">
            <label for="inputEmail4" class="form-label">Company Name <span style="color: red;">*</span></label>
            <input type="text" class="form-control" id="empName" placeholder="CompanyName"
                aria-label="Recipient's username" aria-describedby="button-addon2">
        </div>

        <div class="col-md-3">
            <label for="inputEmail4" class="form-label">Email <span style="color: red;">*</span></label>
            <input placeholder="Email" type="text" id="empEmail" class="form-control">
        </div>


        <div class="col-md-2">
            <label for="inputEmail4" class="form-label">Phone <span style="color: red;">*</span></label>
            <input placeholder="Phone" type="number" id="empPhone" class="form-control">
        </div>
        <div class="col-md-2" style="position: relative; ">
            <label for="inputEmail4" class="form-label">Fax</label>
            <input placeholder="Fax" type="number" id="empFax" class="form-control">
        </div>

        <div class="col-md-2" style="position: relative; ">
            <label for="inputState" class="form-label">Country <span style="color: red;">*</span></label>
            <select class="form-select" aria-label="Default select example">
                <option [value]="1" selected>United States</option>
            </select>
        </div>
        <div class="col-md-3">
            <label for="inputEmail4" class="form-label">Street Address <span style="color: red;">*</span></label>
            <input placeholder="" type="text" id="empAddress" class="form-control">
        </div>

        <div class="col-md-3">
            <label for="inputEmail4" class="form-label">Street Address 2</label>
            <input placeholder="" type="text" id="empAddress2" class="form-control">
        </div>

        <div class="col-md-2">
            <label for="inputEmail4" class="form-label">City <span style="color: red;">*</span></label>
            <input placeholder="" type="text" id="empCity" class="form-control">
        </div>

        <div class="col-md-2">
            <label for="inputEmail4" class="form-label">State <span style="color: red;">*</span></label>
            <input placeholder="" type="text" id="empState" class="form-control">
        </div>

        <div class="col-md-2">
            <label for="inputEmail4" class="form-label">Zip Code <span style="color: red;">*</span></label>
            <input placeholder="" type="number" id="empZipCode" class="form-control">
        </div>

        <hr class="mt-3">
        <div class="d-flex flex-row-reverse bd-highlight p--0  mt-4">

            <div class="p-2 bd-highlight">
                <button type="submit" (click)="Submit()"
                    class="btn btn-primary btn-lg text-white">Save<i class="fa fa-arrow-up"></i>
                </button>
            </div>
            <div class="p-2 bd-highlight">
                <button class="btn btn-danger btn-lg text-white" routerLink="/Dashboard/Companies">
                    <i class="fa fa-times"></i> Cancel</button>
            </div>

        </div>

    </form>

    <div class="d-flex justify-content-center Divloader" *ngIf="loading">
        <div class="spinner-border  m-3" role="status" role="status">
        <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>