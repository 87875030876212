import { Component, OnInit } from '@angular/core';
import { ProductosService} from '../../services/productos.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-ordenes',
  templateUrl: './ordenes.component.html',
  styleUrls: ['./ordenes.component.scss']
})
export class OrdenesComponent implements OnInit {

   ordenes: any;
   result=false;
   loading = false;
   noData = false;

  constructor( private _producto: ProductosService, private _router : Router) { }

  ngOnInit(): void {

      this.getOrdenes();
  }

getOrdenes(){
  this.loading = true;
 this._producto.MisOrdenes().subscribe(
   res=>{
    this.loading = false;
       this.ordenes = res;
       if (this.ordenes.length  == 0){
        this.noData = true;
       }
       console.log(res);
   }, err =>{
    if (err.status == 401) {
      // window.localStorage.removeItem('token');
      this._router.navigateByUrl('/Relogin')
    }
        this.loading = false;
        this.noData = true;
       console.log(err);
   })
}

}
