import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AdminService, Rol, Permisos } from '../../../services/admin.service'
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-roles-editar',
  templateUrl: './roles-editar.component.html',
  styleUrls: ['./roles-editar.component.scss']
})
export class RolesEditarComponent implements OnInit {

  rolePermisos: any[] = [];
  currenPermisos: any[] = [];
  estListo = true;
  loading = false;
  myForm: FormGroup;

  currentRol: Rol = {
    rolPermisos: [],
    rolDescripcion: '',
    rolId: 0
  };

  permisoAdd: Permisos = {
    permisoString: '',
    rolId:  0,
    perID: 0,

  };

  constructor(
    private _fb: FormBuilder,
    private service: AdminService,
    private _router: Router,
    private _activeRoute: ActivatedRoute
  ) { }


  ngOnInit(): void {
    this.myForm = this._fb.group({
      permisosS: this.currenPermisos,
    });
    this._activeRoute.params.subscribe(params => {
      this.getCurrentRol(params.id);
    });
  }

  getPermisos() {
    this.loading = true;
    this.service.getPermisos().subscribe(
      (res) => {
        //this.rolePermisos = res;      
        res.forEach((item) => {

          if(!this.currentRol.rolPermisos.some((x) => x.perID == item.perID)){
            this.rolePermisos.push(item)
          }

        });

        this.estListo = false;
        this.loading = false;

        console.log(res);
      },
      (err) => {
        if (err.error.includes('You do not have permissions to access to ')) {
          Swal.fire({
            icon: 'error',
            title: err.error,
            showConfirmButton: true
          }).then(() => {
            this._router.navigateByUrl('/Dashboard');
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: err.error,
            showConfirmButton: true
          })
        }

        this.loading = false;
        console.error(err.error);
      }
    );
  }

  getCurrentRol(id) {
    this.loading = true;
    this.service.getRoleID(id).subscribe(
      (res) => {
        this.currentRol = res;
        this.loading = false;
        console.log(res);
        this.getPermisos();
      },
      (err) => {
        if (err.error.includes('You do not have permissions to access to ')) {
          Swal.fire({
            icon: 'error',
            title: err.error,
            showConfirmButton: true
          }).then(() => {
            this._router.navigateByUrl('/Dashboard');
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: err.error,
            showConfirmButton: true
          })
        }

        this.loading = false;
        console.error(err.error);
      }
    );
  }

  borrarRolPermiso(eliminarPermiso: any) {
    Swal.fire({
      title: 'Are you sure you want to remove this permission?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Remove it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true;

        this.service.borrarRolPermiso(eliminarPermiso).subscribe(
          (res) => {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Permission has been deleted.',
              showConfirmButton: false,
              timer:1500
            }).then((result) => {
              window.location.reload();
            });

          },
          (err) => {
            if (err.error.includes('You do not have permissions to access to ')) {
              Swal.fire({
                icon: 'error',
                title: err.error,
                showConfirmButton: true
              }).then(() => {
                this._router.navigateByUrl('/Dashboard');
              })
            } else {
              Swal.fire({
                icon: 'error',
                title: err.error,
                showConfirmButton: true
              })
            }
            this.loading = false;
            console.error(err);
          }
        );
      }
    })

  }

  addRolPermiso() {
    console.log(this.currenPermisos[0])

    if (this.currenPermisos.length > 0) {
      Swal.fire({
        title: 'Are you sure you want to add this permission?',
        text: "Add permission",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Add it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;

          this.permisoAdd.perID = this.currenPermisos[0].perID;
          this.permisoAdd.rolId = this.currentRol.rolId
          this.permisoAdd.permisoString = this.currenPermisos[0].permisoString;

          this.service.addRolPermiso(this.permisoAdd).subscribe(
            (res) => {
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Permission has been added.',
                showConfirmButton: false,
                timer:1500
              }).then((result) => {
                window.location.reload();
              });
            },
            (err) => {
              if (err.error.includes('You do not have permissions to access to ')) {
                Swal.fire({
                  icon: 'error',
                  title: err.error,
                  showConfirmButton: true
                }).then(() => {
                  this._router.navigateByUrl('/Dashboard');
                })
              } {
                Swal.fire({
                  icon: 'error',
                  title: err.error,
                  showConfirmButton: true
                });
              }
              this.loading = false;
              console.error(err);
            }
          );
        }
      })

    }
    else{
      Swal.fire({
        icon: 'error',
        title: 'Select any Permission',
        showConfirmButton: true
      });
    }

  }


}
