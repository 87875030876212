import { Component, OnInit } from '@angular/core';
import { ProductosService, detalleCarrito, brrCarrito, datosGuardar, datosBuscar, datosPieza} from '../../services/productos.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-carro',
  templateUrl: './carro.component.html',
  styleUrls: ['./carro.component.scss']
})
export class CarroComponent implements OnInit {
  
  carros: any;
  public pieNombre: any;

  constructor(
    private _productosService: ProductosService,
    private _router: Router,
    private _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this._route.params.subscribe(params =>{
      this.getcarro(params.id)
    });
  }

  getcarro(id){
    this._productosService.getcarro(id).subscribe(
      response =>{
        this.pieNombre = response[0].mainCarString;
          this.carros = response;
      },
      err =>{
        if (err.status == 401) {
          // window.localStorage.removeItem('token');
          this._router.navigateByUrl('/Relogin')
        }
          console.log(<any>err); 
      }
    )}
}
