
<app-navbar></app-navbar>
<div class="row p-2">
    <div class="d-flex align-items-center">
        <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
            aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/Dashboard/Cars">Cars</a></li>
                <li class="breadcrumb-item active" aria-current="page">Add Car</li>
            </ol>
        </nav>
    </div>
    <div class="d-flex justify-content-between">
        <h2 class="text-primary">Edit Car</h2>

        <button id="postSale" type="submit" class="btn btn-primary text-white" style="margin-bottom: 5px;" data-bs-toggle="modal" data-bs-target="#editPartPopUp">
           Post to sale
        </button>
    </div>


    <form [formGroup]="carro" class="row g-3" *ngIf="!loading;">
        <div class="col-md-12 ">
            <h3 class="text-primary">General information</h3>
            <hr>
        </div>
        <div class="col-12">
            <div class="row p-2">
                <div class="card border border-light  col-6 col-sm-6 col-md-3 col-lg-3  p-2" *ngFor="let img of urls">
                    <a (click)="verImagen(img)" class="fit-image">
                        <img class="img-fluid fit-image" [src]="img" alt="" (click)="verImagen(img)">
                    </a>

                    <div class="btnOverImg card-img-overlay text-cend">
                        <a (click)="removeItemFromArr(img)" class="btn btn-danger"><i class="fa fa-trash"></i></a>
                    </div>
                </div>
            </div>
            <h3>Car Image:</h3>
            <button type="button" class="btn  btn-info text-white" (click)="chooseFile()">choose file <i
                    class="fa fa-image"></i></button>
            <button type="button" class="ms-1 btn  btn-info text-white onlyMobil" (click)="startPhotoCapture()"> <i
                        class="fa fa-camera"></i></button>

            <input resize-quality="0.7" resize-type="image/jpg" id="fileInput" name="fileInput"
                style="height:0px;overflow:hidden" type="file" multiple accept="image/png,image/jpeg"
                (change)="detectFiles($event)" formControlName="PieImagenes">
            <div class="invalid-feedback">
                <div> Please enter at least one Image.</div>
            </div>
        </div>
        <label for="inputEmail4" class="form-label mt-4">VIN</label>
        <div class="col-md-8 d-flex" style="height: 40px;">
            <div class="input-group mb-3">
                <input type="text" value="{{carroD.carVIN}}" class="form-control" id="Vin" [disabled]="true" />
            </div>
        </div>
        <div class="col-12">
        </div>
        <div class="col-md-2" style="position: none; ">
            <label for="inputState" class="form-label mr-2">Status</label>
            <select class="form-select" id="status" aria-label="Default select example">
                <option value="{{carroD.carStatus}}" selected>{{carroD.estadoString}}</option>
                <option value="1">Active</option>
                <option value="2">Inactive</option>
                <!-- <option value="3">published</option> -->
                <option value="4">Sold Out</option>
            </select>
        </div>
        <div class="col-md-2" style="position: relative; ">
            <label for="inputState" class="form-label">Year</label>
            <ng-multiselect-dropdown appSelectAutoFoucs name="year" formControlName="year" [(ngModel)]="this.currentYear"
                [placeholder]="'Select year'" [settings]="{      
                closeDropDownOnSelection: true,
                singleSelection: true,
                idField: 'year',
                textField: 'year',
                itemsShowLimit: 1,
                allowSearchFilter: true}" [data]="years">
            </ng-multiselect-dropdown>

        </div>
        <div class="col-md-2" style="position: relative; ">
            <label for="inputState" class="form-label mr-2">Make</label>

            <ng-multiselect-dropdown appSelectAutoFoucs [disabled]="marListo" name="marcaf" formControlName="marcaf"
                [(ngModel)]="this.currentMarca" [placeholder]="'Select make'" [settings]="{   
                closeDropDownOnSelection: true,   
                singleSelection: true,
                idField: 'marID',
                textField: 'marDescripcion',
                itemsShowLimit: 1,
                allowSearchFilter: true}" [data]="marca" (onDeSelect)="UnSelectObject($event)"
                (onSelect)="cargarModelo($event)">
            </ng-multiselect-dropdown>

        </div>
        <div class="col-md-2 " style="position: relative; ">
            <label for="inputState" class="form-label">Model</label>
            <ng-multiselect-dropdown appSelectAutoFoucs name="modelof" formControlName="modelof" [(ngModel)]="this.currentModelo"
                [placeholder]="'Select model'" [settings]="{      
                closeDropDownOnSelection: true, 
                singleSelection: true,
                idField: 'modID',
                textField: 'modDescripcion',
                itemsShowLimit: 1,
                allowSearchFilter: true}" [data]="modelo">
            </ng-multiselect-dropdown>
        </div>
        <div class="col-md-2" style="position: relative; ">
            <label for="inputState" class="form-label">Type</label>

            <ng-multiselect-dropdown appSelectAutoFoucs name="tipo" formControlName="cartipof" [(ngModel)]="this.currentType"
                [placeholder]="'Select type'" [settings]="{      
                closeDropDownOnSelection: true,
                singleSelection: true,
                idField: 'usoValor',
                textField: 'usoDescripcion',
                itemsShowLimit: 1,
                allowSearchFilter: true}" [data]="cartypes">
            </ng-multiselect-dropdown>
        </div>
        <div class="col-md-2">
            <label for="inputEmail4" class="form-label">Car Version</label>
            <input placeholder="Car Version" value="{{carroD.carVersion}}" id="carversion" type="text"
                class="form-control">
        </div>

        <div class="col-md-2">
            <label for="Color" class="form-label">Color *</label>
            <input #Color placeholder="Color" value="{{carroD.carColor}}"  id="Color" type="text" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.Color.errors }">
            <div *ngIf="submitted && f.Costo.errors" class="invalid-feedback">
                <div>Please enter color.</div>
            </div>
        </div>

        <div class="col-md-2">
            <label for="ColorCode" class="form-label">Color Code</label>
            <input #Color placeholder="Color Code" value="{{carroD.carColorCode}}"  id="ColorCode" type="text" class="form-control">
        </div>

        <div class="col-md-2">
            <label for="inputEmail4" class="form-label">Mileage</label>
            <input placeholder="Mileage" value="{{carroD.carKilometraje}}" id="Millage" type="number"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Millage.errors }">
            <div *ngIf="submitted && f.Millage.errors" class="invalid-feedback">
                <div>Please enter mileage.</div>
            </div>
        </div>
        <div class="col-md-2">
            <label for="inputEmail4" class="form-label">Cost</label>
            <input placeholder="$0" value="{{carroD.carCosto}}" id="Costo" type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.Costo.errors }">
            <div *ngIf="submitted && f.Costo.errors" class="invalid-feedback">
                <div>Please enter cost.</div>
            </div>
        </div>
        <div class="col-md-2">
            <label for="inputEmail4" class="form-label">Tow Cost </label>
            <input placeholder="$0" value="{{carroD.carCostoRemolque}}" id="TowCosto" type="number"
                class="form-control">
        </div>

        <div class="col-md-2">
            <label for="inputEmail4" class="form-label">Dismantle Cost</label>
            <input placeholder="$0" value="{{carroD.carCostoDesmantelamiento}}" id="DismantleCosto" type="number"
                class="form-control">
        </div>

        <div class="col-md-2">
            <label for="inputEmail4" class="form-label">Miscellaneous Cost</label>
            <input placeholder="$0" value="{{carroD.carCostoExtra}}" id="MiscellaneousCosto" type="number"
                class="form-control">
        </div>

        <div class="col-md-2">
            <label for="inputEmail4" class="form-label">Transmission info.</label>
            <input value="{{carroD.carTranmicionInfo}}" placeholder="Transmission info." id="Transmissioninfo"
                type="text" class="form-control">
        </div>
        <div class="col-md-2">
            <label for="inputEmail4" class="form-label">Engine Model</label>
            <input value="{{carroD.carModeloMotor}}" placeholder="Engine Model" id="EngineModel" type="text"
                class="form-control">
        </div>
        <div class="col-md-2">
            <label for="inputEmail4" class="form-label">Engine cylinders</label>
            <input value="{{carroD.carCilindros}}" placeholder="Engine cylinders" type="number" id="Enginecylinders"
                class="form-control">
        </div>


       <div>
            <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a (click)="SelectTab(1)" [ngClass]="currentTab ==1 ? 'nav-link active btn text-white bg-primary' : 'nav-link btn'">Parts Included</a>
                </li>
                <li class="nav-item">
                  <a  (click)="SelectTab(2)" [ngClass]="currentTab ==2 ? 'nav-link active btn text-white bg-primary' : 'nav-link btn'">Add New Parts</a>
                </li>
              </ul>
        </div>

        <div id="addedParts" *ngIf="currentTab == 1">
            <div class="col-md-12">
                <div class="d-flex justify-content-between flex-row">
                    <h3>Parts Included</h3>
                    <a class="btn btn-success btn-lg" (click)="addPart()"> Add Part</a>
                </div>

                <hr>
                <div class="d-flex justify-content-between flex-row">
                    <p class="fs-3">Car Cost Total: <strong>{{costo | currency}}</strong> | Cost Per Part:
                        <strong>{{costoperPart | currency}}</strong></p>


                    <div class="srch_bar">
                        <div class="stylish-input-group">
                            <input id="search_im" type="text" placeholder="Search" (keyup)="filter($event)">
                            <span class="input-group-addon">
                                <a> <i class="fa fa-search" aria-hidden="true"></i> </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="row">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Part #</th>
                            <th scope="col">Part Included</th>
                            <th scope="col">Status</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let part of piezas">
                            <th>{{part.pieId}}</th>
                            <td>{{part.pieNombre}}</td>
                            <td>
                                <h4>
                                    <ng-container [ngSwitch]="part.pieEstado">
                                        <ng-container *ngSwitchCase="1">
                                            <span class="badge rounded-pill bg-danger">{{part.estadoString}}</span>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="2">
                                            <span class="badge rounded-pill bg-warning">{{part.estadoString}}</span>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="3">
                                            <span class="badge rounded-pill bg-primary">{{part.estadoString}}</span>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="4">
                                            <span class="badge rounded-pill bg-secondary">{{part.estadoString}}</span>
                                        </ng-container>
                                    </ng-container>
                                </h4>

                            </td>
                            <td><a class="btn btn-success btn-lg" [routerLink]="['/editpieza',part.pieId]" target="_blank">
                                    <i class="fa fa-edit"></i></a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


        <div id="addNewSParts" class="row g-2" *ngIf="currentTab == 2">
            <div class="col-md-12">
                <h3>Add New Parts</h3>
                <hr>
                <div class="row">
                    <p class="fs-3">Car Cost Total: <strong>{{costo | currency}}</strong> | Part Price: <strong>{{PartPrice | currency}}</strong></p>
                </div>
            </div>
            <div class="col-md-2">
                <label for="inputEmail4" class="form-label">Category</label>

                <ng-multiselect-dropdown appSelectAutoFoucs name="catf" formControlName="catf" [(ngModel)]="this.currentCategory"
                    [placeholder]="'Select Category'" [settings]="{      
            closeDropDownOnSelection: true, 
            singleSelection: true,
            idField: 'catID',
            textField: 'catDescripcion',
            itemsShowLimit: 1,
            allowSearchFilter: true}" [data]="categoria" (onDeSelect)="UnSelectObject($event)"
                    (onSelect)="cargarPart($event)"></ng-multiselect-dropdown>
            </div>
            <div class="col-md-2">
                <label for="inputEmail4" class="form-label">Parts</label>

                <ng-multiselect-dropdown appSelectAutoFoucs name="partf" formControlName="partf" 
                [(ngModel)]="this.currentPart"
                    [placeholder]="'Select Part'" [settings]="{      
                    closeDropDownOnSelection: true, 
                    singleSelection: false,
                    idField: 'piePreId',
                    textField: 'piePreNombre',
                    itemsShowLimit: 0,
                    allowSearchFilter: true}" 
                    [data]="parts" 
                    (onSelect)="partSelect($event)" 
                    (onDeSelect)="partDeselect($event)"
                    (onSelectAll)="partSelectAll($event)">
                    </ng-multiselect-dropdown>
            </div>
            <div class="col-md-2">
                <button type="submit" class="btn btn-danger btn-sm text-white" (click)="onUnSelectAll()">
                    Delete all parts added <i class="fa fa-trash"></i></button>
            </div>
            <div class="row">
                <div *ngFor="let part of selectedItems" class="col-6 col-sm-6 col-md-6 col-lg-36 portfolio-item my-1 rounded-lg">
                    
                    <div class="d-flex justify-content-between bg-secondary align-items-center">
                        <th scope="row">{{part.piePreId}}</th>
                        <td>{{part.piePreNombre}}</td>
                        <td><button (click)="partDeselect(part)" class="btn btn-danger btn-lg text-white">
                            <i class="fa fa-times"></i> Delete</button></td>                   
                    </div>

                </div>

            </div>
        </div>


        <div class="d-flex flex-row-reverse bd-highlight p--0">
            <div class="p-2 bd-highlight">
                <button type="submit" (click)="Submit()" class="btn btn-primary btn-lg text-white"> <i
                        class="fa fa-floppy-o"></i>
                    Save Car
                </button>
            </div>
            <div class="p-2 bd-highlight"><button routerLink="/Dashboard/Cars"
                    class="btn btn-danger btn-lg text-white"><i class="fa fa-times"></i>
                    Cancel</button>
            </div>
        </div>
    </form>

    <div class="full-screen-overlay" *ngIf="showCaptureComponent" >
        <app-photo-capture 
        *ngIf="showCaptureComponent" 
        (photoCaptured)="onPhotoCaptured($event)" 
        (captureCancelled)="onCaptureCancelled($event)">
      </app-photo-capture>
    </div>


    <div class="d-flex justify-content-center" *ngIf="loading;">
        <div class="spinner-border text-primary  m-3" role="status" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>