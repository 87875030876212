<div id="content">
  <div class="d-flex align-items-center">
    <nav
      style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
      aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/Dashboard">Dashboard</a></li>
        <li class="breadcrumb-item active" aria-current="page">Companies</li>
      </ol>
    </nav>
  </div>
</div>
<hr>
<div class="row px-3">
  <div class="d-flex flex-row  justify-content-between bd-highlight mb-0">
    <div class="px-2 bd-highlight d-flex flex-row">
      <h2 class="text-primary">Companies</h2>
      <button type="button" class="mx-2 btn btn-outline-primary btn-lg"
      [routerLink]="['/Dashboard/CrearCompanies']">add <i class="fa fa-plus"></i></button>    
    </div>

    <div class="px-2 py-0 m-0 bd-highlight">
      <h3 class="text-primary">Total companies: {{empresas.length}}</h3>
    </div>


  </div>
</div>
<div class="row">
  <form [formGroup]="myForm">
    <div class="m-0 my-3 row">

      <!-- <div class="col-4 col-sm-4 col-md-3 col-lg-3 nav-item mt-2">
        <h6 class="text-primary">Company ID# </h6>
        <input type="text" class="form-control" id="Company#" placeholder="Company#" aria-label="Recipient's username"
          aria-describedby="button-addon2">
      </div> -->
      <div class="col-4 col-sm-4 col-md-3 col-lg-3 nav-item mt-1">
        <h6 class="text-primary">Name</h6>
        <input type="text" class="form-control" id="empname" placeholder="Company Name">
      </div>
      <div class="col-4 col-sm-4 col-md-3 col-lg-3">
        <h6 class="text-primary">Company status</h6>

          <ng-multiselect-dropdown appSelectAutoFoucs
          name="companiS"
          [disabled]="estListo"
          formControlName="companiS"
          [(ngModel)]="this.currentStatus"
          [placeholder]="'Select status'"
          [settings]="{      
            closeDropDownOnSelection: true,
            singleSelection: true,
            idField: 'estEstado',
            textField: 'estDescripcion',
            itemsShowLimit: 1,
            allowSearchFilter: true}"
          [data]="empresaStatus"
         
        ></ng-multiselect-dropdown>  
     
  
      </div>
  
      <div class="col-4 col-sm-4 col-md-2 col-lg-2">
        <h6 class="text-white">search</h6>
        <button class="btn btn-primary text-white" (click)="buscarcompanies()">
          Search </button>
      </div>
    </div>

    
    <div *ngIf="loading" class="row p-2">
      <div class="row">
        <div class="col-6 col-sm-6 col-md-4 col-lg-3 portfolio-item my-1 rounded-lg">
          <app-ph class="p-3">
            (tipo)=''home''
          </app-ph>
        </div>

        <div class="col-6 col-sm-6 col-md-4 col-lg-3 portfolio-item my-1 rounded-lg">
          <app-ph class="p-3">
            (tipo)="home"
          </app-ph>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-3 portfolio-item my-1 rounded-lg">
          <app-ph class="p-3">
            [tipo]="home"
          </app-ph>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-3 portfolio-item my-1 rounded-lg">
          <app-ph class="p-3">
            [tipo]="home"
          </app-ph>
        </div>
      </div>
    </div>

    <div *ngIf="empresas.length == 0" class="p-2 px-0 d-flex justify-content-center">
      <div class="d-flex flex-column justify-content-center">
        <h3 class="d-flex justify-content-center">Data not found.</h3>
        <img class="d-flex justify-content-center" src="../../../assets/img/phvp.png" width="200" height="200" alt="">
      </div>
    </div>

    <div class="card mb-3" *ngFor="let empresa of empresas let i = index">
      <div class="row g-0">
        <div class="col-4 col-sm-5 col-md-3 col-lg-3 d-flex align-items-center">
          <img src="{{empresa.empLogoPath}}" class="card-img-top ml-5 mt-2 mb-2 fit-image img-fluid rounded-start" alt="..." >
        </div>
        <div class="col-8 col-sm-8 col-md-9 col-lg-9 d-flex align-items-center">
          <div class="card-body">
            <a> 
              <p class="Nombre-text">{{empresa.empNombre}}</p>
            </a>
            <hr class="my-0">


            <div class="row mt-0">

              <div class="col-6 col-sm-6 col-md-6 col-lg-6 d-flex align-items-center my-0 py-0">
                <div class="card-body my-auto py-0">
                  <p class="secom-text my-1"><small class="text-muted">Status: {{empresa.empEstatusString}}</small></p>
                  <p class="secom-text my-1"><small class="text-muted">Phone Number: {{empresa.empTelefono}}</small></p>
                  <p class="secom-text my-1"><small class="text-muted">Email: {{empresa.empEmail}}</small></p>                       
                </div>        
              </div>

              <div class="col-6 col-sm-6 col-md-6 col-lg-6 d-flex align-items-center my-0">
                <div class="card-body my-auto">
                  <p class="secom-text mb-1 mt-3 "><small class="text-muted">Address: {{empresa.empDireccion}} {{empresa.empDireccion2}}</small></p>
                  <p class="secom-text my-1"><small class="text-muted">{{empresa.empEstado}}, {{empresa.empCiudad}} {{empresa.empZipCode}}</small></p>
                  <p class="secom-text my-1"><small class="text-muted">From: {{empresa.empFechaCreacion | date:'MMMM yyyy'}}</small></p>
                </div>
              </div>

            </div>

            <div class="d-grid gap-2 d-md-flex justify-content-md-star my-0">
              <button class="btn btn-success btn-lg my-0" [routerLink]="['/Dashboard/CompaniesEditar',empresa.empID]"> <i class="fa fa-edit"></i></button>
              <button *ngIf="empresa.empEstatus == 1" (click)="SuspenderCompany(empresa)" class="btn btn-danger btn-lg my-0" > <i class="fa fa-trash"></i></button>
              <button *ngIf="empresa.empEstatus == 2" (click)="ReActiveCompany(empresa)"class="btn btn-primary btn-lg my-0" > <i class="text-white fa fa-refresh"></i></button>
            </div>

          </div>

        </div>
     
      </div>