import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { Url } from '../ApiUrl/url';



const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': ' application/json-patch+json',
     'Authorization': "Bearer " + localStorage.getItem("token") })
  };
  
  @Injectable({
    providedIn: 'root'
  })
  export class DefaultService {
  
    public url : string;
  
    constructor(
      private _http: HttpClient
    ) { 
      this.url = Url.url; 
    }

    getDefault(CatId: any, nombre:string):Observable<any>{

        return this._http.get(this.url + 'UsosMultiples/Default?catid='+ CatId 
        + '&nombre=' + nombre , httpOptions); 
      }
      getCategoria():Observable<any>{
        return this._http.get(this.url + 'UsosMultiples/Categorias',  httpOptions); 
      }
    
}

