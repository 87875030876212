<div class="d-flex flex-column me-3">
  <div id="root">
    <h3 class="p-2">WELCOME TO {{nombreAdmin | uppercase}}</h3>
    <input  id="seach" class="form-control flex-shrink-1" (click)="focusOut()" placeholder="Search parts">    

    <div class="row justify-content-center mt-4 noScroll">

      <div class="c-dashboardInfo col-6 col-sm-6 col-md-4 col-lg-3" *ngIf="canCars">
        <a [routerLink]="['/Dashboard/Cars']" style="text-decoration:none">
          <div class="wrap carsLine">
            <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Cars
              <div class="popover__wrapper">
                <svg class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24" aria-hidden="true"
                  role="presentation">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z">
                  </path>
                </svg>
                <div class="popover__content">
                  <p class="popover__message">All your actives cars</p>
                </div>
              </div>
            </h4><span id="carsTotal" class="hind-font caption-12 c-dashboardInfo__count"></span>
          </div>
        </a>
      </div>

      <div class="c-dashboardInfo col-6 col-sm-6 col-md-4 col-lg-3" *ngIf="canParts">
        <a [routerLink]="['/Dashboard/Parts']" style="text-decoration:none">
          <div class="wrap partLine">
            <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Parts
              <div class="popover__wrapper">
                <svg class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24" aria-hidden="true"
                  role="presentation">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z">
                  </path>
                </svg>
                <div class="popover__content">
                  <p class="popover__message">All your posted Parts</p>
                </div>
              </div>
            </h4><span id="partsTotal" class="hind-font caption-12 c-dashboardInfo__count"></span>
          </div>
        </a>
      </div>

      <div class="c-dashboardInfo col-6 col-sm-6 col-md-4 col-lg-3" *ngIf="canStore">
        <a (click)="MoreStore()" style="text-decoration:none">
          <div class="wrap storeLine">
            <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Store
              <div class="popover__wrapper">
                <svg class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24" aria-hidden="true"
                  role="presentation">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z">
                  </path>
                </svg>
                <div class="popover__content">
                  <p class="popover__message">Your Store</p>
                </div>
              </div>
            </h4>
            <span id="storeTotal" class="hind-font caption-10 c-dashboardInfo__count">{{storeTotal | currency}}</span>
          </div>
        </a>
      </div>

      <div class="c-dashboardInfo col-6 col-sm-6 col-md-4 col-lg-3" *ngIf="canTires">
        <a [routerLink]="['/Dashboard/tires']" style="text-decoration:none" data-bs-toggle="popover"
          data-bs-trigger="hover focus" data-bs-content="Disabled popover">
          <div class="wrap normalline">
            <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Tires
              <div class="popover__wrapper">
                <svg class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24" aria-hidden="true"
                  role="presentation">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z">
                  </path>
                </svg>
                <div class="popover__content">
                  <p class="popover__message">View your Tires</p>
                </div>
              </div>
            </h4><span id="tiresTotal" class="hind-font caption-12 c-dashboardInfo__count"></span>
          </div>
        </a>
      </div>

      <div class="c-dashboardInfo col-6 col-sm-6 col-md-4 col-lg-3" *ngIf="canParts">
        <a [routerLink]="['/Dashboard/partLocations']" style="text-decoration:none">
          <div class="wrap partLocationLine">
            <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Shelf Locations
              <div class="popover__wrapper">
                <svg class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24" aria-hidden="true"
                  role="presentation">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z">
                  </path>
                </svg>
                <div  class="popover__content">
                  <p class="popover__message">Search parts by locations</p>
                </div>
              </div>
            </h4>
            <span class="hind-font caption-10 c-dashboardInfo__count">Parts</span>
          </div>
        </a>
      </div>

      <div class="c-dashboardInfo col-6 col-sm-6 col-md-4 col-lg-3" *ngIf="canDefaults">
        <a [routerLink]="['/Dashboard/Defaults']" style="text-decoration:none">
          <div class="wrap normalline">
            <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Defaults
              <div class="popover__wrapper">
                <svg class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24" aria-hidden="true"
                  role="presentation">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z">
                  </path>
                </svg>
                <div class="popover__content">
                  <p class="popover__message">All Your Default parts name</p>
                </div>
              </div>
            </h4><span id="defaultsTotal" class="hind-font caption-12 c-dashboardInfo__count"></span>
          </div>
        </a>
      </div>

      <div class="c-dashboardInfo col-6 col-sm-6 col-md-4 col-lg-3" *ngIf="canCompanie">
        <a [routerLink]="['/Dashboard/Companies']" style="text-decoration:none">
          <div class="wrap companieLine">
            <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Companies
              <div class="popover__wrapper">
                <svg class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24" aria-hidden="true"
                  role="presentation">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z">
                  </path>
                </svg>
                <div class="popover__content">
                  <p class="popover__message">View All your Companies</p>
                </div>
              </div>
            </h4><span id="companiesTotal" class="hind-font caption-12 c-dashboardInfo__count"></span>
          </div>
        </a>
      </div>

      <div class="c-dashboardInfo col-6 col-sm-6 col-md-4 col-lg-3" *ngIf="canMU">
        <a [routerLink]="['/Dashboard/MultipleUses']" style="text-decoration:none" data-bs-toggle="popover"
          data-bs-trigger="hover focus" data-bs-content="Disabled popover">
          <div class="wrap normalline">
            <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">MultipleUses
              <div class="popover__wrapper">
                <svg class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24" aria-hidden="true"
                  role="presentation">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z">
                  </path>
                </svg>
                <div class="popover__content">
                  <p class="popover__message">Multiple Uses for VP</p>
                </div>
              </div>
            </h4><span id="usosMultiplesTotal" class="hind-font caption-12 c-dashboardInfo__count"></span>
          </div>
        </a>
      </div>

      <div class="c-dashboardInfo col-6 col-sm-6 col-md-4 col-lg-3" *ngIf="canStatus">
        <a [routerLink]="['/Dashboard/Estados']" style="text-decoration:none" data-bs-toggle="popover"
          data-bs-trigger="hover focus" data-bs-content="Disabled popover">
          <div class="wrap normalline">
            <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Status
              <div class="popover__wrapper">
                <svg class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24" aria-hidden="true"
                  role="presentation">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z">
                  </path>
                </svg>
                <div class="popover__content">
                  <p class="popover__message">Status for VP</p>
                </div>
              </div>
            </h4><span id="statusTotal" class="hind-font caption-12 c-dashboardInfo__count"></span>
          </div>
        </a>
      </div>

      <div class="c-dashboardInfo col-6 col-sm-6 col-md-4 col-lg-3" *ngIf="canEbay">
        <a [routerLink]="['/Dashboard/Ebay']">
          <div class="wrap ebayLine">
            <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Integration
              <div class="popover__wrapper">
                <svg class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24" aria-hidden="true"
                  role="presentation">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z">
                  </path>
                </svg>
                <div class="popover__content">
                  <p class="popover__message">Login with ebay to export Parts</p>
                </div>
              </div>
            </h4><span class="hind-font caption-12 c-dashboardInfo__count">eBay</span>
          </div>
        </a>
      </div>

      <div class="c-dashboardInfo col-6 col-sm-6 col-md-4 col-lg-3" *ngIf="canUser">
        <a [routerLink]="['/Dashboard/Usuarios']" style="text-decoration:none" data-bs-toggle="popover"
          data-bs-trigger="hover focus" data-bs-content="Disabled popover">
          <div class="wrap normalline">
            <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Employees
              <div class="popover__wrapper">
                <svg class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24" aria-hidden="true"
                  role="presentation">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z">
                  </path>
                </svg>
                <div class="popover__content">
                  <p class="popover__message">View your users</p>
                </div>
              </div>
            </h4><span id="userTotal" class="hind-font caption-12 c-dashboardInfo__count"></span>
          </div>
        </a>
      </div>

      <div class="c-dashboardInfo col-6 col-sm-6 col-md-4 col-lg-3" *ngIf="canRol">
        <a [routerLink]="['/Dashboard/roles']" style="text-decoration:none" data-bs-toggle="popover"
          data-bs-trigger="hover focus" data-bs-content="Disabled popover">
          <div class="wrap normalline">
            <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">User Role
              <div class="popover__wrapper">
                <svg class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24" aria-hidden="true"
                  role="presentation">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z">
                  </path>
                </svg>
                <div class="popover__content">
                  <p class="popover__message">View your role</p>
                </div>
              </div>
            </h4><span id="rolTotal" class="hind-font caption-12 c-dashboardInfo__count"></span>
          </div>
        </a>
      </div>

      <div class="c-dashboardInfo col-6 col-sm-6 col-md-4 col-lg-3" *ngIf="canPermiso">
        <a [routerLink]="['/Dashboard/permit']" style="text-decoration:none" data-bs-toggle="popover"
          data-bs-trigger="hover focus" data-bs-content="Disabled popover">
          <div class="wrap normalline">
            <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Companies Permissions
              <div class="popover__wrapper">
                <svg class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24" aria-hidden="true"
                  role="presentation">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z">
                  </path>
                </svg>
                <div class="popover__content">
                  <p class="popover__message">View your Permissions</p>
                </div>
              </div>
            </h4><span id="permitTotal" class="hind-font caption-12 c-dashboardInfo__count"></span>
          </div>
        </a>
      </div>

    </div>
  </div>
  

  <div id="storeMore_d" class="Divloader" *ngIf="openStore" (click)="MoreStore()">

    <button class="btn btn-danger text-white mt-4 ms-4">Close</button>
    <div class="d-flex align-items-center justify-content-center" style="height: 100vh; margin-top: -30px;">

      <div class="c-dashboardInfo col-6 col-sm-6 col-md-4 col-lg-3 mx-2" *ngIf="canStore">
        <a [routerLink]="['/Dashboard/storeSales']" style="text-decoration:none">
          <div class="wrap storeLine">
            <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">New Sale
              <div class="popover__wrapper">

              </div>
            </h4><span class="hind-font caption-12 c-dashboardInfo__count"><i class="fa fa-cart-arrow-down" aria-hidden="true"></i></span>
          </div>
        </a>
      </div>

      <div class="c-dashboardInfo col-6 col-sm-6 col-md-4 col-lg-3 mx-2" *ngIf="canStore">
        <a [routerLink]="['/Dashboard/invoices']" style="text-decoration:none">
          <div class="wrap storeLine">
            <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Invoices
              <div class="popover__wrapper">

              </div>
            </h4><span class="hind-font caption-12 c-dashboardInfo__count">{{storeTotal | currency}}</span>
          </div>
        </a>
      </div>

    </div>
  </div>


  <div *ngIf="loading" id="loader" style="z-index:9999" class="Divloader">
    <div class="loader">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
</div>
