<div class="" id="reporte">

    <header id="header_div">
        <button id="print_btn" class="btn btn-success" (click)="print()">Print</button>
        <div class="d-flex justify-content-between mx-2 my-1 py-2 px-3 rounded rounded-2" style="background-color: #e3e3e3;">

            <div class="d-flex justify-content-start">
                <div class="d-flex flex-column justify-content-start" style="font-size: 14px">
                    <span class="text-start" style="color: #607d8b;">SALES ORDER FOR:</span>
                    <span class="text-start">User code: {{currentFactura.cliente.usuId}}</span>
                    <span class="text-start">{{currentFactura.cliente.usuNombres}} {{currentFactura.cliente.usuApellidos}}</span>
                    <span class="text-start">Email: {{currentFactura.cliente.usuEmail}}</span>
                    <span class="text-start">Phone: {{currentFactura.cliente.usuTelefonoBill}}</span>
                </div>

                <div class="d-flex flex-column justify-content-start mx-4" style="font-size: 14px">
                    <span class="text-start" style="color: #607d8b;">
                        CUSTOMER ADDRESS:
                    </span>
                    <span class="text-start">{{currentFactura.cliente.usuDireccionShip}} {{currentFactura.cliente.usuDireccion2Ship}}</span>
                    <span class="text-start">{{currentFactura.cliente.usuCiudadShip}}, {{currentFactura.cliente.usuStateShip}}
                        {{currentFactura.cliente.usuZipCodeShip}}</span>
                    <span class="text-start">United States</span>
                </div>
            </div>

            <div style="background-color:black; height: 90px; width:1px"></div>

            <div class="d-flex justify-content-start">
                <div class="d-flex flex-column justify-content-start" style="font-size: 14px">
                    <span class="text-start" style="color: #607d8b;">{{currentFactura.empresa.empNombre}}:</span>
                    <span class="text-start">{{currentFactura.empresa.empDireccion}} {{currentFactura.empresa.empDireccion2 }}</span>
                    <span class="text-start">{{currentFactura.empresa.empCiudad}}, {{currentFactura.empresa.empEstado }} {{currentFactura.empresa.empZipCode }}</span>
                    <span class="text-start">United States</span>
                    <span class="text-start">Phone: {{currentFactura.empresa.empTelefono}}</span>
                </div>

                <div class="d-flex flex-column justify-content-start mx-4" style="font-size: 14px">
                    <img class="mb-0 pt-0 me-0 align-items-center" src={{currentFactura.empresa.empLogoPath}} height="100" width="100" alt="...">
                </div>
            </div>

        </div>

    </header>

    <main id="main_div" class="m-3">
        <!-- Contenido principal aquí -->
        <div *ngIf="loading == false" class="scrollable-list">
            <table class="table table-bordered">
                <thead>
                    <tr style="background-color: gainsboro; color: #607d8b; padding-bottom: -100px;" class="p-0 m-0">
                        <th style="background-color: gainsboro;">
                            <p class="fs-5 fw-light">Description</p>
                        </th>
                        <th width="15px" style="background-color: gainsboro;">
                            <p class="fs-5 fw-light">Qty</p>
                        </th>
                        <th width="20px" style="background-color: gainsboro;">
                            <p class="fs-5 fw-light">Price</p>
                        </th>
                        <th width="25px" style="background-color: gainsboro;">
                            <p class="fs-5 fw-light">Total</p>
                        </th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let pieza of currentFactura.facturaDetalle">

                        <td>{{pieza.proNCD}}</td>
                        <td>{{pieza.facCantidad}} </td>
                        <td>{{pieza.facPrecio | currency}}</td>
                        <td>{{pieza.facMontoTotal | currency}}</td>


                    </tr>

                </tbody>
            </table>
        </div>


        <div *ngIf="loading" class="row p-5">
            <div class="d-flex justify-content-center">
                <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>

        <div *ngIf="currentFactura.facturaDetalle.length == 0" class="row p-5">
            <div class="d-flex justify-content-center">
                <h3>
                    <span>
                        Data not found.</span>
                </h3>
                <img src="../../../assets/img/phvp.png" width="200" height="200" alt="">
            </div>
        </div>


    </main>


    <footer id="footer_div" class="p-0 mx-3 mb-1">
        <div class="d-flex flex-row justify-content-between align-items-center my-0 py-0 bg-primary rounded rounded-2">

            <div class="d-flex align-items-center pt-2 ms-2" data-bs-toggle="modal" data-bs-target="#RecallModal">

            </div>

            <div class="d-flex flex-row align-items-center p-2 ">
                <div class="d-flex flex-row text-bg-primary mx-1  pt-2 px-3 text-white">
                    <h5> SubTotal: </h5>
                    <h5 class="ms-1" style="text-align:end; font-weight:bold"> {{currentFactura.factura.facSubTotal | currency}}</h5>
                </div>

                <div class="d-flex flex-row text-bg-primary mx-1 pt-2   px-3 text-white">
                    <h5> Sales Tax: </h5>
                    <h5 id="tax_lb" class="ms-1" style="text-align: end; font-weight: bold">$0</h5>
                </div>

                <div class="d-flex flex-row text-bg-primary mx-1 pt-2 px-3 text-white">
                    <h5> Total: </h5>
                    <h5 class="ms-1" style="text-align: end; font-weight: bold">{{currentFactura.factura.facTotal| currency}} </h5>
                </div>
            </div>

        </div>
    </footer>
</div>