import { TerminosDePagos } from '../Entities/TerminosDePagos';
import { Pieza } from '../Entities/Pieza';
import { FacturasDetalle } from '../Entities/FacturaDetalle';
import { Usuario } from '../Entities/Usuario';
import { vwEmpresas } from '../Entities/Empresa';

export class Facturas {
  facID: number;
  empID?: number;
  usuID: number;
  facCompania: string;
  facEmail: string;
  facFecha?: Date;
  facEstatus?: number;
  facTotal?: number;
  facTotalDescuento?: number;
  facTotalItbis?: number;
  facIndicadorCompleto?: number;
  facFechaEntrega?: Date;
  facTipoFactura?: number;
  facOrdenCompra: string;
  monID?: number;
  pedSecuencia?: number;
  fopID?: number;
  cldDirTipo: string;
  usuInicioSesion: string;
  facFechaActualizacion?: Date;
  facComentario: string;
  campo1: string;
  campo2: string;
  campo3?: number;
  campo4?: number;
  pedID?: number;
  facUsuInicioSesion: string;
  facSubTotal?: number;
  terID?: number;
  facEfectivo?: number;
  facCambio?: number;
  facReferenciaPago: string;
  enrSecuencia?: number;
  facDescuentoManual?: number;
  almID?: number;
  facPONumber: string;
  facUsuID?: number;
  facTelefono: string;

  constructor() {
    this.facID = 0;
    this.empID = 0;
    this.usuID = 0;
    this.facCompania = '';
    this.facEmail = '';
    this.facFecha = new Date();
    this.facEstatus = 0;
    this.facTotal = 0;
    this.facTotalDescuento = 0;
    this.facTotalItbis = 0;
    this.facIndicadorCompleto = 0;
    this.facFechaEntrega = new Date();
    this.facTipoFactura = 0;
    this.facOrdenCompra = '';
    this.monID = 0;
    this.pedSecuencia = 0;
    this.fopID = 0;
    this.cldDirTipo = '';
    this.usuInicioSesion = '';
    this.facFechaActualizacion = new Date();
    this.facComentario = '';
    this.campo1 = '';
    this.campo2 = '';
    this.campo3 = 0;
    this.campo4 = 0;
    this.pedID = 0;
    this.facUsuInicioSesion = '';
    this.facSubTotal = 0;
    this.terID = 0;
    this.facEfectivo = 0;
    this.facCambio = 0;
    this.facReferenciaPago = '';
    this.enrSecuencia = 0;
    this.facDescuentoManual = 0;
    this.almID = 0;
    this.facPONumber = '';
    this.facUsuID = 0;
    this.facTelefono = '';
  }
}

export class PostFacturaViewModel {
  Factura: Facturas;
  Piezas: Pieza[];
  TerminosPagos: TerminosDePagos[];

  constructor() {
    this.Factura = new Facturas();
    this.Piezas = [new Pieza()];
    this.TerminosPagos = [new TerminosDePagos()]
  }
}

export class GetFacturaViewModel {
  factura: Facturas;
  facturaDetalle: FacturasDetalle[];
  terminosPagos: TerminosDePagos[];
  cliente: Usuario;
  empresa: vwEmpresas;

  constructor() {
    this.factura = new Facturas();
    this.facturaDetalle = [new FacturasDetalle()];
    this.terminosPagos = [new TerminosDePagos()]
    this.cliente = new Usuario();
    this.empresa = new vwEmpresas()
  }
}