<div class="modal-dialog modal-lm text-center ounded shadow">
    <div class="modal-content">
        <div class="container container--mini">
         <h3 class="mt-3" style="color: #008c44;"> 
            Recover password.</h3>
            <hr>
             <form [formGroup]="Passform" (submit)="Recuperar()" class="needs-validation" novalidate>
                <div class="form-group">
                   <label for="user_login"></label>
                   <input type="text" name="log" id="user_login" class="form-control" value="" size="20" placeholder="Enter username or email" formControlName="CredencialUsu" style="font-family:Arial, FontAwesome" name="UsuSesion" [(ngModel)]="CredencialUsu" [ngClass]="{ 'is-invalid': submitted && f.CredencialUsu.errors }"/>
                   <div *ngIf="submitted && f.CredencialUsu.errors" class="invalid-feedback">
                     <div>
                        Please, enter your username or email.</div>
                   </div> 
               </div>
                   <h4 class="text-gray-soft text-center mt-3"><a href="#" [routerLink]="['/cambiarpass']">I already have a code!</a></h4>
               <div class="form-group">
                 <button type="submit" class="btn btn-primary btn-block mb-3  mt-3 text-white"> <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Recover  <i class="fa fa-arrow-right"></i></button>
               </div>
             </form>
     </div>
    <div class="ml-3 mb-3 " >
       <a class="btn btn-danger"  [routerLink]="['/login']"><i class="fa fa-times-circle"></i> Cancel</a>
    </div>
   </div>
</div>