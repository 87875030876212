import { Router } from '@angular/router';
import { AdminService, filtros } from '../../../services/admin.service';
import { ProductosService, Modelo } from '../../../services/productos.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { esColorValido, colorMap, rgbToHex } from '../../../helpers/Util';

@Component({
  selector: 'app-admin-carro',
  templateUrl: './admin-carro.component.html',
  styleUrls: ['./admin-carro.component.scss']
})
export class AdminCarroComponent implements OnInit {


  public qrPartId = '0';
  currentYear: string[] = [];
  currentMarca: any[] = [];
  currentModelo: any[] = [];

  myForm: FormGroup;

  years: number[] = [];
  marListo = true;
  catListo = true;
  idmodelo: any;
  categoria: any;
  marca: any;
  modelo: any;
  parts: any;
  resultados: any;
  result = false
  loading = false;
  noData = false;
  latitude: string;
  longitude: string;
  printOpen = false;
  currentPrintCar = 0;


  datosBuscar: filtros = {

    vin: '',
    mod: '',
    mar: '',
    status: 1,
    anos: '',
    pageNumber: 1,
    pageSize: 20


  };


  carros: any;
  cargar = true;
  title = '';
  page = 1;
  count = 0;
  pageSize = this.datosBuscar.pageSize;
  loadrecom = true;
  token: any;

  Modelo: Modelo = {

    idMarca: 0,
    marcastring: ""

  }

  constructor(private auth: ProductosService, private adService: AdminService, private router: Router, private _fb: FormBuilder) {
    this.qrPartId = 'eric';
  }


  ngOnInit(): void {

    this.myForm = this._fb.group({
      year: this.currentYear,
      marcaf: this.currentMarca,
      modelof: this.currentModelo
    });

    this.buscarcarro(false);
    this.getMarca();
    this.selectYear();
    //this.retrieveProductos();
  }

  getRequestParams(page: number, pageSize: number): any {
    let params: any = {};

    if (page) {
      params['pageNumber'] = page;
    }

    return params;

  }

  buscarcarro(limpiar) {

    if (limpiar) {
      this.currentMarca = [];
      this.currentModelo = [];
      this.currentYear = [];
      (<HTMLInputElement>document.getElementById('vin')).value = "";
      this.datosBuscar.pageNumber = 1;
    }

    this.datosBuscar.vin = (<HTMLInputElement>document.getElementById('vin')).value;
    if (this.currentMarca.length == 0) {
      this.datosBuscar.mar = "";
    } else {
      this.datosBuscar.mar = this.currentMarca[0].marDescripcion;
    }
    console.log(this.currentModelo);
    if (this.currentModelo.length == 0) {
      this.datosBuscar.mod = "";
    } else {
      this.datosBuscar.mod = this.currentModelo[0].modDescripcion;
    }
    if (this.currentYear.length == 0) {
      this.datosBuscar.anos = "";
    } else {
      this.datosBuscar.anos = this.currentYear[0].toString();
    }
    this.cargar = true;
    console.log(this.datosBuscar)
    this.adService.getBuscar(this.datosBuscar).subscribe(
      res => {
        this.loadrecom = false;

        const { results, totalRecordCount } = res;
        this.carros = results;
        this.count = totalRecordCount;
        this.cargar = false;
        console.log(results);
      },
      err => {
        if (err.error == 'Carga Completa') {
          // this.cargar = false;

          if (this.page == 1) {
            this.carros = [];
            this.count = 0;
          }
        }
        else {
          if (err.error == 'You do not have permissions to access to Cars') {
            Swal.fire({
              icon: 'error',
              title: err.error,
              showConfirmButton: true
            }).then(() => {
              this.router.navigateByUrl('/Dashboard');
            })
          }
          else {
            this.carros = [];
            this.count = 0;
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: err.error,
              showConfirmButton: true
            })
          }
        }
        this.cargar = false;
        console.log(err);
      }
    )

  }

  selectYear() {
    var fully = new Date().getFullYear();
    for (let y = fully; y >= 1970; y--) {
      this.years.push(y);
    }
  }

  getAnoFabricacion(carro: any): any {
    return carro['añoFabricacion'];
  }

  getMarca() {
    this.auth.getMarca().subscribe(
      res => {
        this.marListo = false;
        this.marca = res;
        console.log(this.marca)
      }, err => {
        console.log(err);
      })
  }

  cargarModelo(item: any) {

    this.Modelo.idMarca = item.marID;
    this.currentModelo = [];
    this.auth.getModelo(this.Modelo).subscribe(
      res => {
        this.modelo = res;
        console.log(this.modelo);
      }, err => {
        console.log(err);
      }
    )
  }


  UnSelectObject(item: any) {

    console.log(this.currentMarca)
    if (this.currentMarca.length == 0) {
      this.currentModelo = [];
      this.modelo = null;
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.datosBuscar.pageNumber = event;
    this.startup();

  }

  startup() {
    window.scroll(0, 0);
    this.cargar = true;
    this.buscarcarro(false);
  }

  imprSelec(nombre) {

    try {

      var loa = Swal.fire({
        title: 'Loading',
        showConfirmButton: false,
        allowOutsideClick: false,
        html: 'Please wait',

        timerProgressBar: true,
        didOpen: () => {
          //Swal.showLoading()
        },
        willClose: () => {

        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          console.log('I was closed by the timer')
        }
      })

      this.adService.getCarroid(nombre).subscribe(
        response => {

          response.carPiezas.forEach((pieza) => {

            document.getElementById(nombre).innerHTML += `
            <div style="width: 100%; page-break-before:always;">
            <div>
              <p style="font-size: 18px;">${pieza.pieNombre}</p>
              <p style="font-size: 16px;">${pieza.mainCarString}</p>
              <hr>
    
              <div style="width: 100%;">
                <div style="width: 40%; display: inline-block; zoom: 1; vertical-align: top; font-size: 15px;">
                  <p>Mark:  ${pieza.marcaString}</p>
                  <p>Model:  ${pieza.modeloString}</p>        
                  <p>Year:  ${pieza.pieAnoDesde} - ${pieza.pieAnoHasta}</p>                  
                  <p>Shelf/Location:  ${pieza.pieLocation}</p>
                </div>
    
                <div style="width: 40%; display: inline-block; zoom: 1; vertical-align: top; font-size: 15px;">
                  <p>Condition:  ${pieza.pieCondicion}</p>
                  <p>Part VIN:  ${pieza.pieVIN}</p>
                  <p>Grade:  ${pieza.pieSeñalUso}</p>
                  <p>Color:  ${pieza.pieColor}4</p>
                </div>
    
                <div style="width: 18%; display: inline-block; vertical-align: top; font-size: 12px;">
                <ngx-qrcode 
                [value] = "'${pieza.pieId}'"
                cssClass = "aclass">
              </ngx-qrcode>
                <img src="https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=${pieza.pieId}" style="width:100%;"> 
                  <p style="text-align: center; margin-left: 10px; margin-top: 0px;">${pieza.pieId}</p>
                </div>
    
              </div>
    
            </div>
          </div>
            
            `;

          })

          setTimeout(function () {
            document.getElementById(nombre).style.display = "block"
            var ventimp = window.open(' ', 'popimpr');
            ventimp.document.write(document.getElementById(nombre).innerHTML);
            ventimp.document.close();

            setTimeout(function () {
              ventimp.print();
              document.getElementById(nombre).style.display = "none"
              ventimp.close();
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: "Printed",
                showConfirmButton: false,
                timer: 1500,
              });
            }, 2000);

          }, 2000);

        },
        error => {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: error.error,
            showConfirmButton: false,
            timer: 1500,
          });
        });

    } catch ({ name, message }) {

      Swal.fire({
        position: 'center',
        icon: 'error',
        title: message,
        showConfirmButton: true
      })
    }

  }

  onChangeURL(url: SafeUrl) {
    console.log(url);
  }

  deleteCar(carID: Number) {

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        var loa = Swal.fire({
          title: 'Loading',
          showConfirmButton: false,
          allowOutsideClick: false,
          html: 'Please wait',

          timerProgressBar: true,
        })

        this.adService.deleteCar(carID).subscribe(
          res => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: "Removed",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              location.reload();
            });
          }, err => {
            console.log(err);
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: err,
              showConfirmButton: true
            })
          })
      }
    });

  }

  OpenPrint(carID: number) {
    if(this.currentPrintCar == carID){
      this.currentPrintCar = 0;

    }
    else{
      this.currentPrintCar = carID;
    }
  }

  addAndPrint(carID: Number) {

    try {
      var nombre = carID.toString()
      var loa = Swal.fire({
        title: 'Loading',
        showConfirmButton: false,
        allowOutsideClick: false,
        html: 'Please wait',

        timerProgressBar: true,
        didOpen: () => {
          //Swal.showLoading()
        },
        willClose: () => {

        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          console.log('I was closed by the timer')
        }
      })

     var quantity = Number((<HTMLInputElement>document.getElementById('QuantityToPrint'+carID+'_ip')).value);
      if (quantity == 0) {
        throw new TypeError("Quantity is necesary");
      }

      this.adService.addAndPrintLabels(carID, quantity).subscribe(
        response => {
          response.forEach((pieza) => {

            var nombre = carID.toString()
            document.getElementById(nombre).innerHTML += `
            <div style="width: 100%; page-break-before:always;">
            <div>
              <p style="font-size: 18px;">${pieza.pieNombre}</p>
              <p style="font-size: 16px;">${pieza.mainCarString}</p>
              <hr>
    
              <div style="width: 100%;">
                <div style="width: 40%; display: inline-block; zoom: 1; vertical-align: top; font-size: 15px;">
                  <p>Mark:  ${pieza.marcaString}</p>
                  <p>Model:  ${pieza.modeloString}</p>        
                  <p>Year:  ${pieza.pieAnoDesde} - ${pieza.pieAnoHasta}</p>                  
                  <p>Shelf/Location:  ${pieza.pieLocation}</p>
                </div>
    
                <div style="width: 40%; display: inline-block; zoom: 1; vertical-align: top; font-size: 15px;">
                  <p>Condition:  ${pieza.pieCondicion}</p>
                  <p>Part VIN:  ${pieza.pieVIN}</p>
                  <p>Grade:  ${pieza.pieSeñalUso}</p>
                  <p>Color:  ${pieza.pieColor}4</p>
                </div>
    
                <div style="width: 18%; display: inline-block; vertical-align: top; font-size: 12px;">
                <ngx-qrcode 
                [value] = "'${pieza.pieId}'"
                cssClass = "aclass">
              </ngx-qrcode>
                <img src="https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=${pieza.pieId}" style="width:100%;"> 
                  <p style="text-align: center; margin-left: 10px; margin-top: 0px;">${pieza.pieId}</p>
                </div>
    
              </div>
    
            </div>
          </div>
            
            `;

          })

          setTimeout(function () {
            document.getElementById(nombre).style.display = "block"
            var ventimp = window.open(' ', 'popimpr');
            ventimp.document.write(document.getElementById(nombre).innerHTML);
            ventimp.document.close();

            setTimeout(function () {
              ventimp.print();
              document.getElementById(nombre).style.display = "none"
              ventimp.close();
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: "Printed",
                showConfirmButton: false,
                timer: 1500,
              });

              (<HTMLInputElement>document.getElementById('QuantityToPrint'+carID+'_ip')).value = ""
              this.currentPrintCar = 0;

            }, 2000);

          }, 2000);

        },
        error => {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: error.error,
            showConfirmButton: false,
            timer: 1500,
          });
        });

    } catch ({ name, message }) {

      Swal.fire({
        position: 'center',
        icon: 'error',
        title: message,
        showConfirmButton: true
      })
    }

  }

  setCalor(color) {
    var Color = color.replace(/\s+/g, '');
    if (colorMap[Color.toLowerCase()]) {
      Color = colorMap[Color.toLowerCase()];
    }

    const rgbRegex1 = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\s*,\s*(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\s*,\s*(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    if(Color.includes('rgb') || rgbRegex1.test(Color)){
      Color = rgbToHex(Color);
    }

    if(esColorValido(Color)){
      return color
    }
    else{
       return "#cfcfcf";
    }

   }

}
