import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService, DatosLogin } from '../../services/usuario.service';
import { ProductosService } from '../../services/productos.service';
import { Usuario } from '../../Entities/Usuario';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as braintree from 'braintree-web';

import { FireBaseCredencials } from '../../helpers/Util';
import { getMessaging, getToken } from 'firebase/messaging';


@Component({
  selector: 'app-registrar',
  templateUrl: './registrar.component.html',
  styleUrls: ['./registrar.component.scss']
})

export class RegistrarComponent implements OnInit {

  public tokenClient: string;
  loading = false;
  imageURL: string = '';

  msg = getMessaging();
  urls: string[] = [];
  postUrl = new Array<string>();

  DatosRegistro = new Usuario();
  DatosLogin: DatosLogin = {

    Usuario: '',
    Password: ''

  };

  errorForm = {

    UsuSesion: '',
    UsuNombres: '',
    UsuApellidos: '',
    UsuTelefono: '',
    UsuEmail: '',
    UsuPass: '',
    confirmarUsuPass: '',
    usuFoto: '',
    UsuDireccion: '',
    UsuCiudadBill: '',
    UsuStateBill: '',
    UsuZipCodeBill: '',
    UsuCountry: '',
    Condiciones: '',

    empName: '',
    empTaxID: '',
    empSalesTaxLicID: '',
    empBusinessLinc: '',

    cvv: '',
    CardExpiryDate: '',
    CardNumber: '',
    nameCard: '',
  };

  usertype = "0";

  constructor(private auth: UsuarioService, private _productosService: ProductosService, private router: Router) { }

  ngOnInit(): void {
    this.gettokenPaypal();
    this.userSelected("0");
  }

  readUrl(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.imageURL = (<FileReader>event.target).result.toString();
      }
      this.errorForm.usuFoto = ''
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  Next(current: number) {
    if (current == 0) {
      if (this.ValidateForm(current)) {
        (<HTMLElement>document.getElementById('pills-profile-tab')).click();
        (<HTMLElement>document.getElementById('progress_barr')).style.width = '50%'
      }
    }

    if (current == 1) {
      if (this.ValidateForm(current)) {
        if (this.usertype != "2") {
          this.register('')
        } else {
          (<HTMLElement>document.getElementById('payment-method-tab')).click();
        }
        (<HTMLElement>document.getElementById('progress_barr')).style.width = '100%'
      }
    }

    if (current == 2) {
      if (this.ValidateForm(current)) {
        braintree.client.create({
          authorization: this.tokenClient
        }, (err, client) => {
          console.log(err + "---" + client)
          client.request({
            endpoint: 'payment_methods/credit_cards',
            method: 'post',
            data: {
              creditCard: {
                number: Number((<HTMLInputElement>document.getElementById('card-number')).value.replace(/ /g, "")),
                expirationDate: (<HTMLInputElement>document.getElementById('expiry-date')).value,
                cvv: Number((<HTMLInputElement>document.getElementById('cvv')).value),
                billingAddress: {
                  postalCode: (<HTMLInputElement>document.getElementById('billing-street-zip')).value
                }
              }
            }

          }, (err, response) => {

            if (err == null) {
              this.register(response.creditCards[0].nonce);
            }
            else {
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: "We were unable to verify this information, please check that it is correct.",
                showConfirmButton: true,
              })
              console.log("Error: " + err);
            }
          });
        });
      }
    }
  }

  Back(current: number) {
    if (current == 1) {
      (<HTMLElement>document.getElementById('progress_barr')).style.width = '0%';
      (<HTMLElement>document.getElementById('pills-home-tab')).click();
    }
    if (current == 2) {
      (<HTMLElement>document.getElementById('progress_barr')).style.width = '50%';
      (<HTMLElement>document.getElementById('pills-profile-tab')).click();
    }
  }

  ValidateForm(currentTab: number): boolean {
    var success = true;
    this.errorForm = {
      UsuSesion: '',
      UsuNombres: '',
      UsuApellidos: '',
      UsuTelefono: '',
      UsuEmail: '',
      UsuPass: '',
      confirmarUsuPass: '',
      usuFoto: '',
      UsuDireccion: '',
      UsuCiudadBill: '',
      UsuStateBill: '',
      UsuZipCodeBill: '',
      UsuCountry: '',
      Condiciones: '',

      empName: '',
      empTaxID: '',
      empSalesTaxLicID: '',
      empBusinessLinc: '',
      cvv: '',
      CardExpiryDate: '',
      CardNumber: '',
      nameCard: ''
    };

    if (currentTab == 0) {

      if (this.DatosRegistro.usuSesion == "") {
        this.errorForm.UsuSesion = 'Username is necessary'
        success = false
      }

      if (this.DatosRegistro.usuPass == "") {
        this.errorForm.UsuPass = 'Password is necessary'
        success = false
      }

      if (this.DatosRegistro.usuPass.length < 6) {
        this.errorForm.UsuPass = 'Password should be at least 6 digits'
        success = false
      }

      if ((<HTMLInputElement>document.getElementById('confirmarUsuPass')).value == "") {
        this.errorForm.confirmarUsuPass = 'Confirm password is necessary'
        success = false
      }

      if ((<HTMLInputElement>document.getElementById('confirmarUsuPass')).value != this.DatosRegistro.usuPass) {
        this.errorForm.confirmarUsuPass = 'Password does not match confirmation'
        success = false
      }

      if (this.DatosRegistro.usuEmail == "") {
        this.errorForm.UsuEmail = 'Email is necessary'
        success = false
      }

      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      //return regex.test(email);
      if(regex.test(this.DatosRegistro.usuEmail) == false) {
        this.errorForm.UsuEmail = 'The email is not in the correct format'
        success = false
      }
    }

    if (currentTab == 1) {

      if (this.imageURL == "") {
        this.errorForm.usuFoto = 'Photo is necessary'
        success = false
      }

      if (this.DatosRegistro.usuNombres == "") {
        this.errorForm.UsuNombres = 'First Name is necessary'
        success = false
      }

      if (this.DatosRegistro.usuApellidos == "") {
        this.errorForm.UsuApellidos = 'LastName is necessary'
        success = false
      }

      if (this.DatosRegistro.usuTelefonoBill == "") {
        this.errorForm.UsuTelefono = 'Telephone is necessary'
        success = false;
      }

      if (this.DatosRegistro.usuDireccionBill == "") {
        this.errorForm.UsuDireccion = 'Address is necessary'
        success = false;
      }
      if (this.DatosRegistro.usuCiudadBill == "") {
        this.errorForm.UsuCiudadBill = 'City is necessary'
        success = false;
      }
      if (this.DatosRegistro.usuStateBill == "") {
        this.errorForm.UsuStateBill = 'State is necessary'
        success = false;
      }
      if (this.DatosRegistro.usuZipCodeBill == "") {
        this.errorForm.UsuZipCodeBill = 'ZipCode is necessary'
        success = false;
      }
      if (this.DatosRegistro.paiIDBill == 0) {
        this.errorForm.UsuCountry = 'Country is necessary'
        success = false;
      }

      if (this.usertype == "0") {
        document.getElementById('account-cost').innerText = 'Please Select account type'
        document.getElementById('account-cost').style.color = "red";
        success = false;
      }

      if (this.usertype == "2") {

        if (this.DatosRegistro.empName == "") {
          this.errorForm.empName = 'Company name is necessary'
          success = false;
        }
        if (this.DatosRegistro.empTaxID == "") {
          this.errorForm.empTaxID = 'EIN is necessary'
          success = false;
        }
        if (this.DatosRegistro.empSalesTaxLicID == "") {
          this.errorForm.empSalesTaxLicID = 'Sales Tax ID is necessary'
          success = false;
        }
        if (this.DatosRegistro.empBusinessLinc == "") {
          this.errorForm.empBusinessLinc = 'Business License is necessary'
          success = false;
        }
      }

      if ((<HTMLInputElement>document.getElementById('Condiciones')).checked == false) {
        this.errorForm.Condiciones = 'the terms and conditions must be accepted'
        success = false;
      }
    }

    if (currentTab == 2) {
      var _zipCode = (<HTMLInputElement>document.getElementById('billing-street-zip')).value;
      var _name = (<HTMLInputElement>document.getElementById('cardholder-name')).value;
      var _number = Number((<HTMLInputElement>document.getElementById('card-number')).value.replace(/ /g, ""));
      var _cvv = Number((<HTMLInputElement>document.getElementById('cvv')).value);
      var _expirationDate = (<HTMLInputElement>document.getElementById('expiry-date')).value;

      if (_name == "") {
        this.errorForm.nameCard = 'Name is necessary'
        success = false
      }

      if (_number.toString().length != 16) {
        this.errorForm.CardNumber = 'The card number should be at least 16 digits'
        success = false
      }

      if (_cvv.toString().length != 3) {
        this.errorForm.cvv = 'Incorrect cvv'
        success = false;
      }

      if (_expirationDate.length != 5) {
        success = false;
        this.errorForm.CardExpiryDate = 'incorrect Date'
      }

    }

    return success;
  }

  ValidateCurrentImput(currentImput: string) {
    this.errorForm[currentImput] = ''
  }

  //Method Handles operations after a user accout type has been selected.
  userSelected(type: string) {

    const _account = document.getElementById('account-cost');

    let NormalCost = "Normal account is free for all users";
    let BusinessCost = "Business account costs $150.00 USD per month.";

    this.usertype = type
    if (this.usertype == "0") {
      _account.innerText = 'select account type';
      _account.style.color = "white";
    }
    else if (this.usertype == "1") {
      _account.innerHTML = NormalCost;
      _account.style.color = "black";

    }
    else if (this.usertype == "2") {
      _account.innerHTML = BusinessCost;
      _account.style.color = "black";
    }
  }

  gettokenPaypal() {
    this._productosService.getTokenpaypal().subscribe(
      res => {
        this.tokenClient = res
      }, err => {
        console.log("...");
        console.log(err);

      }
    );
  }

  detectFiles(event: any) {
    this.urls = [];
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.urls.push(e.target.result);
        }
        reader.readAsDataURL(file);
      }
    }
    console.log(this.urls);
  }

  register(payToken: string) {
    this.loading = true;

    //this.credentials.UsuFoto = ((document.getElementById("preview") as HTMLInputElement).src);
    this.DatosLogin.Usuario = this.DatosRegistro.usuSesion;
    this.DatosLogin.Password = this.DatosRegistro.usuPass;

    try {
      this.DatosRegistro.usuFoto = this.imageURL
      this.DatosRegistro.paymentId = payToken;

      this.DatosRegistro.usuType = Number(this.usertype)

      this.DatosRegistro.usuStateShip = this.DatosRegistro.usuStateBill;
      this.DatosRegistro.usuCiudadShip = this.DatosRegistro.usuCiudadBill;
      this.DatosRegistro.usuZipCodeShip = this.DatosRegistro.usuZipCodeBill;
      this.DatosRegistro.usuDireccionShip = this.DatosRegistro.usuDireccionBill;
      this.DatosRegistro.ususTelefonoShip = this.DatosRegistro.usuTelefonoBill;
      this.DatosRegistro.usuDireccion2Ship = this.DatosRegistro.usuDireccion2Bill;
      this.DatosRegistro.paiIDShip = this.DatosRegistro.paiIDShip;

      this.auth.register(this.DatosRegistro).subscribe(
        data => {

          this.auth.login(this.DatosLogin).subscribe(res => {

            localStorage.setItem('usuId', res.usuId);
            localStorage.setItem('usuSesion', res.usuSesion);
            localStorage.setItem('usuFoto', res.usuFoto);
            localStorage.setItem('token', res.token);
            if (res.empID != 0) {
              localStorage.setItem('empID', res.empID);
            }

            //try to register topics to firebase
            getToken(this.msg, { vapidKey: FireBaseCredencials.vapidKeyToken }).then((tok) => {

              //check if a Company and register topic to empId
              if (res.empID != 0) {
                localStorage.setItem('empID', res.empID);
                fetch('https://iid.googleapis.com/iid/v1/' + tok + '/rel/topics/emp' + res.empID, {
                  method: 'POST',
                  headers: new Headers({
                    'Authorization': FireBaseCredencials.vpauth
                  })
                }).then(async (response) => {
                  if (response.status == 200) {
                    console.log("Company has been suscribed")
                  }
                  else {
                    var ress = await response.json();
                    throw 'Error subscribing to topic emp: ' + response.status + ' - ' + await response.json();
                  }
                }).catch(error => {
                  console.error(error);
                })
              }

              //register topic to usuId
              fetch('https://iid.googleapis.com/iid/v1/' + tok + '/rel/topics/' + res.usuId, {
                method: 'POST',
                headers: new Headers({
                  'Authorization': FireBaseCredencials.vpauth
                })
              }).then(async (response) => {

                if (response.status == 200) {
                  console.log("User has been suscribed")
                }
                else {
                  console.log(response);
                  throw 'Error subscribing to topic Usu: ' + response.status + ' - ' + await response.json();
                }

              }).catch(error => {
                console.error(error);
              })
            })

            this.router.navigateByUrl('/');

          }, (err) => {

            this.loading = false;
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: err.error,
              showConfirmButton: false,
              timer: 1500
            })
          }
          );

        },
        err => {
          this.loading = false;
          console.log(err);
          Swal.fire({
            icon: 'error',
            position: 'top-end',
            title: err.error,
            showConfirmButton: true
          })
        });

    } catch (error) {
      this.loading = false;
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: error,
        showConfirmButton: true,
      })
      console.log(error);
    }
  }

}
