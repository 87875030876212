<app-navbar></app-navbar>
<app-buscar></app-buscar>
<div class="row p-5">
  <h3 class="mb-2 text-primary">
    Order Detail </h3>
    <div class="col-md-4 mt-4  mr-2 mb-5" >
        <h4 class=" text-left font-weight-light "><strong > Discount: {{0 | currency}} </strong></h4>
        <h4 class=" text-left font-weight-light "><strong >Sppining: {{0 | currency}}</strong></h4>
        <h4 class=" text-left font-weight-light "><strong >SubTotal: {{ subTotal | currency}}</strong></h4>
        <h6 class=" text-left font-weight-light "><strong >Total de Articulos: {{ totalItems }} </strong></h6>
        <hr>
        <h4 class=" text-left font-weight-light text-primary"><strong >Total: {{ subTotal | currency}}</strong></h4>
        </div>
        <div class="col-md-8 mt-8  ml-5 mb-5" >
            <h3 class="mb-3 text-primary">
              Payment method</h3>
            <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseTwo">
                      Pay with Paypal
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <div class="row">
                          <button class="btn btn-primary text-white" (click)="bntPaypal()"> <i class="fa fa-paypal  text-white"></i>  Pay</button> 
                      </div>
                    </div>
                  </div>
                </div>
               <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Pay with credit card
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <form [formGroup]="card">
                        <div class="row">
                          <div class="col-md-6 mb-3">
                              <label for="cc-name">Name on card*</label>
                              <input type="text" class="form-control" id="cc-name" placeholder=""  id="cc-name" formControlName="_name" [ngClass]="{ 'is-invalid': submitted && f._name.errors }">
                              <div *ngIf="submitted && f._name.errors" class="invalid-feedback">
                                Name on card is required
                              </div>
                            </div>
                            <div class="col-md-4 mb-3">
                              <label for="cc-number">Credit card number*</label>
                              <input type="text" class="form-control" id="cc-number" placeholder=""  id="cc-number" formControlName="number"  [ngClass]="{ 'is-invalid': submitted && f.number.errors }">
                              <div *ngIf="submitted && f.number.errors" class="invalid-feedback">
                                Credit card number is required
                              </div>
                            </div>
                          </div>
                          <div class="row">
                              <div class="col-md-3 mb-3">
                                  <label for="cc-number">Expiration*</label>
                                  <input type="text" class="form-control" id="exp-number" placeholder="" id="exp-number" formControlName="expirationDate"  [ngClass]="{ 'is-invalid': submitted && f.expirationDate.errors }">
                                  <div  *ngIf="submitted && f.expirationDate.errors" class="invalid-feedback">
                                    Credit card number is required
                                  </div>
                                </div>
                                <div class="col-md-3 mb-3">
                                  <label for="cc-number">CVV*</label>
                                  <input type="text" class="form-control" id="ccv-number" placeholder="" formControlName="ccv" [ngClass]="{ 'is-invalid': submitted && f.ccv.errors }">
                                  <div *ngIf="submitted && f.ccv.errors" class="invalid-feedback">
                                    CVV number is required
                                  </div>
                                </div>
                                <div class="col-md-3 mb-3">
                                  <label for="cc-number">Postal Code</label>
                                  <input type="text" class="form-control" id="ccv-number" placeholder="" required>
                                  <div class="invalid-feedback">
                                    Postal Code is required
                                  </div>
                                </div>
                                
                                <hr>
                                <div class="row">
                                      <button class="btn btn-primary text-white" (click)="bntCreditcard()"> <i class="fa fa-credit-card"></i>  Pay</button>
                                </div>  
                      </div>  
                      </form>     
                    </div>
                  </div>
                </div>
              </div>
        
