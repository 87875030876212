import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { GetFacturaViewModel } from '../../Entities/Factura';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { vwEmpresas }  from '../../Entities/Empresa';
import { Usuario }  from '../../Entities/Usuario';

@Component({
  selector: 'app-invoce-detail',
  templateUrl: './invoce-detail.component.html',
  styleUrls: ['./invoce-detail.component.scss']
})
export class InvoceDetailComponent implements OnInit {

  constructor(
    private _adminService: AdminService,
    private _Activedroute: ActivatedRoute,
    private _router: Router) { }

  currentFactura= new GetFacturaViewModel();
  loading = false;
  total = 0;
  subTotal = 0;


  ngOnInit(): void {

    this._Activedroute.params.subscribe(params => {
      let id = params.facId;
      this.GetTerminosPagos(id)
    });

  }

  GetTerminosPagos(id:any) {
    this.loading = true;
    try {

      this._adminService.getFacturaDetail(id).subscribe(
        response => {

          this.currentFactura = response;
          console.log(response);
          console.log(this.currentFactura);
          if(this.currentFactura.empresa == null)
            this.currentFactura.empresa = new vwEmpresas()
          
          if(this.currentFactura.cliente == null){
            var usu = new Usuario()
            usu.usuEmail = this.currentFactura.factura.facEmail;
            usu.usuTelefonoBill =  this.currentFactura.factura.facTelefono;
            usu.usuNombres =  this.currentFactura.factura.facCompania;
            usu.usuDireccionShip =  this.currentFactura.factura.facComentario;
            this.currentFactura.cliente = usu;
          }
        this.loading = false;
        }, (err: any) => {

          if (err.error == 'You do not have permissions to access to Parts') {
            Swal.fire({
              icon: 'error',
              title: err.error,
              showConfirmButton: true
            }).then(() => {
              this._router.navigateByUrl('/Dashboard');
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: err.error,
              showConfirmButton: true
            })
          }
          console.log(err);
          this.loading = false;
        })

    } catch (TypeError) {
      this.loading = false;
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: TypeError as string,
        showConfirmButton: true,
      });
      console.log(TypeError);
    }
  }

  print(){
    document.getElementById("print_btn").style.display = "none";
    window.print();
    document.getElementById("print_btn").style.display = "block";
  }

}
