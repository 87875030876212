<div id="content">
  <div class="d-flex align-items-center">
    <nav
      style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
      aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/Dashboard">Dashboard</a></li>
        <li class="breadcrumb-item active" aria-current="page">Defaults</li>
      </ol>
    </nav>
  </div>
</div>
<hr>
<div class="row px-3 py-0 m-0">
  <div class="d-flex flex-row bd-highlight mb-0 justify-content-between">

    <div class="d-flex flex-row">
      <div class="px-2 py-0 m-0 bd-highlight">
        <h2 class="text-primary">Defaults</h2>
      </div>
      <div class="px-2 py-0 m-0 bd-highlight">
        <button type="button" class="btn btn-outline-primary btn-lg" [routerLink]="['/Dashboard/Defaultscrear']">add <i
            class="fa fa-plus"></i>
        </button>
      </div>
    </div>

    <div class="px-2 py-0 m-0 bd-highlight">
      <h3 class="text-primary">Total Defaults: {{totalDefault}}</h3>
    </div>

  </div>
</div>
<div class="row">
  <form [formGroup]="myForm">
    <div class="m-2 mt-5 row">

      <div class="col-4 col-sm-4 col-md-5 col-lg-5 nav-item mt-3">
        <h6 class="text-primary">Category</h6>

        <ng-multiselect-dropdown name="catf" formControlName="catf" [(ngModel)]="this.currentCategory"
          [placeholder]="'Select Category'" [settings]="{      
            closeDropDownOnSelection: true, 
            singleSelection: true,
            idField: 'catID',
            textField: 'catDescripcion',
            itemsShowLimit: 1,
            allowSearchFilter: true}" [data]="categorias" (onDeSelect)="UnSelectObject($event)"
          (onSelect)="cargarPart()"></ng-multiselect-dropdown>
      </div>

      <div class="col-4 col-sm-4 col-md-5 col-lg-5 nav-item mt-3">
        <h6 class="text-primary">Part name</h6>
        <input type="text" class="form-control" id="partName_ip" placeholder="Search..."
          aria-label="Recipient's username" aria-describedby="button-addon2" (keydown.enter)="cargarPart()">
      </div>

      <div class="col-4 col-sm-4 col-md-2 col-lg-2 nav-item mt-3">
        <h6 class="text-primary">Buscar</h6>
        <button (click)="cargarPart()" class="btn btn-primary text-white">
          Search </button>
      </div>
    </div>

  </form>
</div>

<div *ngIf="loading" class="row p-2">
  <div class="row">
    <div class="col-6 col-sm-6 col-md-4 col-lg-3 portfolio-item my-1 rounded-lg">
      <app-ph class="p-3">
        (tipo)=''home''
      </app-ph>
    </div>

    <div class="col-6 col-sm-6 col-md-4 col-lg-3 portfolio-item my-1 rounded-lg">
      <app-ph class="p-3">
        (tipo)="home"
      </app-ph>
    </div>
    <div class="col-6 col-sm-6 col-md-4 col-lg-3 portfolio-item my-1 rounded-lg">
      <app-ph class="p-3">
        [tipo]="home"
      </app-ph>
    </div>
    <div class="col-6 col-sm-6 col-md-4 col-lg-3 portfolio-item my-1 rounded-lg">
      <app-ph class="p-3">
        [tipo]="home"
      </app-ph>
    </div>
  </div>
</div>

<div *ngIf="parts.length == 0" class="p-2 px-0 d-flex justify-content-center">
  <div class="d-flex flex-column justify-content-center">
    <h3 class="d-flex justify-content-center">Data not found.</h3>
    <img class="d-flex justify-content-center" src="../../../assets/img/phvp.png" width="200" height="200" alt="">
  </div>
</div>

<div class="row p-3">
  <div class="card mb-3" *ngFor="let resultado of parts let i = index">
    <div class="row g-0">
      <div class="col-7 col-sm-7 col-md-8 col-lg-8 d-flex align-items-center">
        <div class="card-body">
          <p class="Nombre-text">{{resultado.piePreNombre}}</p>

        </div>
        <td colspan="4" class="p-3 redboton"><button class="btn btn-danger btn-md" (click)="borrarDefault(resultado)"><i
              class="fa fa-trash"></i></button></td>

      </div>

    </div>
  </div>
</div>

<app-footer></app-footer>