import { Component, OnInit } from '@angular/core';
import { AdminService,Rol } from '../../../services/admin.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-permisos',
  templateUrl: './permisos.component.html',
  styleUrls: ['./permisos.component.scss'],
})
export class PermisosComponent implements OnInit {
  rolePermisos: any[] = [];

  loading = false;

  myForm: FormGroup;
  constructor(private _fb: FormBuilder, private service: AdminService, private _router: Router) {}

  ngOnInit(): void {
    this.myForm = this._fb.group({
      multipleS: this.rolePermisos,
    });
    this.getPermisos();
  }

  getPermisos() {
    this.loading = true;
    this.service.getPermisos().subscribe(
      (res) => {
        this.rolePermisos = res;
        this.loading = false;

        console.log(res);
      },
      (err) => {
        this.loading = false;
        if(err.error.includes('You do not have permissions to access to ')){
          Swal.fire({
            icon: 'error',
            title:err.error,
            showConfirmButton: true
          }).then(()=>{
            this._router.navigateByUrl('/Dashboard');
          })
        }else{
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: err.error,
            showConfirmButton: false,
            timer: 1500,
          });           
         }
        console.error(err.error);
      }
    );
  }
}
