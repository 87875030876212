import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ProductosService, detalleCarrito, brrCarrito, datosGuardar, datosBuscar, datosPieza, pieVendida } from '../../services/productos.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import Swal from 'sweetalert2';
import { LocationService } from '../../services/location.service';
import { UsuarioService } from '../../services/usuario.service';
import { AdminService } from '../../services/admin.service';



@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.scss']
})
export class ProductoComponent implements OnInit {

  public producto: any;
  public proImagenes: any;
  public img1: string;
  public UsuId: any;
  public EmpId: any;
  public cant: any;
  public MontoTotal;
  public btnAgregar = true;
  public btnGuardar = true;
  public resultados: any;
  public modelo: any;
  public latitude: number;
  public longitude: number;
  public latitude2: number;
  public longitude2: number;
  public distancia: any;
  public infoOwner: any;
  public despHtml: string;

  loadig: Boolean = false;
  loadigrecon: Boolean = false;
  previewImageSrc: any;
  zoomImageSrc: any;

  datosCarrito: detalleCarrito = {

    PedPosicion: 0,
    PieID: 0,
    PedUsuID: 0,
    EmpID: 0,
    PedCantidad: 0,
    PedPrecio: 0,
    PedPorcientoIImpuestos: 0,
    PedMontoImpuestos: 0,
    PedDescuentoPorciento: 0,
    PedMontoDescuento: 0,
    PedMontoSubTotal: 0,
    PedMontoTotal: 0,
    PedMontoEnvio: 0,
    //PedFecha: string,
    PedNumTracking: '',
    UsuID: 0,
    PedEstatus: 0,
    PedNota: '',
    PedImagen: '',
    ProNombre: ''

  };

  datoBorrar: brrCarrito = {

    PedPosicion: 0,
    PieID: 0,
    PedUsuID: 0,
    EmpID: 0,
    PedCantidad: 0,
    PedPrecio: 0,
    PedPorcientoIImpuestos: 0,
    PedMontoImpuestos: 0,
    PedDescuentoPorciento: 0,
    PedMontoDescuento: 0,
    PedMontoSubTotal: 0,
    PedMontoTotal: 0,
    PedMontoEnvio: 0,
    //PedFecha: string,
    PedImagen: '',
    ProNombre: '',
    PedEstatus: 0,
    PedNumTracking: '',
    UsuID: 0,
    PedNota: ''
  };

  datosGuardar: datosGuardar = {

    guardId: 0,
    pieId: 0,
    userId: 0
    // guardFecha: ''

  }

  datosBuscar: datosBuscar = {

    buscar: '',
    cat: 0,
    mo: '',
    mar: '',
    anos: '',
    freeshipping: false,
    ubicacion: '',
    pageNumber: 1

  }

  pieVendida: pieVendida = {

    cantidad: 0,
    pieId: 0

  }

  numeroTel = '';
  canCall = false;

  constructor(
    private _productosService: ProductosService,
    private _adminService: AdminService,
    private _usuarioService: UsuarioService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _locationService: LocationService,
  ) {

  }

  ngOnInit(): void {
    this._route.params.subscribe(params => {
      let id = params.id;
      if (localStorage.getItem('empID') == null || localStorage.getItem('empID') == "0") {
        this.EmpId = 0;
      }
      else
        this.EmpId = localStorage.getItem("empID")

      this._productosService.getPedidosTemp().subscribe(
        res => {
          for (var i = 0; i < res.length; i++) {
            if (res[i].pieID == id) {
              this.btnAgregar = false;
              break;
            }
          }
        }, err => { })

      this._productosService.getGuardados().subscribe(
        res => {
          for (var i = 0; i < res.length; i++) {
            if (res[i].pieId == id) {
              this.btnGuardar = false;
              break;
            }
          }
        }, err => {
          console.log(err);
        })
      this.getProducto(id);
    });

  }

  getProducto(id) {
    this._productosService.getProducto(id).subscribe(
      response => {
        this.producto = response;
        this.loadig = true;
        this.proImagenes = this.producto.pieImagenes[0];
        this.modelo = this.producto.modelId;
        (<HTMLInputElement>document.getElementById('pDespc')).innerHTML = this.producto.pieDescripcion;
        this.latitude2 = Number(this.producto.pieLatitud);
        this.longitude2 = Number(this.producto.pieLongitud);
        this.numeroTel = response.telOwner.replace("-\g", "").replace("(\g", "").replace(")\g", "");

        if (this.numeroTel != "0") {
          this.canCall = true;
        }

        console.log(response)
        this.Owner(id);
        this._locationService.getPosition().then(pos => {
          this.latitude = Number(pos.lat);
          this.longitude = Number(pos.lng);
          var R = 6378.137; //Radio de la tierra en km
          var dLat = (this.latitude2 - this.latitude) * Math.PI / 180;
          var dLong = (this.longitude2 - this.longitude) * Math.PI / 180;
          var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.latitude * Math.PI / 180) * Math.cos(this.latitude2 * Math.PI / 180) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
          var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
          var d = R * c;
          this.distancia = d.toFixed(3);
        });
        this.getRecomendados();
      },
      error => {

        console.log(<any>error);
      }
    )
  }

  postCarrito() {
    this.cant = Number((<HTMLInputElement>document.getElementById('cantPr')).value);
    this.MontoTotal = this.cant * this.producto.piePrecio;
    this.datosCarrito.PedMontoTotal = this.MontoTotal;
    this.datosCarrito.PedMontoSubTotal = this.MontoTotal;
    this.datosCarrito.PedCantidad = this.cant;
    this.datosCarrito.PieID = this.producto.pieId;
    this.datosCarrito.PedImagen = this.proImagenes;
    this.datosCarrito.ProNombre = this.producto.pieNombre;
    this.datosCarrito.PedPrecio = this.producto.piePrecio;
    this.datosCarrito.UsuID = Number(this.UsuId);
    this._productosService.postCarrito(this.datosCarrito).subscribe(
      response => {
      },
      err => {
        if (err.status == 200) {
          window.location.reload();
        }

        if (err.status == 401) {
          Swal.fire({
            title: 'You are not logged in',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#008c44',
            cancelButtonColor: '#d33',
            confirmButtonText: 'go to login'
          }).then((result) => {
            if (result.isConfirmed) {
              this._router.navigateByUrl('/login');
            }
          })
        }
        console.log(err);
      })
  }

  borrarItem(item: any) {
    this.datoBorrar.PieID = item.pieId;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {

      if (result.isConfirmed) {
        this._productosService.borrartCarrito(this.datoBorrar).subscribe(
          res => {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            window.location.reload();
          }, err => {

            if (err.status == 200) {
              window.location.reload();
            }
            console.log(err);
          }
        )
      }
    })
  }

  postGuardar() {
    this.datosGuardar.pieId = this.producto.pieId;
    console.log(this.datosGuardar);
    this._productosService.postGuardar(this.datosGuardar).subscribe(
      res => {


      }, err => {
        if (err.status == 200) {
          if (err.error.text == "Quitado") {
            Swal.fire(
              'Deleted',
              'Your part has been deleted.',
              'success'
            )
            window.location.reload();
          } else {
            Swal.fire(
              'Saved',
              'Your part has been saved.',
              'success'
            )
            window.location.reload();
          }
        }
        if (err.status == 401 && !window.localStorage.getItem('token')) {
          Swal.fire({
            title: 'You are not logged in',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#008c44',
            cancelButtonColor: '#d33',
            confirmButtonText: 'go to login'
          }).then((result) => {
            if (result.isConfirmed) {
              this._router.navigateByUrl('/login');
            }
          })
          if (err.status == 401 && window.localStorage.getItem('token')) {
            // window.localStorage.removeItem('token');
            this._router.navigateByUrl('/Relogin')
          }
        }
        // console.log(err);
      }
    )
  }

  getRecomendados() {
    this.datosBuscar.mar = this.producto.marcaString;
    this._productosService.getBuscar(this.datosBuscar).subscribe(
      res => {
        this.loadigrecon = true;
        const { results, totalRecordCount } = res;
        this.resultados = results;
      }, err => {
        console.log(err);
      })
  }

  Owner(pieId: number) {
    this._productosService.PiezaOwner(pieId).subscribe(
      (res: any) => {
        this.infoOwner = res;
      }, err => {
        console.log(err);
      })
  }

  CallOwner() {
    var ua = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      this._router.navigateByUrl(`tel:+1${this.numeroTel}`)
    }
    else {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: `Phone number: ${this.numeroTel} `,
        showConfirmButton: true
      })
    }

  }

  Vendida() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Marked as sold!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Marke it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.pieVendida.cantidad = Number((<HTMLInputElement>document.getElementById('cantPr')).value);
        this.pieVendida.pieId = this.producto.pieId;
        this._productosService.Vendido(this.pieVendida).subscribe(
          res => {
            console.log(res);
            Swal.fire(
              'Mark as sold',
              'Marked as sold!!',
              'success'
            ).then(() => {
              window.location.reload();
            })
          }, err => {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: err.error,
              showConfirmButton: false,
              timer: 1500,
            });
          })
      }
    })
  }
}
