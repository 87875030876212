<div id="content">
    <div class="d-flex align-items-center">
        <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
            aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/Dashboard">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page">Permissions</li>
            </ol>
        </nav>
    </div>
</div>
<hr>
<div class="row p-3">
    <div class="d-flex flex-row bd-highlight mb-3">
        <div class="p-2 bd-highlight">
            <h2 class="text-primary">Permissions</h2>
        </div>
        <div class="p-2 bd-highlight"> <button type="button" class="btn btn-outline-primary btn-lg"
                [routerLink]="['/Dashboard/permitCrear']">add <i class="fa fa-plus"></i></button></div>
    </div>
</div>

<div class="row flex-nowrap pb-5">
    <div class="row">
        <div class="d-flex justify-content-center" *ngIf="loading; ">
            <div class="spinner-border text-primary  m-3" role="status" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div *ngIf="!loading; ">

            <div class="card mb-1 p-0" *ngFor="let permiso of rolePermisos">
                <div class="row">

                    <div class="col-8 col-sm-8 col-md-8 col-md-8">
                        <div class="card-body">
                            <a>
                                <p class="Nombre-text">#{{permiso.perID}} - {{permiso.permisoString}} </p>
                            </a>
                        </div>

                    </div>

                </div>
            </div>

        </div>
    </div>
</div>