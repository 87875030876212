import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {NgxPaginationModule} from 'ngx-pagination'; 
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgxImageCompressorModule } from 'ngx-image-compressor';
import { NgxPayPalModule } from 'ngx-paypal';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { InicioComponent } from './components/inicio/inicio.component';
import { ProductoComponent } from './components/producto/producto.component';
import { LoginComponent } from './components/login/login.component';
import { RegistrarComponent } from './components/registrar/registrar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PHComponent } from './components/ph/ph.component';
import { RecuperarpassComponent } from './components/recuperarpass/recuperarpass.component';
import { CambiarpassComponent } from './components/cambiarpass/cambiarpass.component';
import { BuscarComponent } from './components/buscar/buscar.component';
import { CommonModule } from '@angular/common';
import { ResultadosComponent } from './components/resultados/resultados.component';
import { CarritofullComponent } from './components/carritofull/carritofull.component';
import { GuardadosComponent } from './components/guardados/guardados.component';
import { ProfileComponent } from './components/profile/profile.component';
import { PayComponent } from './components/pay/pay.component';
import { AddpiezaComponent } from './components/addpieza/addpieza.component';
import { FooterComponent } from './components/footer/footer.component';
import { EditprofileComponent } from './components/editprofile/editprofile.component';
import { AuthGuardGuard } from './components/guard/auth-guard.guard';
import { RelacionadosComponent } from './components/relacionados/relacionados.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CarroComponent } from './components/carro/carro.component';
import { NgxImgZoomModule  } from 'ngx-img-zoom';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { ChatService } from './services/chat.service';
import { DefaultService } from './services/default.service';
import { environment } from  '../environments/environment';
import { initializeApp } from 'firebase/app';
import { AllmessegeComponent } from './components/allmessege/allmessege.component';
import { OrdenesComponent } from './components/ordenes/ordenes.component';
import { RedirectGuardGuard } from './components/redirect-guard.guard/redirect-guard.guard';
import { SearchFilterPipe } from './pipe/search-filter.pipe';
import { EditPiezaComponent } from './components/edit-pieza/edit-pieza.component';
import { OwnerComponent } from './components/owner/owner.component';
import { CommunicationServiceService } from './services/communication-service.service';
import { RedZoomModule } from 'ngx-red-zoom';
import { PlanesComponent } from './components/planes/planes.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ReloginComponent } from './components/relogin/relogin.component';
import { AdminCarroComponent } from './components/admin/admin-carro/admin-carro.component';
import { AdminPartesComponent } from './components/admin/admin-partes/admin-partes.component';
import { CrearCarroComponent } from './components/admin/crear-carro/crear-carro.component';
import { CrearParteComponent } from './components/admin/crear-parte/crear-parte.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { InicioAdminComponent } from './components/admin/inicio-admin/inicio-admin.component';
import { BusquedaComponent } from './components/busqueda/busqueda.component';
import { AdminService } from './services/admin.service';
import { EditarCarroComponent } from './components/admin/editar-carro/editar-carro.component';
import { DefaultsComponent } from './components/admin/defaults/defaults.component';
import { CompaniesComponent } from './components/admin/companies/companies.component';
import { MultipleUsesComponent } from './components/admin/multiple-uses/multiple-uses.component';
import { DefaultsCrearComponent } from './components/admin/defaults-crear/defaults-crear.component';
import { CompaniesCrearComponent } from './components/admin/companies-crear/companies-crear.component';
import { MultipleusesCrearComponent } from './components/admin/multipleuses-crear/multipleuses-crear.component';
import { EbayComponent } from './components/admin/ebay/ebay.component';
import { EstadosComponent } from './components/admin/estados/estados.component';
import { CrearEstadosComponent } from './components/admin/crear-estados/crear-estados.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { UsuariosComponent } from './components/admin/usuarios/usuarios.component';
import { UsersCrearComponent } from './components/admin/users-crear/users-crear.component';
import { UsersEditarComponent } from './components/admin/users-editar/users-editar.component';
import { RolesComponent } from './components/admin/roles/roles.component';
import { RolesCrearComponent } from './components/admin/roles-crear/roles-crear.component';
import { PermisosComponent } from './components/admin/permisos/permisos.component';
import { PermisosCrearComponent } from './components/admin/permisos-crear/permisos-crear.component';
import { RolesEditarComponent } from './components/admin/roles-editar/roles-editar.component';
import { CompaniesEditarComponent } from './components/admin/companies-editar/companies-editar.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { PartLocationComponent } from './components/admin/part-location/part-location.component';
import { TiresComponent } from './components/admin/tires/tires.component';
import { TiresCrearComponent } from './components/admin/tires-crear/tires-crear.component';
import { TiresEditarComponent } from './components/admin/tires-editar/tires-editar.component';
import { StoreSaleComponent } from './components/admin/store-sale/store-sale.component';
import { InvoceDetailComponent } from './components/invoce-detail/invoce-detail.component';
import { AdminInvoicesComponent } from './components/admin/admin-invoices/admin-invoices.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxMaskModule } from 'ngx-mask';
import { SelectAutoFoucsDirective } from './helpers/select-auto-foucs.directive';
import { PhotoCaptureComponent } from './components/photo-capture/photo-capture.component';

initializeApp({
  apiKey: "AIzaSyA5J2t8k65U21ezEKjQ1uprzBsyoWEK7zM",
  authDomain: "vehiclepartsapp.firebaseapp.com",
  projectId: "vehiclepartsapp",
  storageBucket: "vehiclepartsapp.appspot.com",
  messagingSenderId: "340686574692",
  appId: "1:340686574692:web:c10a270dcb754e95703b71",
  measurementId: "G-YM2MMSVJCM"
});

@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    ProductoComponent,
    LoginComponent,
    RegistrarComponent,
    NavbarComponent,
    PHComponent,
    RecuperarpassComponent,
    CambiarpassComponent,
    BuscarComponent,
    ResultadosComponent,
    CarritofullComponent,
    GuardadosComponent,
    ProfileComponent,
    PayComponent,
    AddpiezaComponent,
    FooterComponent,
    EditprofileComponent,
    RelacionadosComponent,
    CarroComponent,
    AllmessegeComponent,
    OrdenesComponent,
    SearchFilterPipe,
    EditPiezaComponent,
    OwnerComponent,
    PlanesComponent,
    InvoicesComponent,
    ReloginComponent,
    AdminCarroComponent,
    AdminPartesComponent,
    CrearCarroComponent,
    CrearParteComponent,
    DashboardComponent,
    InicioAdminComponent,
    BusquedaComponent,
    EditarCarroComponent,
    DefaultsComponent,
    CompaniesComponent,
    MultipleUsesComponent,
    DefaultsCrearComponent,
    CompaniesCrearComponent,
    MultipleusesCrearComponent,
    EstadosComponent,
    CrearEstadosComponent,
    EbayComponent,
    UsuariosComponent,
    UsersCrearComponent,
    UsersEditarComponent,
    RolesComponent,
    RolesCrearComponent,
    PermisosComponent,
    PermisosCrearComponent,
    RolesEditarComponent,
    CompaniesEditarComponent,
    PartLocationComponent,
    TiresComponent,
    TiresCrearComponent,
    TiresEditarComponent,
    StoreSaleComponent,
    InvoceDetailComponent,
    AdminInvoicesComponent,
    SelectAutoFoucsDirective,
    PhotoCaptureComponent,
  ],
  imports: [
    ModalModule.forRoot(),  
    NgMultiSelectDropDownModule.forRoot(),
    NgxPayPalModule,
    AngularEditorModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    FormsModule,
    RedZoomModule,
    CommonModule,
    IvyCarouselModule,
    NgxImgZoomModule,
    SlickCarouselModule,
    QRCodeModule,
    ZXingScannerModule,
    NgApexchartsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    BrowserAnimationsModule,
  	ToastrModule.forRoot(),
    NgxMaskModule.forRoot()
  ],
  providers: [AuthGuardGuard,AdminService,CommunicationServiceService, ChatService,DefaultService, AsyncPipe, RedirectGuardGuard, {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
