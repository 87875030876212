export class FacturasDetalle {
  facID: number;
  facPosicion: number;
  pieID: number;
  usuID?: number;
  facCantidad?: number;
  facCantidadDetalle?: number;
  lipID?: number;
  facPrecio?: number;
  facPorcientoIImpuestos?: number;
  facMontoImpuestos?: number;
  facSelectivo?: number;
  facAdValorem?: number;
  desID?: number;
  facIndicadorDescuento?: boolean;
  facDesPorciento?: number;
  facMontoDescuento?: number;
  facIndicadorCompleto?: boolean;
  facIndicadorOferta?: boolean;
  cedCodigo: string;
  ofeID?: number;
  facTipoOferta?: number;
  undID?: number;
  taxIDNumber: string;
  taxValorPorciento?: number;
  taxMonto?: number;
  taxFechaExpiracion?: Date;
  facSubMontoTotal?: number;
  facMontoTotal?: number;
  facIndicadorExentoImpuesto?: boolean;
  facFechaActualizacion?: Date;
  usuInicioSesion: string;
  campo1: string;
  campo2: string;
  campo3?: number;
  campo4?: number;
  proNCD: string;
  uniID?: number;
  pedCantidadPendiente?: number;

  constructor() {
    this.facID = 0;
    this.facPosicion = 0;
    this.pieID = 0;
    this.usuID = 0;
    this.facCantidad = 0;
    this.facCantidadDetalle = 0;
    this.lipID = 0;
    this.facPrecio = 0;
    this.facPorcientoIImpuestos = 0;
    this.facMontoImpuestos = 0;
    this.facSelectivo = 0;
    this.facAdValorem = 0;
    this.desID = 0;
    this.facIndicadorDescuento = false;
    this.facDesPorciento = 0;
    this.facMontoDescuento = 0;
    this.facIndicadorCompleto = false;
    this.facIndicadorOferta = false;
    this.cedCodigo = '';
    this.ofeID = 0;
    this.facTipoOferta = 0;
    this.undID = 0;
    this.taxIDNumber = '';
    this.taxValorPorciento = 0;
    this.taxMonto = 0;
    this.taxFechaExpiracion = new Date();
    this.facSubMontoTotal = 0;
    this.facMontoTotal = 0;
    this.facIndicadorExentoImpuesto = false;
    this.facFechaActualizacion = new Date();
    this.usuInicioSesion = '';
    this.campo1 = '';
    this.campo2 = '';
    this.campo3 = 0;
    this.campo4 = 0;
    this.proNCD = '';
    this.uniID = 0;
    this.pedCantidadPendiente = 0;
  }
}


export class FacturasDetalleTemp {
  facUsuID: number;
  facPosicion: number;
  pieID: number;
  usuID?: number;
  facCantidad?: number;
  lipID?: number;
  facPrecio?: number;
  facPorcientoImpuestos?: number;
  facMontoImpuestos?: number;
  facSelectivo?: number;
  facAdValorem?: number;
  desID?: number;
  facIndicadorDescuento?: boolean;
  facDesPorciento?: number;
  facMontoDescuento?: number;
  facIndicadorCompleto?: boolean;
  facIndicadorOferta?: boolean;
  cedCodigo: string;
  ofeID?: number;
  facTipoOferta?: number;
  undID?: number;
  taxIDNumber: string;
  taxValorPorciento?: number;
  taxMonto?: number;
  taxFechaExpiracion?: Date;
  facSubMontoTotal?: number;
  facMontoTotal?: number;
  facIndicadorExcentoImpuesto?: boolean;
  facFechaActualizacion?: Date;
  campo1: string;
  campo2: string;
  campo3?: number;
  campo4?: number;
  facIndicadorExentoImpuesto?: boolean;
  uniID?: number;
  proNombre: string;
  proImagen: string;

  constructor() {
    this.facUsuID = 0;
    this.facPosicion = 0;
    this.pieID = 0;
    this.usuID = 0;
    this.facCantidad = 0;
    this.lipID = 0;
    this.facPrecio = 0;
    this.facPorcientoImpuestos = 0;
    this.facMontoImpuestos = 0;
    this.facSelectivo = 0;
    this.facAdValorem = 0;
    this.desID = 0;
    this.facIndicadorDescuento = false;
    this.facDesPorciento = 0;
    this.facMontoDescuento = 0;
    this.facIndicadorCompleto = false;
    this.facIndicadorOferta = false;
    this.cedCodigo = '';
    this.ofeID = 0;
    this.facTipoOferta = 0;
    this.undID = 0;
    this.taxIDNumber = '';
    this.taxValorPorciento = 0;
    this.taxMonto = 0;
    this.taxFechaExpiracion = new Date();
    this.facSubMontoTotal = 0;
    this.facMontoTotal = 0;
    this.facIndicadorExcentoImpuesto = false;
    this.facFechaActualizacion = new Date();
    this.campo1 = '';
    this.campo2 = '';
    this.campo3 = 0;
    this.campo4 = 0;
    this.facIndicadorExentoImpuesto = false;
    this.uniID = 0;
    this.proNombre = '';
    this.proImagen = '';
  }
}