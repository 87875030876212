import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ProductosService } from '../../../services/productos.service';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { BehaviorSubject } from 'rxjs';
import { BarcodeFormat } from '@zxing/library';
import { piezasBuscar } from '../../../Entities/Pieza';

@Component({
  selector: 'app-part-location',
  templateUrl: './part-location.component.html',
  styleUrls: ['./part-location.component.scss']
})
export class PartLocationComponent implements OnInit {

  @ViewChild('scanner', { static: false })
  scanner: ZXingScannerComponent;
  
  //scam
  availableDevices: MediaDeviceInfo[] = [];
  deviceCurrent: MediaDeviceInfo;
  deviceSelected: string;
  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];
  hasDevices: boolean;
  hasPermission: boolean;
  torchEnabled = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);

  noData = true;
  productos: any[] = [];
  cargar = false;
  page = 1;
  count = 0;
  pageSize = 20;

  mispiezas = new piezasBuscar();

  constructor(private _productosService: ProductosService,
    private router: Router) { }

  ngOnInit(): void {
  }

  getRequestParams(page: number, pageSize: number): any {
    let params: any = {};

    if (page) {
      params['pageNumber'] = page;
    }
    return params;
  }


  Mispiezas(limpiar) {
    this.productos = []; 
    this.noData = false;
    this.cargar = true;
    if(limpiar){
      (<HTMLInputElement>document.getElementById('tbxBuscar')).value = "";
      this.mispiezas.pageNumber = 1;
    }

    this.mispiezas.nombre = (<HTMLInputElement>document.getElementById('tbxBuscar')).value;

    if(this.mispiezas.nombre.length > 0){
      this._productosService.Mipiezas(this.mispiezas).subscribe(
        response => {
          console.log(response)
          const { results, totalRecordCount } = response;
          this.productos = results;
          this.count = totalRecordCount;
          this.cargar = false;
        }, (err: any) => {
          if (err.error == 'Carga Completa') {
            // this.cargar = false;
            if(this.page == 1){
              this.productos = [];
              this.count = 0;
            }
        }
        else{
          
          if(err.error == 'You do not have permissions to access to Parts'){
            Swal.fire({
              icon: 'error',
              title:err.error,
              showConfirmButton: true
            }).then(()=>{
              this.router.navigateByUrl('/Dashboard');
            })
          }
          
          this.productos = [];
          this.count = 0;
          console.log(err)
          this.noData = true;

        }
          this.cargar = false;

        })      
    }
    else{
      this.noData = true;
      this.cargar = false;
    }


  }

  handlePageChange(event: number): void {
    this.page = event;
    this.mispiezas.pageNumber = event;
    this.startup();
    this.Mispiezas(false);
  }

  startup() {
    window.scroll(0, 500);
    this.cargar = true;
  }

  imprSelec(nombre) {
    //https://api.qrserver.com/v1/create-qr-code/?size=150x150&data={{piezas.pieId}}
	  var ficha = document.getElementById(nombre);
    document.getElementById(nombre).style.display = "block"
	  var ventimp = window.open(' ', 'popimpr');
	  ventimp.document.write( ficha.innerHTML );
	  ventimp.document.close();
	  ventimp.print( );
    document.getElementById(nombre).style.display = "none"
	  ventimp.close();
  }

  getsenalUso(producto: any): any {
    return producto['pieSeñalUso'];
  }

  OpenCamera() {
    this.torchEnabled = true;
    (<HTMLAnchorElement>document.getElementById("openCamera_btn")).click();
    console.log("open");   
  }

  closeCamera() {
    this.torchEnabled = false;
    (<HTMLAnchorElement>document.getElementById("closeCamera_btn")).click();
    console.log("close");  
  }
  
  onCamerasFound(devices: any): void {
    console.log(devices)
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);

    if(this.hasDevices == false){
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Unsupported device',
        showConfirmButton: true
      }).then(() =>{
        this.closeCamera()
      })
    }
  }

  onCodeResult(resultString: any) {
    this.cargar = true;
    this.torchEnabled = false;
    (<HTMLInputElement>document.getElementById('tbxBuscar')).value = resultString;
    this.closeCamera();
    this.handlePageChange(1);
  }

  onDeviceSelectChange() {
    if( this.availableDevices.length > 1){
      const device = this.availableDevices.filter(x => x.deviceId != this.deviceCurrent.deviceId)[0];
      this.deviceCurrent = device || undefined;
    }
  }

  onDeviceChange(device: any) {
    const selectedStr = device?.deviceId || '';
    if (this.deviceSelected === selectedStr) { return; }
    this.deviceSelected = selectedStr;
    this.deviceCurrent = device || undefined;
  }


  onHasPermission(has: any) {
    this.hasPermission = has;
    console.log(has)
    if(has == false){
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'We do not have permission to open the camera',
        showConfirmButton: true
      }).then(() =>{
        this.closeCamera()
      })
    }
  }

  onTorchCompatible(isCompatible: any): void {
    this.torchAvailable$.next(isCompatible || false);
    console.log("compati"+isCompatible)
  }


  crearQR() {

    Swal.fire({
      title: 'Submit your Location code',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Create QR',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        if( result.value == "" || result.value == " "){
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'the location code is necessary',
            showConfirmButton: true
          }).then(() =>{
            this.crearQR() 
          })
        }else{
          Swal.isLoading()
          Swal.fire({
            imageUrl: `https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=${result.value}`,
            imageWidth: 200,
            imageHeight: 200,
            confirmButtonText: 'Print',
            showCancelButton: true,
          }).then((re) => {
            if (re.isConfirmed) {
              (<HTMLInputElement>document.getElementById("createLocation")).innerHTML = "";
              (<HTMLInputElement>document.getElementById("createLocation")).innerHTML += `
              <center>
                <img src="https://api.qrserver.com/v1/create-qr-code/?size=50x50&data=${result.value}" style="width:40%;"> 
                <p style="text-align: center; margin-left: 10px; margin-top: 0px;">${result.value}</p>
           </center>
              `;
              setTimeout(function () {
                document.getElementById("createLocation").style.display = "block"
                var ventimp = window.open(' ', 'popimpr');
                ventimp.document.write(document.getElementById("createLocation").innerHTML);
                ventimp.document.close();
    
                setTimeout(function () {
                  ventimp.print();
                  document.getElementById("createLocation").style.display = "none"
                  ventimp.close();
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: "Printed",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }, 500);
    
              }, 1000);
            }
          })
          !Swal.isLoading()
        }

      }
    })
  }
  

}
