<app-navbar></app-navbar>
<app-buscar></app-buscar>
<div class="p-5">

  <div *ngIf="totalg == 0; else guardados" class="text-center">
    <h3>Nothing saved</h3>
   <a href=""  [routerLink]="['/']"><img src="../../../assets/img/phvp2.png" width="500" height="250" alt=""> </a> 
  </div>
  <ng-template #guardados>
    <table class="table table-hover">
      <thead>
          <tr> 
            <th scope="col" class="text-primary">Imgen</th>
            <th scope="col" class="text-primary">Name</th>
            <th scope="col" class="text-primary">Price</th>
            <th scope="col" class="text-primary">View</th>
            <th scope="col" class="text-primary">Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr  *ngFor="let producto of productos; let i = index">
            <td><img src="{{producto.pieImagenes[0]}}" alt="" class="fit-image"></td>
            <td class="p-4">{{producto.pieNombre}}</td>
            <td class="p-4">{{producto.piePrecio | currency}}</td>
            <td class="p-3"><button class="btn btn-success btn-lg"  [routerLink]="['/producto', producto.pieId]" > <i class="fa fa-eye"></i></button></td>
            <td class="p-3"><button class="btn btn-danger btn-lg" (click)="postGuardar(producto)"><i class="fa fa-trash"></i></button></td>
          </tr>
        </tbody>
    </table>
  </ng-template> 
</div>
