<app-navbar></app-navbar>
<div class="row p-5">
  <div class="d-flex align-items-center">
    <nav
      style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
      aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="../" (click)="goBack()">My Tiress</a></li>
        <li class="breadcrumb-item active" aria-current="page">Add Tire</li>
      </ol>
    </nav>
  </div>

  <h1>New Tire</h1>
  <hr>
  <form [formGroup]="pieza" class="row g-3">

   
 
    <div class="col-12">
      <div class="row p-2">
        <div class="card border border-light col-lg-3 col-md-4 p-2" *ngFor="let img of urls">
          <a (click)="verImagen(img)" class="fit-image">
            <img class="fit-image" [src]="img" alt="" (click)="verImagen(img)" />
          </a>

          <div class="btnOverImg card-img-overlay text-cend">
            <a (click)="removeItemFromArr(img)" class="btn btn-danger"><i class="fa fa-trash"></i></a>
          </div>
        </div>
      </div>
      <button type="button" class="btn  btn-info text-white" (click)="chooseFile()">add image <i
          class="fa fa-image"></i></button>
      <input resize-quality="0.7" resize-type="image/jpg" id="fileInput" name="fileInput"
        style="height:0px;overflow:hidden" type="file" multiple accept="image/png,image/jpeg"
        (change)="detectFiles($event)">
      <div class="invalid-feedback">
        <div> Please enter at least one Image.</div>
      </div>
    </div>
    <div class="col-md-2">
      <label for="inputEmail4" class="form-label">Width</label>
      <input type="text" class="form-control" name="PieNombreWidth" id="PieNombreWidth" required />
      <div class="invalid-feedback">
        <div>Please enter Width.</div>
      </div>
    </div>
    <div class="col-md-2">
      <label for="inputEmail4" class="form-label">Height</label>
      <input type="text" class="form-control" name="PieNombreHeight" id="PieNombreHeight" required />
      <div class="invalid-feedback">
        <div>Please enter Height.</div>
      </div>
    </div>
    <div class="col-md-2">
      <label for="inputEmail4" class="form-label">Ring Size</label>
      <input type="text" class="form-control" name="PieNombreRing" id="PieNombreRing" required />
      <div class="invalid-feedback">
        <div>Please enter Ring Size.</div>
      </div>
    </div>
    <div class="col-md-6">
      <label for="inputPassword4" class="form-label">Manufacturer Tire Number</label>
      <input type="text" class="form-control" name="PartNumber" id="PartNumber">
    </div>
    <div class="col-12">
      <div class="mb-3">
        <label for="exampleFormControlTextarea1" class="form-label">Tire Description</label>
        <!-- <textarea class="form-control" value="{{producto.pieDescripcion}}" name="PieDescripcion" id="PieDescripcion" rows="3" required></textarea> -->
        <angular-editor id="PieDescripcion" formControlName="htmlContent" [(ngModel)]="htmlContent" [config]="config">
        </angular-editor>

        <div class="invalid-feedback">
          <div>Please enter Tire Description.</div>
        </div>
      </div>
    </div>
    
    <!-- <div class="col-md-4" >
        <label for="inputState" class="form-label">Parts</label>
        <select [disabled]="catListo" id="catId" class="form-select" aria-label="Default select example">
          <option selected value="{{producto.categoriaString}}" >{{producto.categoriaString}} (Actual)</option>
          <option *ngFor="let part of parts" [value]="part.piePreId">
            {{part.piePreNombre}}
          </option>
        </select>   
      </div> -->
    <div class="col-md-12 mt-5">
      <h3>General information</h3>
      <hr>
    </div>
    <div class="col-md-2">
      <label for="PiePrecio" class="form-label">Price</label>
      <input type="number" for="inputEmail4" class="form-control" id="PiePrecio" min="1" name="PiePrecio" required>
      <div class="invalid-feedback">
        <div>Please enter Price.</div>
      </div>
    </div>

    <div class="col-md-2">
      <label for="pieCosto" class="form-label">Cost </label>
      <input type="number" placeholder="$0" class="form-control" id="pieCosto" min="0" name="pieCosto" required>
      <div class="invalid-feedback">
        <div>Please enter Cost.</div>
      </div>
    </div>

    <div class="col-md-2">
      <label for="PieDisponible" class="form-label">Quantity On Hand </label>
      <input type="number" class="form-control" id="PieDisponible" min="1" name="PieDisponible" required>
      <div class="invalid-feedback">
        <div>Please enter Stoke.</div>
      </div>
    </div>


    <div class="col-md-2">
      <label for="pieLocation" class="form-label">Shelf/Location</label>
      <div class="input-group mb-3">
        <input placeholder="Shelf/Location" class="form-control" id="pieLocation" name="pieLocation">
        <button class="btn btn-primary text-white" type="button" (click)="OpenCamera()" ><i class="fa fa-qrcode" aria-hidden="true"></i></button>
      </div>
      <button hidden id="openCamera_btn" type="button" data-bs-toggle="modal" data-bs-target="#scanPopUp"></button>
    </div>

    <div class="col-md-2">
      <label for="pieMarcaFabricacion" class="form-label">Tire brand</label>
      <input placeholder="Part brand" class="form-control" id="pieMarcaFabricacion" name="pieMarcaFabricacion">
    </div>


    <!-- <div class="col-md-2">
          <label for="pieCostoEnvio" class="form-label">Shipping Cost </label>
          <input placeholder="$0" class="form-control" value="{{producto.pieCostoEnvio}}" id="pieCostoEnvio" name="pieCostoEnvio">
      </div>

      <div class="col-md-2">
          <label for="piePeso" class="form-label">Weight kg</label>
          <input placeholder="0 kg" class="form-control" value="{{producto.piePeso}}" id="piePeso" name="piePeso">
      </div> -->

    <div class="col-md-2">
      <label for="pieSeñalUso" class="form-label">Grade</label>
      <select id="pieSeñalUso" class="form-select" name="pieSeñalUso">
        <option value="A">A</option>
        <option value="B">B</option>
        <option value="C">C</option>
        <option value="D">D</option>
        <option value="E">E</option>
        <option value="F">F</option>
      </select>
    </div>

    <div class="col-md-2">
      <label for="inputState" class="form-label">Condition </label>

      <ng-multiselect-dropdown name="conditionf" [required]="'usoDescripcion'" formControlName="conditionf"
        [(ngModel)]="this.currentCondition" [placeholder]="'Select Condition'" [settings]="{      
          closeDropDownOnSelection: true, 
          singleSelection: true,
          idField: 'usoValor',
          textField: 'usoDescripcion',
          itemsShowLimit: 1,
          allowSearchFilter: true}" [data]="conditions"></ng-multiselect-dropdown>
    </div>
    <div class="col-md-2" style="position: relative; ">
      <label for="inputState" class="form-label">Warranty </label>
      <ng-multiselect-dropdown name="warrantyf" formControlName="warrantyf" [(ngModel)]="this.currentWarranty"
        [placeholder]="'Select Warranty'" [settings]="{      
              closeDropDownOnSelection: true,
              singleSelection: true,
              idField: 'usoValor',
              textField: 'usoDescripcion',
              itemsShowLimit: 1,
              allowSearchFilter: true}" [data]="warranty">
      </ng-multiselect-dropdown>
    </div>
    <div class="col-md-2" style="position: relative; ">
      <label for="inputState" class="form-label">Status </label>
      <ng-multiselect-dropdown name="statusf" formControlName="statusf" [(ngModel)]="this.currentStatus"
        [placeholder]="'Select status'" [settings]="{      
              closeDropDownOnSelection: true,
              singleSelection: true,
              idField: 'estEstado',
              textField: 'estDescripcion',
              itemsShowLimit: 1,
              allowSearchFilter: true}" [data]="status">
      </ng-multiselect-dropdown>
    </div>
  

    <div class="d-flex flex-row-reverse bd-highlight p-5">
      <div class="p-2 bd-highlight">
        <button type="submit" class="btn btn-warning btn-lg" (click)="Submit()"> <i class="fa fa-edit"></i> | Save <span
            *ngIf="loadingup" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
      </div>

      <div class="p-2 bd-highlight">
        <button type="button" class="btn btn-danger btn-lg text-white"
          (click)="goBack()">Cancel</button>
      </div>
    </div>
  </form>

  <div class="d-flex justify-content-center Divloader" style="z-index: 9999;" *ngIf="loadingup;">
    <div class="spinner-border text-white  m-3" role="status" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>


        <!-- ModalScan -->
        <div class="modal fade modal-xl" id="scanPopUp" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5">Scan Shelf/Location</h1>
              <button type="button" class="btn-close" (click)="closeCamera()"></button>
              <button hidden id="closeCamera_btn" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
    
            <div class="modal-body">
              <div *ngIf="torchEnabled">
                <zxing-scanner 
                [enable]="torchEnabled"
                [torch]="torchEnabled" 
                [device]="deviceCurrent" 
                (deviceChange)="onDeviceChange($event)"
                (scanSuccess)="onCodeResult($event)"
                [formats]="formatsEnabled" 
                [tryHarder]="false" 
                (permissionResponse)="onHasPermission($event)"
                (camerasFound)="onCamerasFound($event)" 
                (torchCompatible)="onTorchCompatible($event)">
                </zxing-scanner>
            
            
                <button *ngIf="availableDevices.length > 1" (click)="onDeviceSelectChange()" type="button" class="btn btn-secondary"><i class="fa fa-retweet" aria-hidden="true"></i></button>
            
              </div>
    
            </div>
          </div>
        </div>
      </div>

</div>