<app-navbar></app-navbar>


<div class="row">
  <form [formGroup]="myForm">
    <div class="m-2 mt-5 row">

      <!-- <div class="col-3 col-sm-3 col-md-1 col-lg-1  mt-4 ">
        <button (click)="clean()" class="btn btn-danger text-white"> <i class="fa fa-x"></i> Clear</button>
      </div> -->
      <div class="col-8 col-sm-8 col-md-10 col-lg-10 mt-4">
        <input type="text" class="form-control" id="tbxBuscar" placeholder="Search..." aria-label="Recipient's username"
          aria-describedby="button-addon2" (keydown.enter)="buscar()">
      </div>
  
      <div class="col col-sm col-md col-lg nav-item mt-3">
        <div class="form-check mb-3">
          <input class="form-check-input" type="checkbox" value="true" id="CheckFreeshp">
          <label class="form-check-label" for="flexCheckDefault">
            Free Shipping
          </label>
        </div>
        <div class="form-check ">
          <input class="form-check-input" type="checkbox" value="" id="CheckNearby">
          <label class="form-check-label" for="flexCheckChecked">
            Nearby item
          </label>
        </div>
      </div>
  
      <div class="col-4 col-sm-4 col-md-2 col-lg-2 nav-item mt-3">
        <h6 class="text-primary">Year</h6>

          <ng-multiselect-dropdown
          name="year"
          formControlName="year"
          [(ngModel)]="this.currentYear"
          [placeholder]="'Select year'"
          [settings]="{      
            closeDropDownOnSelection: true,
            singleSelection: true,
            idField: 'year',
            textField: 'year',
            itemsShowLimit: 1,
            allowSearchFilter: true}"
          [data]="years"
          appSelectAutoFoucs></ng-multiselect-dropdown>  
     
  
      </div>
  
  
      <div class="col-4 col-sm-4 col-md-2 col-lg-2 nav-item mt-3 ">
        <h6 class="text-primary ">Make</h6>

        <ng-multiselect-dropdown [disabled]="marListo"
        name="marcaf"
        formControlName="marcaf"
        [(ngModel)]="this.currentMarca"
        [placeholder]="'Select make'"
        [settings]="{   
          closeDropDownOnSelection: true,   
          singleSelection: true,
          idField: 'marID',
          textField: 'marDescripcion',
          itemsShowLimit: 1,
          allowSearchFilter: true}"
        [data]="marca"
        (onDeSelect)="UnSelectObject($event)"
        (onSelect)="cargarModelo($event)"
        appSelectAutoFoucs></ng-multiselect-dropdown> 
  
      </div>
  
      <div class="col-4 col-sm-4 col-md-2 col-lg-2 nav-item mt-3">
        <h6 class="text-primary">Model</h6>
  
        <ng-multiselect-dropdown
        name="modelof"
        formControlName="modelof"
        [(ngModel)]="this.currentModelo"
        [placeholder]="'Select model'"
        [settings]="{      
          closeDropDownOnSelection: true, 
          singleSelection: true,
          idField: 'modID',
          textField: 'modDescripcion',
          itemsShowLimit: 1,
          allowSearchFilter: true}"
        [data]="modelo"
        appSelectAutoFoucs></ng-multiselect-dropdown> 
  
      </div>
  
      <div class="col-4 col-sm-4 col-md-2 col-lg-2 nav-item mt-3">
        <h6 class="text-primary">Category</h6>

        <ng-multiselect-dropdown
        name="catf"
        formControlName="catf"
        [(ngModel)]="this.currentCategory"
        [placeholder]="'Select Category'"
        [settings]="{      
          closeDropDownOnSelection: true, 
          singleSelection: true,
          idField: 'catID',
          textField: 'catDescripcion',
          itemsShowLimit: 1,
          allowSearchFilter: true}"
        [data]="categoria"
        (onDeSelect)="UnSelectObject($event)"
        (onSelect)="cargarPart($event)"
        appSelectAutoFoucs></ng-multiselect-dropdown> 
      </div>
  
      <div class="col-4 col-sm-4 col-md-2 col-lg-2 nav-item mt-3">
        <h6 class="text-primary">Part</h6>

        <ng-multiselect-dropdown
        name="partf"
        formControlName="partf"
        [(ngModel)]="this.currentPart"
        [placeholder]="'Select Part'"
        [settings]="{      
          closeDropDownOnSelection: true, 
          singleSelection: true,
          idField: 'piePreId',
          textField: 'piePreNombre',
          itemsShowLimit: 1,
          allowSearchFilter: true}"
        [data]="parts"
        appSelectAutoFoucs></ng-multiselect-dropdown> 
      </div>
  
      <div class="col-4 col-sm-4 col-md-2 col-lg-2 nav-item mt-3">
        <h6 class="text-primary text-white">buscar</h6>
        <button (click)="buscar()" class="btn btn-primary text-white btnCuston"> 
          Search </button>
      </div>
  
      <hr style="border: black; border-width: 1px;"/>
  
    </div>
  </form>


</div>

<div class="row flex-nowrap pb-5">

  <div class="col py-0">


    <div *ngIf="loading" class="row p-2">
      <div class="row">
        <div class="col-6 col-sm-6 col-md-4 col-lg-3 portfolio-item my-1 rounded-lg">
          <app-ph class="p-3">
            (tipo)=''home''
          </app-ph>
        </div>

        <div class="col-6 col-sm-6 col-md-4 col-lg-3 portfolio-item my-1 rounded-lg">
          <app-ph class="p-3">
            (tipo)="home"
          </app-ph>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-3 portfolio-item my-1 rounded-lg">
          <app-ph class="p-3" >
            [tipo]="home"
          </app-ph>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-3 portfolio-item my-1 rounded-lg">
          <app-ph class="p-3" >
            [tipo]="home"
          </app-ph>
        </div>         
      </div>
    </div>

    <div *ngIf="noData" class="row p-2 px-0">
      <div class="d-flex justify-content-center">
        <h3>
          <span>
            Data not found.</span>
        </h3>
        <img src="../../../assets/img/phvp.png" width="500" height="500" alt="">
      </div>
    </div>

    <div *ngIf="result" class="row p-3">
      <div class="card mb-3"
        *ngFor="let resultado of resultados| paginate : { itemsPerPage: pageSize, currentPage: page, totalItems: count  }; let i = index">
          <a [routerLink]="['/producto', resultado.pieId]">
            <div class="row g-0" >
          <div class="col-5 col-sm-5 col-md-4 col-lg-4 d-flex align-items-center">
            <img src="{{resultado.imagen1}}"
              class="card-img-top ml-5 mt-2 mb-2 fit-image img-fluid rounded-start" alt="...">
          </div>
          <div class="col-7 col-sm-7 col-md-8 col-lg-8 d-flex align-items-center">
            <div class="card-body">
              <p class="Nombre-text">{{resultado.pieNombre}}</p>
          
              <p class="secom-text"><small class="text-muted">Shipping: {{resultado.pieCostoEnvio}}</small></p>
              <p class="Nombre-text">Price:<strong>{{resultado.piePrecio | currency}}</strong></p>
              <p class="secom-text"><small class="text-muted">Stock: {{resultado.pieDisponible}}</small></p>
            </div>
          </div>

        </div>
      </a>
      </div>
      <style>
        .my-pagination /deep/ .ngx-pagination .current {
          background: #008c44;
        }
      </style>
      <div class="col-12 mt-3 d-flex justify-content-center">
        <pagination-controls class="my-pagination" previousLabel="Anterior" nextLabel="Siguiente" [responsive]="true"
          (pageChange)="handlePageChange($event)"></pagination-controls>
      </div>

    </div>
  </div>
  <div class="row" style="display: none;">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">First</th>
          <th scope="col">Last</th>
          <th scope="col">Handle</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let marcas of marca;let i = index">
          <th scope="row">1</th>
          <td>Mark</td>
          <td>Otto</td>
          <td>d</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>