import { Component, OnInit } from '@angular/core';
import { ProductosService } from '../../services/productos.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  slideConfig = {"slidesToShow": 4, "slidesToScroll": 4,
  responsive: [
    {
    breakpoint: 576,
    settings: {
    slidesToShow: 2
    }
    }
  ]
};

  productos: any;
  recomendados: any;
  cargar = true;
  title = '';
  page = 1;
  count = 0;
  pageSize = 20;
  loadrecom = true;
  token: any;

  constructor(
    private _productosService: ProductosService,
    private router: Router
  ) {

   }

  ngOnInit(): void { 
    this.retrieveProductos();
    //this.GetRecomendadosNoLogin();
    // if(window.localStorage.getItem('token')){
    //   this.token = localStorage.getItem('token');  
    //   this.GetRecomendadosNoLogin();
    // }else{
    //   this.GetRecomendadosNoLogin();
    // }
  }

  getRequestParams(page: number, pageSize: number): any {
    let params: any = {};

    if (page) {
      params['pageNumber'] = page ;
    }
    
    return params;

  }

 slickInit(e) {
  }

  breakpoint(e) {
  }
  
  afterChange(e) {
  }

  beforeChange(e) {
  }  

  retrieveProductos(): void {
    //const params = this.getRequestParams(this.page);
    //console.log(params);
    this._productosService.getProductos(this.page)
    .subscribe(
      response => {
        const { results, totalRecordCount} = response;
        this.productos = results;
        this.count = totalRecordCount;
        this.cargar = false;
        console.log(this.productos)
      },
      error => {
        if (error.status == 401) {
          window.localStorage.removeItem('token');
          window.location.reload();
        }
        console.log(error);
      });
  }

  GetRecomendadosNoLogin(): void  {
    this._productosService.getRecomendadosNoLogin().subscribe(
      res =>{ 
        this.loadrecom = false;
        this.recomendados = res;
        console.log(this.recomendados); 
           },
      err =>{
        console.log(err);
      }
    )
  }

  GetRecomendados(): void  {
    this._productosService.getRecomendadosNoLogin().subscribe(
      res =>{ 
        this.loadrecom = false;
        this.recomendados = res;
      },
      err =>{
        if (err.status == 401) {
          window.localStorage.removeItem('token');
          window.location.reload();
        }
        console.log(err);
      }
    )
  }


  handleCarouselEvents(event:any) {
	}

  handlePageChange(event: number): void {
    this.page = event;
    this.startup();
    this.retrieveProductos();

  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveProductos();
  }

  startup(){
    window.scroll(0, 0); 
    this.cargar = true;
  }
  
}
