import { Component, ElementRef, OnInit, } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductosService, datosBuscar,Modelo } from '../../services/productos.service';
import Swal from 'sweetalert2';
import { LocationService } from '../../services/location.service';


@Component({
  selector: 'app-busqueda',
  templateUrl: './busqueda.component.html',
  styleUrls: ['./busqueda.component.scss']
})
export class BusquedaComponent implements OnInit {

  currentYear: string[] = [];
  currentMarca: any[] =[];
  currentModelo: any[] =[];
  currentCategory: any[] = [];
  currentPart: any[] =[];

  myForm:FormGroup;

  years: number[]= [];
  marListo=true;
  catListo=true;
  idmodelo:any;
  categoria:any;
  marca:any;
  modelo:any;
  parts: any;
  resultados: any;
  result=false
  loading = false;
  noData = true;
  latitude: string;
  longitude: string;

  page = 1;
  count = 0;
  pageSize = 20;
 
  datosBuscar : datosBuscar = {

    buscar: '',
    cat: 0, 
    mo: '',
    mar: '',
    anos: '',
    freeshipping: false,
    ubicacion: '',
    pageNumber: 1
  

  };

  Modelo: Modelo ={

    idMarca : 0,
    marcastring: ""
   
  }

  constructor( private auth: ProductosService, private _locationService: LocationService, private _fb: FormBuilder) { }

  ngOnInit(): void {

       let elementReference = <HTMLOptionElement>document.getElementById('tbxBuscar');
        elementReference.focus();

        this.myForm = this._fb.group({
          year:this.currentYear,
          marcaf: this.currentMarca,
          modelof: this.currentModelo,
          catf: this.currentCategory,
          partf: this.currentPart,
      });

       this.startup();
       this.getMarca();
       this. getCategoria();
       this.selectYear();
       this.getLocation();


  }

  getLocation(){

    this._locationService.getPosition().then(pos => {
      this.latitude = pos.lat;
      this.longitude = pos.lng; });

      }

  getRequestParams(page: number): any {
    let params: any = {};

    if (page) {
      params['pageNumber'] = page ;
    }
    
    return params;

  }

 selectYear(){

      var fully = new Date().getFullYear();
      for(let y = fully; y >= 1970; y--){
      this.years.push(y);
      console.log(y);
    }
    
 }

  buscar(): void{
    this.startup();
    this.result = false;
    this.loading = true;
    this.noData = false;
    if ((<HTMLInputElement>document.getElementById('CheckFreeshp')).checked)
    {
      this.datosBuscar.freeshipping= true;
    }else{
      this.datosBuscar.freeshipping= false;
    }
    if((<HTMLInputElement>document.getElementById('CheckNearby')).checked)
    {
      this.datosBuscar.ubicacion = String(this.latitude +',' + this.longitude);
    }else{
      this.datosBuscar.ubicacion = '';
    }

    this.datosBuscar.buscar = (<HTMLInputElement>document.getElementById('tbxBuscar')).value;
    if (this.currentMarca.length == 0){
      console.log("vacio");
      this.datosBuscar.mar="";
    } else{
      this.datosBuscar.mar= this.currentMarca[0].marDescripcion;
    }
    console.log(this.currentModelo);
    if (this.currentModelo.length == 0){
      this.datosBuscar.mo="";
    } else{
      this.datosBuscar.mo= this.currentModelo[0].modDescripcion;
    }
    if (this.currentYear.length == 0){
      this.datosBuscar.anos="";
    }else{
      this.datosBuscar.anos= this.currentYear[0]; 
    }
    
    this.datosBuscar.pageNumber = this.page;
    if (this.datosBuscar.buscar == '' && this.datosBuscar.mar == '' && this.datosBuscar.mo == '' &&  this.datosBuscar.freeshipping == false && this.datosBuscar.ubicacion == ''){ 
    /*  
      this.noData = true;
       Swal.fire({
         icon: 'error',
         title: 'Oops...',
        text: 'Ingrese algun parametro de busqueda!',
       }) */
       this.loading = false;
       return;
    }else{
      this.startup();
      this.auth.getBuscar(this.datosBuscar).subscribe( ( res: any )=>{
        this.loading = false;
        this.result = true;
        const { results, totalRecordCount} = res;
        this.resultados = results;
        this.count = totalRecordCount;
        //(<HTMLInputElement>document.getElementById('pDespc')).innerHTML = results.pieDescripcion;
      },(err) =>{
        this.loading = false;
        this.noData = true;
        console.log(err); 
      });
    }
  }

  getMarca(){
    this.auth.getMarca().subscribe(
      res =>{
        this.marListo=false;
        this.marca=res;
        console.log(this.marca)
      }, err =>{
       console.log(err);
      })
  }
  

  getCategoria(){
    this.auth.getCategoria().subscribe(
      res =>{
        this.catListo=false;
        this.categoria=res;
       // console.log(res);
      }, err =>{
       console.log(err);
      })
}

cargarPart(item: any){
  this.currentPart = [];
 this.auth.getPart(item.catID).subscribe(
   (res: any) =>{
     this.parts =res;
     console.log(res);

   }, err =>{
    console.log(err);
   })
}


  cargarModelo(item: any){
    
    this.Modelo.idMarca = item.marID;
    this.currentModelo = [];
    this.auth.getModelo(this.Modelo).subscribe(
      res =>{
        this.modelo=res;
         console.log(this.modelo);
      }, err =>{
       console.log(err);
      }
    )
  }

  handleCarouselEvents(event:any) {
		console.log(event);
	}

  handlePageChange(event: number): void {
    this.page = event;
    this.buscar();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.buscar();
  }

  startup(){
    window.scroll(0, 0); 

  }
  clean(){
    window.location.reload(); 

  }

  SelectObject(item: any){
    console.log(this.currentYear);
   // this.currentYear
    // var tipo = (typeof item)
    // //console.log(item['marID']);
  
    // if(tipo == "number"){
    //   //this.currentYear = item;
    // }
    // else if(item['marID'] && item['marDescripcion']){
    //   this.currentMarca = item;
    //   this.cargarModelo(item.marID);
    // }

   // console.log(item);
  }

  UnSelectObject(item: any){

    console.log(this.currentMarca)
    if(this.currentMarca.length == 0){
      this.currentModelo = [];
      this.modelo =  null;
    }

    if(this.currentCategory.length == 0){
      this.currentPart = [];
      this.parts =  null;
    }

  }
}
