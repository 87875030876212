import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductosService, Modelo } from '../../../services/productos.service';
import { AdminService } from '../../../services/admin.service';
import { UsuarioService } from '../../../services/usuario.service';
import { Usuario } from '../../../Entities/Usuario';
import { Pieza } from '../../../Entities/Pieza';
import { TerminosDePagos } from '../../../Entities/TerminosDePagos';
import { Facturas, PostFacturaViewModel } from '../../../Entities/Factura';
import { piezasBuscar } from '../../../Entities/Pieza';
import { FacturasDetalleTemp } from '../../../Entities/FacturaDetalle';
import { CommunicationServiceService } from '../../../services/communication-service.service';

@Component({
  selector: 'app-store-sale',
  templateUrl: './store-sale.component.html',
  styleUrls: ['./store-sale.component.scss']
})
export class StoreSaleComponent implements OnInit {

  constructor(private _productosService: ProductosService,
    private router: Router,
    private _usuService: UsuarioService,
    private _adminService: AdminService,
    private communicationService: CommunicationServiceService,
    private _activeRoute: ActivatedRoute,
    private _fb: FormBuilder) { }

  piezasAgregadas: any[] = [];
  currentPieza = new Pieza();
  cliente = new Usuario();
  loading = false;
  posicion = 0;
  Requestquantity = 1;
  terminosPagoList: any[] = [];
  terminoPago: any[] = [];


  //currentStatus: any[] = [];
  currentYear: string[] = [];
  currentMarca: any[] = [];
  currentModelo: any[] = [];
  currentCategory: any[] = [];
  currentPart: any[] = [];

  myForm: FormGroup;
  noData = false;
  years: number[] = [];
  marListo = true;
  catListo = true;
  categoria: any;
  marca: any;
  modelo: any;
  parts: any;
  productos: any[] = [];
  //status: any[] = [];
  page = 1;
  count = 0;
  pageSize = 20;

  mispiezas = new piezasBuscar();
  Modelo: Modelo = {

    idMarca: 0,
    marcastring: ""

  }

  total = 0;
  subTotal = 0;

  routeId = 0;

  ngOnInit(): void {
    var parametro = this._activeRoute.snapshot.queryParamMap.get('routeId');
    if (parametro == null) {
      setTimeout(() => {
        (<HTMLButtonElement>document.getElementById('advanced_btn')).click();
      }, 600);
    }
    else {
      this.routeId = parametro as unknown as number
    }

    this.communicationService.componentMethodCalled.subscribe(
      res => {
        if (res == 'deleteFromcar') {
          this.getFacturaTemp();
        }
      })
    document.getElementById("SearchPopUp").addEventListener('shown.bs.modal', () => {
      if (this.productos.length == 0) {
        this.BuscarMispiezas(true);
      }
      (<HTMLInputElement>document.getElementById('tbxBuscar')).focus();
    })

    document.getElementById("setEditModal").addEventListener('hidden.bs.modal', () => {
      this.currentPieza = new Pieza();
    })

    document.getElementById("setSoliCantModal").addEventListener('hidden.bs.modal', () => {
      (document.getElementById("quantity_ip") as HTMLOptionElement).value = "";
      setTimeout(function () {
        (document.getElementById("quantity_ip") as HTMLElement).focus();
      }, 1000);;
    })

    this.myForm = this._fb.group({
      year: this.currentYear,
      marcaf: this.currentMarca,
      modelof: this.currentModelo,
      catf: this.currentCategory,
      partf: this.currentPart,
      //statusf: this.currentStatus
    });
    this.getFacturaTemp();
    //this.Mispiezas(false);
    this.getMarca();
    this.getCategoria();
    //this.getStatus();
    this.selectYear();

    //this.BuscarMispiezas(true);
  }


  compareNumbers(a, b) {
    return a.posicion - b.posicion;
  }

  SearchMainEnter(event, isMain) { //0-Main,1-Quantity, 2-custiomer
    var keycode = event.keyCode;
    if (keycode == '13') {

      switch (isMain) {
        case 0:
          this.CheckRequestQuantity();
          break;
        case 1:
          this.SendRequestQuantity();
          break;
        case 2:
          this.searchUser();
          break;
      }

    }
  }

  AddPieza(pieza: Pieza) {

    var loa = Swal.fire({
      title: 'Loading',
      showConfirmButton: false,
      allowOutsideClick: false,
      html: 'Please wait',
      timerProgressBar: true,
    })
    try {
      console.log(pieza.facCantidad);
      if (pieza.facCantidad == null)
        pieza.facCantidad = 1;
      console.log(pieza.facCantidad);
      var temp = new FacturasDetalleTemp();

      temp.pieID = pieza.pieId;
      temp.facPrecio = pieza.piePrecio;
      temp.facCantidad = pieza.facCantidad;
      this._adminService.PostFacturaDetalleTemp(temp).subscribe(
        response => {
          Swal.fire({
            icon: 'success',
            title: pieza.pieNombre + "added",
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.getFacturaTemp();
          })
        }, (err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.error,
            showConfirmButton: true,
          })

        });
    } catch (TypeError) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: TypeError as string,
        showConfirmButton: true,
      });
      console.log(TypeError);
      (document.getElementById("SearchMain_ip") as HTMLOptionElement).disabled = false;
      (document.getElementById("SearchMain_ip") as HTMLOptionElement).value = "";
      (document.getElementById("SearchMain_ip") as HTMLOptionElement).focus();
    }
    (document.getElementById("SearchMain_ip") as HTMLOptionElement).value = "";
    (document.getElementById("SearchMain_ip") as HTMLOptionElement).focus();

    // (document.getElementById("SearchMain_ip") as HTMLOptionElement).value = String(pieza.pieId);
    // this.buscarByCode();
  }

  buscarByCode() {

    if (this.loading == false) {
      this.loading = true;
      //(document.getElementById("SearchMain_ip") as HTMLOptionElement).disabled = true;
      try {

        var busqueda = (document.getElementById("SearchMain_ip") as HTMLOptionElement).value;
        if (busqueda == "" || busqueda == " " || busqueda == null) {
          throw new TypeError("Required fields");
        }

        this._productosService.getMiProducto(busqueda).subscribe(
          response => {
            response.facCantidad = this.Requestquantity;
            this.loading = false;
            this.AddPieza(response);
          }, (err: any) => {

            if (err.error == 'You do not have permissions to access to Parts') {
              Swal.fire({
                icon: 'error',
                title: err.error,
                showConfirmButton: true
              }).then(() => {
                this.router.navigateByUrl('/Dashboard');
              })
            } else {
              Swal.fire({
                icon: 'error',
                title: err.error,
                showConfirmButton: true
              })
            }
            console.log(err);
            this.loading = false;
            (document.getElementById("SearchMain_ip") as HTMLOptionElement).disabled = false;
            (document.getElementById("SearchMain_ip") as HTMLOptionElement).value = "";
            (document.getElementById("SearchMain_ip") as HTMLOptionElement).focus();

          })

      } catch (TypeError) {
        this.loading = false;
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: TypeError as string,
          showConfirmButton: true,
        });
        console.log(TypeError);
        (document.getElementById("SearchMain_ip") as HTMLOptionElement).disabled = false;
        (document.getElementById("SearchMain_ip") as HTMLOptionElement).value = "";
        (document.getElementById("SearchMain_ip") as HTMLOptionElement).focus();
      }
      (document.getElementById("SearchMain_ip") as HTMLOptionElement).value = "";
      (document.getElementById("SearchMain_ip") as HTMLOptionElement).focus();

    }

  }

  CheckRequestQuantity() {

    if ((document.getElementById("soliCant_ck") as HTMLInputElement).checked == true) {
      (document.getElementById("openSoli_btn") as HTMLInputElement).click();
    }

    else {
      this.Requestquantity = 1
      this.buscarByCode();
    }

  }

  SendRequestQuantity() {
    var qty = (document.getElementById("quantity_ip") as HTMLInputElement).value;
    var qtyNumber = Number(qty);
    if (qtyNumber == 0)
      this.Requestquantity = 1
    else
      this.Requestquantity = qtyNumber;


    (document.getElementById("closeSoliCantModal") as HTMLInputElement).click();
    this.buscarByCode();
  }


  Calculare() {
    this.subTotal = 0;
    this.piezasAgregadas.forEach((elem) => {
      this.subTotal = this.subTotal + elem.facTotal;
    })
    this.total = this.subTotal;

  }

  DeletePart(part: any) {

    Swal.fire({
      title: "Remove Part",
      text: "Are you sure you want to remove this part?",
      icon: "warning",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      confirmButtonColor: "red",
      confirmButtonText: "Remove Part",
    }).then((result) => {

      if (result.isConfirmed) {
        var loa = Swal.fire({
          title: 'Loading',
          showConfirmButton: false,
          allowOutsideClick: false,
          html: 'Please wait',

          timerProgressBar: true,
        })
        var temp = new FacturasDetalleTemp();
        temp.pieID = part.pieId;
        this._adminService.DeleteFacturaDetalleTemp(part).subscribe(
          res => {
            this.getFacturaTemp();
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Has been deleted.',
              showConfirmButton: false,
              timer: 1000
            })
          }, err => {
            console.log(err);
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: err.error,
              showConfirmButton: true
            })
          }
        )

      }
    });
  }


  getsenalUso(producto: any): any {
    return producto['pieSeñalUso'];
  }

  verImagen(img): void {
    Swal.fire({
      imageUrl: img,
      imageWidth: '100%',
      imageHeight: '100%',
    })
  }

  searchUser() {
    this.loading = true;
    try {

      var busqueda = (document.getElementById("customerSearch_im") as HTMLOptionElement).value;
      if (busqueda == "" || busqueda == " " || busqueda == null) {
        throw new TypeError("Required fields");
      }

      this._usuService.getUserStore(busqueda).subscribe(
        response => {
          console.log(response);
          this.cliente = response;

          (document.getElementById("borrarCli_btn") as HTMLOptionElement).style.display = "block";
          (document.getElementById("customerSearch_im") as HTMLOptionElement).value = "";
          (document.getElementById("closeCustomerModal") as HTMLOptionElement).click();
          this.loading = false;
          Swal.fire({
            position: 'top-end',
            icon: "success",
            showConfirmButton: false,
            timer: 1500
          })
        }, (err: any) => {

          if (err.error == 'You do not have permissions to access to Parts') {
            Swal.fire({
              icon: 'error',
              title: err.error,
              showConfirmButton: true
            }).then(() => {
              this.router.navigateByUrl('/Dashboard');
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: err.error,
              showConfirmButton: true
            })
          }
          console.log(err);
          this.loading = false;
        })

    } catch (TypeError) {
      this.loading = false;
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: TypeError as string,
        showConfirmButton: true,
      });
      console.log(TypeError);
    }
  }

  RemoveCustomer(): void {
    Swal.fire({
      title: "Remove Customer",
      text: "Are you sure you want to remove this Customer?",
      icon: "warning",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      confirmButtonColor: "red",
      confirmButtonText: "Remove Customer",
    }).then((result) => {

      if (result.isConfirmed) {
        (document.getElementById("borrarCli_btn") as HTMLOptionElement).style.display = "none";
        this.cliente = new Usuario();
      }
    });

  }

  CheckClick(): void {
    if ((document.getElementById("soliCant_ck") as HTMLInputElement).checked == true)
      (document.getElementById("soliCant_ck") as HTMLInputElement).checked = false;
    else
      (document.getElementById("soliCant_ck") as HTMLInputElement).checked = true;

    (document.getElementById("SearchMain_ip") as HTMLOptionElement).value = "";
    (document.getElementById("SearchMain_ip") as HTMLOptionElement).focus();
  }

  EditPartClick(part: any): void {
    this.currentPieza = part;
    (document.getElementById("openEditModal_btn") as HTMLInputElement).click();

  }

  UpdatePartClick(): void {

    try {

      var qty = (document.getElementById("editQty_ip") as HTMLOptionElement).value;
      if (qty == "" || qty == " " || qty == null) {
        throw new TypeError("Quantity is Required");
      }

      var precio = (document.getElementById("editPrice_ip") as HTMLOptionElement).value;
      if (precio == "" || precio == " " || precio == null) {
        throw new TypeError("Price is Required");
      }

      this.currentPieza.facCantidad = Number(qty);
      this.currentPieza.piePrecio = Number(precio);

      this.AddPieza(this.currentPieza);


      (document.getElementById("closeEditModal") as HTMLOptionElement).click();

    } catch (TypeError) {
      this.loading = false;
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: TypeError as string,
        showConfirmButton: true,
      });
      console.log(TypeError);
    }
  }

  GetTerminosPagos() {
    this.loading = true;
    try {

      if (this.piezasAgregadas.length == 0) {
        throw new TypeError("any part added");
      }

      this._adminService.getTerminosDePagos().subscribe(
        response => {
          console.log(response);
          this.terminosPagoList = response;

          (document.getElementById("openTermino_btn") as HTMLOptionElement).click()
          this.terminoPago = [this.terminosPagoList[0]]

          this.changePaid();
          this.loading = false;
        }, (err: any) => {

          if (err.error == 'You do not have permissions to access to Parts') {
            Swal.fire({
              icon: 'error',
              title: err.error,
              showConfirmButton: true
            }).then(() => {
              this.router.navigateByUrl('/Dashboard');
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: err.error,
              showConfirmButton: true
            })
          }
          console.log(err);
          this.loading = false;
        })

    } catch (TypeError) {
      this.loading = false;
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: TypeError as string,
        showConfirmButton: true,
      });
      console.log(TypeError);
    }
  }

  PostFactura() {
    this.loading = true;
    try {

      if (this.piezasAgregadas.length == 0) {
        throw new TypeError("any part added");
      }

      var Postfac = new PostFacturaViewModel();
      var fac = new Facturas();

      fac.facTotal = this.total;
      fac.usuID = this.cliente.usuId;
      fac.facEmail = this.cliente.usuEmail;
      fac.facTelefono = this.cliente.usuTelefonoBill;
      fac.facSubTotal = this.subTotal;
      fac.facEfectivo = Number((document.getElementById("paidTerminar_ip") as HTMLOptionElement).value)
      fac.facCambio = Number((document.getElementById("retornedTerminar_ip") as HTMLOptionElement).value)

      Postfac.Factura = fac;
      Postfac.Piezas = this.piezasAgregadas;
      Postfac.TerminosPagos = this.terminoPago;


      this._adminService.PostFactura(Postfac).subscribe(
        response => {
          console.log(response);

          var x = (screen.width / 2) - (1000 / 2);
          //Ajustar verticalmente
          var y = (screen.height / 2) - (800 / 2);
          window.open('https://vehiclepart.shop/#/invocesDetail/' + response, 'popup', 'width=1000 , height=800,location=no,resizable=no,' + 'left=' + x + ', top=' + y + '');
          window.location.reload();
        }, (err: any) => {

          if (err.error == 'You do not have permissions to access to Parts') {
            Swal.fire({
              icon: 'error',
              title: err.error,
              showConfirmButton: true
            }).then(() => {
              this.router.navigateByUrl('/Dashboard');
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: err.error,
              showConfirmButton: true
            })
          }
          console.log(err);
          this.loading = false;
        })

    } catch (TypeError) {
      this.loading = false;
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: TypeError as string,
        showConfirmButton: true,
      });
      console.log(TypeError);
    }
  }


  changePaid(): void {

    var devuelto = Number((document.getElementById("paidTerminar_ip") as HTMLInputElement).value);
    (document.getElementById("retornedTerminar_ip") as HTMLInputElement).value = `${(devuelto - this.total)}`;

  }

  BuscarMispiezas(limpiar) {
    this.productos = null;
    this.noData = false;
    this.loading = true;
    if (limpiar) {
      this.currentMarca = [];
      this.currentModelo = [];
      this.currentYear = [];
      //this.currentStatus = [];
      this.currentPart = [];
      this.currentCategory = [];
      (<HTMLInputElement>document.getElementById('tbxBuscar')).value = "";
      this.mispiezas.pageNumber = 1;
    }

    this.mispiezas.nombre = (<HTMLInputElement>document.getElementById('tbxBuscar')).value;
    if (this.currentMarca.length == 0) {
      this.mispiezas.mar = "";
    } else {
      this.mispiezas.mar = this.currentMarca[0].marDescripcion;
    }

    if (this.currentModelo.length == 0) {
      this.mispiezas.mo = "";
    } else {
      this.mispiezas.mo = this.currentModelo[0].modDescripcion;
    }

    if (this.currentYear.length == 0) {
      this.mispiezas.anos = "";
    } else {
      this.mispiezas.anos = this.currentYear[0];
    }

    // if (this.currentStatus.length == 0) {
    //   this.mispiezas.status = 0;
    // } else {
    //   this.mispiezas.status = 3;
    // }
    this.mispiezas.status = 3;

    if (this.currentCategory.length == 0) {
      this.mispiezas.cat = 0;
    } else {
      this.mispiezas.cat = this.currentCategory[0].catID;
    }

    this._productosService.Mipiezas(this.mispiezas).subscribe(
      response => {
        console.log(response)
        const { results, totalRecordCount } = response;
        this.productos = results;
        this.count = totalRecordCount;
        this.loading = false;
      }, (err: any) => {
        if (err.error == 'Carga Completa') {
          // this.cargar = false;
          if (this.page == 1) {
            this.productos = [];
            this.count = 0;
          }
        }
        else {

          if (err.error == 'You do not have permissions to access to Parts') {
            Swal.fire({
              icon: 'error',
              title: err.error,
              showConfirmButton: true
            }).then(() => {
              this.router.navigateByUrl('/Dashboard');
            })
          }

          this.productos = [];
          this.count = 0;
          console.log(err)
          this.noData = true;

        }
        this.loading = false;

      })
  }

  selectYear() {

    var fully = new Date().getFullYear();
    for (let y = fully; y >= 1970; y--) {
      this.years.push(y);

    }

  }

  getCategoria() {
    this.catListo = true;
    this._productosService.getCategoria().subscribe(
      res => {
        this.catListo = false;
        this.categoria = res;
        // console.log(res);
      }, err => {
        console.log(err);
      })
  }

  // getStatus() {
  //   this._productosService.getStatus("Piezas").subscribe(
  //     res => {
  //       this.status = res;

  //     }, err => {
  //       console.log(err);
  //     })
  // }

  getMarca() {
    this._productosService.getMarca().subscribe(
      res => {
        this.marListo = false;
        this.marca = res;
        console.log(this.marca)
      }, err => {
        console.log(err);
      })
  }

  cargarModelo(item: any) {

    this.Modelo.idMarca = item.marID;
    this.currentModelo = [];
    this._productosService.getModelo(this.Modelo).subscribe(
      res => {
        this.modelo = res;
        console.log(this.modelo);
      }, err => {
        console.log(err);
      }
    )
  }

  UnSelectObject(item: any) {

    console.log(this.currentMarca)
    if (this.currentMarca.length == 0) {
      this.currentModelo = [];
      this.modelo = null;
    }
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.mispiezas.pageNumber = event;
    this.BuscarMispiezas(false);
  }

  getFacturaTemp() {
    var loa = Swal.fire({
      title: 'Loading',
      showConfirmButton: false,
      allowOutsideClick: false,
      html: 'Please wait',

      timerProgressBar: true,
    })
    this._adminService.getFacturaDetalleTempByParts().subscribe(
      res => {
        this.piezasAgregadas = res;
        this.Calculare();
        this.communicationService.callComponentMethod("update");
        Swal.close();
        if (this.routeId > 0) {
          var part = this.piezasAgregadas.find((element) => element.pieId == this.routeId)
          setTimeout(() => {
            this.EditPartClick(part);
            this.routeId = 0;
          }, 500);
        }
      },
      err => {
        Swal.close();
        console.error(err)
        Swal.fire({
          icon: 'error',
          title: err.error,
          showConfirmButton: true,
        })
      }
    )
  }

  openNewCustomer() {

    if (document.getElementById("searchCustumer_dv").style.display == "block") {

      document.getElementById("customerTitle").innerText = "Add Customer";
      document.getElementById("searchCustumer_dv").style.display = "none";
      document.getElementById("addCustumer_dv").style.display = "block";
      document.getElementById("closeCustumer_btn").style.display = "none";

      (<HTMLInputElement>document.getElementById("firstNameC_ip")).value = "";
      (<HTMLInputElement>document.getElementById("lastNameC_ip")).value = "";
      (<HTMLInputElement>document.getElementById("phoneC_ip")).value = "";
      (<HTMLInputElement>document.getElementById("emailC_ip")).value = "";

      (<HTMLInputElement>document.getElementById("addressC_ip")).value = "";
      (<HTMLInputElement>document.getElementById("cityC_ip")).value = "";
      (<HTMLInputElement>document.getElementById("stateC_ip")).value = "";
      (<HTMLInputElement>document.getElementById("zipCodeC_ip")).value = "";
    }
    else {
      document.getElementById("customerTitle").innerText = "Set Customer";
      document.getElementById("searchCustumer_dv").style.display = "block";
      document.getElementById("addCustumer_dv").style.display = "none";
      document.getElementById("closeCustumer_btn").style.display = "block";
    }
  }

  newCustomer() {
    this.loading = true;
    try {
      if ((<HTMLInputElement>document.getElementById("firstNameC_ip")).value == "") {
        throw new TypeError("First name is necessary");
      }

      if ((<HTMLInputElement>document.getElementById("lastNameC_ip")).value == "") {
        throw new TypeError("Last name is necessary");
      }

      if ((<HTMLInputElement>document.getElementById("phoneC_ip")).value == "") {
        throw new TypeError("Telephone is necessary");
      }

      var customer = new Usuario();

      customer.usuNombres = (<HTMLInputElement>document.getElementById("firstNameC_ip")).value;
      customer.usuApellidos = (<HTMLInputElement>document.getElementById("lastNameC_ip")).value;
      customer.usuTelefonoBill = (<HTMLInputElement>document.getElementById("phoneC_ip")).value;
      customer.usuEmail = (<HTMLInputElement>document.getElementById("emailC_ip")).value;
      customer.usuDireccionBill = (<HTMLInputElement>document.getElementById("addressC_ip")).value;
      customer.usuCiudadBill = (<HTMLInputElement>document.getElementById("cityC_ip")).value;
      customer.usuStateBill = (<HTMLInputElement>document.getElementById("stateC_ip")).value;
      customer.usuZipCodeBill = (<HTMLInputElement>document.getElementById("zipCodeC_ip")).value;
      customer.paiIDBill = 1;

      this._adminService.PostCustomer(customer).subscribe(
        response => {
          console.log(response);
          this.loading = false;
          (<HTMLInputElement>document.getElementById("customerSearch_im")).value = customer.usuEmail;  
          this.openNewCustomer();     
          this.searchUser();
        }, (err: any) => {
          console.log(err);
          if (err.error == 'You do not have permissions to access to Parts') {
            Swal.fire({
              icon: 'error',
              title: err.error,
              showConfirmButton: true
            }).then(() => {
              this.router.navigateByUrl('/Dashboard');
            })
          } else if (err.error.includes('There is already an account with the')) {

            Swal.fire({
              icon: 'error',
              position: 'top-end',
              title: err.error,
              showConfirmButton: true,
              showCancelButton: true,
              cancelButtonText: "Edit",
              confirmButtonText: "Select"
            }).then((ed) => {
              if (ed.isConfirmed) {
                if (err.error.includes('There is already an account with the e-mail')) {
                  (<HTMLInputElement>document.getElementById("customerSearch_im")).value = customer.usuEmail;
                } else {
                  (<HTMLInputElement>document.getElementById("customerSearch_im")).value = customer.usuTelefonoBill;
                }
                this.openNewCustomer();
                this.searchUser();
              }
            })
          }
          else {
            Swal.fire({
              icon: 'error',
              title: err.error,
              showConfirmButton: true
            })
          }
          console.log(err);
          this.loading = false;
        })

    }
    catch (error) {
      Swal.fire({
        position: 'top-end',
        icon: "error",
        title: error
      })
      this.loading = false;
    }
  }

}
