<app-navbar></app-navbar>
<div class="row p-2 d-flex align-items-center " *ngIf="!loading; ">
  <div class="d-flex align-items-center">
    <nav
      style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
      aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/Dashboard/permit"> Permissions</a></li>
        <li class="breadcrumb-item active" aria-current="page">Add Permission</li>
      </ol>
    </nav>
  </div>
  <h1 class="text-primary">Add Permission
  </h1>
  <hr>

  <form [formGroup]="myForm">
    <div class="row">
      <div class="col-md-3">
        <label for="inputEmail4" class="form-label"> Permission Description</label>
        <input placeholder="Description" type="text" class="form-control" id="PDescription">
      </div>


      <div class="d-flex flex-row-reverse bd-highlight p--0">

        <div class="p-2 bd-highlight"><button type="submit" (click)="Submit()"
            class="btn btn-primary btn-lg text-white ">
            Save Permission

            <i class="fa fa-floppy-o"></i></button></div>

        <div class="p-2 bd-highlight">
          <button class="btn btn-danger btn-lg text-white" routerLink="/Dashboard/permit">
            <i class="fa fa-times"></i> Cancel</button>
        </div>

      </div>

    </div>
  </form>

</div>

<div class="d-flex justify-content-center" *ngIf="loading;">
  <div class="spinner-border text-primary  m-3" role="status" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>